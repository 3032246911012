import { Box, styled, Typography } from "@material-ui/core";
import MyPlatformSubscription from "../../../subscriptionbilling2/src/MyPlatformSubscription.web";
import React, { useState } from "react";
import PaidContent from "../../../subscriptionbilling2/src/PaidContent.web";
import { TAny } from "../../../../components/src/utils";
import MyGroupsSubscription from "../../../subscriptionbilling2/src/MyGroupsSubscription.web";

interface IPropMyListSubscriptionType {
    navigation: TAny;
    checked: boolean;
}

const MyListSubscriptionType = (props: IPropMyListSubscriptionType) => {
    const [currentIndexType, setCurrentIndexType] = useState(0);

    const listTypeSubscription = [
        {
            id: "platform",
            label: "Platform",
            component: <MyPlatformSubscription id="" navigation={props.navigation} checked={props.checked}/>,
        },
        {
            id: "paidContent",
            label: "Paid Content",
            component: <PaidContent id="" navigation=""/>,
        },
        {
            id: "groupChats",
            label: "Group Chats",
            component: <MyGroupsSubscription id="" navigation={props.navigation} checked={props.checked}/>,
        },
    ]

    const handleSelectTab = (indexTab: number) => {
        if (currentIndexType !== indexTab)
            setCurrentIndexType(indexTab);
    }
    
    return (
        <MyListSubscriptionTypeStyled>
            <Box className="listTabSubscription">
                {
                    listTypeSubscription.map((type, index) => (

                        <Box
                            key={type.id}
                            className={`tabSubscription ${index === currentIndexType && "tabSubscriptionActive"}`}
                            onClick={() => handleSelectTab(index)}
                        >
                            <Typography variant="h5">
                                {type.label}
                            </Typography>
                        </Box>
                    ))
                }
            </Box>
            {listTypeSubscription[currentIndexType].component}
        </MyListSubscriptionTypeStyled>
    )
}

const MyListSubscriptionTypeStyled = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: 20,
    height: "calc(100vh - 84px)",
    boxSizing: "border-box",
    "& .listTabSubscription": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        "& .tabSubscription": {
            flex: 1,
            cursor: "pointer",
            height: 35,
            boxSizing: "border-box",
            // borderBottom: "3px solid #35383F",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "& h5": {
                fontFamily: "Manrope",
                fontSize: 16,
                fontWeight: 600,
                width: "100%",
                textAlign: "center",
                paddingBottom: "4px",
            },
            "&:after": {
                content:'""',
                background: "#35383F",
                display: "block",
                height: "3px",
                width: "100%",
                position: "absolute",
                bottom: 0,
            }
        },
        "& .tabSubscription.tabSubscriptionActive": {
            "& h5": {
                background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent' as "transparent",
            },
            "&:after": {
                background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            },
        }
    }
}))

export default MyListSubscriptionType;