import { Box, Button, IconButton, styled, Typography } from "@material-ui/core";
import { icCallBtn, icCallPlus, icIncomingCall, icOutgoingCall } from "../../../blocks/call-recording/src/assets";
import ImageRenderer from "../../../components/src/ImageRenderer.web";
import React from "react";
import { CallLog, CallUser } from "@cometchat/calls-sdk-javascript";
import { format, formatDistanceToNow, fromUnixTime, isToday, isYesterday } from "date-fns";
import CometChat from "@cometchat/chat-sdk-javascript";
import Loader from "../../../components/src/Loader.web";
import { TTypeConversation } from "../../../components/src/CometChat";

interface IPropsHistoryCall {
    checked?: boolean;
    loading: boolean;
    loggedUser: CometChat.User | null;
    listHistoryCallLog: CallLog[];
    startCallAudio: ({ receiverID, receiverType }: {
        receiverID: string;
        receiverType: TTypeConversation;
    }) => Promise<void>
}

const HistoryCall = (props: IPropsHistoryCall) => {

    const getIsMissedCall = (callLog: CallLog) => {
        if (props.loggedUser?.getUid() === callLog.getInitiator().getUid()) return false;
        if (callLog.getStatus() !== "unanswered") return false;
        return true;
    }

    const getProfileCallLog = (callLog: CallLog) => {
        if (callLog.getInitiator().getUid() !== props.loggedUser?.getUid()) {
            return callLog.getParticipants().find(participant => participant.getUid() === callLog.getInitiator().getUid())
        }
        return callLog.getParticipants().find(participant => participant.getUid() === (callLog.getReceiver() as unknown as CallUser).getUid())
    }

    const renderIconCallStatus = (callLog: CallLog) => {
        // OutgoingCall
        if (props.loggedUser?.getUid() === callLog.getInitiator().getUid())
            return <ImageRenderer src={icOutgoingCall} style={{ color: "#34D399" }} width={16} height={16} />
        // IncomingCall
        if (callLog.getStatus() === "unanswered") {
            return <ImageRenderer src={icIncomingCall} style={{ color: "#EF4444" }} width={16} height={16} />
        }
        return <ImageRenderer src={icIncomingCall} style={{ color: "#34D399" }} width={16} height={16} />
    }

    const convertUnixTimeStamp = (milliseconds: number) => {
        const timestamp = milliseconds;
        const pastDate = fromUnixTime(timestamp);
        const now = new Date();
        const diffInHours = Math.floor((now.getTime() - pastDate.getTime()) / (1000 * 60 * 60));
        let result;
        if (diffInHours < 12) {
            result = `${formatDistanceToNow(pastDate, { addSuffix: true })}`;
        } else if (isToday(pastDate)) {
            result = `Today, ${format(pastDate, 'hh:mm a')}`;
        } else if (isYesterday(pastDate)) {
            result = `Yesterday, ${format(pastDate, 'hh:mm a')}`;
        } else {
            result = format(pastDate, 'd MMMM, hh:mm a');
        }
        return result;
    }

    const EmptyCall = () => <Box className="containerEmptyCall">
        <Box className="textContent">
            <Typography variant="h4">
                Start a voice call
            </Typography>
            <Typography>
                Make calls to your Arrows app People, including group calls.
            </Typography>
        </Box>
        <Box className="buttonContainer">
            <Button startIcon={<ImageRenderer src={icCallPlus} width={24} height={24} />}>
                Add call
            </Button>
        </Box>
    </Box>

    const ListCallHistory = () => <Box className="listCallHistory">
        {props.listHistoryCallLog.map(callLog => {
            if (callLog.getReceiverType() === "group") return;
            return <Box className="callHistoryItem">
                <Box className="profile_user">
                    <ImageRenderer width={48} height={48} src={getProfileCallLog(callLog)?.getAvatar() ?? ""} style={{ borderRadius: 40 }} />
                </Box>
                <Box className="information_call">
                    <Box className={`information_name ${getIsMissedCall(callLog) && "missedCallColor"}`}>
                        <Typography>
                            {getProfileCallLog(callLog)?.getName()}
                        </Typography>
                    </Box>
                    <Box className="call_log">
                        {renderIconCallStatus(callLog)}
                        <Typography className="logTime">
                            {convertUnixTimeStamp(callLog.getInitiatedAt())}
                        </Typography>
                    </Box>
                </Box>
                <IconButton className="callBtn" onClick={() => props.startCallAudio({ receiverID: getProfileCallLog(callLog)?.getUid() ?? "", receiverType: "user" })}>
                    <ImageRenderer src={icCallBtn} width={24} height={24} />
                </IconButton>
            </Box>
        })}
    </Box>

    if (props.loading) return <Loader loading={props.loading} />

    return (
        <HistoryCallStyled checked={props.checked ?? false}>
            {
                !props.listHistoryCallLog.length ? <EmptyCall /> : <ListCallHistory />
            }
        </HistoryCallStyled>
    )
}

const HistoryCallStyled = styled(Box)(({checked}: {checked: boolean}) => ({
    padding: "20px 16px",
    height: "calc(100vh - 48px - 15px - 48px - 80px - 60px)",
    overflow: "auto",
    boxSizing: "border-box",
    scrollbarWidth: "none",
    "& .containerEmptyCall": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        "& .textContent": {
            display: "flex",
            gap: 20,
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
            "& h4": {
                fontSize: "24px",
                fontFamily: "Manrope",
                fontWeight: 800,
                backgroundClip: 'text',
                background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            },
            "& p": {
                textAlign: "center",
                fontFamily: "Manrope",
                fontWeight: 600,
                fontSize: 16,
                color: "#FFF"
            }
        },
        "& .buttonContainer": {
            margin: "20% 0",
            "& button": {
                width: 160,
                padding: 16,
                borderRadius: 10,
                background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                "& .MuiButton-label": {
                    fontFamily: "Manrope",
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#FFF",
                    textTransform: "none"
                }
            }
        }
    },
    "& .listCallHistory": {
        display: "flex",
        flexDirection: "column",
        gap: 20,
        "& .callHistoryItem": {
            display: "flex",
            gap: 10,
            alignItems: "center",
            "& .profile_user": {
                borderRadius: 40,
            },
            "& .information_call": {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                "& .information_name": {
                    "& p": {
                        fontFamily: "Manrope",
                        fontWeight: 600,
                        fontSize: 16,
                        color: checked ? "#222" : "#FFF"
                    }
                },
                "& .information_name.missedCallColor": {
                    color: "#EF4444",
                },
                "& .call_log": {
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    "& .logTime": {
                        fontFamily: "Manrope",
                        fontWeight: 500,
                        fontSize: 12,
                        color: "#BABABA"
                    }
                },
            },
            "& .callBtn": {
                marginRight: "-12px",
            }
        }
    }
}))

export default HistoryCall;