import React from 'react'
// Customizable Area Start 
import {
    Box,
    Dialog,
    Typography,
    IconButton,
    Button,
    Grid,
    styled
} from '@material-ui/core'
import PostHeader from '../../activityfeed/src/PostHeader.web';
import { infoIcon, commentIcon, favActive, favIcon, repostActive, reverseIcon, } from '../../activityfeed/src/assests';
import PostActivityModalController, { Props } from './PostActivityModalController.web';
import ReactPlayer from 'react-player';
import {ArrowIcon,darkArrowIcon} from "./assets"
import { Media } from '../../activityfeed/src/ActivityFeedController';
// Customizable Area End

export default class PostActivity extends PostActivityModalController {

    constructor(props: Props) {
        // Customizable Area Start      
        super(props);
        // Customizable Area End  
    }
    // Customizable Area Start
    conditionRemover = (condition: boolean, trueStatement: string, falseStatement: string) => {
        return condition ? trueStatement : falseStatement
    }

    getBorderRadius = (index: number, length: number,) => {
        if (length === 1) return "borderRadius";
        const border = [];
        if (index === 0) border.push("topLeftRadius");
        if (index === 1) {
            if (length === 2) {
                border.push("topRightRadius", "bottomRightRadius");
            } else {
                border.push("topRightRadius");
            }
        }
        if (index === length - 1) {
            if (length === 3) {
                border.push("bottomLeftRadius");
            } else {
                border.push("bottomRightRadius");
            }
        }
        if (index === length - 2) {
            if (length === 3) {
                border.push("bottomRightRadius");
            } else {
                border.push("bottomLeftRadius");
            }
        }
        return border.join(' ');
    };


    mediaRenderer = (src: Media, index: number, length: number) => {
        const borderRadiusClass = this.getBorderRadius(index, length);
        if (src.content_type.startsWith("image")) {
            return <img key={src.id} src={src.url} loading="lazy" alt="post"
                className={`${"postImageStyle"} ${borderRadiusClass}  ${this.conditionRemover(length > 2,"imageStyle","imageStyle2")}`} />
        } else if (src.content_type.startsWith("audio")) {
            return <audio key={src.id} src={src.url} controls className={this.conditionRemover(length > 2,"imageStyle","imageStyle2")} >
                Your browser does not support the audio element.
            </audio>
        } else if (src.content_type.startsWith("video")) {
            return <div
                key={src.id}
                className={`${"postImageStyle"} ${borderRadiusClass} ${this.conditionRemover(length > 2,"imageStyle","imageStyle2")}`}
            >
                <ReactPlayer
                    url={src.url}
                    controls={true}
                    loading="lazy"
                    className={`${"reactPlayerStyle"} ${borderRadiusClass}`}
                />
            </div>
        }
    }
    // Customizable Area End
    render() {
        const { checked, post, postActivity, onToggleRemoveAddUserCustomList } = this.props
        // Customizable Area Start
        return (
            <DialogContainer
                data-testid = "DialogContainer"
                onClose={this.props.onClosePostActivityModel}
                open={this.props.postActivityModel}
                fullWidth
                data-test-id="showpostactivtyId"
                PaperProps={{
                    className: this.conditionRemover(!this.props.checked, "modelDarkStyle", "modelLightStyle")
                }}
            >
                <GridContainer container justifyContent="center" alignItems="center" direction="column" >
                    <Box className="PostActivitHeaderContainer">
                        <IconButton 
                            className='IconButtonStyle'
                            data-test-id="showpostactivtyCloseButton"
                            onClick={this.props.onClosePostActivityModel}
                            disableRipple disableFocusRipple disableTouchRipple
                        >
                          <img  src= {this.conditionRemover(this.props.checked,darkArrowIcon,ArrowIcon)} />
                        </IconButton>
                        <Typography className={'name'}>Post Activity</Typography>
                        <Typography>{" "}</Typography>
                    </Box>

                    <div style={{ padding: "8px 20px", width: "92%" }} className={this.conditionRemover(!this.props.checked, "modelDarkStyle", 'modelLightStyle')}>
                        <Box className={'postDisplay'} key={post.id}>
                            <Box>
                                <PostHeader
                                    checked={checked}
                                    postData={post}
                                    onFollow={this.props.onFollow}
                                    onClickMoreOption={this.props.onClickMoreOption}
                                    onMuteConformation={this.props.onMuteConformation}
                                    onShowReportPost={this.props.onShowReportPost}
                                    onBlockAccount={this.props.onBlockAccount}
                                    onToggleRemoveAddUserCustomList={onToggleRemoveAddUserCustomList}
                                />
                            </Box>

                            <div style={{ display: "flex", gap: "15px" }}>
                                <Box className={this.conditionRemover(post.attributes.media.length > 1 , 'feedmultiImages' ,'feedImages')}>
                                    {post.attributes.media.map((src, index) => (
                                        this.mediaRenderer(src, index, post.attributes.media.length)
                                    ))}
                                </Box>
                                <Typography className={"postBodyContain"} dangerouslySetInnerHTML={{ __html: this.props.highlightedBody }} />
                            </div>
                        </Box>
                    </div>

                    <div
                        style={{ padding: "10px 45px", width: "83%" }}
                        className={this.conditionRemover(!this.props.checked, "modelDarkStyle", "modelLightStyle")}
                    >
                        <div className={"postActivityActions"}>

                            <div className={"activityAction"}>
                                <img src={post.attributes.is_liked ? favActive : favIcon} alt="" width={20} height={17} />
                                <Typography className={"userName"} style={{ color: `${post.attributes.is_liked && "#EF4444"}` }}>{post.attributes.total_likes}</Typography>
                            </div>

                            <div className={"activityAction"}>
                                <img src={post.attributes?.is_repost ? repostActive : reverseIcon} alt="" width={20} height={17} />
                                <Typography className={'userName'} style={{ color: `${post.attributes?.is_repost && "#34D399"}` }}>{post.attributes?.total_reposts}</Typography>
                            </div>

                            <div className={"activityAction"}>
                                <img src={commentIcon} alt="" width={20} height={17} />
                                <Typography className={"userName"}>{post.attributes.total_comments}</Typography>
                            </div>
                        </div>
                    </div>

                    <div className="activityCountDisplayContainer" >
                        <div className={"activityCountDisplay"}>
                            <div className={"activityCountDisplayBox"}>
                                <div className={"activityCountDisplayHeader"}>
                                    <Typography className={"activityCountName"}>Impressions</Typography>
                                    <img src={infoIcon} alt="" />
                                </div>
                                <Typography className={"activityCount"}>{postActivity.impressions} </Typography>
                            </div>
                            <div>

                                <div className={"activityCountDisplaychieldWrapper"}>
                                    <div className={"activityCountDisplaychield"}>
                                        <div className={"activityCountDisplayBox"}>
                                            <div className={"activityCountDisplayHeader"}>
                                                <Typography className={"activityCountName"}>Engagements</Typography>
                                                <img src={infoIcon} alt="" />
                                            </div>
                                            <Typography className={"activityCount"}>{postActivity.engagements} </Typography>
                                        </div>

                                        <div className={"activityCountDisplayBox"}>
                                            <div className={"activityCountDisplayHeader"}>
                                                <Typography className={"activityCountName"}>Detail expands</Typography>
                                                <img src={infoIcon} alt="" />
                                            </div>
                                            <Typography className={"activityCount"}>{postActivity.detail_expands} </Typography>
                                        </div>
                                    </div>

                                    <div className={"activityCountDisplaychield"}>
                                        <div className={"activityCountDisplayBox"}>
                                            <div className={"activityCountDisplayHeader"}>
                                                <Typography className={"activityCountName"}>New Followers</Typography>
                                                <img src={infoIcon} alt="" />
                                            </div>
                                            <Typography className={"activityCount"}>{postActivity.new_followers} </Typography>
                                        </div>

                                        <div className={"activityCountDisplayBox"}>
                                            <div className={"activityCountDisplayHeader"}>
                                                <Typography className={"activityCountName"}>Profile visits</Typography>
                                                <img src={infoIcon} alt="" />
                                            </div>
                                            <Typography className={"activityCount"}>{postActivity.profile_visits} </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='activePostContainer' >

                        <div className={"activityPostFooter"}>
                            <div className={"activityPostFooterChield"}>
                                <Typography className={'ModalHeadtitle'}>Promote your post</Typography>
                                <Typography className={"subcriptionBody"}>Get more impressions and grow your brand.</Typography>
                                <Typography className={"subcriptionBody"}>Promote this post and broaden your reach.</Typography>
                            </div>
                            <Button className={"muteBtn"}>Coming Soon</Button>
                        </div>
                    </div>

                </GridContainer>
            </DialogContainer>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

const GridContainer = styled(Grid)({
    rowGap: "15px",
    padding: "15px 24px",
    
    "& .PostActivitHeaderContainer":{
        justifyContent: "space-between", 
        alignItems: "center",
        display: "flex",
        width: "100%",
    },

    "& .IconButtonStyle":{
            padding: "10px 12px",
            height: "48px",
            width: "48px"
    },

    "& .name": {
        fontSize: "18px",
        fontFamily: "Manrope",
        fontWeight: 800,
        lineHeight: "21px",
        whiteSpace: "nowrap" as "nowrap",
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    "& .modelDarkStyle": {
        borderRadius: "10px",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border: "1px solid transparent",
        backdropFilter: "blur(1 px)",
        background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
        boxShadow: "none"
    },

    "& .modelLightStyle": {
        borderRadius: "10px",
        boxShadow: "none",
        border: "1px solid transparent",
        background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        backdropFilter: "blur(1 px)",
    },

    "& .postBodyContain": {
        fontSize: "14px",
        fontFamily: "Manrope",
        fontWeight: 500,
        lineHeight: "21px",
        height: "63px",
        overflowY: "scroll",
    },

    "& .feedmultiImages": {
        gap: "2px",
        gridTemplateColumns: '1fr 1fr',
        display: "grid" as "grid",
        width: "25%"
    },

    "& .feedImages": {
        display: "grid" as "grid",
        gap: "2px",
        width: "25%"
    },

    "& .postImageStyle": {
        width: "100%",
    },

    "& .postDisplay": {
        padding: "8px 0px",
        display: "flex",
        flexDirection: "column" as "column",
        gap: "20px",
        width:"100%"
    },

    "& .postActivityActions": {
        display: "flex",
        justifyContent: "space-between",
        height: "43px",
        alignItems: "Center"
    },
    "&  .activityAction": {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        gap: "5px"
    },

    "& .userName": {
        fontSize: "12px",
        fontFamily: "Manrope",
        fontWeight: 500,
        lineHeight: "14px",
        whiteSpace: "nowrap" as "nowrap",
        color: "#6B6B6B"
    },

    "& .activityCountDisplay": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .activityCountDisplayBox": {
        display: "flex",
        flexDirection: "column" as "column",
        gap: "10px"
    },

    "& .activityCountDisplayHeader": {
        display: "flex",
        gap: "5px"
    },

    "& .activityCountName": {
        fontFamily: "Manrope",
        fontSize: "10px",
        fontWeight: 500,
        lineHeight: "12px",
        color: "#6B6B6B"
    },
    "& .activityCount": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 800,
        lineHeight: "27.12px"
    },

    "& .activePostContainer":{
         padding: "20px", 
         width: "92%",
        borderTop: "1px solid #4E4E4E", 
        borderBottom: "1px solid #4E4E4E" 
    },

    "& .activityCountDisplaychieldWrapper": {
        display: "flex",
        flexDirection: "column" as "column",
        gap: "20px"
    },

    "& .activityCountDisplaychield": {
        display: "flex",
        gap: "20px"
    },

    "& .activityPostFooter": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .activityPostFooterChield": {
        display: "flex",
        gap: "6px",
        flexDirection: "column" as "column"
    },

    "& .activityCountDisplayContainer":{
        padding: "20px 79px",
        width: "71%",
        borderTop: "1px solid #4E4E4E" 
    },

    "& .subcriptionBody": {
        fontSize: "14px",
        fontFamily: "Manrope",
        fontWeight: 500,
        lineHeight: "21px",
        color: "#6B6B6B"
    },

    "& .muteBtn": {
        boxShadow: "none !important",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        borderRadius: "10px",
        textTransform: "capitalize" as "capitalize",
        padding: "16px",
        width: "160px",
        height: "48px",
        color: "white",
        fontSize: "14px",
        fontWeight: 700
    },

    "& .borderRadius": {
        borderRadius: "10px",
    },
    "& .topLeftRadius": {
        borderTopLeftRadius: "10px",
    },
    "& .topRightRadius": {
        borderTopRightRadius: "10px",
    },
    "& .bottomLeftRadius": {
        borderBottomLeftRadius: "10px",
    },
    "& .bottomRightRadius": {
        borderBottomRightRadius: "10px",
    },

    "& .imageStyle":{
        height:'31px',
    },

    "& .imageStyle2":{
        height:'62px'
    },

    "& .reactPlayerStyle": {
        width: "100% !important",
        height: "100%  !important",

        "& :first-child":{
            objectFit:'fill !important' as 'fill',
            borderRadius: "16px"
        }
    },
})

const DialogContainer = styled(Dialog)({
    "& .modelDarkStyle": {
        borderRadius: "10px",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border: "1px solid transparent",
        backdropFilter: "blur(1 px)",
        background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
        boxShadow: "none"
    },

    "& .modelLightStyle": {
        borderRadius: "10px",
        boxShadow: "none",
        border: "1px solid transparent",
        background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        backdropFilter: "blur(1 px)",
    },
})
// Customizable Area End