import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import React, { ChangeEvent } from "react";
import {cloneDeep} from 'lodash'
import { getStorageData } from "../../../framework/src/Utilities";
import { Post, PostAttributes } from "../../activityfeed/src/ActivityFeedController";
import { sendAPIRequest } from "../../../components/src/utils";
import { IReportIssue } from "../../customisableuserprofiles/src/ProfileController.web";
import { toast } from "react-toastify";

export interface FollowersData {
  name:string,
  username:string,
  userProfile:string
 }

 export interface Media {
  id: number;
  url: string;
  thumbnail_url: string | null;
  filename: string;
  content_type: string;
  file_size: number;
}



export interface CommentAttributes {
  id: number;
  body: string;
  created_at: string;
  updated_at: string;
  is_reply: boolean;
  is_liked: boolean;
  total_likes: number;
  total_replies: number;
  model_name: string;
  hash_tags: HashTag[];
  body_mentions: BodyMention[];
  media: Media[];
  comment_by: User;
  mentioned_accounts: User[];
}


export interface LikeAttributes{
    likeable_type:string
    likeable:PostAttributes
}



export interface IUserProfile{
  id: string,
  type: string,
  attributes:User
}

export interface User {
  id: number;
  full_name: string;
  user_name: string;
  email: string ;
  full_phone_number: string;
  country_code: string;
  phone_number: number;
  bio: string | null;
  location: string | null;
  website: string | null;
  has_subscriptions:boolean;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string;
  cometchat_uid: string | null;
  is_subscribed: boolean;
  admin_verification: boolean;
  profile_photo: string | null;
  cover_photo: string | null;
  is_following: boolean;
  is_follower: boolean;
  is_muted: boolean;
  is_blocked: boolean;
  following_count: number;
  followers_count: number;
  user_subscription: string;
  user_ownership: boolean;
  is_tailored: boolean;
  stories_count: number;
  viewed_stories: number;
}

export interface BodyMention {
  id: number;
  full_name: string;
  user_name: string;
  indices: [number, number];
}

export interface HashTag {
  hash_tag: string;
  indices: [number, number];
}

export interface MentionedData {
  id: string;
  type: string;
  attributes: {
  reposted_by: string | null;
  id: number;
  connected_post_id: number | null;
  is_repost: boolean;
  body: string;
  body_mentions: BodyMention[];
  hash_tags: HashTag[];
  media: Media[];
  media_mentions:string[];
  location: string | null;
  created_at: string;
  updated_at: string;
  is_saved: boolean;
  is_pinned: boolean;
  is_liked: boolean;
  total_likes: number;
  total_reposts: number;
  total_comments: number;
  impressions: number;
  model_name: string;
  post_by: User;
  mentioned_accounts: string[];
  parent_post: null;
  }
}

interface PaginationDetails {
  page_number: number;
  records_in_this_page: number;
  records_per_page: number;
  total_pages: number;
  total_records: number;
}

interface INotificationAttributes{
    id: number,
    created_by: string|null,
    headings: string,
    contents: string,
    app_url: string,
    is_read: boolean,
    read_at: null|string,
    created_at: string,
    updated_at: string,
    action: string,
    notification_type: string,
    account_id: number,
    today: boolean,
    notifiable: PostAttributes,
    mentioned_accounts: Array<User>,
}


interface INotificationdata{
  id:string;
  type:string
  attributes: INotificationAttributes
  }

  export interface IAllNotificationAttributes{
    id: number,
    created_by: string|null,
    headings: string,
    contents: string,
    app_url: string,
    is_read: boolean,
    read_at: null|string,
    created_at: string,
    updated_at: string,
    action: string,
    notification_type: string|null,
    followed_by_id:number,
    account_id: number,
    today: boolean,
    notifiable: PostAttributes|User|LikeAttributes|CommentAttributes,
    mentioned_accounts: Array<User>,
  }
 export interface IAllNotificaiton {
  id:string,
  type:string,
  attributes:IAllNotificationAttributes
 }
export interface AllNotificationResponse{
  notifications:{
    data:Array<IAllNotificaiton>
    meta:{
      message:string
    }
  }
  pagination_details:null|PaginationDetails
}

export interface GroupedByDate {
  date: string;
  Notificationdata: IAllNotificaiton[];
}


export interface NotificationResponse{
  notifications:{
    data:Array<INotificationdata>
    meta:{
      message:string
    }
  }
  pagination_details:null|PaginationDetails
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:{[key:string]:string}
  checked?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: GroupedByDate[];
  selectedData: any;
  token: string;
  followers:FollowersData[],
  filter:string,
  activeIndex:number,
  filterModel:boolean,
  filterOption:string[],
  selectedItems:string,
  mentionData:Post[],
  page:number,
  activePostId:string,
  pagination:PaginationDetails,
  showComments:boolean,
  selectedPostComment: Post|undefined,
  activeUser:User|undefined,
  reportingPostId:string,
  muteModal:boolean,
  bottompoper: boolean;
  showReportPostModal: boolean;
  reportPageLoading: boolean;
  reportIssue: number | undefined
  reportPostMainList: Array<IReportIssue>;
  reportPostSubCatList: Array<IReportIssue>;
  reportSubIssue: number | undefined
  reportCurrentView: string;
  reportPostLoading: boolean;
  LoggedUser: IUserProfile | undefined;
  showRepostModal:boolean;
  showQuote:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  getMentionedPostApiCallId:string="";
  markAsReadCallId: string = "";
  savePostApiCallId:string="";
  deleteCallId: string = "";
  unMuteUserApiCallId:string="";
  muteUserApiCallId:string="";
  blockAccountApiCallId:string="";
  followApiCallId:string="";
  likeUnLikedPostApiCallId:string="";
  apiReportPostSubmitCallId:string="";
  apiGetReportSubIssueCallId:string="";
  repostId:string="";
  apiGetReportIssuesCallId:string="";
  undoRepostId:string="";
  apiGetLoggedUserCallId:string="";
  toggleRemoveAddUserCustomListOnPostId: {id: string, userId: number} = {id: '', userId: 0}
  scrollParentRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.ReportDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      token: "",
      followers:[{name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""},
      {name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""},
      {name:"ralhps vibes",username:"@ralhps",userProfile:""}
      ],
      filter:"",
      activeIndex:0,
      filterModel:false,
      selectedItems:"",
      filterOption:[
        "Accounts you follow",
        "Accounts you don't follow",
        "New users on the platform"
      ],
      mentionData:[],
      activePostId:'',
      page:1,
      pagination:{
        page_number: 0,
        records_in_this_page: 0,
        records_per_page: 0,
        total_pages: 0,
        total_records: 0 
      },
      activeUser:undefined,
      selectedPostComment:undefined,
      showComments:false,
      reportingPostId:'',
      muteModal:false,
      bottompoper: false,
      showReportPostModal: false,
      reportPageLoading: false,
      reportPostMainList: [],
      reportIssue: undefined,
      reportSubIssue: undefined,
      reportCurrentView: 'main',
      reportPostLoading:false,
      showRepostModal:false,
      showQuote:false,
      reportPostSubCatList: [],
      LoggedUser:undefined,
      selectedData:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getLoggedUserData()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId =  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    } 
    else{
      this.handleResponse(apiRequestCallId,message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  getNotifications =async()=> {
    const token = await getStorageData("authToken");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint+`?filter_by=${this.state.selectedItems}&page=${this.state.page}&per_page=10`
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  getMentionedPostList=async()=>{
    const token = await getStorageData("authToken");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const httpHeader ={
      "Content-Type": configJSON.apiContentType,
      token: token,
    }
    this.getMentionedPostApiCallId = getDataMsg.messageId;
    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.mentionNotifictionEndPoint+`?filter_by=${this.state.selectedItems}&page=${this.state.page}&per_page=10`);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(httpHeader));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.getDataMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  handleLikePost = async (id: string, type: string, likeable_type: string) => {
    this.setState({ activePostId:id})
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    let httpBody = {
      likeable_id: id,
      likeable_type: likeable_type
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeUnLikedPostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.likePostEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type == "like" ? configJSON.httpPostMethod : configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleSavePost = async(id:string,type:string) => {
    let authToken = await getStorageData("authToken")
    const header = {
      token: authToken,
      "Content-Type": configJSON.apiContentType,
    };

    let httpBody = {
      post_id:id,    
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savePostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),type=="save"?configJSON.savePostEndpoint:configJSON.unSavePostEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type=="save"?configJSON.httpPostMethod:configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleMuteAccount = async (id: string) => {  
    const token = await getStorageData("authToken")
    const url = configJSON.muteUserEndpoint

    this.muteUserApiCallId = sendAPIRequest(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': configJSON.apiContentType,
          token,
        },
        body: {
          account_id: id
        },
      }
    )
  }

  handleUnmuteUser = async (id: string) => {
    let token = await getStorageData("authToken")
    const header = {
      token: token,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    let httpBody = {
      account_id: id
    }
    this.unMuteUserApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.unmuteUserEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleFollow = async (id: number, type: string) => {

    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    let httpBody = {
      account_id: id,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.followEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type == "follow" ? configJSON.httpPostMethod : configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleBlockAccount = async (id: number) => {
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    let httpBody = {
      account_id: id,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.blockAccountEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleSubmitReport = async () => {
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    let endpoint=configJSON.ReportPost
    let httpBody={
      "report": {
        "post_id": Number(this.state.reportingPostId),
        "report_issue_id": this.state.reportIssue,
        "report_sub_issue_id": this.state.reportSubIssue
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiReportPostSubmitCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getLoggedUserData = async () => {
    let token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetLoggedUserCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getLoggedUserInfo);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  getReportUserSubCategories = async () => {
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetReportSubIssueCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getReportSubIssues}${this.state.reportIssue}`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReportUserMainCategories = async () => {
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetReportIssuesCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getReportIssues)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUndoRepost = async () => {
    const token = await getStorageData("authToken")
    const selectedPostComment= this.state.selectedPostComment  as Post
    this.undoRepostId = sendAPIRequest(
      `bx_block_posts/undo_repost`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
        body: {
          parent_post_id: selectedPostComment?.attributes.parent_post?.id 
        },
      }
    )
    this.setState({showRepostModal: false})
  }
  handleRepost = async () => {
    const token = await getStorageData("authToken")
    
    this.repostId = sendAPIRequest(
      `bx_block_posts/repost`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
        body: {
          parent_post_id: this.state.selectedPostComment?.id
        },
      }
    )
    this.setState({showRepostModal: false})
  }

  setFilter=((event:React.ChangeEvent<HTMLInputElement>)=> {    
    this.setState({ filter: event.target.value })
  })

  handleClick = (activeUser: User, postId: string) => {
    this.setState({
      activeUser: activeUser,
      reportingPostId: postId || ""
    })
  }

  handleShowRepostModel = (postId: string) => {
    if(this.state.activeIndex===0){
    this.handleRepostNotification(postId)
    }else{
    this.setState((prev) => {
      const selectedPost = prev.mentionData?.find(post => post.id === postId)
      return {
        ...prev, 
        showRepostModal: true,
        selectedPostComment: selectedPost
      }
    })
  }
  }

  handleRepostNotification=(postId: string)=>{
     const postList=  this.state.data.map(item=>item.Notificationdata).flat().filter(post=>post.attributes.notification_type==="mention")
     const postItem = postList?.find(post => (post.attributes.notifiable as PostAttributes).id.toString() === postId)
    const SelectedPost = {
      id:(postItem?.attributes.notifiable as PostAttributes).id.toString(),
      type:"post",
      attributes:postItem?.attributes.notifiable as PostAttributes
    }

     this.setState((prev)=>({
       ...prev,
        selectedPostComment: SelectedPost,
        showRepostModal: true,
       }))
  }
  
  handleCloseRepostModal = () => {
    this.setState({showRepostModal: false})
  }

  handleQuote = () => {
    this.setState({showRepostModal: false,showQuote:true})
  }
  
  handleMuteModelClose = () => {
    this.setState({ muteModal: false, })
  }

  muteConformation = () => {
    this.setState({ muteModal: true })
  }

  handleButtompoperlClose = ()=>{
    this.setState({ bottompoper:false })
  }

  setReportPostIssue = (id: number) => {
    this.setState({
      reportIssue: id
    })
  }

  setReportPostSubIssues = (id: number) => {
    this.setState({
      reportSubIssue: id
    })
  }

  closeReportPostModal = () => {
    this.setState({
      showReportPostModal: false,
      reportIssue: undefined,
      reportSubIssue: undefined,
      reportingPostId: '',
      reportCurrentView: "main",
      reportPostSubCatList: []
    })
  }

  showReportPost = () => {
    this.setState({
      showReportPostModal: true,
      reportPageLoading: true,
    }, this.getReportUserMainCategories)
  }

  handleCloseQuote = () => {
    this.setState({showQuote: false})
  }

  handleGoBackAfterSubmit = () => {
    this.setState({page: 1, mentionData: [], pagination:{
      page_number:0,
      records_in_this_page:0,
      records_per_page:0,
      total_pages:0,
      total_records:0
    }, showQuote: false})
    this.handleApi()
  }

  changeReportView = () => {
    if (this.state.reportIssue !== undefined && this.state.reportSubIssue !== undefined) {
      this.handleSubmitReport()
    } else if (this.state.reportIssue !== undefined) {
      this.setState({
        reportCurrentView: "subcat",
        reportPostLoading: true
      }, this.getReportUserSubCategories)
    }
  }

  handleOpenCommentModal=(postId:string)=>{
    if(this.state.activeIndex===0){
      this.handleNotificaitonCommentOpenModal(postId)
    }else{
    this.setState((prev) => {
      const selectedPost = prev.mentionData?.find(post => post.id === postId)
      return {
        ...prev, 
        showComments: true,
        selectedPostComment: selectedPost
      }
    })
  }
  }

  handleNotificaitonCommentOpenModal=(postId:string)=>{
    const postList=  this.state.data.map(item=>item.Notificationdata).flat().filter(post=>post.attributes.notification_type==="mention")
    const findPost = postList?.find(post => (post.attributes.notifiable as PostAttributes).id.toString() === postId)
   const SelectedPost = {
     id:(findPost?.attributes.notifiable as PostAttributes).id.toString(),
     type:"post",
     attributes:findPost?.attributes.notifiable as PostAttributes
   }
    this.setState((prev)=>({
      ...prev,
       selectedPostComment: SelectedPost,
       showComments: true,
      }))
  }

  handlePostActivityModel=()=>{
    return
  }

  handleCloseCommentsModal = () => {
    this.setState({showComments: false, selectedPostComment: undefined})
  }

  handleCommentFunctions=()=>{
      const cloneFeeds = [...this.state.mentionData]
      const updateFeed = cloneFeeds.map((post) => {
        if (post.id === this.state.selectedPostComment?.id) {
          return {
            ...post,
            attributes: {
              ...post.attributes,
              total_comments: post.attributes.total_comments + 1
            }
          }
        } else {
          return post
        }
      })
      this.setState({mentionData: updateFeed})
  }

  handleTabsChange = (event: ChangeEvent<{}>, newValue: number) => {    
    this.setState({ activeIndex: newValue,
      mentionData:[],
      data:[],
      page:1,
      pagination:{
        page_number: 0,
        records_in_this_page: 0,
        records_per_page: 0,
        total_pages: 0,
        total_records: 0 
      }
    },this.handleApi)
   };

   handleApi=()=>{
    if(this.state.activeIndex===0){
      this.getNotifications()
    }else{
      this.getMentionedPostList()
    }
   }


   handleMuteModel = (status: string) => {
    if (status == "no") {
      this.setState({ muteModal: false })
    } else {
     this.state.activeUser?.is_muted?this.handleUnmuteUser(this.state.activeUser?.id.toString()): this.handleMuteAccount(this.state.activeUser?.id.toString()as string)
    }
  }
  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }

  handleClose =()=>{
    this.setState({filterModel:false})
  }

  handleOpenModel =()=>{
    this.setState({filterModel:true})
  }

  handleItemClick = (index:string) => {
    this.setState({ selectedItems: index });
  };

  handleConfirmation=()=>{
    this.handleClose()
    if(this.state.selectedItems !=="New users on the platform"){
      this.setState({
        mentionData:[],
        data:[],
        page:1,
        pagination:{
          page_number: 0,
          records_in_this_page: 0,
          records_per_page: 0,
          total_pages: 0,
          total_records: 0 
        }
      })
      this.handleApi()
    }
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: number) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

   mergeGroupedData(existingData: GroupedByDate[], newData: GroupedByDate[]): GroupedByDate[] {
    newData.forEach(newGroup => {
      const existingGroup = existingData.find(group => group.date === newGroup.date)
      if (existingGroup) {
        existingGroup.Notificationdata.push(...newGroup.Notificationdata);
      } else {
        existingData.push(newGroup);
      }
    });
  
    return existingData;
  }

  handleNotificationResponse=(message:Message)=>{
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(!apiResponse?.errors){
       
      const response = apiResponse as AllNotificationResponse
      const groupedByDateArray: Array<GroupedByDate> =Object.values(
        response.notifications.data.reduce<Record<string, GroupedByDate>>((acc, obj) => {
          const date = new Date(obj.attributes.created_at).toISOString().split("T")[0];
          if (!acc[date]) {
            acc[date] = { date: date, Notificationdata: [] };
          }
          acc[date].Notificationdata.push(obj);
          return acc;
        }, {})
      );
       const groupData= this.mergeGroupedData(this.state.data,groupedByDateArray)
        this.setState({
          data: [...groupData],
          pagination:apiResponse?.pagination_details,
          page:this.state.page+1
        });
      }
  }
  
  handleMentionedlistResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(!apiResponse?.errors){ 
      const response: NotificationResponse  =  apiResponse
      const mentionedList = response?.notifications?.data.map((item)=>({
        id:item.attributes.notifiable.id.toString(),
        type:"post",
        attributes:item.attributes.notifiable
      }))


      this.setState((prev)=>({
      mentionData: [...prev.mentionData,...mentionedList],
      page:prev.page+1,
      pagination:apiResponse.pagination_details
     }))
    }
  }
  
  handleSavePostResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(!apiResponse?.errors){
      if (apiResponse && apiResponse?.data) {
        let newfeeds = [...this.state.mentionData]
        let feeds = newfeeds.map(post => {
          if (post.attributes.id == apiResponse.data.id) {
            return {
                   ...post,
                   attributes: {
                     ...post.attributes,
                     is_saved: apiResponse.data.attributes.is_saved
                   }
                 };
               }
               return post;
         });

         if(this.state.activeIndex===0){
          this.handleNotifcationSave(apiResponse.data.attributes)
         }

         this.setState({mentionData:feeds})
    }
  }
  }

  handleNotifcationSave=(response:PostAttributes)=>{
    const Notificaitons = this.state.data.map((task)=>{
      const updateData = task.Notificationdata.map((item)=>{
        if(item.attributes.notification_type==="mention"){
            if((item.attributes.notifiable as PostAttributes).id==response.id){
              return {
                ...item,
                attributes:{
                  ...item.attributes,
                  notifiable:{
                    ...item.attributes.notifiable,
                    is_saved: response.is_saved
                  }
                }
              }
            }
            return item
          }
  
          return item
        })
        return {...task,Notificationdata:updateData}
      })
  
   this.setState({
      data:Notificaitons
   })
  }
  

  handleMuteUserResponse = (message:Message) => {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({
      muteModal: false, bottompoper: true,
    })
    if (!apiResponse?.errors) {
      let newfeeds = [...this.state.mentionData]
      this.handlemuteNotificaitonRes(apiResponse.data.attributes)
        let feeds = newfeeds.map(post => {
          if (post.attributes.post_by.id == apiResponse.data.id) {
            return {
                   ...post,
                   attributes: {
                     ...post.attributes,
                     post_by: {
                      ...post.attributes.post_by,
                     is_muted: apiResponse.data.attributes.is_muted,
                     }
                   }
                 };
               }
               return post;
         });

         if (apiResponse.data.id === this.state.selectedPostComment?.attributes.post_by.id) {
          const updateCommentsPost = feeds?.find(post => post.attributes.post_by.id=== this.state.selectedPostComment?.attributes?.post_by.id as number)
          this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
         }
      this.setState({ mentionData:feeds })
    }
  }

  handlemuteNotificaitonRes=(response:User)=>{
    if(this.state.activeIndex===0){
      const Notificaitons = this.state.data.map((task)=>{
        const updateData = task.Notificationdata.map((item)=>{
          if(item.attributes.notification_type==="mention"){
              if((item.attributes.notifiable as PostAttributes).post_by.id==response.id){
                return {
                  ...item,
                  attributes:{
                    ...item.attributes,
                    notifiable:{
                      ...item.attributes.notifiable,
                      post_by:response
                    }
                  }
                }
              }
              return item
            }
            return item
          })
          return {...task,Notificationdata:updateData}
        })
    
     this.setState({
        data:Notificaitons
     })
    }
  }

  handleRepostResponse = (message:Message) => {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiResponse && apiResponse?.data) {
      let feeds: Post[]=[]
      
        feeds= [...this.state.mentionData]
       
      this.handleRepostNotificationList()

      const newFeed = feeds.map(post => {
        if (post.id === this.state.selectedPostComment?.id) {
          return {
            ...post,
            attributes: {
              ...post.attributes,
              total_reposts: post.attributes.total_reposts + 1
            }
          }
        }
        return post
       });
       return this.setState({ mentionData: newFeed})
    }
    if (apiResponse?.errors?.[0]) {
      toast.error(apiResponse?.errors?.[0])
    }
  }

  handleToggleAddRemoveUserCustomListOnPostRes = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
		const messageRes = responseJson?.messages?.[0]
		const userId = this.toggleRemoveAddUserCustomListOnPostId.userId
		if (messageRes === 'Member added to your tailored accounts list' || messageRes === 'Removed from your tailored accounts list') {
			return
		} else {
			const listPost = [...this.state.mentionData];
			const updateListPost = listPost.map((postItem) => {
			let postBy = postItem.attributes.post_by;
			if (postItem.attributes.post_by.id == userId) {
				postBy = {
				...postItem.attributes.post_by,
				is_tailored: !postItem.attributes.post_by.is_tailored,
				};
			}
			
			return {
				...postItem,
				attributes: {
				...postItem.attributes,
				post_by: postBy,
				},
			};
			});

			this.setState({ mentionData: updateListPost });
		}
	}

  handleRepostNotificationList=()=>{
    if(this.state.activeIndex===0){
      const Notificaitons = this.state.data.map((task)=>{
        const updateData = task.Notificationdata.map((item)=>{
          if(item.attributes.notification_type==="mention"){
              if((item.attributes.notifiable as PostAttributes).id.toString()==this.state.selectedPostComment?.id){
                return {
                  ...item,
                  attributes:{
                    ...item.attributes,
                    notifiable:{
                      ...item.attributes.notifiable,
                      is_repost:true,
                      total_reposts: (item.attributes.notifiable as PostAttributes).total_reposts+1
                    }
                  }
                }
              }
    
              return item
            }
    
            return item
          })
          return {...task,Notificationdata:updateData}
        })
    
     this.setState({
        data:Notificaitons
     })
    }
  }

  handleUnmuteUserResponse = (message:Message) => {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({
      bottompoper:false
    })
    if (!apiResponse?.errors) {
      let newfeeds = [...this.state.mentionData]
      this.handleUnmuteNotificaitonRes(apiResponse.data.attributes)
        let feeds = newfeeds.map(post => {
          if (post.attributes.post_by.id == apiResponse.data.id) {
            return {
                   ...post,
                   attributes: {
                     ...post.attributes,
                     post_by: {
                      ...post.attributes.post_by,
                     is_muted: apiResponse.data.attributes.is_muted
                     }
                   }
                 };
               }
               return post;
         });

         if (apiResponse.data.id === this.state.selectedPostComment?.attributes.post_by.id) {
          const updateCommentsPost = feeds?.find(post => post?.id === this.state.selectedPostComment?.id)
          this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
         }

         this.setState({mentionData:feeds})    
    }
  }

  handleUnmuteNotificaitonRes=(response:User)=>{
    if(this.state.activeIndex===0){
      const Notificaitons = this.state.data.map((task)=>{
        const notifcationData = task.Notificationdata.map((postItem)=>{
          if(postItem.attributes.notification_type==="mention"){
              if((postItem.attributes.notifiable as PostAttributes).post_by.id==response.id){
                return {
                  ...postItem,
                  attributes:{
                    ...postItem.attributes,
                    notifiable:{
                      ...postItem.attributes.notifiable,
                      post_by:response
                    }
                  }
                }
              }
              return postItem
            }
            return postItem
          })
          return {...task,Notificationdata:notifcationData}
        })
    
     this.setState({
        data:Notificaitons
     })
    }
  }


  handleFollowUnfollowResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!apiResponse?.errors) {
      let newfeeds = [...this.state.mentionData]
        let feeds = newfeeds.map(post => {
          if (post.attributes.post_by.id == apiResponse.data.id) {
            return {
                   ...post,
                   attributes: {
                     ...post.attributes,
                     post_by: {
                      ...post.attributes.post_by,
                      is_following: apiResponse.data.attributes.is_following
                    },
                   }
                 };
               }
               return post;
         }); 

          if(this.state.activeIndex===0){
            this.handleFollowMentionNotification(apiResponse.data.attributes)
            this.handleFolloBackButton(apiResponse.data.attributes)
          }

         if (apiResponse.data.id === this.state.selectedPostComment?.attributes.post_by.id.toString()) {
          const updateCommentsPost = feeds?.find(post => (post.id === this.state.selectedPostComment?.id))
          this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
         }

         this.setState({mentionData:feeds})    
    }else{
      toast.error(apiResponse.errors[0])
    }
  }

  handleFollowMentionNotification=(data:User)=>{
    const Notificaitons = this.state.data.map((task)=>{
       const updateData = task.Notificationdata.map((item)=>{
         if(item.attributes.notification_type==="mention"){
             if((item.attributes.notifiable as PostAttributes).post_by.id=== data.id){
               return {
                 ...item,
                 attributes:{
                   ...item.attributes,
                   notifiable:{
                     ...item.attributes.notifiable,
                     post_by:data
                   }
                 }
               }
             }
 
             return item
           }
 
           return item
         })
         return {...task,Notificationdata:updateData}
       })
 
    this.setState({
       data:Notificaitons
    })
   }

  handleFolloBackButton=(data:User)=>{
   const Notificaitons = this.state.data.map((task)=>{
      const updateData = task.Notificationdata.map((item)=>{
        if(item.attributes.notification_type==="new_follower"){
            if(item.attributes.followed_by_id=== data.id){
              return {
                ...item,
                attributes:{
                  ...item.attributes,
                  notifiable:{
                    ...item.attributes.notifiable,
                    is_follower:true,
                    is_following:true
                  }
                }
              }
            }

            return item
          }

          return item
        })
        return {...task,Notificationdata:updateData}
      })

   this.setState({
      data:Notificaitons
   })
  }

  handleBlockAccountResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage))
      if (!apiResponse?.errors) {
        let newfeeds = [...this.state.mentionData]
        this.handleBlockNotification(apiResponse.data.attributes)
          let feeds = newfeeds.map(post => {
            if (post.attributes.post_by.id  == apiResponse.data.id) {
              return {
                     ...post,
                     attributes: {
                       ...post.attributes,
                       post_by: {
                        ...post.attributes.post_by,
                       is_blocked: apiResponse.data.attributes.is_blocked
                     }
                    }
                   };
                 }
                 return post;
           });

           toast.success(apiResponse.messages[0])
           if (apiResponse.data.id === this.state.selectedPostComment?.attributes.post_by.id) {
            const updateCommentsPost = feeds?.find(post => post.id === this.state.selectedPostComment?.id)
            this.setState({selectedPostComment: cloneDeep(updateCommentsPost)})
           }
           this.setState({mentionData:feeds})    
      }   
  }


  handleBlockNotification=(response:PostAttributes)=>{
    if(this.state.activeIndex===0){
    const Notificaitons = this.state.data.map((task)=>{
      const updateData = task.Notificationdata.filter((item)=>{
        if(item.attributes.notification_type==="mention"){
            if((item.attributes.notifiable as PostAttributes).post_by.id==response.id){
              return {
                ...item,attributes:{
                  ...item.attributes,
                  notifiable:{
                    ...item.attributes.notifiable,
                    post_by:response.post_by
                  }

                }
              }
            }
            return item
          }
          return item
        })

        return {...task,Notificationdata:updateData}
      })
  
   this.setState({
      data:Notificaitons
   })
  }
  }
  handleLikeUnLikePostResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(!apiResponse?.errors){
      if(apiResponse?.messages) {
        let feeds = [...this.state.mentionData]
        let newarray = feeds.map(post => {
          if (post.id === this.state.activePostId) {
            return {
                   ...post,
                   attributes: {
                     ...post.attributes,
                     is_liked:  apiResponse.messages[0]=="Liked Successfully"?true:false,
                     total_likes:apiResponse.messages[0]=="Liked Successfully"?post.attributes.total_likes+1:post.attributes.total_likes-1
                   }
                 };
               }
               return post;
         });
         this.handleNotificationList(apiResponse.messages[0])

         this.setState({mentionData:newarray})    
      }
    }
   
}

handleNotificationList=(message:string)=>{
  if(this.state.activeIndex===0){
    this.handleNotificationLike(message)
  }
}

handleNotificationLike=(message:string)=>{
  const Notificaitons = this.state.data.map((task)=>{
    const updateData = task.Notificationdata.map((item)=>{
      if(item.attributes.notification_type==="mention"){
          if((item.attributes.notifiable as PostAttributes).id.toString()==this.state.activePostId){
            return {
              ...item,
              attributes:{
                ...item.attributes,
                notifiable:{
                  ...item.attributes.notifiable,
                  is_liked:  message=="Liked Successfully"?true:false,
                     total_likes:message=="Liked Successfully"?(item.attributes.notifiable as PostAttributes).total_likes+1:(item.attributes.notifiable as PostAttributes).total_likes-1
                }
              }
            }
          }

          return item
        }

        return item
      })
      return {...task,Notificationdata:updateData}
    })

 this.setState({
    data:Notificaitons
 })
}


  handleMarkAsReadResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    apiResponse && this.getNotifications();
  }

  handleDeleteNotificationResponse=(message:Message)=>{
       const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.getNotifications();
  }

  handleLoggedUserResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!apiResponse.errors) {
      this.setState({
        LoggedUser: apiResponse.data
      })
    }
  }

  handleReportIssueResponse = (message:Message) => {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!apiResponse.errors) {
      this.setState({
        reportPostMainList: apiResponse.data,
      })
    }
  }


  handleReportPostResponse = (message:Message) => {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!apiResponse.errors) {
      toast.success(apiResponse.messages[0])
      this.setState({
        reportCurrentView: "submit"
      })
    }
  }


  handleSubCatReportResponse = (message:Message) => {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!apiResponse.errors) {
      this.setState({
        reportPostSubCatList: apiResponse.data,
        reportPostLoading: false
      })
    }
  }

  handleReportedPage=(notification:IAllNotificaiton)=>{
    const addMessage: Message = new Message(getName(MessageEnum.NavigationAlertWebMessage));
    addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    addMessage.addData(getName(MessageEnum.ReportDataMessage),{
      report_type:notification.attributes.notification_type,
      report_id:notification.attributes.app_url,
      contents: notification.attributes.contents
    });
    this.send(addMessage);
  }

  handleResponse=(apiRequestCallId:string,message:Message)=>{
    switch(apiRequestCallId){
      case this.getDataCallId:
        return this.handleNotificationResponse(message)
      case this.markAsReadCallId:
        return this.handleMarkAsReadResponse(message)
      case this.deleteCallId:
        return this.handleDeleteNotificationResponse(message)  
      case this.getMentionedPostApiCallId:
        return this.handleMentionedlistResponse(message)
      case this.likeUnLikedPostApiCallId: 
        return this.handleLikeUnLikePostResponse(message)
      case this.savePostApiCallId:
        return this.handleSavePostResponse(message)
      case this.followApiCallId:
        return this.handleFollowUnfollowResponse(message)
      case this.unMuteUserApiCallId:
        return this.handleUnmuteUserResponse(message)
      case  this.muteUserApiCallId:
        return this.handleMuteUserResponse(message)
      case this.blockAccountApiCallId:
        return this.handleBlockAccountResponse(message)
      
      case this.apiGetLoggedUserCallId:
        return this.handleLoggedUserResponse(message)


      case this.apiGetReportIssuesCallId:
        return this.handleReportIssueResponse(message)

      case this.apiReportPostSubmitCallId:
        return this.handleReportPostResponse(message)

      case this.apiGetReportSubIssueCallId:
        return this.handleSubCatReportResponse(message)

      case this.repostId:
        return this.handleRepostResponse(message)
      
      case this.toggleRemoveAddUserCustomListOnPostId.id:
        return this.handleToggleAddRemoveUserCustomListOnPostRes(message)
    }
  }

  handleToggleRemoveAddUserCustomList = async (userId: number, isAdd: boolean) => {
		const token = await getStorageData("authToken")
		const formdata = new FormData();
		formdata.append("member_id", userId.toString())
	
		const feed = [...this.state.mentionData];
		const updateFeed = feed.map((postItem) => {
		  let postBy = postItem.attributes.post_by;
		  if (postItem.attributes.post_by.id == userId) {
			postBy = {
			  ...postItem.attributes.post_by,
			  is_tailored: isAdd,
			};
		  }
		  
		  return {
			...postItem,
			attributes: {
			  ...postItem.attributes,
			  post_by: postBy,
			},
		  };
		});
	
		this.setState({ mentionData: updateFeed });
	
		const apiId = sendAPIRequest("account_block/tailored_accounts", {
		  method: isAdd ? "POST" : "DELETE",
		  headers: { token: token },
		  body: formdata,
		});
	
		this.toggleRemoveAddUserCustomListOnPostId = {id: apiId, userId }
	  };
  // Customizable Area End
}
