import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {CometChat} from "@cometchat/chat-sdk-javascript";
import { EMITTER_EVENT_VARIABLES, emitterEvents, generateTokenCall, startCallSession } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { CallLog, CometChatCalls } from "@cometchat/calls-sdk-javascript";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?: boolean;
  tokenSessionCall: string;
  currentCall: CometChat.Call | null;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class IncomingCallController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    switch (message.id) {
      case getName(MessageEnum.SessionResponseMessage): {
        break;
      }
      case getName(MessageEnum.NavigationPayLoadMessage): {
        break;
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleAcceptCall = async () => {
    try {
      const getCometChatToken = await getStorageData("cometChatToken") || "";
      const callLogs: Array<CallLog> = await CometChatCalls.getCallDetails(this.props.currentCall?.getSessionId() || "", getCometChatToken)
      if(callLogs[0].getStatus() === "ended"){
        console.info("Call ended: ", callLogs[0]);
        toast.info("Call ended");
        emitterEvents.emit(EMITTER_EVENT_VARIABLES.RESET_CALL);
        return;
      }
      const call = await CometChat.acceptCall(this.props.currentCall?.getSessionId() ?? "")
      console.log("Call accepted successfully:", call);
      const token = await generateTokenCall(call.getSessionId())
      await startCallSession(token, call);
    }
    catch (error) {
      console.log("Call acceptance failed with error", error);
      toast.error("Call acceptance failed");
    }
    this.handleCloseModalCall();
  }

  handleRejectCall = async () => {
    try {
      const status = CometChat.CALL_STATUS.REJECTED
      const call = await CometChat.rejectCall(this.props.currentCall?.getSessionId() ?? "", status)
      console.log("Call rejected successfully:", call)
    } catch(error) {
      console.error("Call Reject failed with error", error);
    }
    emitterEvents.emit(EMITTER_EVENT_VARIABLES.REJECT_CALL, true)
    this.handleCloseModalCall();
  }

  handleCloseModalCall = () => {
    emitterEvents.emit(EMITTER_EVENT_VARIABLES.CLOSE_MODAL_CALL, true)
  }

  // Customizable Area End
}
