// Customizable Area Start
import React from "react";
import {
  Box,
  withStyles,
  createStyles,
  Typography,
  Modal,
  Button,
} from "@material-ui/core";
import PostBodyController, { Props } from "./PostBodyController.web";
import {
  getBorderRadiusClass,
  highlightText,
  verifyImg,
} from "../../../components/src/utils";
import ParentPostUi from "../../repost/src/ParentPostUi.web";
import { Post, PostAttributes } from "./ActivityFeedController";
import YoutubeVideo from "../../../components/src/Posts/YoutubeVideo";
import VideoPlayer from "../../../components/src/Posts/VideoPlayer";
import { pdfPlaceholder, profileLogo } from "./assests";

export class PostBody extends PostBodyController {
  constructor(props: Props) {
    super(props);
  }

  mediaRender = (classes: any, src: any, index: number, length: number) => {
    const borderRadiusClass = getBorderRadiusClass(index, length, classes);
    if (src.content_type.startsWith("image")) {
      return (
        <img
          key={src.id}
          src={src.thumbnail_url ?? src.url}
          loading="lazy"
          alt="post"
          className={`${
            length > 1 ? classes.multiMedia : classes.singleMedia
          } ${borderRadiusClass}`}
          {...({} as any)}
        />
      );
    } else if (src.content_type.startsWith("video")) {
      return (
        <div
          key={src.id}
          className={`${
            length > 1 ? classes.multiMedia : classes.singleMedia
          } ${borderRadiusClass}`}
        >
          <VideoPlayer
            videoUrl={src?.hls_playlist_url || src.url}
            videoDownloadUrl={src.url}
            classesName={`${classes.reactPlayerStyle} ${borderRadiusClass}`}
            isShowMini={length > 1}
          />
        </div>
      );
    } else if (src.content_type.startsWith("audio")) {
      return (
        <audio key={src.id} src={src.url} controls>
          Your browser does not support the audio element.
        </audio>
      );
    } else if (src.content_type === "application/pdf") {
      return (
        <a
          key={src.id}
          href={src.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={src?.thumbnail_url || pdfPlaceholder}
            loading="lazy"
            alt="post"
            className={`${
              length > 1 ? classes.multiMedia : classes.singleMedia
            } ${borderRadiusClass}`}
            {...({} as any)}
          />
        </a>
      );
    }
  };

  renderParentPost = () => {
    const { is_repost, parent_post, body } = this.props.postData.attributes;
    if (is_repost && parent_post && body) {
      const customParentPost: Post = {
        id: parent_post.id.toString(),
        type: "post",
        attributes: parent_post,
      };

      return (
        <ParentPostUi
          postData={customParentPost}
          checked={this.props.checked}
        />
      );
    }
  };

  renderTaggedPeopleModal = (renderPost: PostAttributes) => {
    const { classes, checked, onFollowTaggedPeople } = this.props;

    return (
      <Modal
        open={this.state.showTaggedModal}
        className={classes.modalContainer}
        onClose={this.handleCloseTaggedModal}
      >
        <Box
          className={classes.modalWrapper}
          style={{
            color: checked ? "#222" : "#fff",
            backgroundColor: checked ? "#fff" : "#0B0B0B",
          }}
        >
          <Box className={classes.modalTitleWrapper}>
            <Typography className={classes.taggedPeopleTitle}>
              Tagged People
            </Typography>
          </Box>
          <Box className={classes.taggedPeopleListWrapper}>
            {renderPost.mentioned_accounts.map((user) => (
              <Box key={user.id} className={classes.taggedUserWrapper}>
                <img
                  src={user.profile_photo || profileLogo}
                  alt="user photo"
                  className={classes.taggedUserAvatar}
                />
                <Box className={classes.taggedUserInfoWrapper}>
                  <Box className={classes.taggedUserNameWrapper}>
                    <Typography className={classes.taggedUserFullname}>
                      {user.full_name}
                    </Typography>
                    <img
                      src={verifyImg(user.user_subscription || "")}
                      alt="verify-icon"
                    />
                  </Box>
                  <Typography className={classes.taggedUserName}>
                    {user.user_name}
                  </Typography>
                </Box>
                {!user.is_following && onFollowTaggedPeople && (
                  <Button
                    className={classes.followTaggedUserBtn}
                    onClick={() => onFollowTaggedPeople(user.id)}
                  >
                    Follow
                  </Button>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    );
  };

  render() {
    const { classes, postData, checked } = this.props;
    let { is_repost, parent_post, body, media_links } = postData.attributes;
    media_links = media_links || [];
    const renderPost =
      is_repost && parent_post && !body ? parent_post : postData.attributes;

    const highlights = [...renderPost.body_mentions, ...renderPost.hash_tags];
    const highlightedBody =
      (renderPost.body && highlightText(renderPost.body, highlights)) || "";

    return (
      <Box className={classes.postBody}>
        <Typography
          className={classes.postBodyContain}
          style={{ color: checked ? "#222222" : "#FFF" }}
        >
          {highlightedBody.split("\r\n").map((line, index) => (
            <React.Fragment key={index}>
              <Typography
                className={classes.postBodyContain}
                dangerouslySetInnerHTML={{ __html: line }}
              />
              <br style={{ lineHeight: 0 }} />
            </React.Fragment>
          ))}
        </Typography>

        {media_links.length > 0 && renderPost.media.length === 0 && (
          <YoutubeVideo youtubeUrl={media_links[media_links.length - 1].url} />
        )}

        <Box
          className={
            renderPost.media.length > 1
              ? classes.feedmultiImages
              : classes.feedImages
          }
        >
          {renderPost.media.map((src: any, index: number) =>
            this.mediaRender(classes, src, index, renderPost.media.length)
          )}
        </Box>
        {renderPost.media.length > 0 && renderPost.media_mentions.length > 0 && (
          <Box
            style={{ marginTop: "-14px" }}
            onClick={this.handleShowTaggedModal}
          >
            <Typography
              className={classes.mentionMediaText}
              style={{ color: checked ? "#222222" : "#FFF" }}
            >
              {this.generateMentionMediaText(renderPost.media_mentions)}
            </Typography>
          </Box>
        )}
        {this.renderParentPost()}
        {this.renderTaggedPeopleModal(renderPost)}
      </Box>
    );
  }
}

const styles = createStyles({
  postBody: {
    display: "flex",
    flexDirection: "column" as "column",
    rowGap: "20px",
  },
  postBodyContain: {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
    paddingRight: "24px",
  },
  feedmultiImages: {
    display: "grid" as "grid",
    gap: "10px",
    gridTemplateColumns: "1fr 1fr",
  },
  feedImages: {
    display: "grid" as "grid",
    gap: "10px",
  },
  mentionMediaText: {
    display: "inline-block",
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: "12px",
    color: "#FFF",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  borderRadius: {
    borderRadius: "16px",
  },
  topLeftRadius: {
    borderTopLeftRadius: "16px",
  },
  topRightRadius: {
    borderTopRightRadius: "16px",
  },
  bottomLeftRadius: {
    borderBottomLeftRadius: "16px",
  },
  bottomRightRadius: {
    borderBottomRightRadius: "16px",
  },
  singleMedia: {
    maxWidth: "100%",
    minWidth: "50%",
    maxHeight: "510px",
    objectFit: "cover",
  },
  multiMedia: {
    width: "100%",
    objectFit: "cover",
    height: "200px",
  },
  reactPlayerStyle: {
    width: "100% !important",
    height: "100%  !important",
    background: "#000",
    "& :first-child ": {
      borderRadius: "16px",
    },
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.01)",
  },
  modalWrapper: {
    width: "420px",
    padding: "24px 0",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    outline: "none",
    borderRadius: "10px",
    border: "1px solid #35383F",
  },
  modalTitleWrapper: {
    borderBottom: "1px solid #222",
    paddingBottom: "12px",
  },
  taggedPeopleTitle: {
    fontSize: "18px",
    fontWeight: 800,
    textAlign: "center",
    fontFamily: "Manrope",
  },
  taggedPeopleListWrapper: {
    padding: "0 24px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  taggedUserWrapper: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  taggedUserAvatar: {
    width: "44px",
    height: "44px",
    borderRadius: "50%",
    border: "0.5px solid #ccc",
  },
  taggedUserInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  taggedUserNameWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  taggedUserFullname: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: 1.2,
    fontFamily: "Manrope",
  },
  taggedUserName: {
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: 1.2,
    color: "#6B6B6B",
    fontFamily: "Manrope",
  },
  followTaggedUserBtn: {
    marginLeft: "auto",
    height: "25px",
    width: "65px",
    borderRadius: "10px",
    padding: "4px 8px 4px 8px",
    fontSize: "12px",
    fontFamily: "Manrope",
    color: "white",
    fontWeight: 500,
    textTransform: "capitalize" as "capitalize",
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  },
});

export default withStyles(styles)(PostBody);
// Customizable Area End