import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled } from "@material-ui/core";
import React from "react";

interface IPropsModalAction {
    title: string;
    dialogContent: JSX.Element;
    actionDialog: JSX.Element;
    isOpen: boolean;
    onSetModal: (bool: boolean) => void;
}

const ModalAction = (props: IPropsModalAction) => {
    return (<DialogModalAction
        open={props.isOpen}
        onClose={() => props.onSetModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
                background: "#0B0B0B",
                border: "1px solid #35383F",
                padding: 24,
                borderRadius: 10,
                width: "calc(375px + 32px + 32px)",
                boxSizing: "border-box",
            }
        }}
    >
        <DialogTitle>
            {props.title}
        </DialogTitle>
        {props.dialogContent}
        <DialogActions>
            <Button className="dialogBtn noBtn" onClick={() => props.onSetModal(false)}>No</Button>
            {props.actionDialog}
        </DialogActions>

    </DialogModalAction>)
}

const DialogModalAction = styled(Dialog)(() => ({
    "& .MuiDialogTitle-root": {
        borderBottom: "1px solid #222222",
        textAlign: "center",
        height: 53,
        padding: 0,
        "& h2": {
            fontSize: 18,
            fontFamily: "Manrope",
            fontWeight: 800,
            color: "#FFF"
        }
    },
    "& .MuiDialogContent-root": {
        padding: "17px 0 25px",
        textAlign: "center",
        "& .MuiDialogContentText-root": {
            fontWeight: 600,
            fontFamily: "Manrope",
            fontSize: 16,
            color: "#FFF",
            margin: 0,
        }
    },
    "& .MuiDialogActions-root": {
        padding: 0,
        justifyContent: "space-between",
        gap: 12,
        "& .dialogBtn": {
            flex: 1,
            padding: 16,
            border: "1px solid #FFF",
            borderRadius: 10,
            cursorL: "pointer",
            "& .MuiButton-label": {
                textTransform: "capitalize",
                fontFamily: "Manrope",
                fontWeight: 700,
                fontSize: 14,
                color: "#FFF",
            }
        },
        "& .dialogBtn.modalPrimaryBtn": {
            background: "#DC2626",
            "& .MuiButton-label": {
                color: "#FFF",
            },
            borderColor: "transparent",
        },
    }
}))

export default ModalAction;