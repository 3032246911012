Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postMethod = "POST";
exports.putMethod = "PUT";
exports.patchMethod = "PATCH";
exports.deleteMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.getListSubscriptions = "/bx_block_subscriptions/my_subscriptions";

exports.cancelSubscriptionEndpoint = "bx_block_subscriptions/cancel_subscription";

exports.purchaseSubscriptionEndpoint = "bx_block_stripe_integration/create_payment_link?flow=subscription";

// Customizable Area End