import React from "react";
import { toast } from "react-toastify";
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { logoutCometChat, sendAPIRequest } from "../../../components/src/utils";
import PlatformSubscriptionExpiry, { IPlatformSubscription } from "./components/PlatformSubscriptionExpiry";

export interface Props {
  isLoggedIn: boolean;
  navigation: any;
  path: string
}


export interface S {
  isTrialExpiredState: boolean;
  listPlatformSubscription: IPlatformSubscription[];
  isShowModalDelete: boolean;
}

export default class SessionGate extends BlockComponent<Props, S, {}> {
  isTrialExpired: boolean = false
  getListSubscriptionId = "";
  purchaseSubscriptionId = "";
  deleteAccountId = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ]

    this.state = {
      isTrialExpiredState: false,
      listPlatformSubscription: [],
      isShowModalDelete: false,
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message): Promise<void> {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && (responseJson?.errors?.[0] === 'Invalid token' || responseJson?.errors?.[0] === 'Token has Expired')) {
        if (this.props.path !== '/') {
          toast.info(responseJson?.errors?.[0], {
            toastId: "invalidToken"
          });
          await this.handleLogout();
        }
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && this.getListSubscriptionId === apiRequestCallId) {
        this.setState({ listPlatformSubscription: responseJson.data })
      }
      if (responseJson && this.deleteAccountId === apiRequestCallId) {
        toast.success("Your account has been successfully deleted.", {
          toastId: "successDeleteAccount"
        });
        this.handleLogout();
      }
      if (responseJson && this.purchaseSubscriptionId === apiRequestCallId) {
        this.handlePurchaseSubscriptionResponse(responseJson)
      }
      await this.handleCheckExpiryAccount(responseJson);
    }
  }

  handlePurchaseSubscriptionResponse = (responseJson: any) => {
    if (responseJson?.payment_link) {
      const { url } = responseJson.payment_link;
      const { location: navigateLocation } = window;
      navigateLocation.href = url;
    } else {
      toast.error("Oops! It looks like you can’t purchase the subscription right now. Please try again later.", {
        toastId: "errorPurchaseSubscription"
      })
    }
  }

  handleCheckExpiryAccount = async (responseJson: any) => {
    const listScreenIsNotSubscription = [
      "/automaticrenewals",
      "/emailaccountloginblock",
      "/",
      "/emailaccountregistration",
      "/otpinputauth",
      "/categories",
      "/customisableusersubscriptions",
      "/becomeowner",
      "/followers",
    ]
    const isNotSubsciptionScreen = !listScreenIsNotSubscription.includes(this.props.path.toLowerCase());
    const isLoggedUserApi = responseJson?.data?.type === 'logged_user'
    if (isNotSubsciptionScreen && !this.isTrialExpired && isLoggedUserApi) {
      const endDateStr = responseJson?.data?.attributes?.subscription_details?.end_date
      if (endDateStr) {
        const endDate = new Date(endDateStr);
        const currentDate = new Date();
        if (currentDate > endDate) {
          const token = await getStorageData("authToken");
          if (!token) return;
          this.isTrialExpired = true
          this.setState({ isTrialExpiredState: true })
          await this.getListSubscriptions();
        }
      }
    }
  }

  getListSubscriptions = async () => {
    const token = await getStorageData("authToken");
    const headers = {
      token,
    }
    const endpoint = "bx_block_subscriptions/subscriptions";
    this.getListSubscriptionId = sendAPIRequest(endpoint, {
      headers,
      method: "GET",
    })
  }

  handlePurchaseSubscription = async (subscriptionId: number) => {
    const token = await getStorageData("authToken");
    const headers = {
      token,
    }
    const endpoint = "bx_block_stripe_integration/create_payment_link?flow=subscription";
    this.purchaseSubscriptionId = sendAPIRequest(`${endpoint}&subscription_id=${subscriptionId}`, {
      method: "POST",
      headers,
    })
  }

  handleLogout = async () => {
    try {
      await logoutCometChat();
      Object.keys(localStorage).forEach(async (key) => {
        await removeStorageData(key);
      });
      this.props.navigation.history.replace("/")
    } catch (error) {
      console.error("Handle logout in session gate failed with error ", error)
    }
  }

  handleOnClickDelete = () => {
    this.setState({ isShowModalDelete: true })
  }
  handleCloseDeleteModal = () => {
    this.setState({ isShowModalDelete: false })
  }

  handleDeleteAccount = async () => {
    const token = await getStorageData("authToken");
    let currentUser = await getStorageData("selectedUser");
    if (!currentUser) {
      toast.error("Oops! It looks like you can't delete your account right now. Please try again later.", {
        toastId: "errorDeleteAccount"
      })
    }
    currentUser = JSON.parse(currentUser);
    const headers = {
      token,
    }
    const endpoint = "account_block/accounts";
    this.deleteAccountId = sendAPIRequest(`${endpoint}/${currentUser.user_id}`, {
      method: "DELETE",
      headers,
    })
  }

  render() {
    return (
      <>
        {this.props.children}
        <PlatformSubscriptionExpiry
          isOpen={this.state.isTrialExpiredState}
          navigation={this.props.navigation}
          isShowModalDelete={this.state.isShowModalDelete}
          listPlatformSubscription={this.state.listPlatformSubscription}
          onLogout={this.handleLogout}
          onClickDelete={this.handleOnClickDelete}
          onDeleteAccount={this.handleDeleteAccount}
          onCloseDelete={this.handleCloseDeleteModal}
          onPurchaseSubscription={this.handlePurchaseSubscription}
        />
      </>
    )
  }
}