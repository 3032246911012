import React from "react";
// Customizable Area Start
import {
  Typography,
  Switch,Box
} from "@material-ui/core";
import { createTheme,withStyles} from "@material-ui/core/styles";

// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const IOSSwitch = withStyles(({
  root: {
    width: 48,
    height: 28,
    borderRadius:'40px',
    padding:0
  },

  switchBase: {
    padding: 0,
    transform: "translate(5px,4.4px)",
    left:-2,
    "&$checked": {
      transform: "translate(23px,4.4px)",
      left:0,
      color: theme.palette.common.white,
      "& + $track": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        opacity: 1,
        border: "none",
      },
    },
    
  },
  thumb: {
    width: 24,
    height: 24,
    margin:"-2.5px -1px"
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: "#1F1F1F",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(Switch);

// Customizable Area End

import CommonSwitchController, {
  Props,
  configJSON,
} from "./CommonSwitchController";

export default class CommonSwitch extends CommonSwitchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
      // Customizable Area Start
     
      // Customizable Area End
    return (
      // Customizable Area Start
     
    <Box style={webStyle.mainContainer}>
    
     {this.props.toggle=="ON"?<Box style={webStyle.switchContainer}>
        <Box style={webStyle.switchTextContainer}>
         <IOSSwitch
          data-test-id="switch"
          checked={this.state.checked}
           onChange={(event) => this.handleChange(event)}
          />
            {!this.state.checked?<Typography data-test-id='dark' style={webStyle.lightText}>{configJSON.dark}</Typography>:
            <Typography data-test-id='light' style={webStyle.darkText}>{configJSON.light}</Typography>}
          </Box>
      </Box>:""}
          {this.props.render({handleChange:this.handleChange,...this.state,...this.props})}


       </Box>               
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle={
  mainContainer:{
    position:'relative' as 'relative',
    width: '100%',
    height: '100%',
  },
  switchContainer:{
    display:'flex',
    flexDirection:'column' as 'column',
    alignItems:'flex-end',
    position:"absolute" as 'absolute',
    right:"0%"
  },
  lightText:{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    color:'#FFFFFF',
    marginTop:'3px',
    marginRight:'30px',
    marginLeft:'8px'
  },
  darkText:{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    color:'#222222',
    marginTop:'3px',
    marginRight:'30px',
    marginLeft:'8px'
  },
  switchTextContainer:{
    display:'flex',
    flexDirection:'row' as 'row',
    marginTop:'20px',
    marginRight:'10px',
  }
}
// Customizable Area End