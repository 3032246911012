// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest, TAny } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  checked: boolean;
}

interface IPlatformSubscription {
  id: string;
  type: string;
  attributes: {
    name: string,
    description: string,
    features: string[],
    price: string,
    validity_unit: string,
    subscription_id?: string;
    is_subscribed?: boolean,
    renewal_date?: string,
    start_on?: string,
    status?: string
  };
}

interface S {
  listActiveSubscription: IPlatformSubscription[];
  listUpcomingSubscription: IPlatformSubscription[];
  listAvailableSubscription: IPlatformSubscription[];
  currentSubscriptionId: string;
  expiryMessage: string;
}

interface SS {
  id: any;
}

export default class MyPlatformSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetListSubscriptions = "";
  apiPurchaseSubscription = "";
  apiCancelSubscription = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      listActiveSubscription: [],
      listUpcomingSubscription: [],
      listAvailableSubscription: [],
      currentSubscriptionId: "",
      expiryMessage: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiGetListSubscriptions && responseJson) {
      this.setState({ listActiveSubscription: responseJson?.active_subscriptions?.data });
      this.setState({ listUpcomingSubscription: responseJson?.upcoming_subscriptions?.data});
      this.setState({ listAvailableSubscription: responseJson?.other_subscriptions?.data });
      this.setState({ expiryMessage: responseJson?.expiry_message ?? "" });
    }
    if (apiRequestCallId === this.apiPurchaseSubscription && responseJson) {
      this.handleResponseSubmitSubscription(responseJson)
    }
    if (apiRequestCallId === this.apiCancelSubscription && responseJson) {
      if (responseJson.errors) {
        toast.error("Cannot cancel subscription at this moment");
      } else {
        toast.success("Cancel subscription successfully");
        await this.getListSubscriptions();
      }
      this.setState({ currentSubscriptionId: "" });
    }
    runEngine.debugLog("Message Recived", message);
  }

  async componentDidMount(): Promise<void> {
    const paymentStatus = new URLSearchParams(window.location.search).get('payment');
    if (paymentStatus === "success") toast.success("Subscription successfully", {
      toastId: "subscriptionSuccessfully"
    });
    await this.getListSubscriptions();
  }

  getListSubscriptions = async () => {
    const token = await getStorageData("authToken");
    const endPointGetSubscriptions = `${configJSON.getListSubscriptions}?tab=Platform`
    const headers = {
      token
    }
    this.apiGetListSubscriptions = sendAPIRequest(endPointGetSubscriptions, {
      method: "GET",
      headers
    })
  }

  handlePurchaseSubscription = async (subscriptionId: string) => {
    await this.purchaseSubscriptionApi(subscriptionId)
  }

  purchaseSubscriptionApi = async (subscriptionId: string) => {
    const token = await getStorageData("authToken");
    const headers = {
      token
    };

    this.apiPurchaseSubscription = sendAPIRequest(`${configJSON.purchaseSubscriptionEndpoint}&subscription_id=${subscriptionId}`, {
      method: "POST",
      headers,
    })
  }

  handleResponseSubmitSubscription = (responseJson: TAny) => {
    if (responseJson?.payment_link) {
      const { url } = responseJson.payment_link;
      this.handleNavigateUrl(url);
    } else {
      toast.error("Oops! It looks like you can’t purchase the subscription right now. Please try again later.", {
        toastId: "errorPurchaseSubscription"
      })
    }
  }

  handleNavigateUrl = (url: string) => {
    const { location: navigateLocation } = window;
    navigateLocation.href = url;
  }

  handleCancelSubscription = (subscriptionId: string) => {
    this.setState({currentSubscriptionId: subscriptionId})
  }

  handleCloseModalCancelSubscription = () => {
    this.setState({currentSubscriptionId: ""});
  }

  cancelSubscription = async () => {
    const token = await getStorageData("authToken");
    const headers = {
      token
    };
    this.apiCancelSubscription = sendAPIRequest(`${configJSON.cancelSubscriptionEndpoint}?subscription_id=${this.state.currentSubscriptionId}`, {
      method: "PUT",
      headers,
    })
  }
}
// Customizable Area End
