import { Box, Button, Paper, styled, Table, TableBody, TableCell, TableCellProps, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@material-ui/core";
import { NotInterestedOutlined } from "@material-ui/icons";
import { down } from "../../../subscriptionbilling2/src/assets";
import React from "react";
import ImageRenderer from "../../../../components/src/ImageRenderer.web";
interface ISubscription {
    [key: string]: any;
}

type TAlignTable = TableCellProps["align"]

interface IColumn {
    key: string;
    label: string;
    width?: number;
    align?: TAlignTable;
    textStyle?: any;
    isHasUserName?: boolean;
}

interface IPropsTableSubscriptions {
    title: string;
    subscriptions: ISubscription[];
    columns: IColumn[];
    tableMaxHeight: number;
}

const TableSubscriptions = (props: IPropsTableSubscriptions) => {
    return <TableSubscriptionsStyled>
        <Box className="header__title">
            <Typography className="h4">
                {props.title}
            </Typography>
        </Box>
        {!props.subscriptions.length ?
            <Box style={{ height: '19px' }}>
                <Typography style={{ fontFamily: 'Manrope', fontSize: '16px', fontWeight: 600, lineHeight: '19.2px', letterSpacing: '0%', textAlign: 'center' }}>You don't have any canceled subscriptions. </Typography>
            </Box>
            :
            <Box className="table_container">
                <TableContainer style={{ maxHeight: props.tableMaxHeight }} component={Paper}>
                    <Table className="myTable">
                        <TableHead className="myTableHead">
                            <TableRow className="myTableRow">
                                {props.columns.map((column, index) => (
                                    <TableCell
                                        key={index}
                                        className="myTableCell"
                                        align={column.align}
                                        style={{
                                            width: column.width && `${column.width}px`,
                                        }}
                                    >
                                        <Typography style={{ ...column.textStyle }}>{column.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="myTableBody">
                            {props.subscriptions.map((subscription, rowIndex) => (
                                <TableRow className="myTableRow" key={rowIndex}>
                                    {props.columns.map((column, colIndex) => {
                                        if (column.key === "action") {
                                            return <TableCell className="myTableCell" align="left">
                                                <Button className="cancelBtn" startIcon={<NotInterestedOutlined style={{ color: "#DC2626" }} />}>
                                                    <Typography>Cancel</Typography>
                                                </Button>
                                            </TableCell>
                                        }
                                        return (
                                            <TableCell
                                                className="myTableCell"
                                                key={colIndex}
                                                align={column.align}
                                            >
                                                <Typography>
                                                    {subscription[column.key]} {column?.isHasUserName && <Typography component="span">{subscription["userName"]}</Typography>}
                                                </Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className="showMoreContainer">
                    <Button className="showMoreBtn">
                        Show more
                        <ImageRenderer src={down} width={16} height={16} />
                    </Button>
                </Box>
            </Box>
        }
    </TableSubscriptionsStyled>
}

const TableSubscriptionsStyled = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& .header__title": {
        padding: "10px 0",
        "& p": {
            fontFamily: "Manrope",
            fontWeight: 600,
            fontSize: 16,
            color: "#FFF",
        }
    },
    "& .table_container": {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        "& .MuiTableContainer-root": {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            "& .myTable": {
                backgroundColor: 'transparent',
                "& .myTableHead": {
                    "& .myTableRow": {
                        "& .myTableCell": {
                            height: 45,
                            borderBottom: '1px solid #772DD9',
                            boxSizing: "border-box",
                            padding: 8,
                            "& p": {
                                fontFamily: 'Manrope',
                                fontWeight: 500,
                                fontSize: '10px',
                                color: '#999999',
                            }
                        }
                    },
                },
                "& .myTableBody": {
                    "& .myTableCell": {
                        height: 56,
                        borderColor: "#222222",
                        boxSizing: "border-box",
                        padding: 8,
                        "& p": {
                            fontFamily: "Manrope",
                            fontWeight: 500,
                            fontSize: 10,
                            color: "#FFF",
                            "& span": {
                                fontFamily: "Manrope",
                                fontSize: 10,
                                fontWeight: 500,
                                background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent' as "transparent",
                            }
                        },
                    }
                },
                "& .myTableHead, .myTableBody": {
                    "& .myTableCell": {
                        padding: "12px 8px",
                        "& .cancelBtn": {
                            "& .MuiButton-label": {
                                display: "flex",
                                gap: 4,
                                "& .MuiButton-startIcon": {
                                    margin: "unset"
                                }
                            },
                            border: "1px solid #DC2626",
                            padding: "4px 6px",
                            borderRadius: 10,
                            "& p": {
                                fontFamily: "Inter",
                                fontSize: 12,
                                fontWeight: 500,
                                color: "#DC2626",
                                textTransform: "capitalize",
                            }
                        }
                    }
                }
            }
        },
        "& .showMoreContainer": {
            height: '24px',
            paddingTop: '4px',
            paddingBottom: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            "& .showMoreBtn": {
                padding: 0,
                "& span": {
                    fontFamily: "Manrope",
                    fontWeight: 700,
                    fontSize: 12,
                    color: "#999999",
                    textTransform: "none"
                },
            }
        }
    },
}))

export default TableSubscriptions;