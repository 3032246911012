import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef, RefObject } from "react";
import { EmojiData } from "emoji-mart";
import { BaseMessage, CometChat } from "@cometchat/chat-sdk-javascript";
import {
  blockUsers,
  CUSTOM_MESSAGE_TYPE,
  TYPE_CONVERSATION,
  getMessageFromConversation,
  sendCustomMessage,
  startTypingMessage,
  stopTypingMessage,
  TTypeConversation,
  unblockUsers,
  CUSTOM_MESSAGE_DATA_TYPE,
  leaveGroup,
  IConversation,
  updateMessageTemplates,
  IReaction,
  clearConversation,
  fetchMessageInConversation,
  getMessageRequestBuilder,
  getMessageRequestBuilderSearchChat,
  getGroupData,
  updateGroupData,
} from "../../../components/src/CometChat";
import { CometChatMessageTemplate, CometChatUIKit } from "@cometchat/chat-uikit-react";
import { EMITTER_EVENT_VARIABLES, emitterEvents, handleConditionFunction, scrollIntoElement, sendAPIRequest, TAny } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { IReportIssue } from "../../customisableuserprofiles/src/ProfileController.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

export interface ISetMessageReply {
  parentMessageId: number | null;
  nameReply: string;
  sentAtReply: string;
  textMessageReply: string;
  messageType: string;
  attachment: any;
}

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}

export interface IConversationSettings {
  "name"?: string;
  "description"?: string;
  "disappearing_messages": number;
  "mute_notification"?: boolean;
  "icon"?: string;
}

export interface IConversationPermissions {
  "can_edit_group_settings": boolean;
  "cannot_send_messages": string[];
  "can_add_members": boolean;
  "admin_approve_new_members": boolean;
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any
  checked?: boolean,
  onSendMessage: (message: string, expiryTime: number, parentMessageIdReply?: number | null) => void
  onSendMediaMessage: (file: File[], expiryTime: number, parentMessageIdReply?: number | null) => void
  conversation: CometChat.User | CometChat.Group;
  onCloseSelectedConversation: () => void;
  refreshMessageList: boolean;
  onRefreshMessage: () => void;
  onRefreshConversations: () => void;
  onStartCall: (receiverID: string, receiverType?: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  token: string;
  template: CometChatMessageTemplate[];
  message: string;
  listMessage: BaseMessage[];
  emojiAnchorEl: null | HTMLElement;
  isTypingMessage: boolean;
  loginUser: CometChat.User | null;
  idReceiverMessage: string;
  listFileUpload: File[];
  muteNotificationsModal: boolean;
  disappearingNotificationModal: boolean;
  blockUserModal: boolean;
  clearChatModal: boolean;
  mediaDocsElement: HTMLDivElement | null;
  isBlockedByMe: boolean;
  hasBlockByMe: boolean;
  isOpenModalTotalReaction: boolean;
  listReaction: IReaction[];
  conversationSetting: IConversationSettings;
  conversationPermissions: IConversationPermissions;
  messageReplyItem: ISetMessageReply | null;
  valueSearchChat: {
    message: BaseMessage | null;
    searchKeyword: string;
  };
  isToggleSearch: boolean;
  reportingUserId: string;
  showReportUserModal: boolean;
  reportPageLoading: boolean;
  reportIssue: number | undefined
  reportUserMainList: Array<IReportIssue>;
  reportUserSubCatList: Array<IReportIssue>;
  reportSubIssue: number | undefined
  reportCurrentView: string;
  isOpenEditGroup: boolean;
  listMemberGroupChat: TAny[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  typingMessageListenerId: string = "Typing_Message_Listener_Id";
  reactionMessageListenerId: string = "Reaction_Message_Listener_Id";
  getConversationSettingsCallID: string = '';
  getConversationPermissionsCallID: string = '';
  updateConversationSettingsCallID: string = '';
  updateConversationPermissionsCallID: string = '';
  apiGetReportIssuesCallId: string = '';
  apiGetReportSubIssueCallId: string = '';
  apiReportSubmitCallId: string = '';
  uploadImageCallId: string = "";
  fileInputRef: RefObject<HTMLInputElement>;
  messageContainerRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      token: '',
      template: [],
      message: "",
      listMessage: [],
      emojiAnchorEl: null,
      isTypingMessage: false,
      loginUser: null,
      idReceiverMessage: "",
      listFileUpload: [],
      muteNotificationsModal: false,
      disappearingNotificationModal: false,
      blockUserModal: false,
      clearChatModal: false,
      mediaDocsElement: null,
      isBlockedByMe: false,
      hasBlockByMe: false,
      isOpenModalTotalReaction: false,
      listReaction: [],
      conversationSetting: {
        "name": "",
        "description": "",
        "disappearing_messages": 0,
        "mute_notification": false,
        "icon": "",
      },
      conversationPermissions: {
        "can_edit_group_settings": true,
        "cannot_send_messages": [],
        "can_add_members": true,
        "admin_approve_new_members": false
      },
      messageReplyItem: {
        parentMessageId: null,
        textMessageReply: "",
        sentAtReply: "",
        nameReply: "",
        messageType: "",
        attachment: [],
      },
      valueSearchChat: {
        message: null,
        searchKeyword: ""
      },
      isToggleSearch: false,
      showReportUserModal: false,
      reportPageLoading: false,
      reportUserMainList: [],
      reportUserSubCatList: [],
      reportIssue: undefined,
      reportSubIssue: undefined,
      reportCurrentView: 'main',
      reportingUserId: "",
      isOpenEditGroup: false,
      listMemberGroupChat: [],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.fileInputRef = createRef()
    this.messageContainerRef = createRef()
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.apiSuccessCallBackController(apiRequestCallId, responseJson)
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.getConversationSettingsCallID]: this.handleGetConversationSettingResponse,
      [this.getConversationPermissionsCallID]: this.handleGetConversationPermissionResponse,
      [this.updateConversationSettingsCallID]: this.handleUpdateConversationSettingsResponse,
      [this.updateConversationPermissionsCallID]: this.handleUpdateConversationPermissionsResponse,
      [this.apiGetReportIssuesCallId]: this.handleReportIssueResponse,
      [this.apiReportSubmitCallId]: this.handleReportPostResponse,
      [this.apiGetReportSubIssueCallId]: this.handleSubCatReportResponse,
      [this.uploadImageCallId]: this.handleUploadImageResponse,
    };

    if (apiRequestCallId) {
      const successCallback: ((responseJson: any) => void) = successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  async componentDidMount() {
    const token = await getStorageData('authToken');
    const { blockedByMe = false, guid = "" } = this.props.conversation as unknown as IConversation;
    this.handleListenTypingMessage();

    // Handle promise all multiple API calling in same time
    await this.handleFetchingDataViewChat();

    CometChat.addMessageListener(this.reactionMessageListenerId, {
      onMessageReactionAdded: async (message: { reaction: { messageId: string } }) => {
        emitterEvents.emit(EMITTER_EVENT_VARIABLES.MESSAGE_ID_REACTION, message.reaction.messageId);
      },
      onMessageReactionRemoved: async (message: { reaction: { messageId: string } }) => {
        emitterEvents.emit(EMITTER_EVENT_VARIABLES.MESSAGE_ID_REACTION, message.reaction.messageId);
      }
    })

    const definedTemplates = updateMessageTemplates({
      userInfo: this.state.loginUser,
      checked: this.props.checked,
      isBlockedByMe: blockedByMe,
      conversationId: this.state.idReceiverMessage,
      onSetParentMessageId: this.handleSetParentMessageId,
      onRefreshMessageList: this.props.onRefreshMessage,
      onOpenModalTotalReaction: this.handleOpenModalTotalReaction,
      onScrollToBottom: this.handleScrollToBottom
    })
    this.setState({ template: definedTemplates, isBlockedByMe: blockedByMe, token });
  }

  handleFetchingDataViewChat = async () => {
    const { guid = "" } = this.props.conversation as unknown as IConversation;
    const token = await getStorageData('authToken');
    try {
      const promises = [
        this.getMessagesInConversation(),
        this.getIdReceiverConversaion(),
        this.setUserLoginInformation(),
        guid ? this.getConversationPermissions(token) : Promise.resolve(),
        this.getConversationSettings(token),
      ];

      await Promise.all(promises);
    } catch (error) {
      console.error('Error executing promises view chat controller:', error);
    }
  }

  handleGetConversationSettingResponse = (responseJson: any) => {
    if (responseJson.data) {
      const { settings } = responseJson.data;
      if (Object.keys(settings).length) {
        const members = responseJson?.members as unknown as { scope: string }[] ?? [];
        const mappingMembers = members.filter(member => member.scope !== "admin");
        this.setState({
          conversationSetting: {
            ...this.state.conversationSetting,
            disappearing_messages: settings.disappearing_messages ?? 0
          },
          listMemberGroupChat: mappingMembers ?? [],
        })
      }
    } else {
      this.setState({
        conversationSetting: {
          ...this.state.conversationSetting,
          disappearing_messages: 0
        },
        listMemberGroupChat: [],
      })
    }
  }

  handleGetConversationPermissionResponse = (responseJson: any) => {
    if (responseJson.data) {
      const { permissions } = responseJson.data;
      if (Object.keys(permissions).length) {
        this.setState({
          conversationPermissions: permissions
        })
      }
    }
  }

  handleReportIssueResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        reportUserMainList: responseJson.data,
        reportPageLoading: false,
      })
    }
  }

  handleReportPostResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      toast.success(responseJson.messages[0])
      this.setState({
        reportCurrentView: "submit"
      })
    }
  }

  handleSubCatReportResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        reportUserSubCatList: responseJson.data,
        reportPageLoading: false
      })
    }
  }

  handleUploadImageResponse = (responseJson: any) => {
    if (!responseJson?.errors) {
      const { url } = responseJson?.data?.attributes;
      this.updateConversationSettings({ icon: url });
    }
  }


  handleUpdateConversationSettingsResponse = async () => {
    const token = await getStorageData("authToken");
    await this.getConversationSettings(token);
  }

  handleUpdateConversationPermissionsResponse = async (responseJson: any) => {
    this.handleGetConversationPermissionResponse(responseJson)
  }

  setUserLoginInformation = async () => {
    const userInformation = await CometChatUIKit.getLoggedinUser();
    this.setState({ loginUser: userInformation });
  }

  setListMessage = async (idType: string, type: TTypeConversation) => {
    const listMessageUser = await getMessageFromConversation(idType, type);
    this.setState({ listMessage: listMessageUser })
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    const { conversation } = this.props;
    const { isBlockedByMe, idReceiverMessage, valueSearchChat } = this.state;
    const { guid = "", blockedByMe = false } = this.props.conversation as unknown as IConversation;
    const token = await getStorageData('authToken');

    if (prevProps.conversation !== conversation) {
      await this.getMessagesInConversation();
      this.getIdReceiverConversaion();
      this.setState({
        message: "",
        listFileUpload: [],
        messageReplyItem: null,
        valueSearchChat: {
          message: null,
          searchKeyword: ""
        },
        isToggleSearch: false,
        isBlockedByMe: blockedByMe,
        conversationSetting: {
          "disappearing_messages": 0,
        },
      });
      if (guid) {
        await this.getConversationPermissions(token);
      }
      await this.getConversationSettings(token)
    }

    if (
      prevState.isBlockedByMe !== isBlockedByMe ||
      prevState.idReceiverMessage !== idReceiverMessage ||
      prevState.valueSearchChat !== valueSearchChat
    ) {
      this.updateTemplates();
    }
  }

  async componentWillUnmount() {
    CometChat.removeMessageListener(this.typingMessageListenerId)
    CometChat.removeMessageListener(this.reactionMessageListenerId)
  }

  updateTemplates() {
    const {
      loginUser,
      isBlockedByMe,
      idReceiverMessage,
      valueSearchChat
    } = this.state;

    const { checked, onRefreshMessage } = this.props;

    const definedTemplates = updateMessageTemplates({
      userInfo: loginUser,
      checked,
      isBlockedByMe,
      conversationId: idReceiverMessage,
      searchKeyword: valueSearchChat.searchKeyword,
      onUnblockUser: this.handleUnblockUser,
      onRefreshMessageList: onRefreshMessage,
      onSetParentMessageId: this.handleSetParentMessageId,
      onOpenModalTotalReaction: this.handleOpenModalTotalReaction,
      onScrollToBottom: this.handleScrollToBottom
    });

    this.setState({ template: definedTemplates });
  }

  getIdReceiverConversaion = async () => {
    const { conversation } = this.props;
    const { uid = "", guid = "" } = conversation as unknown as { uid?: string, guid?: string };
    const typeConversation = guid ? TYPE_CONVERSATION.GROUP : TYPE_CONVERSATION.USER
    await this.setListMessage(uid || guid, typeConversation);
    this.setState({ idReceiverMessage: uid || guid })
  }

  handleListenTypingMessage = () => {
    CometChat.addMessageListener(
      this.typingMessageListenerId,
      new CometChat.MessageListener({
        onTypingStarted: (typingIndicator: CometChat.TypingIndicator) => {
          const senderMessageUid = typingIndicator.getSender().getUid();
          if (senderMessageUid !== this.state.idReceiverMessage) return;
          this.setState({ isTypingMessage: true });
        },
        onTypingEnded: () => {
          this.setState({ isTypingMessage: false });
        }
      })
    );
  }

  handleExitGroup = async (guid: string) => {
    await leaveGroup(guid);
    this.props.onCloseSelectedConversation();
  }

  handleMessageChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ message: event.target.value });
    await startTypingMessage(this.props.conversation);
  };

  handleBlurMessage = () => {
    stopTypingMessage(this.props.conversation);
  }

  handleClickEmoji = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ emojiAnchorEl: event.currentTarget });
  };

  handleCloseEmoji = () => {
    this.setState({ emojiAnchorEl: null });
  };

  handleSelect = (emoji: EmojiData) => {
    this.setState({ message: this.state.message + (emoji as any).native })
  }

  handleClickSendMessage = async () => {
    const { messageReplyItem, message, listFileUpload, conversationSetting } =
      this.state;
    if (message)
      this.props.onSendMessage(
        message,
        Number(conversationSetting.disappearing_messages),
        messageReplyItem?.parentMessageId
      );
    if (!!listFileUpload.length) {
      this.props.onSendMediaMessage(
        listFileUpload,
        Number(conversationSetting.disappearing_messages),
        messageReplyItem?.parentMessageId
      );
    }
    this.setState({ message: "", listFileUpload: [], messageReplyItem: null });
  };

  handleSelectFileIcon = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current?.click();
    }
  }

  handleSelectFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newFiles = Array.from(files);
      const { listFileUpload } = this.state;

      const filesToAdd = newFiles.filter(
        newFile => !listFileUpload.some(existingFile => existingFile.name === newFile.name)
      );

      if (filesToAdd.length > 0) {
        this.setState({ listFileUpload: [...listFileUpload, ...filesToAdd] });
      }
    }
  }

  handleCloseSelectedFile = () => {
    this.setState({ listFileUpload: [] })
  }

  handleRemoveFileUpload = (fileName: string) => {
    const filesSelectedWithoutFileName = this.state.listFileUpload.filter(fileUpload => fileUpload.name !== fileName);
    this.setState({ listFileUpload: filesSelectedWithoutFileName });
  }

  handleToggleMuteModal = () => {
    this.setState({ muteNotificationsModal: !this.state.muteNotificationsModal })
  }

  handleToggleDisappearingModal = () => {
    this.setState({ disappearingNotificationModal: !this.state.disappearingNotificationModal })
  }

  handleToggleBlockModal = () => {
    this.setState({ blockUserModal: !this.state.blockUserModal })
  }

  handleBlockUser = async (receiverUID: string) => {
    const { isBlockedByMe } = this.state
    const messageAction = handleConditionFunction(isBlockedByMe, "You unblock this Person.", "You block this Person tap to unblock");
    const messageType = handleConditionFunction(isBlockedByMe, CUSTOM_MESSAGE_DATA_TYPE.UNBLOCK, CUSTOM_MESSAGE_DATA_TYPE.BLOCK)
    const receiverType = TYPE_CONVERSATION.USER
    const customData = {
      type: messageType,
      message: messageAction,
    }
    const customType = CUSTOM_MESSAGE_TYPE.CUSTOM_ACTION;
    if (!isBlockedByMe) {
      await sendCustomMessage(receiverUID, receiverType, customData, customType);
      await blockUsers([receiverUID]);
    }
    else {
      await unblockUsers([receiverUID]);
      await sendCustomMessage(receiverUID, receiverType, customData, customType);
    }
    this.setState({ blockUserModal: !this.state.blockUserModal, isBlockedByMe: !isBlockedByMe })
  }

  handleMediaDocs = (anchorEl: HTMLDivElement) => {
    this.setState({ mediaDocsElement: anchorEl })
  }
  handleCloseMediaDocs = () => {
    this.setState({ mediaDocsElement: null })
  }
  handleUnblockUser = () => {
    this.setState({ isBlockedByMe: false })
  }

  handleToggleClearChatModal = () => {
    this.setState({ clearChatModal: !this.state.clearChatModal })
  }

  getConversationSettings = async (token: string) => {
    const { uid = "", guid = "" } = this.props.conversation as unknown as { uid?: string, guid?: string };
    await this.getGroupDataCometChat(guid)

    const receiverId = uid || guid;
    const endpointSettingsConversation = guid ? configJSON.getGroupConversationsSettingsEndPoint : configJSON.getConversationsSettingsEndPoint

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
      "ngrok-skip-browser-warning": true
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getConversationSettingsCallID = requestMessage.messageId;

    const endpoint = endpointSettingsConversation + `/${receiverId}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getConversationPermissions = async (token: string) => {
    const { guid = "" } = this.props.conversation as unknown as { guid?: string };
    if (!guid) return;
    const receiverId = guid;

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
      "ngrok-skip-browser-warning": true
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getConversationPermissionsCallID = requestMessage.messageId;

    const endpoint = configJSON.getGroupConversationsPermissionsEndPoint + `/${receiverId}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMessagesInConversation = async () => {
    this.setState({ isLoading: true })
    const { uid = "", guid = "" } = this.props.conversation as unknown as { uid?: string, guid?: string };
    const receiverType = guid ? TYPE_CONVERSATION.GROUP : TYPE_CONVERSATION.USER
    await fetchMessageInConversation(uid || guid, receiverType);
    this.setState({ isLoading: false })
  }

  getGroupDataCometChat = async (guid: string) => {
    if (guid) {
      const dataGroup = await getGroupData(guid);
      const groupName = dataGroup?.getName();
      const groupIcon = dataGroup?.getIcon();
      const groupDescription = dataGroup?.getDescription();
      const currentConversationSetting = {
        ...this.state.conversationSetting,
        name: groupName,
        description: groupDescription,
        icon: groupIcon
      };
      this.setState({
        conversationSetting: currentConversationSetting
      })
    }
  }

  updateDisappearingMessageSetting = (timeoutFor: string) => {
    this.setState({
      conversationSetting: {
        ...this.state.conversationSetting,
        disappearing_messages: Number(timeoutFor)
      }
    }, () => {
      this.updateConversationSettings({ disappearing_messages: Number(timeoutFor) });
    })
    this.handleToggleDisappearingModal()
  }

  updateConversationSettings = async ({
    name,
    description,
    disappearing_messages,
    icon,
  }: Partial<IConversationSettings>) => {
    const token = await getStorageData('authToken');
    const { uid = "", guid = "" } = this.props.conversation as unknown as { uid?: string, guid?: string };

    const getBodySettings = () => {
      if (uid) return {
        disappearing_messages: disappearing_messages ?? this.state.conversationSetting.disappearing_messages,
        mute_notification: this.state.conversationSetting.mute_notification
      }
      else {
        return {
          // name: name ?? this.state.conversationSetting.name,
          // description: description ?? this.state.conversationSetting.description,
          disappearing_messages: disappearing_messages ?? this.state.conversationSetting.disappearing_messages,
          // icon: icon ?? this.state.conversationSetting.icon
        }
      }
    }
    if (disappearing_messages === undefined) {
      await updateGroupData(guid, {
        name: name ?? this.state.conversationSetting.name,
        description: description ?? this.state.conversationSetting.description,
        icon: icon ?? this.state.conversationSetting.icon
      })
      await this.getGroupDataCometChat(guid)
      return;
    }

    let formData = new FormData()
    formData.append("settings", JSON.stringify(getBodySettings()))

    const receiverId = uid || guid;
    const endpoint = guid ? configJSON.getGroupConversationsSettingsEndPoint : configJSON.getConversationsSettingsEndPoint

    if (receiverId) {
      this.updateConversationSettingsCallID = sendAPIRequest(
        endpoint + `/${receiverId}`,
        {
          method: 'PUT',
          headers: {
            token: token,
          },
          body: formData,
        }
      )
    }
  }

  updateConversationPermissions = async ({
    can_edit_group_settings,
    cannot_send_messages,
    can_add_members,
    admin_approve_new_members,
  }: Partial<IConversationPermissions>) => {
    const { guid = "" } = this.props.conversation as unknown as { uid?: string, guid?: string };

    if (!guid) return;

    const getBodySettings = () => {
      return {
        can_edit_group_settings: can_edit_group_settings ?? this.state.conversationPermissions.can_edit_group_settings,
        cannot_send_messages: cannot_send_messages ?? this.state.conversationPermissions.cannot_send_messages,
        can_add_members: can_add_members ?? this.state.conversationPermissions.can_add_members,
        admin_approve_new_members: admin_approve_new_members ?? this.state.conversationPermissions.admin_approve_new_members,
      }
    }

    let formData = new FormData()
    formData.append("permissions", JSON.stringify(getBodySettings()))

    const token = await getStorageData('authToken');

    const receiverId = guid;
    const endpoint = configJSON.getGroupConversationsPermissionsEndPoint;

    if (receiverId) {
      this.updateConversationPermissionsCallID = sendAPIRequest(
        endpoint + `/${receiverId}`,
        {
          method: 'PUT',
          headers: {
            token: token,
          },
          body: formData,
        }
      )
    }
  }

  clearChat = async () => {
    await clearConversation(this.props.conversation);
    this.handleToggleClearChatModal();
    this.props.onCloseSelectedConversation();
  }

  handleOpenModalTotalReaction = (listReaction: IReaction[]) => {
    this.setState({ isOpenModalTotalReaction: true, listReaction });
  }

  handleCloseModalTotalReaction = () => {
    this.setState({ isOpenModalTotalReaction: false });
  }

  handleSetParentMessageId = (messageReplyItem: ISetMessageReply) => {
    this.setState({ messageReplyItem })
  }

  handleToggleSearch = (bool: boolean) => {
    this.setState({ isToggleSearch: !bool });
    if (bool && this.state.valueSearchChat.message) {
      this.handleSetValueSearchChat({
        message: null,
        searchKeyword: ""
      })
    }
  }

  handleSetValueSearchChat = (value: {
    message: CometChat.BaseMessage | null;
    searchKeyword: string;
  }) => {
    this.setState({ valueSearchChat: value }, () => {
      this.props.onRefreshMessage();
    })
  }

  handleGetMessagesRequestBuilder = () => {
    const { conversation } = this.props;
    const { uid = "", guid = "" } = conversation as unknown as IConversation;
    const { valueSearchChat } = this.state;
    if (valueSearchChat.message) {
      return getMessageRequestBuilderSearchChat(
        valueSearchChat.message,
        uid || guid,
        guid ? TYPE_CONVERSATION.GROUP : TYPE_CONVERSATION.USER
      );
    }
    return getMessageRequestBuilder();
  };

  handleScrollToBottom = () => {
    scrollIntoElement(this.messageContainerRef.current, ".cc-list__content")
  }

  showReportModal = () => {
    const { conversation } = this.props;
    const { uid = "" } = conversation as unknown as IConversation;
    if (uid) {
      this.setState({
        showReportUserModal: true,
        reportPageLoading: true,
      }, () => this.getReportUserMainCategories())
    }
  }

  setReportUserIssue = (id: number) => {
    this.setState({
      reportIssue: id
    })
  }

  setReportUserSubIssues = (id: number) => {
    this.setState({
      reportSubIssue: id
    })
  }

  closeReportUserModal = () => {
    this.setState({
      showReportUserModal: false,
      reportIssue: undefined,
      reportSubIssue: undefined,
      reportingUserId: '',
      reportCurrentView: "main",
      reportUserSubCatList: []
    })
  }

  changeReportView = () => {
    if (this.state.reportIssue !== undefined && this.state.reportSubIssue !== undefined) {
      this.handleSubmitReport()
    } else if (this.state.reportIssue !== undefined) {
      this.setState({
        reportCurrentView: "subcat",
        reportPageLoading: true
      }, this.getReportUserSubCategories)
    }
  }

  handleSubmitReport = async () => {
    let token = await getStorageData("authToken")
    const { conversation } = this.props;
    const { uid = "" } = conversation as unknown as IConversation;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    let endpoint = configJSON.ReportAccount
    let httpBody = {
      "report": {
        "reported_account_uid": uid,
        "report_issue_id": this.state.reportIssue,
        "report_sub_issue_id": this.state.reportSubIssue
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiReportSubmitCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getReportUserMainCategories = async () => {
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetReportIssuesCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getReportIssues)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReportUserSubCategories = async () => {
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetReportSubIssueCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getReportSubIssues}${this.state.reportIssue}`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleToggleOpenEditGroup = async (isBool: boolean) => {
    const { guid = "" } = this.props.conversation as unknown as { guid: string };
    const { token } = this.state;
    if (!guid) return;
    if (!isBool) {
      try {
        await this.fetchConversationData(guid);
      } catch (error) {
        console.error('Error executing promises edit chat:', error);
      }
    } else {
      await this.getConversationSettings(token);
    }
    this.setState({ isOpenEditGroup: !isBool }, () => {
      this.props.onRefreshConversations();
    })
  }

  fetchConversationData = async (guid: string) => {
    const { token } = this.state;
    const promises = [
      this.getConversationPermissions(token),
      this.getConversationSettings(token)
    ];
    await Promise.all(promises);
  }

  handleUploadAttachment = async (imageFile: File) => {
    const headers = {
      token: this.state.token,
      "ngrok-skip-browser-warning": true
    };

    const formData = new FormData();
    formData.append("media", imageFile);

    this.uploadImageCallId = sendAPIRequest(
      "bx_block_bulk_uploading/attachments",
      {
        method: 'POST',
        headers,
        body: formData,
      }
    )
  }

  handleCreateGroupPlan = (id: string) => {
    this.props.navigation.navigate("AddGroupSubscription", { id: id })
  }

  // Customizable Area End
}
