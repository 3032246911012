// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, Tabs, Tab, CircularProgress, Typography } from "@material-ui/core";

import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import InfiniteScroll from 'react-infinite-scroller';

import LibraryController, {
  Props,
} from "./LibraryController";
import RightSideBar from "../../activityfeed/src/RightSideBar.web";
import { handleConditionFunction, highlightText } from "../../../components/src/utils";
import PostHeader from "../../activityfeed/src/PostHeader.web";
import PostBody from "../../activityfeed/src/PostBody.web";
import PostFooter from "../../activityfeed/src/PostFooter.web";
import Comments from '../../comments/src/Comments.web'
import ReportUserModal from "../../customisableuserprofiles/src/ReportUserModal.web";
import PostActivity from "../../customisableuserprofiles/src/PostActivityModal.web";
import MuteModal from "../../customisableuserprofiles/src/MuteModal.web";
import DeletePostModal from '../../activityfeed/src/DeletePostModal.web';
import PinedPostModal from '../../activityfeed/src/PinedPostModal.web';
import SelectRepostModal from '../../repost/src/SelectRepostModal.web';
import CreateRepost from '../../repost/src/CreateRepost.web'

const mediaTabs = [
  {
    id: 0,
    name: 'Audio',
  },
  {
    id: 1,
    name: 'Video',
  },
  {
    id: 2,
    name: 'Photo',
  },
  {
    id: 3,
    name: 'Docs.',
  },
]

export class Library extends LibraryController {
  constructor(props: Props) {
    super(props);
  }

  renderReportModal = () => {
    return (
      <ReportUserModal
        navigation={this.props.navigation}
        id={''}
        checked={this.props.checked}
        showReportPostModal={this.state.showReportPostModal}
        reportPostMainList={this.state.reportPostMainList}
        reportPostSubList={this.state.reportPostSubCatList}
        setReportPostMainCat={this.setReportPostIssue}
        setReportPostSubCat={this.setReportPostSubIssues}
        closeReportPostModal={this.closeReportPostModal}
        changeReportView={this.changeReportView}
        reportPostLoading={false}
        reportCurrentView={this.state.reportCurrentView}
        reportCatId={this.state.reportIssue}
        reportSubCatId={this.state.reportSubIssue}
      />
    )
  }

  renderMuteModal = () => {
    return (
      <MuteModal
        navigation={this.props.navigation}
        id={''}
        checked={this.props.checked}
        activeUser={this.state.activePostUser!}
        onMuteModelClose={this.handleCloseMuteConfirmModal}
        onMuteUser={this.handleClickMuteModal}
        bottompoper={this.state.muteUndoPopup}
        muteModel={this.state.showMuteModal}
        onUnmuteUser={this.unmuteUserAccount}
        onButtompoperlClose={this.handleCLoseMuteUndoPopup}
      />
    )
  }

  
  renderDeletePost = () => {
    return (
      <DeletePostModal
        checked={this.props.checked}
        id=''
        open={this.state.showDeletePostModal}
        onClose={this.handleCloseDeletePost}
        onDeleteConfirmation={this.deletePost}
      />
    )
  }

  renderPinPostModal = () => {
    return (
      <PinedPostModal
        checked={this.props.checked}
        open={this.state.showPinPostModal}
        isPinned={this.state.isPinned}
        onClose={this.handleClosePinPost}
        onPinnedConfirmation={this.pinPost}
        id=''
      />
    )
  }

  handleLoadMorePost = () => {
    this.state.activeIndex === 2 ? this.getMediaPostList() : this.getPostList()
  }

  renderPostList = () => {
    const { postList, loggedUser, selectedPost, postActivity, showPostActivity } = this.state
    const { classes, checked, navigation } = this.props
    if (this.state.isLoading) {
      return (
        <Box className={classes.loadingWrapper}>
          <CircularProgress size={32} style={{ color: '#8a33f0' }} />
        </Box>
      )
    }

    if (postList.length === 0) {
      return (
        <Box className={classes.loadingWrapper}>
          <Typography className={classes.noPostTitle}>No post found</Typography>
        </Box>
      )
    }

    return (
      <>
        {postList.map(post => {
          const highlights = [...post.attributes.body_mentions, ...post.attributes.hash_tags];
          const highlightedBody = post.attributes.body && highlightText(post.attributes.body, highlights);

          return (
            <Box key={post.id} className={classes.postDisplay} >
              <Box style={{ paddingLeft: '24px' }}>
                <PostHeader
                  checked={checked}
                  postData={post}
                  navigation={navigation}
                  onFollow={this.handleClickFollow}
                  onClickMoreOption={this.handleClickMoreOption}
                  onMuteConformation={this.handleShowMuteConfirmModal}
                  onShowReportPost={this.showReportPost}
                  onBlockAccount={this.blockAccount}
                  onDeletePost={this.handleClickDeletePost}
                  onPinPost={this.getPinnedPost}
                  onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
                />
              </Box>
              <Box style={{ marginLeft: "67px" }}>
                <PostBody
                  checked={checked}
                  postData={post}
                />
              </Box>
              <Box style={{ marginLeft: "67px" }}>
                <PostFooter
                  checked={checked}
                  postData={post}
                  onSavePost={this.handleClickSave}
                  onLikePost={this.handleClickLike}
                  onClickComments={this.handleClickComments}
                  showPostActivity={this.handleClickPostActivity}
                  onClickRepost={this.handleShowRepostModal}
                />
              </Box>
              {selectedPost?.id == post.id && postActivity && (
                <PostActivity
                  checked={this.props.checked}
                  onBlockAccount={this.blockAccount}
                  onClickMoreOption={this.handleClickMoreOption}
                  onClosePostActivityModel={this.handleClosePostActivity}
                  onFollow={this.handleClickFollow}
                  highlightedBody={highlightedBody as string}
                  navigation={this.props.navigation}
                  postActivity={postActivity}
                  postActivityModel={showPostActivity}
                  onMuteConformation={this.handleShowMuteConfirmModal}
                  onShowReportPost={this.showReportPost}
                  post={post}
                  onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
                />
              )}
            </Box>
          )
        })}
      </>
    )
  }

  render() {
    const { classes, checked } = this.props
    const {
      activeIndex, postPage, postPagination, showQuote,
      loggedUser, selectedPost, showComments, selectedMediaTab,
    } = this.state

    return (
      <NavigationMenu id="Library" tabItem="Library" checked={this.props.checked} navigation={this.props.navigation}>
        <Box className={classes.container}>
          {selectedPost && showQuote ? (
            <Box
              className={`${classes.contentContainer} ${handleConditionFunction(this.props.checked, classes.borderColorLightTheme, "")}`}
              style={{ overflow: 'scroll' }}>
              <CreateRepost
                postData={selectedPost}
                onCloseQuote={this.handleCloseQuote}
                onGoBackAfterSubmit={this.handleGoBackAfterSubmit}
              />
            </Box>
          ) : (
            <Box
              className={`${classes.contentContainer} ${handleConditionFunction(this.props.checked, classes.borderColorLightTheme, "")}`}
            >
              <Box className={classes.tabWrapper}>
                <Tabs
                  value={activeIndex}
                  onChange={this.handleChangeTab}
                  classes={{ indicator: classes.tabIndicator }}
                  textColor="primary"
                  variant="fullWidth"
                  data-test-id="tabBtn"
                >
                  <Tab label="Save" className={classes.tabItem} />
                  <Tab label="Paid" className={classes.tabItem} />
                  <Tab label="Media" className={classes.tabItem} />
                </Tabs>
              </Box>
              <div className={classes.postContainer} ref={this.postsContainerRef}>
                {<InfiniteScroll
                  pageStart={postPage}
                  loadMore={this.handleLoadMorePost}
                  hasMore={postPage <= postPagination?.total_pages}
                  loader={
                    <div className={classes.loadMore}>
                      {postPage <= postPagination?.total_pages && <CircularProgress disableShrink />}
                    </div>}
                  useWindow={false}
                  getScrollParent={() => this.postsContainerRef.current}
                >
                  <Box className={classes.postsWrapper}>
                    {this.renderPostList()}
                  </Box>
                </InfiniteScroll>}
              </div>
              {activeIndex === 2 && (
                <Box className={classes.mediaTabContainer}>
                  {mediaTabs.map(item => (
                    <Box
                      key={item.id}
                      className={classes.mediatabItem}
                      style={{
                        background: selectedMediaTab === item.id ? "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)" : "unset",
                      }}
                      onClick={() => this.handleChangeMediaTab(item.id)}
                    >
                      {item.name}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          )}
          <RightSideBar checked={this.props.checked} navigation={this.props.navigation} />

          {selectedPost && showComments && (
            <Comments
              checked={checked}
              open={showComments}
              onClose={this.handleCloseCommentsModal}
              postData={selectedPost}
              userInfo={{
                id: loggedUser?.attributes.id!
              }}
              onFollow={this.handleClickFollow}
              onClickMoreOption={this.handleClickMoreOption}
              onMuteConformation={this.handleShowMuteConfirmModal}
              onBlockAccount={this.blockAccount}
              onShowReportPost={this.showReportPost}
              onLikePost={this.handleClickLike}
              onSavePost={this.handleClickSave}
              onUpdateNumberOfComments={this.handleUpdateNumberCommentOfPost}
              onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
            />
          )}
          {this.renderReportModal()}
          {this.renderMuteModal()}
          {this.renderDeletePost()}
          {this.renderPinPostModal()}
          {this.state.selectedPost && (
            <SelectRepostModal
              open={this.state.showRepostModal}
              isUndoRepost={this.state.selectedPost.attributes.is_reposted}
              onClose={this.handleCloseRepostModal}
              onRepost={this.handleRepost}
              onQuote={this.handleQuote}
              onUndoRepost={this.handleUndoRepost}
            />
          )}
        </Box>
      </NavigationMenu>
    )
  }
}

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    columnGap: '24px',
  },
  contentContainer: {
    width: '60%',
    maxWidth: '810px',
    height: 'calc(100vh - 20px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
    paddingTop: '20px',
    borderRight: '2px solid #222222',
    rowGap: '24px',
    position: 'relative',
  },
  borderColorLightTheme: {
    borderColor: "#BABABA"
  },
  tabWrapper: {
    height: '35px',
  },
  tabIndicator: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: '2.9px',
  },
  tabItem: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    borderBottom: "3px solid gray",
    textTransform: "capitalize",
    '&.Mui-selected': {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      WebkitBackgroundClip: 'text',
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
  postContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    overflowY: "scroll",
    marginTop: "30px",
    overflowX: "hidden",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  loadMore: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  postsWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  postDisplay: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "8px 0px",
    width: "100%"
  },
  mediaTabContainer: {
    bottom: '40px',
    position: 'absolute',
    padding: '5px',
    borderRadius: '10px',
    background: "linear-gradient(-45deg, #2b292e, #464648)",
    display: 'flex',
    gap: '10px',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  mediatabItem: {
    padding: '7px 16px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: '12px',
  },
  loadingWrapper: {
    marginTop: '16px',
    display: "flex",
    justifyContent: "center",
  },
  noPostTitle: {
    fontFamily: "Manrope",
    fontWeight: 800,
    fontSize: "24px",
    backgroundClip: 'text',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
  },
});

export default withStyles(styles)(Library);
// Customizable Area End
