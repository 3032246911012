import { Box, Dialog, DialogContent, DialogTitle, IconButton, styled, TextField, Typography } from "@material-ui/core";
import ImageRenderer from "../../../../components/src/ImageRenderer.web";
import React from "react";
import { calendarIcon, dollarIcon, dollarIconGrey, trashIcon } from "../../../customisableuserprofiles/src/assets";
import { iconExpiryMessage } from "../../../subscriptionbilling2/src/assets";

interface IPropPlanProfileSubscription {
    checked?: boolean;
    isOpen: boolean;
    isEditPlan: boolean;
    statusStripeConnect?: "created" | "pending" | "notCreated";
    planPrice: string;
    handleCloseModal: () => void;
    onDeletePlan: (bool: boolean) => void;
    onNextButton: () => void;
    onChangePlanPrice: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const PlanProfileSubscription = (props: IPropPlanProfileSubscription) => {
    const titleDialog = props.isEditPlan ? "Edit Plan" : "Create Plan";
    const labelButton =  props.statusStripeConnect === "notCreated" ? "Next" : props.isEditPlan ? "Save" : "Add subscription"

    const renderStatusStripeConnect = (status?: "created" | "pending" | "notCreated") => {
        switch (status) {
            case "created": return <Box className="statusStripe">
                <Box style={{ width: 8, height: 8, borderRadius: 100, background: "#10B981" }} />
                <Typography>Created</Typography>
            </Box>
            case "pending": return <Box className="statusStripe">
                <Box style={{ width: 8, height: 8, borderRadius: 100, background: "#D97706" }} />
                <Typography>Verification pending</Typography>
            </Box>
            default: return <Box className="statusStripe">
                <Box style={{ width: 8, height: 8, borderRadius: 100, background: "#EF4444" }} />
                <Typography>Not Created</Typography>
            </Box>
        }
    }

    return <PlanProfileSubscriptionStyled
        id="planProfileSubscription"
        open={props.isOpen}
        onClose={props.handleCloseModal}
        PaperProps={{
            style: {
                background: "#0B0B0B",
                border: "1px solid #35383F",
                padding: 20,
                borderRadius: 10,
                width: "calc(375px + 32px + 32px)",
                boxSizing: "border-box",
                gap: 24,
                alignItems: "center",
            }
        }}
    >
        <Box className="headerDialog">
            <DialogTitle>
                {titleDialog}
            </DialogTitle>
            {
                props.isEditPlan && <IconButton className="btnDeletePlan" onClick={() => props.onDeletePlan(true)}>
                    <ImageRenderer src={trashIcon} width={24} height={24} />
                </IconButton>
            }
        </Box>
        <DialogContent className="dialogContent">
            <Box className="planInformation">
                <Box className="information_item">
                    <Box className="information_label">
                        <ImageRenderer src={dollarIcon} width={24} height={24} />
                        <Typography>
                            Price
                        </Typography>
                    </Box>
                    <TextField
                        id="priceInputField"
                        InputProps={{
                            startAdornment: (
                                <ImageRenderer src={dollarIconGrey} width={20} height={20} />
                            ),
                            inputProps: {
                                min: 0,
                            }
                        }}
                        value={props.planPrice}
                        onChange={props.onChangePlanPrice}
                        style={{
                            width: 120
                        }}
                    />
                </Box>
                <Box className="information_item">
                    <Box className="information_label">
                        <ImageRenderer src={calendarIcon} width={24} height={24} />
                        <Typography>
                            Frequency
                        </Typography>
                    </Box>
                    <Typography className="period_plan">
                        Monthly
                    </Typography>
                </Box>
            </Box>
            <Box className="stripeContainer">
                <Box className="statusStripeContainer">
                    <Box className="labelStripe">
                        <Typography>
                            Stripe Connect Account
                        </Typography>
                    </Box>
                    {renderStatusStripeConnect(props.statusStripeConnect)}
                </Box>
                {
                    props.statusStripeConnect !== "created" && <Box className="infoNotConnectedStripe">
                        <ImageRenderer src={iconExpiryMessage} width={28} height={28} />
                        <Typography>
                            {
                                props.statusStripeConnect === "notCreated"
                                    ? "Please create a Stripe Connect account to proceed."
                                    : "You cannot proceed further until the Stripe Connect account is created, please check later"
                            }
                        </Typography>
                    </Box>
                }
            </Box>
            {
                props.statusStripeConnect !== "pending" && <Box className="planActions">
                    <Box className="buttonAction" onClick={props.handleCloseModal}>
                        <Typography>
                            Cancel
                        </Typography>
                    </Box>
                    <Box
                     className={`buttonAction activeButton`}
                     onClick={props.onNextButton}
                     >
                        <Typography>
                            {labelButton}
                        </Typography>
                    </Box>
                </Box>
            }
        </DialogContent>
    </PlanProfileSubscriptionStyled>
}

const PlanProfileSubscriptionStyled = styled(Dialog)(() => ({
    "& .headerDialog": {
        position: "relative",
        width: "100%",
        textAlign: "center",
        "& .MuiDialogTitle-root": {
            padding: 0,
            paddingTop: 8,
            paddingBottom: 12,
            "& h2": {
                fontFamily: "Manrope",
                fontWeight: 800,
                fontSize: 24,
                color: "#FFF",
            }
        },
        "& .btnDeletePlan": {
            position: "absolute",
            top: 4,
            right: 0,
        },
    },
    "& .dialogContent": {
        display: "flex",
        flexDirection: "column",
        gap: 24,
        width: "100%",
        boxSizing: "border-box",
        padding: 0,
        "& .planInformation": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            "& .information_item": {
                padding: "10px 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& .information_label": {
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    "& p": {
                        fontFamily: "Manrope",
                        fontSize: 14,
                        fontWeight: 700,
                        color: "#FFF",
                    }
                },
                "& .period_plan": {
                    fontFamily: "Manrope",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "#999"
                },
                "& .MuiTextField-root .MuiInput-underline": {
                    padding: "12px 20px",
                    border: "1px solid #BABABA",
                    borderRadius: 10,
                    height: 44,
                    "&:before": {
                        content: "unset"
                    },
                    "&:after": {
                        content: "unset"
                    },
                    "& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                    },
                    "input[type=number]": {
                        "-moz-appearance": "textfield",
                    }
                },
                "& #priceInputField": {
                    marginLeft: 8,
                    fontFamily: "Manrope",
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#FFF"
                }
            }
        },
        "& .stripeContainer": {
            background: "#181818",
            padding: 12,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            gap: 20,
            "& .statusStripeContainer": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& .labelStripe": {
                    "& p": {
                        fontFamily: "Manrope",
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#FFF",
                    },
                },
                "& .statusStripe": {
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    "& p": {
                        fontFamily: "Manrope",
                        fontWeight: 500,
                        fontSize: 14,
                        color: "#FFF",
                    }
                },
            },
            "& .infoNotConnectedStripe": {
                background: "#222222",
                padding: 12,
                borderRadius: 10,
                display: "flex",
                gap: 12,
                alignItems: "center",
            }
        },
        "& .planActions": {
            display: "flex",
            paddingTop: 12,
            gap: 20,
            "& .buttonAction": {
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                border: "1px solid #FFF",
                borderRadius: 10,
                height: 48,
                boxSizing: "border-box",
                "& p": {
                    fontFamily: "Manrope",
                    color: "#FFF",
                    fontWeight: 700,
                    fontSize: 14,
                }
            },
            "& .buttonAction.activeButton": {
                borderColor: "transparent",
                background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            },
            "& .buttonAction.disabledButton": {
                opacity: 0.6,
                pointerEvents: "none",
                cursor: "not-allowed"
            },
        }
    }
}))

export default PlanProfileSubscription