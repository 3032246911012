import React from "react";

import { Box, styled } from "@material-ui/core";
// Customizable Area Start
import NavigationMenu from '../../navigationmenu/src/NavigationMenu.web';
import RightSideBar from "../../activityfeed/src/RightSideBar.web";
// Customizable Area End

import MySubscriptionController, {
  Props,
  // configJSON,
} from "./MySubscriptionController";
import MyListSubscriptionType from "../../../blocks/utilities/src/components/MyListSubscriptionType";

export default class MySubscription extends MySubscriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <NavigationMenu id='' navigation={this.props.navigation} checked={this.props.checked} tabItem="Subscription">
        <BoxContainer>
          <Box className='centerPortionWithTopNavition'>
            <Box className='TopNavigation'>
              <span className="label">Subscriptions</span>
            </Box>
            <MyListSubscriptionType
              checked={this.props.checked}
              navigation={this.props.navigation}
            />
          </Box>
          <RightSideBar checked={this.props.checked} navigation={this.props.navigation} />
        </BoxContainer>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const BoxContainer = styled(Box)({
  display:"flex",
  width: "100%",
  "& .centerPortionWithTopNavition":{
      display:'flex',
      width:'60%',
      flexDirection:'column',
      overflow:"hidden",
      scrollbarWidth:"none",
      maxWidth:'810px',
      "@media (max-width:1000px)": {
        width:'100%'
       }
    },

    "& .TopNavigation":{
      display:'flex',
      flexDirection:'row',
      top:1,
      zIndex:1000,
      maxWidth:" 810px",
      height:84,
      padding:"0px 20px",
      gap:16,
      alignItems:'center',
      "& .label":{
        fontFamily: "Manrope",
        fontSize: 24,
        fontWeight: "800",
        lineHeight: "27.12px",
        letterSpacing: "-0.02em",
        textAlign: "left",
      }
    },
})

// Customizable Area End
