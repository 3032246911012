// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, Tabs, Tab, CircularProgress, Typography, TextField, InputAdornment, ThemeProvider } from "@material-ui/core";

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import InfiniteScroll from 'react-infinite-scroller';

import AdvancedSearchScreenController, {
  Props,
} from "./AdvancedSearchScreenController"
import RightSideBar from "../../activityfeed/src/RightSideBar.web";
import { handleConditionFunction, highlightText } from "../../../components/src/utils";
import PostHeader from "../../activityfeed/src/PostHeader.web";
import PostBody from "../../activityfeed/src/PostBody.web";
import PostFooter from "../../activityfeed/src/PostFooter.web";
import Comments from '../../comments/src/Comments.web'
import ReportUserModal from "../../customisableuserprofiles/src/ReportUserModal.web";
import PostActivity from "../../customisableuserprofiles/src/PostActivityModal.web";
import MuteModal from "../../customisableuserprofiles/src/MuteModal.web";
import DeletePostModal from '../../activityfeed/src/DeletePostModal.web';
import PinedPostModal from '../../activityfeed/src/PinedPostModal.web';
import SelectRepostModal from '../../repost/src/SelectRepostModal.web';
import CreateRepost from '../../repost/src/CreateRepost.web'
import { blackFilterIcon, blackLeftArrowIcon, blackSearchIcon, whiteFilterIcon, whiteLeftArrowIcon, whiteSearchIcon,noresults } from "./assets";

const searchTabs = [
  {
    id: 0,
    name: 'People',
  },
  {
    id: 1,
    name: 'Trending',
  },
  {
    id: 2,
    name: 'News',
  },
  {
    id: 3,
    name: 'Sports',
  },
  {
    id: 4,
    name: 'Live',
  },
]

export class AdvancedSearchScreen extends AdvancedSearchScreenController {
  constructor(props: Props) {
    super(props);
  }

  renderReportModal = () => {
    return (
      <ReportUserModal
        navigation={this.props.navigation}
        id={''}
        showReportPostModal={this.state.showReportPostModal}
        reportPostMainList={this.state.reportPostMainList}
        reportPostSubList={this.state.reportPostSubCatList}
        setReportPostMainCat={this.setReportPostIssue}
        setReportPostSubCat={this.setReportPostSubIssues}
        closeReportPostModal={this.closeReportPostModal}
        changeReportView={this.changeReportView}
        reportPostLoading={false}
        reportCurrentView={this.state.reportCurrentView}
        reportCatId={this.state.reportIssue}
        reportSubCatId={this.state.reportSubIssue}
        checked={this.props.checked}
      />
    )
  }

  renderMuteModal = () => {
    return (
      <MuteModal
        navigation={this.props.navigation}
        id={''}
        activeUser={this.state.activePostUser!}
        onMuteModelClose={this.handleCloseMuteConfirmModal}
        onMuteUser={this.handleClickMuteModal}
        checked={this.props.checked}
        bottompoper={this.state.muteUndoPopup}
        onUnmuteUser={this.unmuteUserAccount}
        onButtompoperlClose={this.handleCLoseMuteUndoPopup}
        muteModel={this.state.showMuteModal}
      />
    )
  }

  renderDeletePost = () => {
    return (
      <DeletePostModal
        checked={this.props.checked}
        id=''
        onClose={this.handleCloseDeletePost}
        onDeleteConfirmation={this.deletePost}
        open={this.state.showDeletePostModal}
      />
    )
  }

  renderPinPostModal = () => {
    return (
      <PinedPostModal
        checked={this.props.checked}
        open={this.state.showPinPostModal}
        onClose={this.handleClosePinPost}
        isPinned={this.state.isPinned}
        onPinnedConfirmation={this.pinPost}
        id=''
      />
    )
  }

  renderPostList = () => {
    const { classes, checked, navigation } = this.props
    const { postList, loggedUser, selectedPost, postActivity, showPostActivity } = this.state
    if (this.state.isLoading) {
      return (
        <Box className={classes.loadingWrapper}>
          <CircularProgress size={32} style={{ color: '#8a33f0' }} />
        </Box>
      )
    }

    if (this.state.postList.length === 0) {
      return (
        <Box className={classes.loadingWrapper}>
          <Typography className={classes.noPostTitle}>No post found</Typography>
        </Box>
      )
    }

    return (
      <>
        {postList.map(post => {
          const highlights = [...post.attributes.body_mentions, ...post.attributes.hash_tags];
          const highlightedBody = post.attributes.body && highlightText(post.attributes.body, highlights);

          return (
            <Box className={classes.postDisplay} key={post.id}>
              <Box style={{ paddingLeft: '24px' }}>
                <PostHeader
                  checked={checked}
                  postData={post}
                  onFollow={this.handleClickFollow}
                  onClickMoreOption={this.handleClickMoreOption}
                  onMuteConformation={this.handleShowMuteConfirmModal}
                  onShowReportPost={this.showReportPost}
                  onBlockAccount={this.blockAccount}
                  onDeletePost={this.handleClickDeletePost}
                  onPinPost={this.getPinnedPost}
                  navigation={navigation}
                  onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
                />
              </Box>
              <Box style={{ marginLeft: "67px" }}>
                <PostBody
                  postData={post}
                  checked={checked}
                />
              </Box>
              <Box style={{ marginLeft: "67px" }}>
                <PostFooter
                  checked={checked}
                  postData={post}
                  onLikePost={this.handleClickLike}
                  onSavePost={this.handleClickSave}
                  onClickComments={this.handleClickComments}
                  showPostActivity={this.handleClickPostActivity}
                  onClickRepost={this.handleShowRepostModal}
                />
              </Box>
              {selectedPost?.id == post.id && postActivity && (
                <PostActivity
                  checked={this.props.checked}
                  onBlockAccount={this.blockAccount}
                  onClickMoreOption={this.handleClickMoreOption}
                  onClosePostActivityModel={this.handleClosePostActivity}
                  onFollow={this.handleClickFollow}
                  highlightedBody={highlightedBody as string}
                  onMuteConformation={this.handleShowMuteConfirmModal}
                  navigation={this.props.navigation}
                  postActivity={postActivity}
                  postActivityModel={showPostActivity}
                  onShowReportPost={this.showReportPost}
                  post={post}
                  onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
                />
              )}
            </Box>
          )
        })}
      </>
    )
  }

  renderTrendingList = () => {
    const { classes } = this.props
    const { isLoading, trendingList } = this.state
    if (isLoading) {
      return (
        <Box className={classes.loadingWrapper}>
          <CircularProgress size={32} style={{ color: '#8a33f0' }} />
        </Box>
      )
    }

    if (trendingList.length === 0) {
      return (
        <Box className={classes.loadingWrapper}>
          <Typography className={classes.noPostTitle}>No post found</Typography>
        </Box>
      )
    }

    return (
      <>
        {trendingList.map((tag, index) => (
          <Box
            key={tag.name}
            className={classes.tagItemWrapper}
            onClick={() => this.handleSelectTag(tag.name)}
          >
            <Typography className={classes.tagLabel}>
              {index + 1} · Trending in India
            </Typography>
            <Typography className={classes.tagName}>{tag.name}</Typography>
            <Typography className={classes.tagPostCount}>
              {tag.post_count} {tag.post_count > 1 ? 'posts' : 'post'}
            </Typography>
          </Box>
        ))}
      </>
    )
  }

  renderPeopleList = () => {
    const { classes } = this.props
    const { isLoading, peopleList} = this.state
    const recentUsers=JSON.parse(localStorage.getItem('recentUsers') || '[]');
    if (isLoading) {
      return (
        <Box className={classes.loadingWrapper}>
          <CircularProgress size={32} style={{ color: '#8a33f0' }} />
        </Box>
      )
    }
    if(peopleList.length===0){


      return (
        <Box>
          {recentUsers.length > 0 ? (
            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '20px',flexDirection:'column'}}>
              <Box style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', gap: '20px', marginBottom: '10px' }}>
                <Typography style={{ fontSize: '20px', fontFamily: 'Manrope', color: 'grey' }}>Recent Searches</Typography>
                <button style={{ color: 'red', cursor: 'pointer', fontFamily: 'Manrope', border: 'none', fontSize: '15px', background: 'transparent' }}
                  onClick={this.clearRecentSearches}
                >Clear All</button>
              </Box>
              <Box style={{display:'flex',alignItems:'center',gap:"10px",flexWrap:'wrap'}}>
              {recentUsers.map((recentUser: any, index: any) => (
                <Box key={index} style={{ display: 'flex', alignItems: 'center', gap: '20px' ,flexWrap:'wrap'}}>
                  <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '5px' }}>
                    <img src={recentUser.profilePhoto} style={{ width: '35px', height: '35px', borderRadius: '50%' }} />
                    <Typography style={{ fontSize: '15px' }} key={index}>
                      {recentUser.fullName}
                    </Typography>
                    <Typography style={{ fontSize: '9px' }} key={index}>
                      {recentUser.userName}
                    </Typography>
                  </Box>
                </Box>
                
              ))}
              </Box>
            </Box>
          ):(
            <Box className={classes.loadingWrapper}>
            <img src={noresults} style={{width:'80px',height:'80px'}}/>
            <Typography className={classes.noPostTitle}>No results found</Typography>
            <Typography className={classes.noFoundTitle}>We couldn't find what you're looking for</Typography>
          </Box>
          )}
        </Box>
      )
    }

   
    return (
      <>
         <div>
                {this.state.peopleList.map((person: any, index: number) => (
                  <Box style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', padding: '10px', gap: '10px', height: '40px' }}>
                    <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                      <Box >
                        <img src={person.attributes.profile_photo} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                      </Box>
                      <Box style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                        <Typography className={classes.peopleName} style={{ fontSize: '18px', fontFamily: 'Manrope' }}>{person.attributes.full_name}</Typography>
                        <Typography style={{ fontFamily: 'Manrope' }}>{person.attributes.user_name}</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <button
                        style={{
                          backgroundColor: person.attributes.is_following ? 'pink' : 'blue',
                          color: person.attributes.is_following ? 'black' : 'white',
                          border: 'none',
                          borderRadius: '15px',
                          fontSize: '15px',
                          padding: '10px 15px',
                          width: '100px',
                        }}
                      >
                        {person.attributes.is_following ? 'Following' : 'Follow'}
                      </button>

                    </Box>
                  </Box>
                ))}
        </div>
      </>
    )
  }

  renderMainContent = () => {
    const { classes } = this.props
    const {
      activeIndex, postPage, postPagination, showQuote,
      searchValue, selectedPost, showPostList
    } = this.state

    if (selectedPost && showQuote) {
      return (
        <Box
          className={`${classes.contentContainer} ${handleConditionFunction(this.props.checked, classes.borderColorLightTheme, "")}`}
          style={{ overflow: 'scroll' }}
        >
          <CreateRepost
            postData={selectedPost}
            onCloseQuote={this.handleCloseQuote}
            onGoBackAfterSubmit={this.handleGoBackAfterSubmit}
          />
        </Box>
      )
    }

    return (
      <Box
        className={`${classes.contentContainer} ${handleConditionFunction(this.props.checked, classes.borderColorLightTheme, "")}`}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {showPostList && (
            <Box style={{ padding: '12px', cursor: 'pointer' }} onClick={this.handleGoBackFromPosts}>
              <img src={this.props.checked ? blackLeftArrowIcon : whiteLeftArrowIcon} />
            </Box>
          )}
          <TextField
            variant="outlined"
            placeholder="Search"
            className={classes.searchStyle}
            style={{ width: "100%" }}
            fullWidth
            value={searchValue}
            onChange={this.handleChangeSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={this.props.checked ? blackSearchIcon : whiteSearchIcon} alt="search-location" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <img src={this.props.checked ? blackFilterIcon : whiteFilterIcon} alt="search-location" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {!showPostList && (
          <Box className={classes.tabWrapper}>
            <Tabs
              value={activeIndex}
              onChange={this.handleTabChange}
              classes={{ indicator: classes.tabIndicator }}
              textColor="primary"
              variant="fullWidth"
              data-test-id="tabBtn"
            >
              {searchTabs.map(tab => (
                <Tab
                  label={tab.name}
                  className={classes.tabItem}
                  key={tab.id}
                />
              ))}
            </Tabs>
          </Box>
        )}
        <div className={classes.postContainer} ref={this.postsContainerRef}>
          {showPostList ? (
            <Box className={classes.postsWrapper}>
              {this.renderPostList()}
            </Box>
          ) : (
            <InfiniteScroll
              pageStart={postPage}
              loadMore={this.getTrendingList}
              hasMore={postPage <= postPagination?.total_pages}
              loader={
                <div className={classes.loadMore}>
                  {postPage <= postPagination?.total_pages && <CircularProgress disableShrink />}
                </div>}
              useWindow={false}
              getScrollParent={() => this.postsContainerRef.current}
            >
              <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {activeIndex === 0 && this.renderPeopleList()}
              {activeIndex === 1 && this.renderTrendingList()}
              </Box>
            </InfiniteScroll>
          )}
        </div>
      </Box>
    )
  }

  render() {
    const { classes, checked } = this.props
    const { loggedUser, selectedPost, showComments } = this.state

    return (
      <NavigationMenu id="Search" tabItem="Search" checked={this.props.checked} navigation={this.props.navigation}>
        <Box className={classes.container}>
          {this.renderMainContent()}
          <RightSideBar checked={this.props.checked} navigation={this.props.navigation} />

          {selectedPost && showComments && (
            <Comments
              checked={checked}
              open={showComments}
              onClose={this.handleCloseCommentsModal}
              postData={selectedPost}
              userInfo={{
                id: loggedUser?.attributes.id!
              }}
              onFollow={this.handleClickFollow}
              onClickMoreOption={this.handleClickMoreOption}
              onMuteConformation={this.handleShowMuteConfirmModal}
              onShowReportPost={this.showReportPost}
              onBlockAccount={this.blockAccount}
              onLikePost={this.handleClickLike}
              onSavePost={this.handleClickSave}
              onUpdateNumberOfComments={this.handleUpdateNumberCommentOfPost}
              onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
            />
          )}
          {this.renderReportModal()}
          {this.renderMuteModal()}
          {this.renderDeletePost()}
          {this.renderPinPostModal()}
          {this.state.selectedPost && (
            <SelectRepostModal
              open={this.state.showRepostModal}
              onClose={this.handleCloseRepostModal}
              onRepost={this.handleRepost}
              onQuote={this.handleQuote}
              onUndoRepost={this.handleUndoRepost}
              isUndoRepost={this.state.selectedPost.attributes.is_reposted}
            />
          )}
        </Box>
      </NavigationMenu>
    )
  }
}

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    columnGap: '24px',
  },
  contentContainer: {
    width: '60%',
    maxWidth: '810px',
    height: 'calc(100vh - 20px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
    rowGap: '24px',
    borderRight: '2px solid #222222',
    position: 'relative',
    paddingTop: '20px',
  },
  tabWrapper: {
    height: '35px',
  },
  borderColorLightTheme: {
    borderColor: "#BABABA"
  },
  tabIndicator: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: '2.9px',
  },
  tabItem: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    borderBottom: "3px solid gray",
    textTransform: "capitalize",
    '&.Mui-selected': {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      WebkitBackgroundClip: 'text',
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    minWidth: '120px',
  },
  postContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    overflowY: "scroll",
    marginTop: "30px",
    overflowX: "hidden",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  loadMore: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  postsWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  postDisplay: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "8px 0px",
    width: "100%"
  },
  loadingWrapper: {
    marginTop: '16px',
    display: "flex",
    flexDirection:'column',
    justifyContent: "center",
    alignItems:'center'
  },
  noPostTitle: {
    textAlign:'center',
    fontFamily: "Manrope",
    fontWeight: 800,
    fontSize: "24px",
    backgroundClip: 'text',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
  },
  noFoundTitle: {
    textAlign:'center',
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: "19px",
    backgroundClip: 'text',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
  },
  searchStyle: {
    borderRadius: '12px',

    '& [class*="MuiInputAdornment-positionStart"]': {
      marginLeft: '8px',
    },
    '& [class*="MuiInput-underline"]': {
      '&:before': {
        borderWidth: "0",
      },
      '&:after': {
        borderWidth: "0",
      },
      '&:hover': {
        borderWidth: "0",
      }
    },
    '& [class*="MuiInputBase-input"]': {
      padding: '12px 0 14px 12px',
      fontFamily: 'Manrope',
      fontSize: '14px',
      fontWeight: 500,
    },
  },
  tagItemWrapper: {
    borderBottom: '0.5px solid #4E4E4E',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    paddingBottom: '10px',
  },
  tagLabel: {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 800,
    color: '#6B6B6B'
  },
  tagName: {
    fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: '18px',
  },
  tagPostCount: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '14px',
    color: '#BABABA'
  }
});

export default withStyles(styles)(AdvancedSearchScreen);
// Customizable Area End
