import { Box, IconButton, Popover, styled } from "@material-ui/core";
import { handleConditionFunction } from "../../../components/src/utils";
import React from "react"
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { next, prev } from "../../email-account-registration/src/assets";
import { Value } from "react-calendar/dist/cjs/shared/types";

interface IPropCustomCalendar {
    id?: string;
    calendarDate: string | Date;
    checked: boolean;
    anchorEl: null | HTMLElement;
    handleDobChange: (value: Value, event: React.MouseEvent<HTMLButtonElement>) => void;
    handleCloseCalendar: () => void;
}

const CustomCalendar = ({ id, checked, calendarDate, anchorEl, handleCloseCalendar, handleDobChange }: IPropCustomCalendar) => {
    const popper = Boolean(anchorEl)
    return (
        <Popover
            id={id}
            open={popper}
            data-testid="popover"
            anchorEl={anchorEl}
            onClose={handleCloseCalendar}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                style: {
                    borderRadius: "10px",
                    border: "1px solid #999999"
                }
            }}
        >
            <CalendarStyled checked={checked}>
                <div className="calendar_container">
                    <Calendar
                        prev2Label={null}
                        data-testid="calendar"
                        prevLabel={<IconButton><img src={prev} /></IconButton>}
                        nextLabel={<IconButton><img src={next} /></IconButton>}
                        next2Label={null}
                        onChange={handleDobChange}
                        value={calendarDate}
                    />
                </div>
            </CalendarStyled>
        </Popover>
    )
}

const CalendarStyled = styled(Box)(({ checked }: { checked: boolean }) => ({
    "& .calendar-container": {
        border: "0.5px solid #999999"
    },
    "& .calendar_container .react-calendar__tile": {
        fontFamily: "Manrope !important",
        fontSize: 12,
        fontWeight: 500,
        height: 40,
        padding: 10,
        width: 40,
        color: handleConditionFunction(checked, "#222222", "#fff"),
        borderRadius: '10px',
        margin: "2px",
        flex: '0 0 42px !important'
    },

    "& .calendar_container .react-calendar__century-view .react-calendar__tile": {
        fontWeight: 500,
        color: handleConditionFunction(checked, "#222222", "#fff"),
        borderRadius: '10px',
        flex: '0 0 100px !important',
        height: 40,
        padding: 10,
        width: 40,
        fontFamily: "Manrope !important",
        fontSize: 12,
    },

    "& .calendar_container .react-calendar__month-view__days": {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },


    "& .react-calendar__tile": {
        maxWidth: '28% !important'
    },

    '& .react-calendar__year-view__months': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '8px'
    },

    "& .calendar_container .react-calendar": {
        padding: 10,
        borderRadius: "10px",
        backgroundColor: handleConditionFunction(checked, "#fff", "#0B0B0B"),
        border: 'none !important'
    },

    "& .calendar_container .react-calendar__month-view__weekdays__weekday abbr": {
        fontFamily: "Manrope !important",
        fontSize: 12,
        fontWeight: 500,
        textDecoration: 'none !important',
        textTransform: 'none',
        background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        color: 'transparent',
    },

    "& .calendar_container .react-calendar__tile:enabled:hover": {
        width: "40px",
        height: "40px",
        borderRadius: '20px',
        color: handleConditionFunction(checked, "#222222", "#FFFFFF"),
        background: handleConditionFunction(checked, "#BABABA", "#1F1F1F"),

    },


    "& .calendar_container .react-calendar__decade-view .react-calendar__tile": {
        fontFamily: "Manrope !important",
        fontSize: 12,
        fontWeight: 500,
        height: 40,
        padding: 10,
        width: 40,
        color: handleConditionFunction(checked, "#222222", "#fff"),
        borderRadius: '10px',
        flex: '0 0 100px !important'

    },


    '& .react-calendar__navigation button:enabled:hover': {
        backgroundColor: 'transparent !important'
    },
    '& .react-calendar__navigation button:enabled': {
        backgroundColor: 'transparent !important'
    },

    "& .calendar_container .react-calendar__navigation__label__labelText": {
        background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        color: 'transparent',
        fontWeight: 700,
        fontFamily: "Manrope",
        fontSize: '12px',
    },

    "& .calendar_container .react-calendar__tile--active": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
        width: "40px",
        height: "40px",
        borderRadius: '60px',
        color: "#fff !important",
    },

    "& .calendar_container .react-calendar__tile--active:enabled:hover": {
        width: "40px",
        height: "40px",
        borderRadius: '60px',
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
        color: handleConditionFunction(checked, "#222222", "#ffff")
    },
    "& .calendar_container .react-calendar__tile--now": {
        background: 'none',
        width: "40px",
        height: "40px",
        borderRadius: '60px',
    },
    "& .calendar_container .react-calendar__tile--now:hover": {
        backgroundColor: handleConditionFunction(checked, "#BABABA !important", "#1F1F1F !important"),
    },
    "& .calendar_container .react-calendar__tile--now:enabled:hover": {
        width: 40,
        height: 40,
        borderRadius: '20px',
        color: '#fff',
        background: 'none',
    },

    "& .calendar_container .react-calendar__month-view__days__day--neighboringMonth": {
        color: "#6B6B6B",
    },
    "& .calendar_container .react-calendar__year-view__months__month": {
        border: '1px solid #222222',
        borderColor: checked ? "#bababa" : "#222222", 
        margin: '2px',
        width: '10px !important',
        borderRadius: '4px !important'
    },

    "& .calendar_container .react-calendar__year-view__months__month:hover": {
        borderRadius: '4px !important',
        border: 'none !important'
    },

    '& .react-calendar__decade-view__years': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '10px',
        marginLeft: '10px',
    },

    '& .calendar_container .react-calendar__decade-view__years__year': {
        border: '1px solid #222222',
        borderColor: checked ? "#bababa!important" : "#222222!important",
        borderRadius: '4px !important',
        maxWidth: '30% !important'
    },

    '& .calendar_container .react-calendar__decade-view__years__year:hover': {
        border: 'none !important',
        borderRadius: '4px !important',
        maxWidth: '30% !important'
    },

    '& .calendar_container .react-calendar__decade-view__years__year--active': {
        border: 'none !important',
        borderRadius: '4px !important',
        maxWidth: '30% !important'
    },


    '& .react-calendar__navigation button:disabled': {
        backgroundColor: 'transparent !important'
    },

    '& .react-calendar__century-view__decades': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginLeft: '10px',
        gap: '8px'
    },

    '& .calendar_container .react-calendar__century-view__decades__decade': {
        border: '1px solid #222222',
        borderColor: checked ? "#bababa!important" : "#222222!important",
        borderRadius: '4px !important',
        maxWidth: '30% !important'
    },

    '& .calendar_container .react-calendar__century-view__decades__decade:hover': {
        border: 'none !important',
        borderRadius: '4px !important',
        maxWidth: '30% !important'
    },

    "& .react-calendar__tile--hasActive": {
        color: '#E9E9E9 !important',
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
        border: 'none !important',
        borderWidth: '0px !important'
    },

    "& .react-calendar__tile--hasActive:enabled": {
        color: '#E9E9E9 !important',
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
        border: 'none !important',
        borderWidth: '0px !important'
    },

    "& .calendar_container .react-calendar__year-view .react-calendar__tile": {
        flex: '0 0 100px !important',
        borderRadius: '10px',
        color: handleConditionFunction(checked, "#222222", "#fff"),
        width: 40,
        padding: 10,
        height: 40,
        fontWeight: 500,
        fontSize: 12,
        fontFamily: "Manrope !important",
    },
}))

export default CustomCalendar;