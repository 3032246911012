export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const lightBackground=require("../assets/lightBackground.png");
export const darkBackground=require("../assets/darkBackground.png");
export const backArrowWhite=require("../assets/backArrow-white.png");
export const backArrowDark=require("../assets/backArrow-dark.png");
export const editIcon=require("../assets/edit_icon.png");
export const deleteIcon=require("../assets/delete_icon.png");
export const price = require("../assets/price.png")
export const period = require("../assets/period.png")
export const cancel=require("../assets/cancel.png")
export const down=require("../assets/down.png")
export const checkedIcon = require("../assets/checkedIcon.svg")
export const iconExpiryMessage = require("../assets/iconExpiryMessage.svg")
export const logoutExpiryAccount = require("../assets/logoutExpiryAccount.svg")
export const trashIcon = require("../assets/trashIcon.svg")

