// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { Post } from "./ActivityFeedController";

export const configJSON = require("./config");

export interface Props {
  navigation?:any
  classes?: any
  postData: Post
  checked: boolean,
  onFollow: (id: number, type: string) => void,
  onClickMoreOption: (activeUser: any, postId: string) => void
  onMuteConformation: () => void
  onPinPost?:(pinned:boolean)=>void
  onDeletePost?:()=>void
  onCommentSetting?:()=>void
  onBlockAccount: (id: number) => void
  onShowReportPost: () => void
  onToggleRemoveAddUserCustomList: (userId: number, isAdd: boolean) => void
}

interface S {
  anchorEl: null | HTMLElement
  loggedUserId: number;
}

interface SS {
  screenId: string;
}


export default class PostHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {

  getMediaApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];


    this.state = {
      anchorEl: null,
      loggedUserId: 0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const selectedUser = JSON.parse(localStorage.getItem('selectedUser') || '')
    const userId = Number(selectedUser?.user_id)
    this.setState({ loggedUserId: userId })
  }

  handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  };

  handleNavigation=(userId:number)=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Profile');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), userId);
    this.send(message);
}

handlePinpost=(pinned:boolean)=>{
    this.props.onPinPost&&this.props.onPinPost(pinned)
    this.handleClose()
}

handleDeletePost=()=>{
    this.props.onDeletePost&&this.props.onDeletePost()
    this.handleClose()
}

handleCommentSetting=()=>{
   this.props.onCommentSetting&&this.props.onCommentSetting()
   this.handleClose()
} 

conditionRemover = (condition: boolean, trueStatement: string, falseStatement: string) => {
  return condition ? trueStatement : falseStatement
}

}
// Customizable Area End