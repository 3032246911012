import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Input,
  styled,
  IconButton,
  InputAdornment,
  StylesProvider,
  Dialog,
  createGenerateClassName,
} from "@material-ui/core"

import { addUser, closeIcon, copyLinkIcon, createCallLinkIcon, icAddUser, linkCallIcon, profileAvatar, rejectCallIcon, searchDark, searchWhite } from "./assets"
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web"
import { handleConditionFunction } from "../../../components/src/utils"
import { backArrowDark, backArrowWhite } from "../../../blocks/chat/src/assets"
import HistoryCall from "../../utilities/src/HistoryCall"
import ImageRenderer from "../../../components/src/ImageRenderer.web"

// Customizable Area End

import CallRecordingController, {
  Props,
  RecordingDetail,
  configJSON,
} from "./CallRecordingController.web";
import IncomingCall from "./IncomingCall.web";
import clsx from "clsx";
import GroupsMemberListSection from "../../../components/src/GroupsMemberListSelection.web";

// Customizable Area Start
const generateClassName = createGenerateClassName({
  productionPrefix: 'app_jss',
})
// Customizable Area End

export default class CallRecording extends CallRecordingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSideContent = () => {
    return (
      <Box className={`mainCategory`}>
        <Box className={`chatTopHeader`}>
          <Box className="titleContainer">
            <IconButton data-test-id="backBtn" onClick={this.handleBackChatScreen}>
              <img className="backIcon" src={handleConditionFunction(this.props.checked, backArrowDark, backArrowWhite)} />
            </IconButton>
            <Typography className={`chatTitle`}>Calls</Typography>
          </Box>
          <IconButton style={{ padding: 6 }}>
            <ImageRenderer width={24} height={24} src={icAddUser} />
          </IconButton>
        </Box>
        <Box className={`searchContainer`}>
          <Input
            id="input-with-icon-adornment"
            className={`searchField`}
            placeholder="Search"
            // value={this.state.searchKeyword}
            // onChange={this.handleSearchChange}
            startAdornment={
              <InputAdornment position="start">
                <img src={this.props.checked ? searchDark : searchWhite} />
              </InputAdornment>
            }
          />
        </Box>
        <Box className="callLinkContainer" data-test-id="createCallLinkBtn" onClick={this.handleCreateCallLink}>
          <ImageRenderer src={createCallLinkIcon} width={48} height={48} />
          <Box className="description">
            <Typography variant="h4">
              Create call link
            </Typography>
            <Typography>
              Share a link for your Arrows call
            </Typography>
          </Box>
        </Box>
        <HistoryCall
          loading={this.state.loading}
          checked={this.props.checked}
          loggedUser={this.state.loggedInUser}
          listHistoryCallLog={this.state.listCallHistory}
          startCallAudio={this.startCallAudio}
        />
      </Box>
    )
  }

  renderCallLinkModal = () => {
    return <DialogCallLink
      checked={this.props.checked}
      data-test-id="callLinkModal"
      onClose={this.handleResetCallLink}
      open={this.state.isOpenModalCallLink}
      PaperProps={{
        style: {
          border: "none",
          borderRadius: "10px",
        }
      }}
    >
      <Box className="dialogContent">
        <IconButton className="closeIconBtn" data-test-id="closeModalCallLink" onClick={this.handleResetCallLink}>
          <ImageRenderer src={closeIcon} width={24} height={24} style={{ color: "white" }} />
        </IconButton>
        <Box className="header">
          <Typography variant="h4">
            Create call link
          </Typography>
          <Typography>
            Anyone with Arrows can use this link to join this call. <br />
            Only share it with people you trust.
          </Typography>
        </Box>
        <Box className="callLink__listItem">
          <Box className="callLink__item">
            <Box className="callLink__item-icon">
              <ImageRenderer src={linkCallIcon} width={48} height={48} />
            </Box>
            <Box className="callLink__item-description" style={{
              maxWidth: "85%",
            }}>
              <Box
                className="description-content"
                onClick={this.handleClickCallLink}
              >
                <Typography className="anchorLinkCall">
                  {this.state.callLinkUrl ? this.state.callLinkUrl : "Call link is updating..."}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            className="callLink__item callLinkItem-pointer"
            onClick={this.handleCopyCallLink}
            data-test-id="copyCallLinkBtn"
          >
            <Box className="callLink__item-icon">
              <ImageRenderer src={copyLinkIcon} width={48} height={48} />
            </Box>
            <Box className="callLink__item-description">
              <Box className="description-content">
                <Typography variant="h5">
                  Copy link
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </DialogCallLink>
  }

  renderCallRecordingContent = () => {
    return (
      <Box className="contentContainer">
        {this.renderContainerCallScreen()}
        <Box id="callContainer">
        </Box>
      </Box>
    )
  }

  renderModalConnections = () => {
    return <DialogBox
      checked={this.props.checked}
      data-test-id="modalConnnections"
      onClose={() => this.setState({ isOpenModalUser: false })}
      open={this.state.isOpenModalUser}
      PaperProps={{
        style: {
          border: "none",
          borderRadius: "10px",
        }
      }}
    >
      <Box className="dialogContent">
        <Box className="dialog-header">
          <Typography variant="h2">
            Add People
          </Typography>
          <Typography>
            {this.state.listContact.length} Contacts
          </Typography>
        </Box>
        <GroupsMemberListSection
          checked={this.props.checked}
          listMemberSelected={this.state.listMemberSelected}
          listContact={this.state.listContact}
          checkedContacts={this.state.checkedContacts}
          hideSelection={true}
          onCheckboxChange={this.handleCheckboxChange}
          onRemoveMember={this.handleRemoveUserSelected}
        />
        <Box className="dialog-footer">
          <Box className="list_selected">
            <Typography>
              {this.state.listMemberSelected.map(member => member.getName()).toString()}
            </Typography>
          </Box>
          <Box className="list_function">
            <Box className="btn-primary" style={{ visibility: this.state.listMemberSelected.length ? "visible" : "hidden" }} onClick={this.handleResetAddUser}>
              <Typography className="btnText">Cancel</Typography>
            </Box>
            <Box className="btn-primary btn-secondary" onClick={() => { this.handleAddUserInCall() }}>
              <Typography className="btnText">Add</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </DialogBox>
  }

  renderContainerCallScreen = () => {
    if (this.state.isLoadingCallSession) {
      return <Box onClick={() => this.handleClickJoinCallLink()}
        className="joinCallLinkBtn"
        data-test-id="joinCallLinkBtn">
        Join call
      </Box>
    }
    if (this.state.isCaller) return this.renderCallerScreen();
    if (this.state.tokenSessionCall)
      return (
        !this.state.callLinkUrl && this.state.tokenSessionCall && <IconButton className="btnAddUser" onClick={() => this.handleOpenModalConnections(true)}>
          <ImageRenderer src={addUser} width={54} height={54} />
        </IconButton>
      )
    return (
      !this.state.tokenSessionCall && <IncomingCall
        navigation={this.props.navigation}
        checked={this.props.checked}
        id=""
        currentCall={this.state.currentCall}
        tokenSessionCall={this.state.tokenSessionCall}
      />
    )
  }

  renderCallerScreen = () => {
    return <Box className="container_caller">
      <Box className="header_caller">
        <Typography className={`header_caller_text ${clsx(this.props.checked && "lightTheme-text")}`}>
          Waiting for other...
        </Typography>
      </Box>
      <Box className="content">
        <Box className="profile_user">
          <Box className="header_profile_user">
            <Typography className="header_profile_user_text">
              You
            </Typography>
          </Box>
          <Box className="background_profile_user">
            <img src={this.state.loggedInUser?.getAvatar()} />
          </Box>
        </Box>
      </Box>
      <Box className="list_button">
        <IconButton data-test-id="cancelCallBtn" onClick={this.handleCancelCall}>
          <ImageRenderer src={rejectCallIcon} width={64} height={64} />
        </IconButton>
      </Box>
    </Box>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StylesProvider generateClassName={generateClassName}>
        <NavigationMenu
          navigation={this.props.navigation}
          id={""}
          checked={this.props?.checked}
        >
          <CallRecordingStyled checked={this.props.checked}>
            {this.renderSideContent()}
            {this.renderCallRecordingContent()}
          </CallRecordingStyled>
        </NavigationMenu>
        {this.renderCallLinkModal()}
        {this.renderModalConnections()}
      </StylesProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CallRecordingStyled = styled(Box)(({checked}: {checked?: boolean}) => ({
  display: 'flex',
  "& .mainCategory": {
    height: '100vh',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "Manrope",
    padding: '0 15px',
    flex: 4,
    maxWidth: '420px',
    borderRight: '2px solid #222222',
    "& .chatTopHeader": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      paddingTop: '15px',
      "& .titleContainer": {
        display: "flex",
        alignItems: "center",
      },
      "& .chatTitle": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 800,
        color: checked ? "#222" : "#FFF",
      },
    },
    "& .searchContainer": {
      border: '1px solid #6B6B6B',
      borderRadius: '10px',
      height: "50px",
      padding: '0px 15px',
      alignContent: 'center',
      '&:has(.Mui-focused)': {
        border: "1px solid #7A2BF5"
      },
      "& .searchField": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 500,
        color: "#6B6B6B",
        '&::before': {
          display: "none",
        },
        '&::after': {
          display: "none",
        },
        width: "100%",
        "& ::placeholder": {
          color: "#6B6B6B",
          opacity: 1
        }
      }
    },
    "& .callLinkContainer": {
      height: 80,
      boxSizing: "boder-box",
      padding: "10px 20px 20px",
      borderBottom: "1px solid #222222",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      gap: 10,
      "& .description": {
        "& h4": {
          fontFamily: "Manrope",
          fontSize: 16,
          fontWeight: 600,
          color: checked ? "#222" : "#fff",
        },
        "& p": {
          fontFamily: "Manrope",
          fontSize: 12,
          fontWeight: 700,
          color: "#6B6B6B",
        }
      }
    }
  },
  "& .contentContainer": {
    display: "flex",
    flexDirection: "column",
    flex: 6,
    maxWidth: '680px',
    borderRight: '2px solid #222222',
    position: "relative",
    "& .btnAddUser": {
      position: "absolute",
      right: "16px",
      top: "16px",
      padding: 0,
      zIndex: 9,
    },
    "& .joinCallLinkBtn": {
      height: "100%",
      fontFamily: "Manrope",
      fontWeight: 800,
      fontSize: 22,
      justifyContent: "center",
      display: "flex",
      flex: 20,
      maxWidth: '680px',
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
    },
    "& #callContainer": {
      flex: "1",
      " & .cc-bottom-buttons.cc-bottom-buttons-container": {
        background: "#96969633"
      },
      "& .bottom-buttons-other-options": {
        display: "none",
      },
      "& .cc-main-container": {
        background: "transparent",
        "& .awssld__content": {
          background: "transparent",
          "& .tile-main-container": {
            background: "transparent",
            "& .tile-list-wrapper": {
              "& .grid-box": {
                borderWidth: 2,
                borderColor: "#222222",
                "& .cc-video-container": {
                  background: "transparent",
                }
              },
              "& .show-border": {
                borderColor: "#1e79e7"
              }
            }
          }
        },
        "& .reconnect-loading": {
          maxWidth: 680,
          zIndex: 9,
          position: "absolute",
        }
      }
    },
    "& #settingButton": {
      display: "none"
    },
    "& .container_caller": {
      flex: 20,
      maxWidth: '680px',
      padding: "20px 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .header_caller": {
        marginTop: "10vh",
        "& .header_caller_text": {
          fontFamily: "Manrope",
          fontWeight: 600,
          fontSize: "16px",
          color: "#E0E0E0",
        },
        "& .header_caller_text.lightTheme-text": {
          color: "#222222",
        }
      },
      "& .content": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "& .profile_user": {
          padding: 10,
          border: "1px solid transparent",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: 10,
          background: "linear-gradient(90deg, #070707 100%, #070707 100%) padding-box, linear-gradient(99.09deg, #6EE7B7 2.64%, #059669 100%) border-box",
          "& .background_profile_user": {
            width: "140px",
            height: "137px",
            backgroundImage: `url(${profileAvatar})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            "& img": {
              position: "absolute",
              top: "calc(50%)",
              left: "calc(50%)",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
              width: "142px",
              height: "140px",
              borderRadius: "10px",
            }
          },
          "& .header_profile_user": {
            "& .header_profile_user_text": {
              backgroundImage: "linear-gradient(99.09deg, #6EE7B7 2.64%, #059669 100%)",
              color: "transparent",
              backgroundClip: "text",
              fontFamily: "Manrope",
              fontWeight: 700,
              fontSize: "14px"
            }
          }
        }
      },
      "& .list_button": {
        position: "absolute",
        bottom: 0,
        width: "calc(100% - 4px )",
        background: "#96969633",
        display: "flex",
        justifyContent: "space-around",
      }
    }
  }
}))

const DialogCallLink = styled(Dialog)(({ checked }: { checked?: boolean }) => ({
  "& .dialogContent": {
    width: "453px",
    height: "489px",
    boxSizing: "border-box",
    padding: "20px",
    border: "1px solid #35383F",
    borderRadius: "10px",
    background: "#0B0B0B",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    position: "relative",
    "& .header": {
      padding: "20px 0 16px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderBottom: "1px solid #222222",
      "& h4": {
        fontSize: 24,
        fontWeight: 500,
        fontFamily: "Manrope",
        color: "#FFF",
        lineHeight: "28px",
        marginBottom: "15px",
      },
      "& p": {
        textAlign: "center",
        fontFamily: "Manrope",
        fontSize: 14,
        fontWeight: 500,
        color: "#BABABA",
      }
    },
    "& .closeIconBtn": {
      background: "#222222",
      position: "absolute",
      padding: 10,
      right: 15,
      top: 15,
    },
    "& .callLink__listItem": {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      "& .callLink__item:nth-child(2)": {
        paddingBottom: "20px",
        marginTop: "-16px",
        borderBottom: "1px solid #222222"
      },
      "& .callLink__item": {
        padding: "10px 20px",
        display: "flex",
        alignItems: "center",
        gap: 20,
        "& .callLink__item-icon": {
          width: "48px",
        },
        "& .callLink__item-description": {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: 20,
          "& .anchorLinkCall": {
            cursor: "pointer",
            textDecoration: "underline",
            textAlign: "left",
            fontFamily: "Manrope",
            fontWeight: 500,
            fontSize: 14,
            color: "#60A5FA",
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordWrap: "break-word",
          },
          "& .description-content": {
            "& h5": {
              fontFamily: "Manrope",
              fontWeight: 600,
              fontSize: 16,
              color: "#FFF",
            },
            "& .sub-description": {
              fontFamily: "Manrope",
              fontSize: 12,
              fontWeight: 500,
              color: "#6B6B6B",
            }
          }
        }
      },
      "& .callLinkItem-pointer": {
        cursor: "pointer"
      }
    }
  }
}));

const DialogBox = styled(Dialog)(({ checked }: { checked?: boolean }) => ({
  "& .dialogContent": {
    width: "375px",
    height: "730px",
    boxSizing: "border-box",
    padding: "20px",
    border: "1px solid #35383F",
    borderRadius: "10px",
    background: "#0B0B0B",
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    "& .dialog-header": {
      textAlign: "center",
      "& h2, p": {
        fontFamily: "Manrope",
        color: "#FFFFFF",
      },
      "& h2": {
        fontSize: 18,
        fontWeight: 800,
      },
      "& p": {
        fontSize: 10,
        fontWeight: 500,
      }
    },
    "& #suggestPeopleContainer": {
      height: "470px",
      overflowY: "auto",
    },
    "& .dialog-footer": {
      marginTop: "25px",
      display: "flex",
      justifyContent: "space-between",
      "& .list_selected": {
        flex: "0 0 50%",
        maxWidth: "50%",
        display: "flex",
        alignItems: "center",
        "& p": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
      "& .list_function": {
        flex: "0 0 50%",
        maxWidth: "50%",
        display: "flex",
        gap: 15,
        "& .btn-primary": {
          boxSizing: "border-box",
          width: "81px",
          textAlign: "center",
          padding: "2px 10px",
          border: "1px solid transparent",
          borderRadius: "5px",
          display: "inline-block",
          background: "linear-gradient(90deg, #070707 100%, #070707 100%) padding-box, linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box",
          cursor: "pointer",
          "& .btnText": {
            backgroundImage: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            color: "transparent",
            backgroundClip: "text",
            fontFamily: "Manrope",
            fontWeight: 700,
            fontSize: "12px"
          }
        },
        "& .btn-primary.btn-secondary": {
          background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
          "& .btnText": {
            color: "#FFFFFF"
          }
        },
      }
    }
  }
}));
// Customizable Area End
