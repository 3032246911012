// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { User } from './ActivityFeedController'
import { sendAPIRequest, TAny } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

export interface Props {
	classes?: any
	checked: boolean
	navigation: TAny
}

interface S {
	suggestFollowPeople: User[]
	isLoading: boolean
}

interface SS {
}

export default class RightSideBarController extends BlockComponent<
	Props,
	S,
	SS
> {
	suggestFollowPeopleId: string = ''
	followApiCallId: string = ''

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.AccoutLoginSuccess),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.SessionResponseMessage)
		];

		this.state = {
			suggestFollowPeople: [],
			isLoading: false
		};

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async componentDidMount() {
		this.getSuggestFollowPeople()
	}

	async receive(from: string, message: Message) {
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);
		const responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);
		const errorResponse = message.getData(
			getName(MessageEnum.RestAPIResponceErrorMessage)
		);

		if (errorResponse) {
			this.parseApiErrorResponse(errorResponse)
		}

		this.apiSuccessCallBackController(apiRequestCallId, responseJson)
	}

	apiSuccessCallBackController = (
		apiRequestCallId: string,
		responseJson: any
	) => {
		const successCallbackMap = {
			[this.suggestFollowPeopleId]: this.handleSuggestFollowPeopleResponse,
			[this.followApiCallId]: this.handleFollowResponse,
		};

		if (apiRequestCallId) {
			const successCallback: ((responseJson: any) => void) = successCallbackMap[apiRequestCallId];
			!!successCallback && successCallback(responseJson);
		}
	};

	handleFollowResponse = (responseJson: any) => {
		if (responseJson?.messages?.[0] === 'Successfully followed.') {
			const suggestList = [...this.state.suggestFollowPeople]
			const  newSuggestList = suggestList.map((item) => {
				if (item.id === responseJson?.data?.attributes?.id) {
					return {...item, is_following: true}
				} else {
					return item
				}
			})
			this.setState({ suggestFollowPeople: newSuggestList })
		}
	}

	handleSuggestFollowPeopleResponse = (responseJson: any) => {
		this.setState({ isLoading: false })
		if (responseJson?.data) {
			const suggestList = responseJson.data.map((item: any) => item.attributes)
			this.setState({ suggestFollowPeople: suggestList })
		}
	}

	getSuggestFollowPeople = async () => {
		const token = await getStorageData("authToken")
		const url = `bx_block_followers/suggested_accounts?page=1&per_page=10&sort_order=desc`
		this.setState({ isLoading: true })
		this.suggestFollowPeopleId = sendAPIRequest(
			url,
			{
				method: 'GET',
				headers: {
					'Content-Type': configJSON.apiContentType,
					token,
				},
			}
		)
	}

	handleFollow = async (id: number) => {
		const token = await getStorageData("authToken")

		this.followApiCallId = sendAPIRequest(
			'bx_block_followers/follows',
			{
				method: 'POST',
				headers: {
					"Content-Type": configJSON.apiContentType,
					token,
				},
				body: {
					account_id: id,
				}
			}
		)
	}

	handleNavigateCallRecording = () => {
		this.props.navigation.navigate("CallRecording")
	}

}
// Customizable Area End