import React from "react";

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  // Customizable Area Start
  Switch,
  Button,
  ListItemText,
  Modal,
  Backdrop,
  Fade,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import { createTheme, withStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles"
import { checkbox_tick, cross, cross_black, backArrowWhite, backArrowDark, darkRightArrow, rightArrow } from "../../settings2/src/assets";

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    background: {
      default: "#000",
      paper: "#000",
    },
    text: {
      primary: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#000",
      contrastText: "#000",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      primary: "#000",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const inputStyles={  
     
  darkBackdrop:{
     backgroundColor:'#0E0E0E',
     opacity:0.8
  },
   fade:{
      border:'1px solid #5B5B5B',
      borderRadius:'10px',
      display: 'flex'
  },
  lightBackdrop:{
    backgroundColor:'#FFFFFF',
    opacity:0.9
  },
  logoutButtonContainer: {
      display: "flex",
      gap: "12px",
  },
  logoutCancelButton: {
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    background: "linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    borderRadius: "10px",
    color: "#8833FF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    WebkitBackgroundClip:"padding-box, border-box",
    fontWeight: 700,
    lineHeight: '16.8px',
    width: "160px",
    height: "48px",
    gap: "10px",
    textTransform:"none" as "none",
  },

  logoutCancelButtonLight: {
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    background: "linear-gradient(#fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    borderRadius: "10px",
    color: "#8833FF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    WebkitBackgroundClip:"padding-box, border-box",
    fontWeight: 700,
    lineHeight: '16.8px',
    width: "160px",
    height: "48px",
    gap: "10px",
    textTransform:"none" as "none",
  },
  logoutYesButton:{
      width: "160px",
      height: "48px",
      padding: "18px 16px 18px 16px",
      gap: "10px",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal" as 'normal',
      fontWeight: 700,
      lineHeight: '16.8px',
      textTransform:"none" as "none",
      borderRadius: "10px",
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  },
  iconButton:{
      color: "#6B6B6B",
  }
}
import Discoverysettings3 from "../../../blocks/discoverysettings3/src/Discoverysettings3.web";
import Loader from "../../../components/src/Loader.web";
import { unmuteIcon } from "./assets";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
// Customizable Area End
import PrivacySettingsController, {
  Props,
  PrivacySettings
} from "./PrivacyAndSafetyController.web";

export default class PrivacyAndSafety extends PrivacySettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderIswitch = (testId: string, valKey : keyof PrivacySettings) => {
    const switchValue =  this.state.listOfPrivacySettings[valKey];
    return (
      <CustomSwitch
        onChange={() => this.submitPrivacySettings(valKey)}
        dataTestId={testId}
        name=""
        checked={this.props.checked}
        valueSwitch={typeof switchValue == "boolean" ? switchValue : false}
      />
    )
  }
  audienceAndTagging = (theme: any) => {
    const style = audienceStyles(theme);
    const rightArrowUrl = this.getImageUrl(!this.props.checked, 'right');
    return (
      <Box className="audienceContainer">
        {this.renderBackButton()}
        <Box className="audienceContainerTitle" data-test-id="audienceSubtitle">
          Manage what information you allow other people on Arrows to see.
        </Box>
        <Box className="audienceBox" style={style.audienceBox} data-test-id="audienceBox">
          <Box style={style.audienceRowOne} data-test-id="audienceRowOne">
            <Box style={style.audienceBoxTextContainer} data-test-id="audienceBoxTextContainer">
              <div style={{ fontSize: "16px" }} data-test-id="protectPostsTitle">Protect your posts</div>
              <div style={style.audienceTextTwo} data-test-id="protectPostsText">
                When selected, your posts and other account information are only visible to people who follow you.{" "}
                <a className="arrowBlue" href="#" data-test-id="learnMoreLink">Learn more</a>
              </div>
            </Box>
            {this.renderIswitch("protectPostsSwitch", "protect_posts")}
          </Box>
          <Box className="cursorPointer" style={style.audienceRowOne} data-test-id="photoTaggingRow" onClick={() => this.setState({ showPhotoTagModal: true })}>
            <Box data-test-id="photoTaggingText">
              <div style={{ fontSize: "16px" }} data-test-id="photoTaggingTitle">Photo tagging</div>
              <div style={style.audienceTextTwo} data-test-id="photoTaggingDescription">{this.state.listOfPrivacySettings.photo_tagging}</div>
            </Box>
              <img
                style={style.rightArrow}
                src={rightArrowUrl}
                data-test-id="photoTaggingArrow"
              />
          </Box>
        </Box>
        {this.state.showPhotoTagModal && this.photoTaggingModal(theme)}
        </Box>
    );
  };

  photoTaggingModal = (theme: any) => {
    const style = audienceStyles(theme);
    const {checked} = this.props;
    const photoTag = this.state.listOfPrivacySettings.photo_tagging
    return (
      <Modal           
            className={`logoutModal dark-${!this.props.checked}`}
            open={this.state.showPhotoTagModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              style: this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop
            }}
          >
            <Fade 
                in={this.state.showPhotoTagModal} 
                style={inputStyles.fade}
            >
              <div data-test-id="photoTagBackdrop" className={`photoTaggingModalContainer dark-${!this.props.checked}`}>
              <Box
                onClick={e => e.stopPropagation()}
                data-test-id="photoTagContainer"
                style={style.photoTagContainer}
              >
                <div style={style.photoTagHead}>
                  <span onClick={() => this.setState({ showPhotoTagModal: false })} data-test-id="closePhotoTagModal">
                    <img className="cursorPointer" src={checked?cross_black : cross } alt="cross" />
                  </span>
                  <span data-test-id="photoTagTxt" style={style.photoTagTxt}>Photo tagging</span>
                  <span data-test-id="nextBtn" className="cursorPointer" onClick={() => this.submitPrivacySettings("photo_tagging")} style={style.nextBtn}>Next</span>
                </div>
                <div style={style.photoTagRow} data-test-id="tagOption1">
                  <span>Anyone can tag you</span>
                  <span
                    onClick={() => this.handlePhotoTagging('anyone')}
                    style={photoTag === "anyone" ? style.cbClicked : style.cb}
                    data-test-id="tagOption1Checkbox"
                    className="cursorPointer"
                  >
                    {photoTag === "anyone" && <img style={style.checkBoxTick} src={checkbox_tick} alt="checkbox"/>}
                  </span>
                </div>
                <div style={style.photoTagRow} data-test-id="tagOption2">
                  <span>Only People you follow can tag you</span>
                  <span
                    onClick={() => this.handlePhotoTagging('people_you_follow')}
                    style={photoTag === "people_you_follow" ? style.cbClicked : style.cb}
                    data-test-id="tagOption2Checkbox"
                    className="cursorPointer"
                  >
                    {photoTag === "people_you_follow" && <img style={style.checkBoxTick} src={checkbox_tick} alt="checkbox"/>}
                  </span>
                </div>
                <div style={style.photoTagRow} data-test-id="tagOption3">
                  <span>Off</span>
                  <span
                    onClick={() => this.handlePhotoTagging('off')}
                    style={photoTag === "off" ? style.cbClicked : style.cb}
                    className="cursorPointer"
                    data-test-id="tagOption3Checkbox"
                  >
                    {photoTag === "off" && <img style={style.checkBoxTick} src={checkbox_tick} alt="checkbox"/>}
                  </span>
                </div>
              </Box>
              </div>
            </Fade>
          </Modal>
    )
  }

  yourPost = (theme: any) => {
    const style = audienceStyles(theme);

    return (
      <Box className="audienceContainer">
        {this.renderBackButton()}
        <Box className="audienceContainerTitle" data-test-id="yourPostSubTitle">
          Manage the information associated with your posts.
        </Box>
              
              <Box className="audienceBox" style={style.audienceBox} data-test-id="yourPostBoxContainer">
                <Box style={style.audienceRowOne} data-test-id="audienceRowOne">
                  <Box style={style.audienceBoxTextContainer} data-test-id="audienceBoxTextContainer">
                    <div style={{ fontSize: "16px" }} data-test-id="markSensitiveContentTitle">
                      Mark media you post as having material that may be sensitive
                    </div>
                    <div style={style.audienceTextTwo} data-test-id="markSensitiveContentText">
                      When enabled, pictures and videos you post will be marked as sensitive for people who don't want to see sensitive content.{" "}
                      <a className="arrowBlue" href="#" data-test-id="learnMoreLink">Learn more</a>
                    </div>
                  </Box>
                  {this.renderIswitch("markSensitiveContentSwitch","post_sensitive_content")}
                </Box>
              </Box>
            </Box>
    );
  };

  contentYouSee = (theme: any) => {
    const style = audienceStyles(theme);
    const rightArrowUrl = this.getImageUrl(!this.props.checked, 'right');

    return (
            <Box className="audienceContainer">
              {this.renderBackButton()}
              <Box className="audienceContainerTitle" data-test-id="contentYouSeeSubTitle">
                Decide what you see on Arrows based on your preferences like Topics and interests
              </Box>
              <Box className="audienceBox" style={style.audienceBox} data-test-id="yourPostBoxContainer">
                <Box style={style.audienceRowOne} data-test-id="audienceRowOne">
                  <Box style={style.audienceBoxTextContainer} data-test-id="audienceBoxTextContainer">
                    <div style={{ fontSize: "16px" }} data-test-id="sensitiveContentTitle">
                      Display media that may contain sensitive content
                    </div>
                  </Box>
                  {this.renderIswitch("sensitiveContentSwitch", "view_sensitive_content")}
                </Box>
                <Box
                  data-test-id="exploreSettingsRow"
                  style={style.audienceRowOne}
                  className="cursorPointer"
                  onClick={() => this.setState({ currentView: "Exploresettings" })}
                >
                  <Box data-test-id="exploreSettingsText">
                    <div style={{ fontSize: "16px" }} data-test-id="exploreSettingsTitle">Explore settings</div>
                  </Box>
                  <img style={style.rightArrow} src={rightArrowUrl} data-test-id="exploreSettingsArrow"/>
                </Box>
                <Box
                  style={style.audienceRowOne}
                  onClick={() => this.setState({ currentView: "Searchsettings" })}
                  className="cursorPointer"
                  data-test-id="searchSettingsRow"
                >
                  <Box data-test-id="searchSettingsText">
                    <div style={{ fontSize: "16px" }} data-test-id="searchSettingsTitle">Search settings</div>
                  </Box>
                  <img style={style.rightArrow} src={rightArrowUrl} data-test-id="searchSettingsArrow"/>
                </Box>
              </Box>
            </Box>
    );
  };

  exploreSettings = (theme: any) => {
    const style = audienceStyles(theme);
    return (

            <Box className="audienceContainer">
              {this.renderBackButton()}
              <Box className="audienceContainerTitle ml18" data-test-id="contentYouSeeSubTitle">Location</Box>
              <Box className="audienceBox" style={style.audienceBox} data-test-id="exploreBox">
                <Box style={style.audienceRowOne} data-test-id="audienceRowOne">
                  <Box style={style.audienceBoxTextContainer} data-test-id="audienceBoxTextContainer">
                    <div style={{ fontSize: "16px" }} data-test-id="showContentTitle">Show content in this location</div>
                    <div style={style.audienceTextTwo} data-test-id="showContentText">
                      When this is on, you'll see what's happening around you right now.
                    </div>
                  </Box>
                  {this.renderIswitch("showContentSwitch", "show_content_in_this_location")}
                </Box>
              </Box>
            </Box>
    );
  };

  searchSettings = (theme: any) => {
    const style = audienceStyles(theme);
    return (
      
            <Box className="audienceContainer">
              {this.renderBackButton()}
              <Box className="audienceBox" style={style.audienceBox} data-test-id="searchSettingsBox">
                <Box style={style.audienceRowOne} data-test-id="audienceRowOne">
                  <Box style={style.audienceBoxTextContainer} data-test-id="audienceBoxTextContainer">
                    <div style={{ fontSize: "16px" }} data-test-id="hideSensitiveContentTitle">Hide sensitive content</div>
                    <div style={style.audienceTextTwo} data-test-id="hideSensitiveContentText">
                      This prevents posts with potentially sensitive content from displaying in your search results.
                    </div>
                  </Box>
                  {this.renderIswitch("hideSensitiveContentSwitch", "hide_sensitive_content_in_search")}
                </Box>
              </Box>
              <Box className="audienceBox" style={style.audienceBox} data-test-id="exploreBox">
                  <Box style={style.audienceRowOne} data-test-id="audienceRowOne">
                    <Box style={style.audienceBoxTextContainer} data-test-id="audienceBoxTextContainer">
                      <div style={{ fontSize: "16px" }} data-test-id="removeBlockedAccountsTitle">Remove blocked and muted accounts</div>
                      <div style={style.audienceTextTwo} data-test-id="removeBlockedAccountsText">
                        Use this to eliminate search results from accounts youve blocked or muted.
                      </div>
                    </Box>
                  {this.renderIswitch("removeBlockedAccountsSwitch", "exclude_blocked_muted_in_search")}
                  </Box>
                </Box>
            </Box>
        
    );
  };

  muteAndBlock = (theme: any) => {
    const style = audienceStyles(theme);
    const rightArrowUrl = this.getImageUrl(!this.props.checked, 'right');

    return (
      
            <Box className="audienceContainer">
              {this.renderBackButton()}
              <Box className="audienceContainerTitle" data-test-id="contentYouSeeSubTitle">
                Manage the accounts, words, and notifications that youve muted or blocked.
              </Box>
              <Box className="audienceBox" style={style.audienceBox} data-test-id="muteAndBlockBox">
                <Box className="cursorPointer" onClick={this.showBlockedAccounts} style={style.audienceRowOne} data-test-id="blockedAcc">
                  <Box data-test-id="blockedAccountsText">
                    <div style={{ fontSize: "16px" }} data-test-id="blockedAccountsTitle">Blocked accounts</div>
                  </Box>
                  <img style={style.rightArrow} src={rightArrowUrl} data-test-id="blockedAccountsArrow" />
                </Box>
                <Box className="cursorPointer" onClick={this.showMutedAccounts} style={style.audienceRowOne} data-test-id="mutedAccountRow">
                  <Box data-test-id="mutedAccountsText">
                    <div style={{ fontSize: "16px" }} data-test-id="mutedAccountsTitle">Muted accounts</div>
                  </Box>
                  <img style={style.rightArrow} src={rightArrowUrl} data-test-id="mutedAccountsArrow"/>
                </Box>
              </Box>
            </Box>
           
    );
  };

  renderUserList = () => {
    return(
      <React.Fragment>
        {this.state.blockedListOfAccounts.map((each: any, index: number) =>
          (<ListItem 
            className={"listItem"}
            data-test-id={`subcat-${index}`}
            key={`PrivacyCat${index}`}
            onClick={() => this.handleNavigation(each.attributes.id)}
            >
              <ListItemIcon>
                <img src={each.attributes.profile_photo|| ""} className={"img44"} />
              </ListItemIcon>
              <ListItemText className={`subcatTitleContainer ${this.props.checked && "lightThemeColorText"}`} primary={each.attributes.full_name} secondary={each.attributes.user_name} />
              <ListItemIcon className="justify-content-last">
                <span onClick={() => this.showUnmuteModal(each)} className="cursorPointer" style={webStyle.blockedButton}>Blocked</span>
              </ListItemIcon>
        </ListItem>))}
      </React.Fragment>
    )
  }

  blockedAccounts = (theme: any) => {
    
    return (
            <Box className="audienceContainer">
              {this.renderBackButton()}
              {this.state.blockedListOfAccounts.length> 0 ? <List className={"list"}>
                {this.renderUserList()}
              </List> :
              <Box style={webStyle.blockedAccBox} data-test-id="blockedAccBox">
                <div style={webStyle.blockHead} data-test-id="blockHead">Block unwanted accounts</div>
                <div style={webStyle.blockSubHead} data-test-id="blockSubHead">When you block someone, they wont be able to follow or message you, and you wont see notifications from them.</div>
              </Box>}
              {this.renderUnmuteModal(theme)}
            </Box>
          
    );
  };

  renderMutedUserList = () => {
    return(
      <React.Fragment>
        {this.state.blockedListOfAccounts.map((each: any, index: number) =>
          (<ListItem className={"listItem"} data-test-id={`MutedUserList-${index}`} key={`MutedUserList${index}`} >
              <ListItemIcon>
                <img src={each.attributes.profile_photo|| ""} className={"img44"} />
              </ListItemIcon>
              <ListItemText className={`subcatTitleContainer ${this.props.checked && "lightThemeColorText"}`} primary={each.attributes.full_name} secondary={each.attributes.user_name} />
              <ListItemIcon className="justify-content-last">
                <img onClick={() => this.showUnmuteModal(each)} style={{marginRight: "13px"}} src={unmuteIcon} className="iconImage cursorPointer" />
                {each.attributes.is_following? <span className="cursorPointer" data-testid="follow" onClick={()=>this.followApi(each.attributes.is_following,each.attributes.id)} style={webStyle.UnfollowButton}>Following</span>: <span className="submitButton cursorPointer" data-testid="follow" onClick={()=>this.followApi(each.attributes.is_following,each.attributes.id)} style={webStyle.followButton}>Follow</span>}
              </ListItemIcon>
        </ListItem>))}
      </React.Fragment>
    )
  }

  renderUnmuteModal = (theme: any) => {
    const style = audienceStyles(theme);
    const isBlocked = this.state.currentView === "Blockedaccounts";
    return(
      <Modal           
      className={`logoutModal dark-${!this.props.checked}`}
      open={this.state.showUnmuteModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop
      }}
    >
      <Fade 
          in={this.state.showUnmuteModal} 
          style={inputStyles.fade}
      >
        <div data-test-id="photoTagBackdrop" className={`photoTaggingModalContainer dark-${!this.props.checked}`}>
        <Box
          onClick={e => e.stopPropagation()}
          data-test-id="unMuteModalContainer"
          style={style.unMuteModalContainer}
        >
          <div className="txtCenter" style={style.unMuteModalHead}>
            {isBlocked ? "Unblock": "Unmute"} {this.state.selectedUser.name}
          </div>
          <div className="txtCenter" style={style.unMuteModalDesc}>
            Are you sure you want to {isBlocked ? "unblock": "unmute"} {this.state.selectedUser.name}?
          </div>
          <div style={inputStyles.logoutButtonContainer}>
              <CustomButton className="cancelButton noBtn" onClick={this.closeUnmuteModal} style={!this.props.checked?inputStyles.logoutCancelButton:inputStyles.logoutCancelButtonLight}>No</CustomButton>
              <Button className="confirmButton" onClick={this.onConfirmUnmute} style={inputStyles.logoutYesButton} variant="text">{isBlocked ? "Unblock": "Unmute"}</Button>
          </div>
        </Box>
        </div>
      </Fade>
    </Modal>
    )
  }

  mutedAccounts = (theme: any) => {
    
    return (
            <Box className="audienceContainer">
              {this.renderBackButton()}
              {this.state.blockedListOfAccounts.length> 0 ? <List className={"list"}>
                {this.renderMutedUserList()}
              </List> :
              <Box style={webStyle.blockedAccBox} data-test-id="blockedAccBox">
                <div style={webStyle.blockHead} data-test-id="blockHead">Muted accounts</div>
                <div style={webStyle.blockSubHead} data-test-id="blockSubHead">Posts from muted accounts won't show up in your Home timeline. Mute accounts directly from their profile or post.</div>
              </Box>}
              {this.renderUnmuteModal(theme)}
            </Box>
          
    );
  };

  directMessages = (theme: any) => {
    const style = audienceStyles(theme);     
    const rightArrowUrl = this.getImageUrl(!this.props.checked, 'right');
    const msgReqFrom = this.state.listOfPrivacySettings.message_request_from
    const audioReqFrom = this.state.listOfPrivacySettings.allow_audio_calls_from
    return (
       
            <Box className="audienceContainer">
              {this.renderBackButton()}
            <Box className="audienceContainerTitle" data-test-id="contentYouSeeSubTitle">Manage what information you allow other people on Arrows to see.</Box>
            <Box data-test-id="dmBox1"  className="audienceBox" style={style.audienceBox}>
             
              <Box style={style.audienceBoxTextContainer}>
                <div className="arrowFontFamily" style={{fontSize: "16px"}}>Allow message requests from</div>
                <div className="arrowFontFamily" style={style.audienceTextTwo}>People you follow will always be able to message you.</div>
              </Box>
              <Box data-test-id="checkboxes" style={style.checkboxes}>
                <div style={style.cbRow}>
                  <span className="arrowFontFamily" data-test-id="noone">No one</span>
                  <span className="arrowFontFamily cursorPointer" 
                  onClick={() => this.changeMsgRequestFrom('off')}    
                  style={msgReqFrom === "off" ? style.cbClicked : style.cb}
                  data-test-id="checkbox1"
                  >
                    { msgReqFrom === "off" && <img style={style.checkBoxTick} src={checkbox_tick} alt="checkbox"/>}
                  </span>
                </div>
                <div style={style.cbRow}>
                  <span className="arrowFontFamily">Verified users</span>
                  <span className="arrowFontFamily cursorPointer"
                  onClick={() => this.changeMsgRequestFrom('verified_user')}
                  style={msgReqFrom === "verified_user" ? style.cbClicked : style.cb}
                  data-test-id="checkbox2"
                  > 
                   {msgReqFrom === "verified_user" && <img style={style.checkBoxTick} src={checkbox_tick} alt="checkbox"/>}
                  </span>
                </div>
                <div style={style.cbRow}>
                  <span className="arrowFontFamily cursorPointer">Everyone</span>
                  <span className="arrowFontFamily"
                  onClick={() => this.changeMsgRequestFrom('anyone')}
                  style={msgReqFrom === "anyone" ? style.cbClicked : style.cb}
                  data-test-id="checkbox3"
                  >
                   {msgReqFrom === "anyone" && <img style={style.checkBoxTick} src={checkbox_tick} alt="checkbox"/>} 
                  </span>               
                </div>
              </Box>
               
            </Box>

            <Box data-test-id="dmBox2"  className="audienceBox" style={style.audienceBox}>
              <Box style={style.audienceRowOne}>
                <Box style={style.audienceBoxTextContainer}>
                  <div className="arrowFontFamily" style={{fontSize: "16px"}}>Enable audio calling</div>
                  <div className="arrowFontFamily" style={style.audienceTextTwo}>Take messaging to the next level with audio calls. when enabled you can select who you’re comfortable using it with. Learn more</div>
                </Box>
                {this.renderIswitch("enable_audio_calling", "enable_audio_calling")}
              </Box>
              {this.state.listOfPrivacySettings.enable_audio_calling && <React.Fragment>
                <Box style={style.audienceBoxTextContainer}>
                  <div className="arrowFontFamily" style={{fontSize: "16px"}}>Allow audio calls from</div>
                  <div className="arrowFontFamily" style={style.audienceTextTwo}>To reduce unwanted calls, you'll need to have messaged an account at least once before they're able to call you.</div>
                </Box>
                <Box data-test-id="audio-calls-checkbox" style={style.checkboxes}>
                  <div style={style.cbRow}>
                    <span className="arrowFontFamily">People you follow</span>
                    <span className="arrowFontFamily cursorPointer" 
                    onClick={() => this.changeAudioCallingFrom('people_you_follow')}    
                    style={audioReqFrom === "people_you_follow" ? style.cbClicked : style.cb}
                    data-test-id="audio-calls-checkbox1"
                    >
                      {audioReqFrom === "people_you_follow" && <img style={style.checkBoxTick} src={checkbox_tick} alt="checkbox"/>}
                    </span>
                  </div>
                  <div style={style.cbRow}>
                    <span className="arrowFontFamily">People who follow you</span>
                    <span className="arrowFontFamily cursorPointer"
                    onClick={() => this.changeAudioCallingFrom('people_who_follow_you')}
                    style={audioReqFrom === "people_who_follow_you" ? style.cbClicked : style.cb}
                    data-test-id="audio-calls-checkbox2"
                    > 
                    {audioReqFrom === "people_who_follow_you" && <img style={style.checkBoxTick} src={checkbox_tick} alt="checkbox"/>}
                    </span>
                  </div>
                  <div style={style.cbRow}>
                    <span className="arrowFontFamily cursorPointer">Everyone</span>
                    <span className="arrowFontFamily"
                    onClick={() => this.changeAudioCallingFrom('anyone')}
                    style={audioReqFrom === "anyone" ? style.cbClicked : style.cb}
                    data-test-id="audio-calls-checkbox3"
                    >
                    {audioReqFrom === "anyone" && <img style={style.checkBoxTick} src={checkbox_tick} alt="checkbox"/>} 
                    </span>               
                  </div>
                </Box>
                <Box style={style.audienceRowOne}>
                  <Box style={style.audienceBoxTextContainer}>
                    <div className="arrowFontFamily" style={{fontSize: "16px"}}>Always relay calls</div>
                    <div className="arrowFontFamily" style={style.audienceTextTwo}>Enable this setting to avoid revealing your IP address to your contact during the call. This will reduce call quality.Learn more</div>
                  </Box>
                  {this.renderIswitch("", "always_relay_calls")}
                </Box>
                 
                
              </React.Fragment>}
            </Box>
            
            <Box data-test-id="dmBox3" className="audienceBox" style={style.audienceBox}>
            <Box style={style.audienceRowOne}>
              <Box style={style.audienceBoxTextContainer}>
                <div className="arrowFontFamily" style={{fontSize: "16px"}}>Send read receipts</div>
                <div className="arrowFontFamily" style={style.audienceTextTwo}>Let people you’re messaging with know when you’ve seen their messages. Read receipts are not shown on message requests. Learn more</div>
              </Box>
              {this.renderIswitch("","send_read_receipts")}
              </Box>
            </Box>
            </Box>           
    )
  }

  discoverabilityAndContacts = () => {
    return (
      <>
         <Discoverysettings3 
          data-testid="discoverysettings"
          navigation={undefined} id={""} 
          checked={this.props.checked}
          handleBack={this.onClickBackButton} />
      </>
    )
  }

  renderMainView = () => {
    const {checked} = this.props
    return(
        <div>
          <div className="HeadingContainer">          
            <Link to="/settings" style={{ textDecoration: 'none' }}>
            <Button data-test-id="backButtonArrow" className="backButton cursorPointer" variant="text">
              <span  className="icon" > <img src={checked ? backArrowDark : backArrowWhite} /> </span>
            </Button>
          </Link>
           <div className="titleText"><span  data-test-id="pageTitle"> {this.renderBackButtonPrivacyTitle()} </span></div>
          </div>

          <Box style={webStyle.pageSubtitle}>Your Arrows Activity</Box>
          <List className={"list"}>
          {this.state.categories.map((each, index: number) =>
            (<ListItem 
              className={"listItem alignStart"}
              button
              data-test-id={`subcat-${index}`}
              key={`PrivacyCat${index}`} 
              onClick={() => this.setState({ currentView: each.title.split(' ').join('') })}
              >
                <ListItemIcon>
                  <img src={!checked ? each.whiteLogo : each.blackLogo} className={"iconImage"} />
                </ListItemIcon>
                <ListItemText className={`subcatTitleContainer ${this.props.checked && "lightThemeColorText"}`} primary={each.title} secondary={each.description} />
                <ListItemIcon className="justify-content-last">
                  <img src={checked ? darkRightArrow : rightArrow} className={"iconImage marginTopClass"} />
                </ListItemIcon>
            </ListItem>))}
          </List>
        </div>
    )
  }

  renderBackButtonPrivacyTitle = () => {
    const { currentView } = this.state; 
    switch (currentView) {
      case 'Audienceandtagging':
        return "Audience and tagging";
      case 'Yourposts':
        return "Your Post";
      case 'Contentyousee':
        return "Content You See";
      case 'Exploresettings':
        return "Explore settings";
      case 'Searchsettings':
        return "Search Settings";
      case 'Muteandblock':
        return "Mute and block";
      case 'Directmessages':
        return "Direct messages";
      case 'Discoverabilityandcontacts':
        return "Discoverability and contacts";
      case 'Blockedaccounts':
        return "Blocked Accounts";
      case 'MutedAccounts':
          return "Muted Accounts";           
      default:
        return "Privacy & safety";
    }
  }

  renderBackButton = () => {
    const {checked} = this.props
    return (
        <div className="HeadingContainer">
        <Button data-test-id="backButtonArrow" 
          onClick={this.onClickBackButton} 
          className="backButton cursorPointer" variant="text"
        >
            <span className="icon"><img src={checked ? backArrowDark : backArrowWhite} /></span>
        </Button>
         <div className="titleText"><span data-test-id="pageTitle" >{this.renderBackButtonPrivacyTitle()}</span></div>
        </div>
    )
  }

  renderContent = () => {
    const { currentView } = this.state;
    const theme = this.props.checked ? lightTheme : darkTheme

    switch (currentView) {
      case 'Audienceandtagging':
        return this.audienceAndTagging(theme);
      case 'Yourposts':
        return this.yourPost(theme);
      case 'Contentyousee':
        return this.contentYouSee(theme);
      case 'Exploresettings':
        return this.exploreSettings(theme);
      case 'Searchsettings':
        return this.searchSettings(theme);
      case 'Muteandblock':
        return this.muteAndBlock(theme);
      case 'Directmessages':
        return this.directMessages(theme);
      case 'Discoverabilityandcontacts':
        return this.discoverabilityAndContacts();
      case 'Blockedaccounts':
        return this.blockedAccounts(theme);
      case 'MutedAccounts':
        return this.mutedAccounts(theme);
         
      default:
        return this.renderMainView();
    }
  };
  
  // Customizable Area End
  render() {
    const subCategoryStyle = this.state.currentView !== 'main' ? `
    .settingsSubCat {
      max-width: 429px;
    }
  ` : '';
    return (
      // Customizable Area Start
      <StyleContainer>
        <style>
          {subCategoryStyle}
        </style>
        {this.renderContent()}
        <Loader loading={this.state.isLoading} />
      </StyleContainer>     
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomButton  = styled(Button)({
   "& span":{
    color:"#8833FF !important"
   }
})
const baseContainer = {
  height: "900px",
  backgroundColor: "inherit",
  color: "inherit",
  fontFamily: "Manrope"
}
const subTitleBlock= {
  width: "385px",
  fontSize: "18px",
  marginLeft: "20px",
  fontWeight: 800
}
const boxContainer = {
    border: "1px solid #555555",
    backgroundColor: "#181818",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "20px",
    padding: "10px"
}
const webStyle = {
  blockedButton: {
    color: "red",
    border: "1px solid red",
    fontFamily: "Manrope",
    fontSize: "12px",
    fontWight: "700",
    lineHeight: "14.4px",
    borderRadius: "5px",
    padding: "6px 10px 6px 10px"
  },
  followButton: {
    fontFamily: "Manrope",
    fontSize: "12px",
    padding: "6px 20px 6px 20px",
    gap: "7px",
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  },
  UnfollowButton: {
    fontFamily: "Manrope",
    fontSize: "12px",
    padding: "6px 20px 6px 20px",
    gap: "7px",
    borderRadius: "10px",
    background: "#222222"  
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Manrope",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    color: "black",
    fontSize: "20px"
  },
  title:{
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Manrope",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column" as "column",
  },
  backBtn: {
    position: "relative" as "relative",
    top: "-24px",
    left: "20px",
    width: "10px",
    height: "18px"
  },
  description:{
    fontSize: "12px",
    color: "#6B6B6B",
    marginTop: "2px",
    width: "249px",
},
row:{
  display: "flex",
  flexDirection: "row" as "row",
  justifyContent: "space-between",
  width: "455px",
},
rowElOne: {
  display: "flex",
  flexDirection: "row" as "row",
},
rowElTwo: {
  alignSelf: "flex-start",
  paddingTop: "5px"
},
rowIcon: {
  width: "28px",
  height: "28px",
},
pageTitle:{
  fontSize: "24px",
  fontWeight: 500,
  fontFamily: "Manrope",
  marginLeft: "55px"
},
pageSubtitle: {
  fontSize: "16px",
  fontWeight: 600,
  fontFamily: "Manrope",
  marginLeft: "20px",
  marginTop: "24px"
},
titleContainer:{
  position: "absolute" as "absolute",
},
blockedAccBox: {
  width: "389px",
  height: "102px",
  display: "flex",
  flexDirection: "column" as "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "40%",
  gap: "10px"
},
blockSubHead:{
  width: "335px",
  fontFamily: "Manrope",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center" as "center",
},
blockHead:{
  width: "335px",
  fontFamily: "Manrope",
  fontSize: "24px",
  fontWeight: 800,
  textAlign: "center" as "center",
  background: "linear-gradient(to right, #3858E3 100%, #BA05F7 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent"
}
};

const audienceStyles = (theme: any) =>({
  container: {
    color: theme.palette.text.primary,
    fontFamily: "Manrope",
    padding: "0px",
  },
  unMuteModalContainer: {
    width: "325px",
    height: "240px",   
    padding: "8px 24px 27px 24px",
    gap: "24px",
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column' as 'column',
    fontFamily: 'Manrope',
    fontSize: "16px",
    justifyContent: "center",
    border: "1px solid #35383F", 
    borderRadius: "10px",
    color: theme.palette.text.primary,
  },
  unMuteModalDesc: {
    fontFamily: 'Manrope',
    fontSize: "16px",
    lineHeight: "19.2px"
  },
  unMuteModalHead: {
    fontFamily: 'Manrope',
    fontSize: "18px",
    fontWeight: 800
  },
  photoTagContainer: {
    height: "228px",
    width: "543px",
    padding: "20px 24px",
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column' as 'column',
    fontFamily: 'Manrope',
    fontSize: "16px",
    justifyContent: "center",
    gap: "15px",
    border: "1px solid #35383F", 
    borderRadius: "10px",
    color: theme.palette.text.primary,
  },
  removeAllAccountsModalContainer:{
    height: "320px",
    width: "540px",
    margin: "auto",
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column' as 'column',
    fontFamily: 'Manrope',
    fontSize: "16px",
    justifyContent: "center",
    gap: "15px",
    border: "1px solid #35383F", 
    borderRadius: "10px",
    color: theme.palette.text.primary,
  },
  photoTagBackdrop: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,

  },
  photoTagRow:{
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    fontSize: "16px",
    fontWeight: 600,
    gap: "12px",
    marginTop: "14px",
    paddingRight: "15px"
  },
  photoTagTxt:{
    fontSize: "18px",
    fontWeight: 800,
  },
  photoTagHead:{
    
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nextBtn:{
    width: "72px",
    height: "32px",
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "center",
    alignItems: "center",
  },
  audienceAndTaggingContainer:{
    width: "429px",
    ...baseContainer,
  },
  audienceSubtitle: {
    ...subTitleBlock,
    marginTop: "20px",
  },
  yourPostSubTitle:{
    ...subTitleBlock,
    marginTop: "55px",
    marginBottom: "10px",
  },
  contentYouSeeSubTitle: {
    ...subTitleBlock,
    marginTop: "60px",
    width: "335px",
  },
  audienceBox:{
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  audienceBoxTextContainer:{
    display: "flex",
    flexDirection: "column" as "column",
    width: "249px"
  },
  audienceTextTwo:{
    width: "236px",
    color: '#6B6B6B',
    fontSize: "12px",
    fontFamily: "Manrope",
    marginTop: "5px",
    lineHeight: "14.4px",
    fontWeight: 500,
  },
  audienceRowOne:{
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: 'space-between',
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19.2px"
  },
  cbContainer:{
   height: "71px",
   display: "flex",
   flexDirection: "column" as "column",
  },
  rightArrow: {
    ...webStyle.rowElTwo,
    marginRight: "5px",
    width: "20px",
    height: "20px",
  },
  yourPostBoxContainer: {
    ...boxContainer,
    height: "134px",
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  exploreBox:{
    width: "385px",
    margin: "25px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  searchSettingsBox:{
    height: "87px",
    width: "385px",
    margin: "60px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  dmBox1:{
    height: "160px",
    width: "385px",
    margin: "30px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  dmBox2:{
    height: "115px",
    width: "385px",
    margin: "20px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  dmBox3: {
    height: "143px",
    width: "385px",
    margin: "20px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  dmBox4: {
    height: "19px",
    width: "385px",
    margin: "20px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  checkboxes: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "10px"
  },
  cbRow: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    fontSize: "12px",
    paddingRight: "15px"
  },
  cb: {
    display: "block",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: "transparent",
    border: "1px solid #555555",
  },
  cbClicked: {
    width: "17px",
    height: "17px",
    borderRadius: "50%",
    background: "linear-gradient(to right, #3858E3 100%, #BA05F7 100%)",
    border: "none", 
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  checkBoxTick:{
    width: "10px",
    height: "9.5px",
  },
  muteAndBlockBox:{
    height: "110px",
    width: "385px",
    margin: "30px 0 auto 0",
    ...boxContainer,
    gap: "10px",
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  discoverablitySubTitle:{
    marginTop: "60px",
    width: "386px",
    fontSize: "12px",
    fontFamily: "Manrope",
    color: "#6B6B6B",
    fontWeight: 500
  },
  discoverBox:{
    height: "244px",
    width: "386px",
    margin: "20px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  discoverBox2:{
    height: "310px",
    width: "386px",
    margin: "20px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },

})

const StyleContainer = styled(Box)({
 "& .MuiListItemText-root-43": {
    marginTop: 0,
 },
 "& .list": {
  display: "flex",
  flexDirection: "column" as "column",
  marginTop: "10px"
 },
 "& .alignStart":{
  alignItems:'flex-start'
 },

 "& .marginTopClass":{
     marginTop:6,
     height:24,
     width:24
  },
 "& .listItem": {
  paddingTop: "2px",
  paddingBottom: "2px"
 },
 "& .MuiBox-root-25": {
  paddingTop: "15px",
  paddingLeft: "20px"
 },
  "& .backButton:hover":{
    background: "transparent"
  },
  "& .backButton": {
    fontFamily:"Manrope",
    fontSize: "24px",
    fontWeight: 500,
    minWidth:48,
    paddingRight: "0",
    textTransform: "none",
    paddingLeft: "0px"
  },
  "& .backButton .MuiButton-label": {
    justifyContent: "left"
  },

  "& .HeadingContainer":{
    display:'flex',
    alignItems:'center'
  },

  "& .titleText":{
    fontFamily:"Manrope",
    fontSize: "24px",
    fontWeight: 500,
    paddingRight: "0",
    textTransform: "none",
  },

  "& .backButton .icon":{
    height: "48px",
    textAlign: "center",
    alignContent: "center",
    marginRight: "0px"       
  },
  "& .backButton .icon img":{        
    marginTop: "5px"       
  },
  "& .textAlignInitial p,& .subcatTitleContainer p":{
    fontFamily: "Manrope",
    fontSize: "14px",
    margin: "0px",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#6B6B6B",
  },

  "& .noBtn span":{
    color:' !important'
  },

  "& .subcatTitleContainer p":{
      fontSize: "12px",
      fontWeight: 500,
      marginTop:10,
      lineHeight: "14.4px",
      color: "#6B6B6B",
      fontFamily: "Manrope",
      maxWidth: "247px"
  },
  "& .subcatTitleContainer span": {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    fontFamily: "Manrope",
    color: "#FFFFFF",
  },
  "& .subcatTitleContainer.lightThemeColorText span": {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    fontFamily: "Manrope",
    color: "#222222",
  },
  "& .justify-content-last": {
    justifyContent: "end"
  },
  "& .audienceContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "21px"
  },
  "& .audienceContainer .backButton":{
    paddingBottom: "0px"
  },
  "& .audienceContainerTitle":{
    fontFamily: "Manrope",
    fontSize: "18px",
    fontWeight: 800,
    lineHeight: "21.6px",
    maxWidth: "335px",
    margin: "auto"
  },
  "& .audienceBox":{
    height: "auto",
    width: "353px",
    padding: "15px",
    borderRadius: "10px",
    border: "1px solid rgb(78, 78, 78)",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
  },
  "& .audienceContainerTitle.ml18":{
    marginLeft: "18px"
  }
})
// Customizable Area End
