import React from "react";
// Customizable Area Start
import PaidContentController, {
  Props,
  // configJSON,
} from "./PaidContentController";
import TableSubscription from "../../utilities/src/components/TableSubscription";
import { Box, styled } from "@material-ui/core";


export default class PaidContent extends PaidContentController {
  constructor(props: Props) {
    super(props);
  }
 createData(
    id: number,
    name: string,
    userName: string,
    renewalDate: string,
    price: number,
  ) {
    return {id,name,renewalDate,price,userName};
  }

  rows = [
    this.createData(1, 'Ralph Edwards', '@raph', '25 Jan 2025', 30),
    this.createData(2, 'Ralph Edwards', '@raph', '25 Jan 2025', 30),
    this.createData(3, 'Ralph Edwards', '@raph', '25 Jan 2025', 30),
    this.createData(4, 'Ralph Edwards', '@raph', '25 Jan 2025', 30),
    this.createData(5, 'Ralph Edwards', '@raph', '25 Jan 2025', 30),
  ];

  rowsCanceled = [
    this.createData(1, 'Ralph Edwards', '@raph', '25 Jan 2025', 30),
    this.createData(2, 'Ralph Edwards', '@raph', '25 Jan 2025', 30),
    this.createData(3, 'Ralph Edwards', '@raph', '25 Jan 2025', 30),
    this.createData(4, 'Ralph Edwards', '@raph', '25 Jan 2025', 30),
    this.createData(5, 'Ralph Edwards', '@raph', '25 Jan 2025', 30),
  ];

  columns = [
    { key: 'name', label: 'Name', width: 200, isHasUserName: true, },
    { key: 'renewalDate', label: 'Renewal Date', width: 180, align: "center" as "center" },
    {
      key: 'price', label: 'Price (monthly)', align: "left" as "left", textStyle: {
        wordBreak: "break-word"
      }
    },
    { key: 'action', label: 'Cancel option', width: 80, align: "center" as "center" },
  ];

  columnsCanceled = [
    { key: 'name', label: 'Name', width: 200 },
    { key: 'renewalDate', label: 'Renewal Date', align: "center" as "center" },
    {
      key: 'price', label: 'Price (monthly)', width: 100, align: "left" as "left", textStyle: {
        wordBreak: "break-word"
      }
    },
  ];

  render() {
    return (
      <BoxContainer>
      <TableSubscription
        title="Ongoing"
        columns={this.columns}
        subscriptions={this.rows}
        tableMaxHeight={250}
      />
      <TableSubscription
        title="Canceled"
        columns={this.columnsCanceled}
        subscriptions={this.rowsCanceled}
        tableMaxHeight={250}
      />
    </BoxContainer>
    );
  }
}

const BoxContainer = styled(Box)({
  height: "calc(100% - 55px)",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  scrollbarWidth: "none",
  gap: 21,
})
// Customizable Area End
