// Customizable Area Start
import React from "react";
import {
  Box,
  withStyles,
  createStyles,
  Button,
  Chip,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import PostHeaderController, { Props } from "./PostHeaderController.web";
import {
  ownerShipIcon,
  profileLogo,
  reshotIcon,
  reshotLightIcon,
  threeDots,
} from "./assests";
import { verifyImg } from "../../../components/src/utils";
import { configJSON } from "./ActivityFeedController";

export class PostHeader extends PostHeaderController {
  constructor(props: Props) {
    super(props);
  }

  themeValue = (lightValue: any, darkValue: any) => {
    return this.props.checked ? lightValue : darkValue;
  };

  renderCustomText = (isTailored: boolean, userName: string) => {
    if (isTailored) {
      return `Remove ${userName} from Custom`
    } else {
      return `Add ${userName} to Custom`
    }
  }

  render() {
    const {
      classes,
      postData,
      onClickMoreOption,
      onFollow,
      checked,
      onMuteConformation,
      onBlockAccount,
      onShowReportPost,
      onToggleRemoveAddUserCustomList,
    } = this.props;
    let {
      post_by,
      is_repost,
      is_pinned,
      parent_post,
      body,
    } = postData.attributes;
    post_by = post_by || {};
    const author =
      is_repost && parent_post?.post_by && !body
        ? parent_post?.post_by
        : post_by;
    return (
      <Box className={classes.container}>
        <img
          src={author.profile_photo || profileLogo}
          alt="profile-photo"
          className={classes.postImage}
        />

        <Box className={classes.headconatin}>
          <Box className={classes.ContentHead}>
            <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Typography
                className={classes.name}
                onClick={() => this.handleNavigation(author.id)}
                style={{ color: this.themeValue("#222222", "#FFFFFF") }}
              >
                {author.full_name}
              </Typography>
              <img
                src={verifyImg(post_by.user_subscription)}
                alt="verify-icon"
              />
              {author.user_ownership && <img src={ownerShipIcon} alt="" />}
              <Typography className={classes.userName}>
                {author.user_name}
              </Typography>
              {!(author.is_following || this.state.loggedUserId == author.id) && (
                <Button
                  data-test-id="followId"
                  className={classes.followBtn}
                  onClick={() => onFollow(author.id, "follow")}
                >
                  Follow
                </Button>
              )}
            </Box>

            <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <span
                className={classes.statustime}
                style={{ color: this.themeValue("#6B6B6B", "#6B6B6B") }}
              >
                {postData.attributes.created_at}
              </span>
              {is_repost && (
                <Chip
                  className={classes.reshotImg}
                  style={{
                    background: this.themeValue(
                      "",
                      "rgba(232, 227, 227, 0.13)"
                    ),
                  }}
                  icon={
                    <img src={this.themeValue(reshotLightIcon, reshotIcon)} />
                  }
                  label={postData.attributes.reposted_by}
                />
              )}
            </Box>
          </Box>
          <Box
            data-test-id="menuBtn"
            onClick={(e) => {
              this.handleClickMenu(e);
              onClickMoreOption(author, postData.id);
            }}
          >
            <img
              src={threeDots}
              className={classes.threedots}
              alt="more-option"
            />
          </Box>
          <Menu
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            disableAutoFocusItem
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            className={classes.menuStyle}
          >
            {author.id !== this.state.loggedUserId
              ? [
                  <MenuItem
                    key="follow"
                    data-test-id="MenuItems1"
                    style={webStyle.menuItemStyle}
                    className={classes.subcriptionBody}
                    onClick={() =>
                      onFollow(
                        author.id,
                        this.conditionRemover(
                          author.is_following,
                          "UnFollow",
                          "follow"
                        )
                      )
                    }
                  >
                    {this.conditionRemover(
                      author.is_following,
                      configJSON.UnFollow,
                      configJSON.Follow
                    )}{" "}
                    {author.user_name}
                  </MenuItem>,
                  <MenuItem
                    key="custom-list"
                    data-test-id="custom-list-item"
                    style={webStyle.menuItemStyle}
                    className={classes.subcriptionBody}
                    onClick={() => {
                      onToggleRemoveAddUserCustomList(author.id, !author.is_tailored)
                      this.handleClose()
                    }}
                  >
                    {this.renderCustomText(author.is_tailored, author.user_name)}
                  </MenuItem>,
                  <MenuItem
                    key="mute"
                    data-test-id="mute-item"
                    onClick={() => {
                      this.handleClose();
                      onMuteConformation();
                    }}
                    style={webStyle.menuItemStyle}
                    className={classes.subcriptionBody}
                  >
                    {this.conditionRemover(
                      author.is_muted,
                      `Unmute ${author.user_name}`,
                      `Mute ${author.user_name}`
                    )}
                  </MenuItem>,
                  <MenuItem
                    key="block"
                    data-test-id="MenuItems3"
                    onClick={() => onBlockAccount(author.id)}
                    style={webStyle.menuItemStyle}
                    className={classes.subcriptionBody}
                  >
                    {configJSON.Block} {author.user_name}
                  </MenuItem>,
                  <MenuItem
                    key="share-post"
                    data-test-id="share-post"
                    style={webStyle.menuItemStyle}
                    onClick={() => {}}
                    className={classes.subcriptionBody}
                  >
                    Share post
                  </MenuItem>,
                  <MenuItem
                    key="report"
                    onClick={onShowReportPost}
                    style={webStyle.menuItemStyle}
                    className={classes.subcriptionBody}
                  >
                    {configJSON.reportPost}
                  </MenuItem>,
                ]
              : [
                  <MenuItem
                    key="pin"
                    data-test-id="MenuItems1"
                    style={webStyle.menuItemStyle}
                    className={classes.subcriptionBody}
                    onClick={() => this.handlePinpost(is_pinned)}
                  >
                    {this.conditionRemover(
                      is_pinned,
                      configJSON.unpinProfile,
                      configJSON.pinProfile
                    )}
                  </MenuItem>,
                  <MenuItem
                    key="deletePost"
                    onClick={this.handleDeletePost}
                    data-test-id="delete-post"
                    style={webStyle.menuItemStyle}
                    className={classes.subcriptionBody}
                  >
                    {configJSON.deletePost}
                  </MenuItem>,
                  <MenuItem
                    key="block"
                    data-test-id="MenuItems3"
                    onClick={this.handleCommentSetting}
                    style={webStyle.menuItemStyle}
                    className={classes.subcriptionBody}
                  >
                    {configJSON.changeReply}
                  </MenuItem>,
                ]}
          </Menu>
        </Box>
      </Box>
    );
  }
}

const webStyle = {
  menuItemStyle: {
    padding: "9px 20px 9px 16px",
    marginBottom: "8px",
    transition: "all 0.3s ease",
  },
};

const styles = createStyles({
  container: {
    width: "100%",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },

  postImage: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    objectFit: "cover",
  },

  headconatin: {
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },

  ContentHead: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },

  name: {
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 800,
    lineHeight: "21px",
    whiteSpace: "nowrap",
    color: "#fff",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  userName: {
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "14px",
    whiteSpace: "nowrap" as "nowrap",
    color: "#6B6B6B",
  },

  followBtn: {
    width: "65px",
    height: "25px",
    borderRadius: "10px",
    padding: "4px 8px 4px 8px",
    gap: "7px",
    fontSize: "12px",
    fontFamily: "Manrope",
    color: "white",
    fontWeight: 500,
    textTransform: "capitalize" as "capitalize",
    background:
      "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))",
  },

  statustime: {
    fontSize: "11px",
    fontFamily: "Manrope",
    fontWeight: 400,
    lineHeight: "15px",
  },

  reshotImg: {
    height: "16px",
    borderRadius: "6px",
    padding: "5px 8px 5px 6px",
    gap: "4px",
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 500,
  },

  threedots: {
    cursor: "pointer",
    width: "24px",
    height: "24px",
  },

  menuStyle: {
    borderRadius: "5px",
    padding: "10px 0px 10px 0px",
    gap: "8px",
    "& .MuiPaper-root": {
      boxShadow: "none",
    },
  },

  subcriptionBody: {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
  },
});
// Customizable Area End

export default withStyles(styles)(PostHeader);