import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile,chatIcon,timelineIcon,notificationIcon,libraryIcon, darkTimelineIcon, darkLibraryIcon, darkNotificationIcon, darkChatIcon, savedArrows, darkSavedArrows, subscription, darkSubscription, earning, darkEarning, setting, darkSetting, searchIcon, searchDarkIcon, profileIconDark, profileIconLight} from "./assets";
import { Linking } from "react-native";
import React from "react"
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { logoutCometChat } from "../../../components/src/utils";

export interface IUser {
  profile_photo:string;
  fullName:string;
  username:string;
  user_id:string;
}

export interface IUserResponseAttributes{
  id: number,
  full_name: string,
  user_name: string,
  email: string,
  full_phone_number: string,
  country_code: string|null,
  phone_number: string,
  bio: string|null,
  location: string|null,
  website: string|null,
  occupation: string|null,
  created_at: string,
  is_early_adopter: boolean,
  date_of_birth: string,
  gender: string |null,
  profile_photo: string|null,
  cover_photo: string |null
}

export interface IUserResponse {
    data: {
      id: string,
      type: string,
      attributes:IUserResponseAttributes
    }
  
}

export interface IRefreshToken {
  token:string,
  refresh_token:string,
  auth_token?: string;
}

export interface IError {
  error:{}|string
}

export interface IUserList extends IUser{
  refresh_token:string;
  token:string;
  cometChatToken?: string;
}

export interface IUserSessionList extends IUserList {
   isTokenExpired:boolean
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:{[key:string]:string}
  drawerContent?: boolean;
  checked?:boolean;
  tabItem?:'Timeline'|'Library'|'Alerts'|'Chats'|'Profile'|'Search'|'Settings'|'Earnings'|'Subscription'
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  tabItems:{id:number,icon:string, darkIcon:string, name:string,path:string}[]
  AccountModalOpen:boolean;
  selectedAccount:string|undefined; 
  users:Array<IUserList>
  userList:Array<IUserSessionList>
  anchorEl:null | HTMLElement;
  selectedUser:IUserSessionList | undefined;
  multiUserLoading:boolean
  reRenderChild:boolean,
  RefreshUser:IUserList|undefined
  RefreshedTokenLoading:boolean
  deleteUser:boolean
  deletedUser:string
  removedAccount:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  apiGetMultiUserCallId:string=""
  apiRefreshToken:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      selectedAccount:undefined,
      users:[],
      anchorEl:null,
      tabItems:[
        {id:1,icon:timelineIcon, darkIcon:darkTimelineIcon, name:'Timeline',path:'ActivityFeed'},
        {id:2,icon:searchIcon, darkIcon:searchDarkIcon, name:'Search',path:'AdvancedSearch'},
        {id:3,icon:libraryIcon, darkIcon:darkLibraryIcon, name:'Library',path:'Library'},
        {id:4,icon:notificationIcon, darkIcon:darkNotificationIcon, name:'Alerts',path:'Notifications'},
        {id:5,icon:chatIcon, darkIcon:darkChatIcon, name:'Chats',path:'Chat'},
        {id:6,icon:subscription, darkIcon:darkSubscription, name:'Subscription',path:'AutomaticRenewals'},
        {id:7,icon:earning, darkIcon:darkEarning, name:'Earnings',path:''},
        {id:8,icon:setting, darkIcon:darkSetting, name:'Settings',path:'Settings'},
      ],
      AccountModalOpen:false,
      selectedUser:undefined,
      userList:[],
      multiUserLoading:false,
      reRenderChild:false,
      RefreshUser:undefined,
      RefreshedTokenLoading:false,
      deleteUser:false,
      deletedUser:'',
      removedAccount:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(responseJson){
     if(apiRequestCallId === this.apiGetDataCallId) {
        this.handleUserDataResponse(responseJson)
     }else if(apiRequestCallId === this.apiGetMultiUserCallId){
      this.handleMultiUserDataResponse(responseJson)
     }else if(apiRequestCallId === this.apiRefreshToken){
        this.handleRefreshToken(responseJson)
     }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.handleMultiUserList();
    this.handleSelectedUser();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
     if(prevState.selectedAccount!==this.state.selectedAccount){
      this.getUserData()
     }
  }



  handleUserDataResponse=(responseJson:IUserResponse & IError)=>{
    if (responseJson?.data) {
      this.setState((prevState)=>({
         selectedUser:{
          ...prevState.selectedUser as IUserList,
           fullName:responseJson.data.attributes.full_name as string,
           profile_photo:responseJson.data.attributes.profile_photo as string,
           username:responseJson.data.attributes.user_name,
           isTokenExpired:false
         }
      }))
    } 
  }


  handleMultiUserDataResponse=(responseJson:Array<IUserResponse & IError> & {errors:string})=>{
      if(!responseJson?.errors){
        const multiUserList:Array<IUser> = responseJson.map((item) => {
          if (item?.data) {
            return {
              user_id: item.data.id as string,
              profile_photo: item.data.attributes.profile_photo as string,
              username: item.data.attributes.user_name as string,
              fullName: item.data.attributes.full_name as string
            };
          }
        }).filter(item=>item!==undefined) as unknown as Array<IUser>

        const List = this.state.users.map((item)=>{

          const isExpired = multiUserList.find((item2) => item2.user_id == item.user_id)
            if(isExpired){
                 return {...item,
                  isTokenExpired:false,
                  username:isExpired.username, 
                  fullName:isExpired.fullName,
                  profile_photo:isExpired.profile_photo}
            }else{
              return {...item,isTokenExpired:true}
            }
         })

         this.setState({
          userList:List,
          multiUserLoading:false
         })
      }

  }
  
  handleMultiUserList=async()=>{
    const multiUserList = await getStorageData("multi_user",true)
    const activeMultiUser:Array<IUserList> = multiUserList ?? []
    if(activeMultiUser.length>0){
    this.setState({
      users: activeMultiUser,
    })
    } 
  }

  handleSelectedUser=async()=>{
    const selectedUser = await getStorageData("selectedUser",true)
    const activeUser:IUserList = selectedUser ?? undefined
    if(activeUser){
    this.setState({
      selectedUser: {...activeUser,isTokenExpired:false},
      selectedAccount: activeUser.user_id 
    })
   }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  handleRefreshToken=(responseJson:IRefreshToken & IError)=>{
    if(responseJson?.token){
      const payload = this.state.RefreshUser
      const RefreshPayload = {
         ...payload,
         token:responseJson?.token,
         refresh_token:responseJson?.refresh_token
        }
        setStorageData("cometChatToken", responseJson?.auth_token)
        setStorageData("authToken",responseJson.token)
        setStorageData("selectedUser",JSON.stringify(RefreshPayload))
        this.handleSelectedUser()   
        window.location.reload() 
    }
  }

  getUserData = async () => {
    let token = await getStorageData("authToken");
    
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetDataCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getUserInfo);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.getApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  getMultiUsers = async () => {
    let token = await getStorageData("authToken");
    const userTokenList = this.state.users.map(item=>(item.token))   
    const payload={
      "tokens": userTokenList
    }

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetMultiUserCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getMultiUsers);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(payload));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.exampleAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigation=(route:string)=>{
    if(route === 'Profile'){
      const id=this.state.selectedUser?.user_id
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'Profile');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
      this.send(message);
  

    }else{
    this.setNavigation(route)
    }
  }

  handleNavigationToProfile=():void=>{
    this.handleNavigation('Profile')
  }

   handleCheckboxChange = async (switchUser:IUserSessionList) => {
    const payload = {
      refresh_token:switchUser.refresh_token,
      user_id:switchUser.user_id,
      profile_photo:switchUser.profile_photo,
      username:switchUser.username,
      token:switchUser.token,
      fullName:switchUser.fullName,
    };
    if(!switchUser.isTokenExpired){
      this.setState({
        reRenderChild:true,
      })
      setStorageData("authToken",switchUser.token);
      setStorageData("cometChatToken",switchUser?.cometChatToken || "null")
      setStorageData("selectedUser",JSON.stringify(payload));
      try {
        await logoutCometChat();
        console.log("Logout completed successfully");
      } catch (error) {
        console.log("Logout failed with exception:", error);
      };
      this.handleSelectedUser()
      window.location.reload()
    }else{
      this.setState({
        RefreshedTokenLoading:false,
        RefreshUser:payload
      })
      this.refeshToken(switchUser)
    }

  };


  refeshToken=async(switchUser:IUserSessionList)=>{
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
       token: switchUser.refresh_token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiRefreshToken = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getRefreshedToen);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.exampleAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleCreateAccountNavigation=()=>{
    this.setNavigation("EmailAccountRegistration")
  }
  
  handleAddUserNavigation=()=>{
    this.setNavigation("EmailAccountLoginBlock")
  }
   

  setNavigation=(route:string)=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),route
    );
    this.send(message);
  }

  handleAccountModal=()=>{
    this.setState({
      AccountModalOpen:true
    })
    this.getMultiUsers()
  }

  handleClose=()=>{
    this.setState({
      AccountModalOpen:false
    })
  }

  handleMenuClick = (event:React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleMenuClose=()=>{
    this.setState({
      anchorEl: null
    });
  }

  showDeleteUsers=()=>{
    this.setState({deleteUser:true})
  }

  closeUser=()=>{
    this.setState({deleteUser:false})
  }
  removeUser=(title:string)=>{
    this.setState({AccountModalOpen:false,deletedUser:title})
  }
  closeRemoveUser=()=>{
    this.setState({deletedUser:'',deleteUser:true,AccountModalOpen:true})
  }

  removeUserAccount=async()=>{
    const userArray=await getStorageData('multi_user',true)
    const filteredArray=userArray.filter((each:{username:string})=>each.username!==this.state.deletedUser)
    setStorageData('multi_user',JSON.stringify(filteredArray))
    this.handleMultiUserList()
    this.setState({removedAccount:true,deletedUser:''})
    this.handleCheckboxChange(filteredArray[0])
    setTimeout(()=>{
      this.setState({removedAccount:false,deleteUser:false})
    },2000)
  }
  handleRemovePopup=()=>{
    this.setState({removedAccount:false,deleteUser:false})
  }

  handleRemoveNotRemove=()=>{
    this.setState({deletedUser:'',deleteUser:true,AccountModalOpen:true})
  }
  
  // Customizable Area End
}
