import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {setStorageData,getStorageData} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked:boolean;
  classes?: { [key: string]: string }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ownerDetails: { id: number, title: string, description: string, created_at: string, updated_at: string };
  userToken: string;
  ownerShipDetails: {
    id: string,
    type: string,
    attributes: {
      id: number,
      price: string,
      owned: boolean
    }
  }[];
  submitPriceDetails: {
    id: string,
    type: string,
    attributes: {
      Ownerships: {
        id: number,
        price: string,
        owned: boolean
      }
    }
  };
  isButtonEnabled: boolean;
  apiError: string;
  isLoading:boolean;
  updateOwnership:boolean;
  userOwnershipId:number|null;
  activeState:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start  
  id: any;
  // Customizable Area End
}

export default class BecomeOwnerContoller extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start 
   ownerDetailsCallId:string=''
   ownerShipCallId:string=''
   submitSharePriceApiId:string=''
   apiGetDataCallId:string=''
   submitOwnerStatusApi:string=''
   // Customizable Area End 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage)
        // Customizable Area End
    ];

    this.state = {
        // Customizable Area Start
      ownerDetails: { id: 0, title: '', description: '', created_at: '', updated_at: '' },
      userToken: '',
      ownerShipDetails: [],
      submitPriceDetails: {
        id: "0",
        type: "",
        attributes: {
          Ownerships: {
            id: 0,
            price: '',
            owned: false
          }
        }
      },
      isButtonEnabled: false,
      apiError: '',
      isLoading:true,
      updateOwnership:false,
      userOwnershipId:null,
      activeState:''
        // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.ownerDetailsApi(message)
    this.ownerShipApi(message)
    this.submitSharePriceApi(message)
    this.getLoggedUserResponse(message)
    this.showOwnerStatus(message)
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("authToken");
    const paymentStatus = new URLSearchParams(window.location.search).get('payment');
    if (paymentStatus === "success") toast.success("Subscription successfully");
    this.setState({ userToken: token }, () => {
      this.getOwnerShips()
      this.getUserData()
      this.getBecomeOwnerDetails()
    })
  }

  ownerDetailsApi=(message:Message)=>{
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

        if (apiRequestCallId === this.ownerDetailsCallId) {
          if (apiRequestCallId && !responseJson.errors) {
          if (responseJson) {
            this.setState({
              ownerDetails: responseJson
            });
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }else {
          this.setState({ apiError: responseJson.errors[0] })
        }
      }
    }
  }

  submitSharePriceApi=(message:Message)=>{
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({isLoading:false})

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && !responseJson.errors) {
        if (apiRequestCallId === this.submitSharePriceApiId) {
          if (responseJson) {
            this.setState({
              submitPriceDetails: responseJson.data
            });
            this.navigateToFollower()
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
      else {
        this.setState({ apiError: responseJson.errors[0] })
      }
    }

  }

  getLoggedUserResponse=(message:Message)=>{

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
        if (apiRequestCallId === this.apiGetDataCallId) {
          if (apiRequestCallId && !responseJson.errors) {
          if(responseJson){
            this.setState({
             userOwnershipId:responseJson.data.attributes.user_ownership_id
            });
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }

    }

  }
  formatPricesWithComma = (data:any) => {
    return data.map((item: { attributes: { price: { toLocaleString: () => any; }; }; }) => ({
      ...item,
      attributes: {
        ...item.attributes,
        price: `$${item.attributes.price.toLocaleString()}`
      }
    }));
  };

  ownerShipApi=(message:Message)=>{
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({isLoading:false})

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.ownerShipCallId) {
      if (!responseJson.errors) {
          if (responseJson) {
            const alreadyOwns  =  responseJson.data.some((item:{
              id:string,
              type:string,
              attributes:{
                  owned:boolean,
                  price:string,
                  id:number
              }
             })=>item.attributes.owned===true)
            this.setState({
              ownerShipDetails: responseJson.data,
              updateOwnership:alreadyOwns
            },this.enableButton);
          }
          this.setState({ownerShipDetails:this.formatPricesWithComma(responseJson.data)
        })
          this.parseApiCatchErrorResponse(errorReponse);
        } else {
          this.setState({ apiError: responseJson.errors[0] })
        }
      }
    }

  }

  showOwnerStatus=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.submitOwnerStatusApi) {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "Followers"
        );
        this.send(message);

      } else {
        if (responseJson.errors) {
          this.setState({activeState:'invalid token'})
        }
      }
    }
  }

  getBecomeOwnerDetails=async()=>{
    
    const header = {
      token: this.state.userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ownerDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOwnerDetailsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getOwnerShips=async()=>{
    const header = {
      token: this.state.userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ownerShipCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOwnershipApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  selectPrice=(priceId:number)=>{
    const response = this.state.ownerShipDetails.map((ownerShip) => {
      if (ownerShip.attributes.id === priceId) {
        return { ...ownerShip, attributes: { ...ownerShip.attributes, owned: !ownerShip.attributes.owned } }
      }
      else {
        return { ...ownerShip, attributes: { ...ownerShip.attributes, owned: false } }
      }

    })

    this.setState({ ownerShipDetails: response }, () => {
      this.enableButton()
    })

 }

 enableButton=()=>{
   const response = this.state.ownerShipDetails.filter((ownerShip) => ownerShip.attributes.owned === true)
   if (response.length > 0) {
     this.setState({ isButtonEnabled: true })
   }
   else {
     this.setState({ isButtonEnabled: false })
   }
}

getUserData = async () => {
  let token = await getStorageData("authToken");
  
  const header = {
    "Content-Type": configJSON.jsonApiContentType,
    token: token,
  };

  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.apiGetDataCallId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getUserInfo);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};



handleSubmitPrice=()=>{
  const response = this.state.ownerShipDetails.filter((ownerShip) => ownerShip.attributes.owned === true)
  const ownershipId = response[0].attributes.id
  const httpBody = { ownership_id: ownershipId }
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.state.userToken
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.submitSharePriceApiId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.submitPriceEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.submitSubscriptionAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleOwnership=()=>{
    if(this.state.updateOwnership){
      this.updateOwnershipPrice()
    }else{
      this.handleSubmitPrice()
    }
  }
  
  updateOwnershipPrice=()=>{
    const response = this.state.ownerShipDetails.filter((ownerShip) => ownerShip.attributes.owned === true)
    const ownershipId = response[0].attributes.id
    const httpBody = { ownership_id: ownershipId }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.userToken
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitSharePriceApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.submitPriceEndPoint}/${this.state.userOwnershipId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchTypeApi
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }



  navigateToFollower=()=>{
    this.submitOwnerStatus()
  }

  submitOwnerStatus=async()=>{
    const formData = new FormData();
    formData.append("signup_state",'connect_social_account')
    const token = await getStorageData("authToken");

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitOwnerStatusApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPictureApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadPictureApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  navigateToSubscription=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Customisableusersubscriptions"
    );
    this.send(message);

  }
  // Customizable Area End
}
