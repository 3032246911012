export const searchWhite=require("../assets/search-white.png");
export const searchDark=require("../assets/search-dark.png");
export const backgroundIncomingCall=require("../assets/backgroundIncomingCall.svg");
export const acceptCallIcon=require("../assets/acceptCallIcon.svg");
export const rejectCallIcon=require("../assets/rejectCallIcon.svg");
export const callIcon=require("../assets/callIcon.svg");
export const sendMessageIcon=require("../assets/sendMessageIcon.svg");
export const profileAvatar=require("../assets/profileAvatar.svg");
export const addUser=require("../assets/addUser.svg");
export const createCallLinkIcon=require("../assets/createCallLinkIcon.svg");
export const closeIcon=require("../assets/closeIcon.svg");
export const linkCallIcon=require("../assets/linkCallIcon.svg");
export const copyLinkIcon=require("../assets/copyLinkIcon.svg");
export const shareLinkIcon=require("../assets/shareLinkIcon.svg");
export const icCallBtn=require("../assets/ic_callBtn.svg");
export const icIncomingCall=require("../assets/ic_incomingCall.svg");
export const icOutgoingCall=require("../assets/ic_outgoingCall.svg");
export const icAddUser=require("../assets/ic_addUser.svg");
export const icCallPlus=require("../assets/ic_callPlus.svg");