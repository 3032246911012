// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    checked?:boolean
    open:boolean
    handleClose:()=>void
    handlenext:()=>void
    type?:string
    // Customizable Area End
  }
  
  interface S {
    // Customizable Area Start
    termsAndConditionsData:Array<{
        id: number;
        created_at: string;
        updated_at: string;
        title: string;
        description: string[];
    }>
    termsAndConditionsError:string
    // Customizable Area End
  }
  
  interface SS {
    // Customizable Area Start  
    // Customizable Area End
  }
  // Customizable Area Start
  export default class TermsConditionModalController extends BlockComponent<
  Props,
  S,
  SS
> {

    termsAndConditionsApiCallId:string=""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.NavigationPayLoadMessage),
        ];
    
        this.state = {
            termsAndConditionsData:[],
            termsAndConditionsError:''
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      }


      componentDidUpdate=(prevProps:Props,prevState:S)=>{
      if(this.props.open!==prevProps.open){
        if(this.props.open){
            this.termsAndCondition()
        }
      }
      }

      async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.termsAndConditionsApiCallId) {
            if (responseJson && !responseJson.errors) {
                  if (responseJson.data) {
                    const apiDetails = {
                        ...responseJson.data,
                        description: this.unescapeHtml(responseJson.data.description)
                    };
                    const splitDescription = apiDetails.description.split("\r\n\r\n");
                    this.setState({
                        termsAndConditionsData: [{
                            ...apiDetails,
                            description: splitDescription.reverse()
                        }]
                    });
                }
                }else {
                    this.setState({ termsAndConditionsError: configJSON.noTerms });
                  }
              } 
        }      
      }

      unescapeHtml(html: string): string {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;  
    }

  
   termsAndCondition=()=>{
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.termsAndConditionsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.termsAndConditionsApiEndpoint}?type=${this.props.type}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.suggestionApiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
   }

  

}


  // Customizable Area End