import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  withStyles,
  createStyles,
  createTheme,
  IconButton,
} from "@material-ui/core";
import { backArrowDark, backArrowWhite, callDark, callLight, groupIcon, ownerShipIcon, profile, searchDark, searchWhite } from "./assets";
import 'emoji-mart/css/emoji-mart.css'
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatMessageList } from "@cometchat/chat-uikit-react";
import MenuOptions from "../../../components/src/Chats/MoreOptions.web"
import { dateStyle, getConversationAvatar, IConversation, messageListStyle } from "../../../components/src/CometChat";
// Customizable Area End

import ViewChatController, { configJSON, IMessage, Props } from "./ViewChatController";

// Customizable Area Start
import clsx from "clsx"
import { handleConditionFunction, verifyImg } from "../../../components/src/utils"
import MuteNotifiactionsModal from "../../../components/src/Chats/MuteNotificationsModal.web"
import DisappearingMessagesModal from "../../../components/src/Chats/DisappearingMessagesModal.web"
import BlockModal from "../../../components/src/Chats/BlockModal.web"
import ClearChatModal from "../../../components/src/Chats/ClearChatModal.web"
import MediaDocsPopover from "../../../components/src/Chats/MediaDocsPopover.web"
import ModalTotalReaction from "../../../components/src/ModalTotalReaction.web"
import ViewChatFooter from "../../../components/src/ViewChatFooterWrapper.web"
import ViewChatLoading from "../../../components/src/ViewChatLoading.web"
import ViewChatSearch from "../../../components/src/ViewChatSearch.web"
import ReportUserModal from "../../customisableuserprofiles/src/ReportUserModal.web"
import GroupsEdit from "../../../components/src/GroupsEdit.web"
import ImageRenderer from "../../../components/src/ImageRenderer.web"

// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderMuteNotificationsModal = () => (
    <MuteNotifiactionsModal
      data-testid="muteModal"
      isOpen={this.state.muteNotificationsModal}
      checked={!this.props.checked}
      onMute={() => { }}
      value=""
      onClose={this.handleToggleMuteModal}
    />
  );

  renderDisappearingMessagesModal = () => (
    this.state.disappearingNotificationModal && (
      <DisappearingMessagesModal
        data-testid="messagesModal"
        isOpen={this.state.disappearingNotificationModal}
        value={this.state.conversationSetting.disappearing_messages?.toString()}
        checked={!this.props.checked}
        onSubmit={this.updateDisappearingMessageSetting}
        onClose={this.handleToggleDisappearingModal}
      />
    )
  );

  renderClearChatModal = () => (
    <ClearChatModal
      data-testid="clearChatModal"
      isOpen={this.state.clearChatModal}
      checked={!this.props.checked}
      onSubmit={this.clearChat}
      onClose={this.handleToggleClearChatModal}
    />
  );

  renderReportModal = () => {
    return (
      <ReportUserModal
        navigation={this.props.navigation}
        id={''}
        showReportPostModal={this.state.showReportUserModal}
        reportPostMainList={this.state.reportUserMainList}
        reportPostSubList={this.state.reportUserSubCatList}
        setReportPostMainCat={this.setReportUserIssue}
        setReportPostSubCat={this.setReportUserSubIssues}
        closeReportPostModal={this.closeReportUserModal}
        changeReportView={this.changeReportView}
        reportPostLoading={this.state.reportPageLoading}
        reportCurrentView={this.state.reportCurrentView}
        reportCatId={this.state.reportIssue}
        reportSubCatId={this.state.reportSubIssue}
        checked={!!this.props.checked}
        data-testid="Report"
      />
    )
  }

  renderModalTotalReaction = () => (
    <ModalTotalReaction
      checked={this.props.checked}
      listReaction={this.state.listReaction}
      isOpen={this.state.isOpenModalTotalReaction}
      onCloseModal={this.handleCloseModalTotalReaction}
      userInfo={this.state.loginUser} />
  )

  renderModalEditGroup = () => {
    const { conversation } = this.props;
    const {
      scope = "",
      guid = "",
    } = conversation as unknown as IConversation;

    const isCanEditGroup = scope === "admin" || this.state.conversationPermissions.can_edit_group_settings;

    return (<GroupsEdit
      scope={scope}
      guid={guid}
      checked={this.props.checked}
      isOpen={this.state.isOpenEditGroup}
      isCanEdit={isCanEditGroup}
      groupsSettings={this.state.conversationSetting}
      groupsPermissions={this.state.conversationPermissions}
      isOpenModalDisappearing={this.state.disappearingNotificationModal}
      onUploadAttachment={this.handleUploadAttachment}
      onToggleDisappearingModal={this.handleToggleDisappearingModal}
      onToggleModalEditGroup={this.handleToggleOpenEditGroup}
      onUpdateGroupSetting={this.updateConversationSettings}
      onUpdateGroupPermisssions={this.updateConversationPermissions}
      handleCreateGroupPlan={this.handleCreateGroupPlan}
    />
    )
  }

  renderModals = () => (
    <>
      {this.renderMuteNotificationsModal()}
      {this.renderDisappearingMessagesModal()}
      {this.renderClearChatModal()}
      {this.renderModalTotalReaction()}
      {this.renderReportModal()}
      {this.renderModalEditGroup()}
    </>
  );

  renderHeaderViewChat = () => {
    const { isTypingMessage, isBlockedByMe, isToggleSearch, isOpenEditGroup } = this.state;

    const { checked, classes, conversation } = this.props;
    const {
      uid="",
      guid = "",
      scope
    } = conversation as unknown as IConversation;

    const renderConversationName = () => {
      const { name = "" } = this.state.conversationSetting;
      return name || conversation.getName();
    }

    const renderSubConversationHeader = () => {
      const {
        status = ""
      } = conversation as unknown as IConversation;
      const { listMemberGroupChat } = this.state;
      if (isTypingMessage)
        return handleConditionFunction(isTypingMessage, "Typing...", " ")
      if (status)
        return status;
      if (listMemberGroupChat.length) {
        return listMemberGroupChat.map(member => member.name).join(", ")
      }

    }

    return <Box className={classes.chatHeader}>
      <Box className={classes.chatHeaderItem}>
        <span className={classes.iconWrapper} data-test-id="backConversation" onClick={this.props.onCloseSelectedConversation}>
          <img className={classes.icon} src={handleConditionFunction(checked, backArrowDark, backArrowWhite)} />
        </span>
        <ImageRenderer
          className={classes.headerProfileImg}
          width={40}
          height={40}
          style={{ border: handleConditionFunction(checked, "1px solid #D1B7F5", "1px solid #FFFF") }}
          src={this.renderConversationAvatarHeader()}
          alt="conversation-avatart"
        />
        <Box className={classes.headerConversation}>
          <Typography
            data-test-id="nameTitleHeader"
            className={`${classes.headerProfileName} ${!!guid && classes.headerGroupName}`}
            onClick={() => this.handleToggleOpenEditGroup(isOpenEditGroup)}
          >
            {renderConversationName()}
          </Typography>
          <Typography className={classes.headerSubContent}>{renderSubConversationHeader()}</Typography>
        </Box>
      </Box>
      <Box className={classes.chatHeaderItem} style={{ gap: 8 }}>
        <IconButton className={classes.iconWrapper} data-test-id="startCallBtn" onClick={() => this.props.onStartCall(uid || guid, guid && CometChat.RECEIVER_TYPE.GROUP)}>
          <img className={classes.icon} src={handleConditionFunction(checked, callDark, callLight)} alt="" />
        </IconButton>
        <IconButton data-test-id="toggleSearch" className={classes.iconWrapper} onClick={() => this.handleToggleSearch(isToggleSearch)}>
          <img
            className={classes.icon}
            src={handleConditionFunction(checked, searchDark, searchWhite)}
            alt=""
          />
        </IconButton>
        <span className={classes.iconWrapper}>
          <MenuOptions theme={handleConditionFunction(checked, commonLightTheme, commonDarkTheme)}
            checked={checked}
            data-testid="menuBtn"
            menuItems={[
              { id: 1, name: "Media", action: this.handleMediaDocs },
              { id: 2, name: "Mute Notifications", action: () => { this.handleToggleMuteModal() }, disabled: true },
              { id: 3, name: "Disappearing messages", action: () => { this.handleToggleDisappearingModal() }, disabled: true },
              { id: 4, name: "Report", action: () => { this.showReportModal() }, disabled: true },
              { id: 5, name: handleConditionFunction(isBlockedByMe, "Unblock", "Block"), action: () => { this.handleToggleBlockModal() }, disabled: !!guid },
              { id: 6, name: "Clear Chat", action: () => { this.handleToggleClearChatModal() }, },
              { id: 7, name: "Exit Group", action: () => { this.handleExitGroup(guid) }, disabled: scope === "admin" || !guid, textColor: "#DC2626" },
            ]}
          />
        </span>
      </Box>
    </Box>
  }

  renderSearchChatContainer = () => {
    const { valueSearchChat } = this.state;
    const { conversation, checked } = this.props;

    return <ViewChatSearch
      conversation={conversation}
      searchValue={valueSearchChat}
      onSetValueSearchChat={this.handleSetValueSearchChat}
      checked={!!checked}
    />
  }

  renderConversationAvatarHeader = () => {
    const { conversation } = this.props;
    const {
      guid,
    } = conversation as unknown as IConversation;
    const { icon = "" } = this.state.conversationSetting;
    if (!guid && !getConversationAvatar(conversation)) return profile;
    if (!icon && guid && !getConversationAvatar(conversation)) return groupIcon;
    return icon || getConversationAvatar(conversation);
  }

  renderMessageContainer = () => {
    const { listFileUpload } = this.state;
    const { classes, checked, conversation } = this.props;

    const initialMetadata = {
      username: "",
      user_subscription: "",
      user_ownership: false
    };
    const {
      name = "",
      metadata = initialMetadata,
    } = conversation as unknown as IConversation;
    const { username = "", user_subscription = "", user_ownership = false } = metadata;

    return <div className={`${classes.messagesContainer} ${listFileUpload.length && classes.messageContainerContainFiles}`} ref={this.messageContainerRef}>
      <Box className={`${classes.messageInformation} ${clsx(handleConditionFunction(this.state.listMessage.length, "displayNone", ""))}`}>
        <Box className={classes.messageInformation__avatar}>
          <img alt="ava" src={this.renderConversationAvatarHeader()} width={90} height={90} />
        </Box>
        <Box className={classes.messageInformation__info}>
          <Typography component="p" className={`messageInformation__infoName ${clsx(checked && classes.lightThemeText)}`}>
            {name}
          </Typography>
          <Box className="messageInformation__infoBadge">
            <Box>
              <img alt="i" src={verifyImg(user_subscription)} width={14} height={14} />
            </Box>
            <Box>
              {user_ownership && <img alt="i" src={ownerShipIcon} width={14} height={14} />}
            </Box>
          </Box>
        </Box>
        <Box className={classes.messageInformation__username}>
          <Typography component="p" className={clsx(checked && classes.lightThemeText)}>
            {username}
          </Typography>
        </Box>
      </Box>
      {
        this.state.isLoading
          ? <ViewChatLoading />
          : <CometChatMessageList
            user={conversation instanceof CometChat.User ? conversation : undefined}
            group={conversation instanceof CometChat.Group ? conversation : undefined}
            scrollToBottomOnNewMessages={true}
            key={this.props.refreshMessageList.toString()}
            messagesRequestBuilder={this.handleGetMessagesRequestBuilder()}
            templates={this.state.template}
            messageListStyle={messageListStyle}
            dateSeparatorStyle={dateStyle}
            hideReceipt={true}
          />
      }
    </div>
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const initialMetadata = {
      username: "",
      membersPermissions: {
        addMembers: true,
        editSettings: true,
        sendMessages: true
      },
    };
    const { isBlockedByMe, listFileUpload, messageReplyItem, isToggleSearch, loginUser } = this.state;
    const { checked, conversation, classes } = this.props;
    const {
      metadata = initialMetadata,
      uid = "",
      guid = "",
    } = conversation as unknown as IConversation;
    const { username = "" } = metadata;
    const checkUserCanNotSendMessage = this.state.conversationPermissions.cannot_send_messages.some(uidMember => uidMember === this.state.loginUser?.getUid());
    const isCheckCanSendMessage = !guid || !checkUserCanNotSendMessage;

    return (
      <>
        {this.renderModals()}
        <MediaDocsPopover conversation={conversation} anchorEl={this.state.mediaDocsElement} checked={!checked} onClose={this.handleCloseMediaDocs} />
        <BlockModal data-testid="blockModal" isOpen={this.state.blockUserModal}
          checked={!checked} userName={username || `@${conversation.getName()}`}
          onClose={this.handleToggleBlockModal} onSubmit={() => { this.handleBlockUser(uid) }} isBlock={isBlockedByMe} />

        <Box data-test-id="viewChatId" className={classes.container} style={{ gap: isToggleSearch ? "20px" : "15px" }}>
          {this.renderHeaderViewChat()}
          {isToggleSearch && this.renderSearchChatContainer()}
          {this.renderMessageContainer()}

          <ViewChatFooter
            userInfo={loginUser}
            isCheckCanSendMessage={isCheckCanSendMessage ?? false}
            checked={checked || false}
            message={this.state.message}
            listFileUpload={listFileUpload}
            refFileInput={this.fileInputRef}
            messageReplyItem={messageReplyItem}
            onMessageChange={this.handleMessageChange}
            onMessageBlur={this.handleBlurMessage}
            onFileSelect={this.handleSelectFileIcon}
            onSelectFiles={this.handleSelectFileInput}
            onSendMessage={this.handleClickSendMessage}
            onRemoveFile={this.handleRemoveFileUpload}
            onCloseSelectedFile={this.handleCloseSelectedFile}
            onRemoveReply={() => this.setState({ messageReplyItem: null })}
          />
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = createStyles({
  container: {
    height: '100vh',
    display: "flex",
    flexDirection: "column",
    "& .displayNone": {
      display: "none"
    },
    '& .cc-list__content': {
      padding: '0 !important',
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "& .pointer": {
      cursor: "pointer"
    }
  },
  chatHeader: {
    padding: '15px 0',
    display: "flex",
    justifyContent: "space-between"
  },
  chatHeaderItem: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  iconWrapper: {
    width: "48px",
    height: "48px",
    boxSizing: "border-box",
    padding: '10px 12px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  headerProfileImg: {
    borderRadius: "50%",
  },
  headerConversation: {
    marginLeft: '12px',
  },
  headerProfileName: {
    fontSize: "24px",
    fontWeight: 800,
    fontFamily: "Manrope",
    textTransform: "capitalize",
    lineHeight: "27px",
    marginBottom: "4px",
  },
  headerGroupName: {
    cursor: "pointer"
  },
  headerSubContent: {
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: 12,
    color: "#757575",
    lineHeight: "14px",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "150px"
  },
  messagesContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    padding: "0 16px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  messageContainerContainFiles: {
    height: "calc(100vh - 263px)",
  },
  messageInformation: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignItems: "center"
  },
  messageInformation__avatar: {
    "& img": {
      border: "1px solid #F0E5FF",
      borderRadius: 100,
      marginBottom: 10
    }
  },
  messageInformation__info: {
    display: "flex",
    gap: 4,
    "& .messageInformation__infoName": {
      fontFamily: "Manrope",
      fontWeight: 600,
      fontSize: 16,
      color: "#FFFFFF"
    },
    "& .messageInformation__infoBadge": {
      display: "flex",
      gap: 2,
      alignItems: "center"
    }
  },
  messageInformation__username: {
    "& p": {
      fontFamily: "Manrope",
      fontWeight: 500,
      fontSize: 14,
      color: "#FFFFFF"
    }
  },
  messageProfileWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    alignItems: "center"
  },
  messageProfileImg: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
  },
  messageProfileName: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Manrope",
    textTransform: "capitalize",
  },
  messageItemContainer: {
    display: 'flex',
    margin: '10px 0'
  },
  messageItemWrapper: {
    maxWidth: '60%',
    padding: '12px',
    borderRadius: '10px',
    boxShadow: '0 0 5px rgba(0,0,0,0.1)',
    display: 'flex',
    gap: '12px',
    alignItems: 'flex-end',
  },
  messageTextContent: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '14px',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  messageTime: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '10px',
  },
  lightThemeText: {
    color: "#222222!important"
  }
})

const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: 'rgba(0,0,0,0.75)'
    },
    info: {
      main: "#222222",
    },
    warning: {
      main: "#78716C",
    },
  }
});

const commonDarkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: "rgba(255,255,255,0.75)"
    },
    info: {
      main: "#FFFFFF",
    },
    warning: {
      main: "#78716C",
    },
  }
});

export const ConversationContent = withStyles(styles)(ViewChat)
// Customizable Area End
