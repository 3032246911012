import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, styled, Typography } from "@material-ui/core";
import React from "react";
import { TAny } from "../../../../components/src/utils";
import ImageRenderer from "../../../../components/src/ImageRenderer.web";
import { checkedIcon, iconExpiryMessage, logoutExpiryAccount, trashIcon } from "../../../subscriptionbilling2/src/assets";
import CancelSubscription from "./CancelSubscription";

export interface IPlatformSubscription {
    id: number,
    type: string,
    attributes: {
        id: number,
        name: string,
        price: string,
        description: string,
        valid_up_to: string,
        features: string[],
        status: string,
        is_subscribed: boolean,
        valid_till: null,
        period: string
    }
}

interface IPropPlatformSubscriptionExpiry {
    isOpen: boolean;
    navigation: TAny;
    listPlatformSubscription: IPlatformSubscription[],
    isShowModalDelete: boolean;
    onLogout: () => Promise<void>;
    onClickDelete: () => void;
    onCloseDelete: () => void;
    onDeleteAccount: () => Promise<void>;
    onPurchaseSubscription: (subscriptionId: number) => Promise<void>;
}

const PlatformSubscriptionExpiry = (props: IPropPlatformSubscriptionExpiry) => {

    function convertToAdverb(timeUnit: string) {
        const conversions = {
            month: 'monthly',
            year: 'yearly',
            day: 'daily',
        } as TAny;
        return conversions[timeUnit.toLowerCase()] || timeUnit;
    }


    const DialogContentDeleteAccount = () => (
        <DialogContent>
            <DialogContentText>
                Are you sure you want to delete your account?
            </DialogContentText>
        </DialogContent>
    )

    const DialogActionDeleteAccount = () => (
        <Button className="dialogBtn cancelSubscriptionBtn" onClick={props.onDeleteAccount}>
            Delete
        </Button>
    )

    return (
        <Dialog
            open={props.isOpen}
            PaperProps={{
                style: {
                    maxWidth: "unset",
                    borderRadius: 20,
                }
            }}
        >
            <PlatformSubscriptionExpiryStyled>
                <DialogTitle>
                    To continue using the platform, please purchase a subscription plan
                </DialogTitle>
                <DialogContent>
                    {props.listPlatformSubscription.map(subscription => (
                        <Box className="subscriptionItem">
                            <Box className="subscription__header">
                                <Box className="header__title">
                                    <Typography variant="h4">
                                        {subscription.attributes.name}
                                    </Typography>
                                    <Typography component="span">
                                        {convertToAdverb(subscription.attributes.period)}
                                    </Typography>
                                </Box>
                                <Box className="header__price">
                                    <Typography variant="h4">
                                        {subscription.attributes.price}/{subscription.attributes.period}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="subscription__content">
                                <Box className="listItem">
                                    {
                                        subscription.attributes.features.map(feature => (
                                            <Box key={feature} className="content-item">
                                                <ImageRenderer src={checkedIcon} width={16} height={16} />
                                                <Typography variant="h6">
                                                    {feature}
                                                </Typography>
                                            </Box>
                                        ))
                                    }
                                </Box>
                                <Box className="content__button">
                                    <Box className="purchasePlanBtn"
                                        data-test-id="purchaseBtn"
                                        onClick={() => props.onPurchaseSubscription(subscription.id)}
                                    >
                                        <Typography>
                                            Purchase Plan
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </DialogContent>
                <Box className="footerDialog">
                    <Box className="footer__information">
                        <ImageRenderer src={iconExpiryMessage} width={28} height={28} />
                        <Typography>
                            Please create a new account to signup as News Organization if required.
                        </Typography>
                    </Box>
                    <Box className="footer__listFunction">
                        <Button
                            className="deleteAccountBtn"
                            startIcon={<ImageRenderer src={trashIcon} width={24} height={24} />}
                            onClick={props.onClickDelete}>
                            <Typography component="span">
                                Delete  account
                            </Typography>
                        </Button>
                        <Button className="logoutBtn" onClick={props.onLogout}>
                            <Typography component="span">
                                Logout
                            </Typography>
                            <ImageRenderer src={logoutExpiryAccount} style={{ color: "#FFF" }} width={24} height={24} />
                        </Button>
                    </Box>
                </Box>
            </PlatformSubscriptionExpiryStyled>
            <CancelSubscription
                title={"Delete Account"}
                isOpen={Boolean(props.isShowModalDelete)}
                dialogContent={DialogContentDeleteAccount()}
                actionDialog={DialogActionDeleteAccount()}
                onClose={props.onCloseDelete}
            />
        </Dialog>
    )
}

const PlatformSubscriptionExpiryStyled = styled(Box)(() => ({
    background: "#0B0B0B",
    border: "1px solid #35383F",
    padding: "24px 24px 36px",
    borderRadius: 10,
    width: 950,
    maxHeight: 575,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: 17,
    "& .MuiDialogTitle-root": {
        padding: "12px 0",
        borderBottom: "1px solid #222222",
        "& h2": {
            fontFamily: "Manrope",
            fontWeight: 500,
            fontSize: 18,
            color: "#FFF",
        }
    },
    "& .MuiDialogContent-root": {
        padding: 0,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: 15,
        "& .subscriptionItem": {
            display: "flex",
            flexDirection: "column",
            width: "calc(100% / 2 - 8px)",
            boxSizing: "border-box",
            padding: '12px',
            gap: 20,

            border: '1px solid #792FED',
            borderRadius: '10px',
            background: 'transparent',
            "& .subscription__header": {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                "& .header__title": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 6,
                    "& h4": {
                        fontFamily: "Manrope",
                        fontWeight: 800,
                        fontSize: 18,
                        color: "#FFF",
                    },
                    "& span": {
                        fontFamily: "Manrope",
                        fontWeight: 500,
                        fontSize: 10,
                        color: "#FFF",
                        textTransform: "capitalize",
                    },
                },
                "& .header__price h4": {
                    fontFamily: "Manrope",
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#FFFFFF",
                }
            },
            "& .subscription__content": {
                display: "flex",
                justifyContent: "space-between",
                "& .listItem": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    "& .content-item": {
                        display: "flex",
                        gap: 8,
                        "& h6": {
                            fonttFamily: "Manrope",
                            fontWeight: 500,
                            fontSize: 12,
                            color: "#FFF",
                        }
                    }
                },
                "& .content__button": {
                    display: "flex",
                    flexDirection: "column-reverse",
                    "& .purchasePlanBtn": {
                        cursor: "pointer",
                        padding: "7px 28px",
                        borderRadius: 10,
                        display: "inline-block",
                        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                        "& p": {
                            backgroundImage: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                            backgroundClip: "text",
                            fontFamily: "Inter",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "#FFF",
                            width: 90,
                        }
                    }
                }
            },
        },
    },
    "& .footerDialog": {
        display: "flex",
        gap: 24,
        "& .footer__information": {
            background: "#222222",
            padding: 12,
            borderRadius: 10,
            flex: 1,
            display: "flex",
            gap: 12,
            alignItems: "center",
            "& p": {
                fontFamily: "Manrope",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFF"
            }
        },
        "& .footer__listFunction": {
            display: "flex",
            gap: 4,
            alignItems: "center",
            "& > button": {
                height: 52,
            },
            "& .deleteAccountBtn": {
                padding: "10px 21px",
                "& .MuiButton-label": {
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    "& span": {
                        margin: 0,
                        fontFamily: "Manrope",
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#EF4444",
                        textTransform: "capitalize",
                    }
                }
            },
            "& .logoutBtn": {
                background: "#222222",
                padding: "10px 21px",
                borderRadius: 100,
                "& .MuiButton-label": {
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    "& span": {
                        fontFamily: "Manrope",
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#FFF",
                        textTransform: "capitalize",
                    }
                }
            },
        }
    }
}))

export default PlatformSubscriptionExpiry;