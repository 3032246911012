import React from "react";

// Customizable Area Start
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Modal,
  Typography,
  Radio,
  Button,
  Popover,
  CircularProgress
} from "@material-ui/core";
import { createTheme,  ThemeProvider } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import Loader from "../../../components/src/Loader.web";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'


import {AvatarImage, VerifiedImage, arrowLogo,checkbox,darkBackground,darkCheckBox,darkMoreIcon,darkView,deleteImage,lightBackground,lightCheckbox,moreIcon, view} from "./assets";

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    },
    info:{
      main:"#222222",
      light:'#fff'
    }
   }
  });

const darkTheme=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",   
      },
      secondary:{
      main:"rgba(255,255,255,0.75)"
      },
      info:{
        main:"#FFFFFF",
        light:'#0B0B0B'
      }
     }
    });
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  isAccountRemoved=()=>{
    const theme =this.props.checked?lightTheme:darkTheme
    return(
      <ThemeProvider theme={theme}>
       <Modal 
        onClose={this.handleRemovePopup}
        data-testid="Modal"
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        open={this.state.removedAccount}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
        <ModalPopup>
        <Box className={this.props.checked?"darkModal_container":"Modal_Container"}>
          <img src={deleteImage}/>
          <Typography className={this.props.checked?'account_text_light':'account_text'}>{configJSON.removeText}</Typography>
        </Box>
        </ModalPopup>
       </Modal>
       </ThemeProvider>)
  }

  showDeleteUserPopup=()=>{
    const theme =this.props.checked?lightTheme:darkTheme
    return(
      <ThemeProvider theme={theme}>
       <Modal 
        onClose={this.handleRemoveNotRemove}
        data-testid="noModal"
        open={this.state.deletedUser!==''?true:false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
        <ModalBox>
        <Box className={this.props.checked?"darkModal_container":"Modal_Container"}>
          <Typography className={this.props.checked?'account_text_light':'account_text'}>{configJSON.isRemove}{this.state.deletedUser}</Typography>
        <hr className={this.props.checked?'light_line':'dark_line'}/>
        <Typography className={this.props.checked?'account_text_light':'account_text'}>{configJSON.confirmRemove}{this.state.deletedUser} ?</Typography>
        <div className='btn_styles'>
        <Button 
        data-testid='no'
        onClick={this.closeRemoveUser} 
        className={this.props.checked ?"cancel_btn":"cancel_btn_light"}
        >{configJSON.no}</Button>
        <Button
         data-testid='remove'
         className='remove_btn'
         onClick={this.removeUserAccount}
         >{configJSON.remove}</Button>
      </div>
        </Box>
        </ModalBox>
       </Modal>
       </ThemeProvider>)

  }

  

  list = () => (
    <div
      className={"tabsContainer"}>
      <List className={"list"}>
        {this.state.tabItems.map((each) =>
        (<ListItem alignItems="center"
          className={"listItem"}
          button
          data-test-id={`listItem${each.id}`}
          key={each.id} onClick={()=>this.handleNavigation(each.path)}>
          <ListItemIcon className={"drawerItemIcon"}>
            <img src={this.props.checked?each.darkIcon:each.icon} className={"iconImage"} />
          </ListItemIcon>
          <ListItemText primary={each.name} className={each.name!==this.props.tabItem?"itemText":"activeItemText"}/>
        </ListItem>))}
        
      </List>
    </div>
  );

  renderAccountModal=()=>{
    const theme =this.props.checked?lightTheme:darkTheme
    return(
      <ThemeProvider theme={theme}>
       <Modal 
        onClose={this.handleClose}
        data-testid="Modal"
        open={this.state.AccountModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
        <BoxContainer>
        <Box className={this.props.checked?"darkModal_container":"Modal_Container"}>
             <Box className="Header">
              <Box className="backImage" >
                <img data-testid="backImage" onClick={this.state.deleteUser? this.closeUser:this.handleClose} src={this.props.checked?darkView:view}/>
              </Box>
              <Box className="HeadContainer">
               <Typography data-testid='manage' className="account-text" style={{color:theme.palette.info.main}}>{this.state.deleteUser ? "Manage List":"Accounts"}</Typography>
               </Box>
             </Box>        
             <Box className={"userList_Container"}>
             {this.state.multiUserLoading? <CircularProgress size="5rem" color="primary" />:
              this.state.userList.map((item,index)=>{
                return(
                  <Box key={item.user_id} className="main-container">
              <Box className="AccountUser"> 
                <Box className="UserDetail">
                <img src={item.profile_photo} className="avatarImage"/>
                </Box>
                <Box className="Account_info">
                <Box className="Account_userVerified">
                <Typography style={{color:theme.palette.info.main}} className="Account_name">{item.fullName}</Typography>
                 <img src={VerifiedImage} height="13.33" width="13.33"/>
                </Box>
                <Box>
                  <Typography className="Account_username">{item.username}</Typography>
                </Box>
                </Box>
                </Box>  

                <Box className="checkBox-container">
                {this.state.deleteUser ? <DeleteOutlineIcon data-testid={`delete${index}`} style={iconStyles} onClick={()=>this.removeUser(item.username)}/>:
                   <Radio 
                    checked ={this.state.selectedAccount===item.user_id}
                    name="radio-button"
                    data-testid="RadioOptions"
                    value = {item.user_id}
                    onChange={()=>this.handleCheckboxChange(item)}
                    icon={<img src={this.props.checked?lightCheckbox:darkCheckBox} alt="Avatar" />}
                    checkedIcon={<img src={checkbox} alt="Avatar" />}
                    />}   
                </Box>
             </Box>
                )
             })
             }
             </Box>
             <Box className="action-btn">
             {this.state.users.length>1 && !this.state.deleteUser && <Box style={btnsStyle}>
                 <Button data-testid='editBtn' variant="outlined" className="buttonStyle" onClick={this.showDeleteUsers}>
                 <Typography style={{background:theme.palette.info.light}} className="innerButtonStyle">{configJSON.editProfile}</Typography>
                  </Button>
                </Box>}
                <Box style={btnsStyle}>
                 <Button variant="outlined" className="buttonStyle">
                 <Typography style={{background:theme.palette.info.light}} onClick={this.handleCreateAccountNavigation} className="innerButtonStyle"> Create a new  account </Typography>
                  </Button>
                </Box>
                <Box style={btnsStyle}>
                <Button variant="outlined" className="buttonStyle">
                 <Typography style={{background:theme.palette.info.light}} onClick={this.handleAddUserNavigation} className="innerButtonStyle"> Add an existing account </Typography>
                  </Button>
                </Box>
             </Box>
        </Box>
        </BoxContainer>
       </Modal>
       </ThemeProvider>  
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.props.checked?lightTheme:darkTheme}>
         <StyleContainer key={this.state.selectedAccount} isLightTheme={this.props.checked}>
          <Loader  loading={this.state.RefreshedTokenLoading} />
        <Paper className={this.props.checked?"paperLightTheme":"paper"}>
        {this.renderAccountModal()}
        <Box className={`container ${this.props.checked&& "borderColor-lightTheme"}`} 
        >
          <img src={arrowLogo} data-test-id='logoImage' className={"logoImage"}/>
          <Box style={{display:'flex',flexDirection:'column',height:'90%',justifyContent:'space-between'}}>
          {this.list()}
          <Box className="userInfo">
           <Box className="userContainer">
             <Box className="userInfoGroup" onClick={this.handleNavigationToProfile}>
              <img data-testid="profile_image"  src={ this.state.selectedUser?.profile_photo??AvatarImage} className="avatarImage1"/>
               <Box className="user_details_info">
                <Box className="userverifiedImage">
                <Typography className="user-name-text">{this.state.selectedUser?.fullName}</Typography>
                 <img src={VerifiedImage} height="13.33" width="13.33"/>
                </Box>
                <Box>
                  <Typography className="userNameStyle">{this.state.selectedUser?.username}</Typography>
                </Box>
               </Box>
             </Box>
             <Box>
             <img 
                data-testid="openModal"
                onClick={this.handleAccountModal} 
                src={this.props.checked?darkMoreIcon:moreIcon}
                className={"iconImage-Account"}
              />
             </Box>
           </Box>
          </Box>
          </Box>
        </Box>
        <Box className="child_css">
          {this.props.children}
        </Box>
        </Paper>
        
        </StyleContainer>
        {this.state.deletedUser && this.showDeleteUserPopup()}
        {this.state.removedAccount && this.isAccountRemoved()}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const btnsStyle={width:'100%'}
const iconStyles={color:'red',fontSize:'24px',margin:'8px 8px'}
const ModalPopup=styled(Box)({
  position: 'absolute' as 'absolute',
  top: '80%',
  left: '50%',
  outline:"none",
  transform: 'translate(-50%, -50%)',

  "& .Modal_Container":{
    padding: "14px 14px",
    background:'#0B0B0B',
    gap:"5px",
    borderRadius:"6px",
    height:"14px",
    width:'250px',
    border:`1px solid #35383F`,
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
    outline:"none"
  },

  "& .darkModal_container":{
    padding: "14px 14px",
    background:'#fff',
    gap:"5px",
    borderRadius:"6px",
    height:"14px",
    width:'180px',
    border:`1px solid #35383F`,
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center'
  },
  "& .account_text":{
    fontFamily:'Manrope',
    fontWeight:500,
    fontSize:'14px',
    color:'white',
    marginTop:'15px',
    marginBottom:'15px',
  },
  '& .account_text_light':{
    fontFamily:'Manrope',
    fontWeight:500,
    fontSize:'14px',
    color:'black',
    marginBottom:'15px',
    marginTop:'15px'
  },
})

const ModalBox=styled(Box)({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  "& .Modal_Container":{
    padding: "20px 24px",
    background:'#0B0B0B',
    gap:"24px",
    borderRadius:"10px",
    height:"180px",
    width:'290px',
    border:`1px solid #35383F`
  },

  "& .darkModal_container":{
    padding: "20px 24px",
    background:'#fff',
    gap:"24px",
    borderRadius:"10px",
    height:"180px",
    width:'290px',
    border:`1px solid #35383F`
  },

  "& .light_line":{
    width: '95%',
    border: "none",
  height: "1px",
  backgroundColor: "#BABABA"
  },
  "& .dark_line":{
    width: '100%',
    border: "none",
    height: "1px",
    backgroundColor: "#222222"
  },
  "& .account_text":{
    fontFamily:'Manrope',
    fontWeight:600,
    fontSize:'16px',
    textAlign:'center',
    color:'white',
    marginTop:'10px',
    marginBottom:'15px'
  },
  '& .account_text_light':{
    fontFamily:'Manrope',
    fontWeight:600,
    fontSize:'16px',
    textAlign:'center',
    color:'black',
    marginBottom:'15px',
    marginTop:'10px'
  },
  "& .btn_styles":{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:'row' as 'row',
    width:'100%',
    marginTop:"20px",
    gap:'10px'
  },
  '& .cancel_btn':{
    width:'140px',
    fontFamily: "Manrope",
    fontSize: "14px",
    height:'44px',
    padding:"16px", 
    boxShadow:"none !important",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    background: `linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    color: "#8833FF", 
    "&.MuiButton-root.Mui-disabled": {
      opacity:0.64
    }, 
  },
  '& .cancel_btn_light':{
    background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    color: "#8833FF",
    height:'44px',
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    padding:"16px", 
    boxShadow:"none !important",
    "&.MuiButton-root.Mui-disabled": {
      opacity:0.64
    },
    width:'140px'
  },
  '& .remove_btn':{
    background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        width:"140px",
        height:'44px',
        borderRadius:'10px',
        border:'none',
        color:"#fff",
        fontWeight:700,
        textTransform:'capitalize' as 'capitalize',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontFamily: "Manrope",
        fontSize: "14px",
        cursor:'pointer',
        textDecoration:'none'
  }


})

const CustomPopover=styled(Popover)({
  "& .MuiPaper-elevation8-53":{
     boxShadow:'none'
  },
})

const MenuBoxContainer=styled(Box)({
 width: "256px",
 height:"40px",
 display:'flex',
 margin:"8px",
 alignItems:'center',
 cursor:'pointer',
 gap:"10px",

 "& .item_list_name":{
  fontSize:"14px",
  fontWeight:500,
  fontFamily:'Manrope'
 }
 
})

const BoxContainer=styled(Box)(({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  outline:'none',
  transform: 'translate(-50%, -50%)',
  

  "& .Modal_Container":{
    padding: "20px 24px",
    background:'#0B0B0B',
    gap:"24px",
    borderRadius:"10px",
    height:"425px",
    width:'632px',
    outline:'none',
    border:`1px solid #35383F`,
  },

  "& .darkModal_container":{
    padding: "20px 24px",
    background:'#fff',
    gap:"24px",
    borderRadius:"10px",
    height:"425px",
    width:'632px',
    border:`1px solid #35383F`
  },
  "& .account-text":{
    fontFamily:'Manrope',
    fontWeight:800,
    fontSize:18,
    textAlign:'center'
  },

  "& .HeadContainer":{
   width:"96%"
  },

  "& .Header":{
    height:48,
    width:"100%",
    display:'flex',
    flexDirection:'row'
  },
  "& .main-container":{
    padding:'10px 60px',
    justifyContent:'space-between',
    alignItems:'center',
    display:'flex',
    flexDirection:'row'
   },
   "& .AccountUser":{
      height:'32px',
      display:'flex',
      flexDirection:'row',
      gap:'5px',
      alignItems:"center",
      justifyConten:'center'
   },
   
   "& .avatarImage":{
    height:"35px",
    width:"35px",
    borderRadius:"100px",
    objectFit:'cover'
    },

   "& .Account_name":{
    fontFamily:'Manrope',
    fontWeight:800,
    fontSize:18,
   },

   "& .Account_userVerified":{
    display:'flex',
    gap:'2px'
   },

   "& .Account_info":{
    display:"flex",
    flexDirection:'column',
    gap:'4px'
   },

  "& .action-btn":{
    display:"flex",
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'flex-start',
    gap:'15px',
    padding:"28px"
  },

  "& .backImage":{
    marginLeft:20
  },

   "& .Account_username":{
    color:'#E0E0E0',
    fontFamily:'Manrope',
    fontSize:'10px',
    fontWeigth:800,
   },
   "& .buttonStyle":{
    position: 'relative' as 'relative',
    background: 'linear-gradient(to right, #3858E3, #BA05F7)',
    cursor: 'pointer' as 'pointer',
    zIndex: 1,
    overflow: 'hidden',
    WebkitBackgroundClip: 'padding-box',
    backgroundClip: 'padding-box',
    height:"48px",
    width:"100%",
    padding:"0px",
    borderRadius:'10px',
    gap:"8px",
   },

   "& .buttonStyle > span":{
    width:"100%",
    textTransform:'none'
   },

   "& .innerButtonStyle":{
    borderRadius: '10px',
    background: '#fff',
    padding: '13px 16px',
    width:"94%",
    fontFamily:"Manrope",
    fontSize:'12px',
    fontWeight:800,
    position: 'relative' as 'relative',
    zIndex: 2,
   },
   
   "& .userList_Container":{
     height:"180px",
     overflow:'auto'
   }
   

}))

export const StyleContainer = styled(Box)(({isLightTheme}: {isLightTheme?: boolean}) => ({

  "& .iconImage":{
  height:'24px',
  width:'24px'
  },

  "& .iconImage-Account":{
    height:'24px',
    width:'24px',
    cursor:'pointer',
  },

  "& .child_css":{
    width:"calc(100% - 289px)",
    "@media(max-width:960px)":{
      width:"calc(100% - 150px)",
    }
  },

  "& .list":{
    marginTop:'20px',
  },
  "& .listItem":{
    
    "&.MuiListItem-button": {
      minHeight:'50px',
      padding: "8px 16px!important",
    },
    "&.MuiListItem-button:hover": {
      backgroundColor: "transparent"
    },
    "@media(max-width:960px)":{
      marginLeft:-10
    }
  },
  "& .userProfileWrapper": {
    flexDirection: "column" as "column",
    justifyContent: "center",
    display: "flex",
    paddingTop: 30,
    alignItems: "center",
  },
  "& .userProfile": {
    width: 100,
  },
  "& .userDesignation": {
    fontSize: 14,
    color: "#ccc",
  },
  "& .logout": {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  "& .drawerItemIcon": {
    "&.MuiListItemIcon-root":{
      minWidth:'36px'
    }
  },
  "& .moreIcon":{
    "&.MuiListItemIcon-root":{
      minWidth:'40px !important'
    }

  },
  "& .tabsContainer":{
    width:'250px',
    "@media(max-width:960px)":{
      width:"100%"
    }
  },
  "& .paper":{
    display:"flex",
    backgroundColor:'#070707',
    backgroundImage:`url(${darkBackground})`,
    backgroundRepeat:"no-repeat",
    backgroundSize:'100% 100%',
    minHeight:'100vh',
    width:"100vw"
  },
  "& .paperLightTheme":{
    display:"flex",
    backgroundColor:'#fff',
    backgroundRepeat:"no-repeat",
    backgroundImage:`url(${lightBackground})`,
    backgroundSize:'100% 100%',
    minHeight:'100vh',
    width:"100vw",
  },
  "& .container":{
    position:'relative',
    borderRight:'2px solid #222222',
    height:'100vh',
    display:'flex',
    flexDirection:'column' as 'column',
    alignItems:'center',
    paddingLeft:'50px',
    "@media(max-width:960px)":{
      width:'100px',
    }
  },
  "& .borderColor-lightTheme": {
    borderColor: "#BABABA"
  },
  "& .user_details_info":{
    "@media(max-width:960px)":{
      display:"none"
    }
  },
  "& .userInfo":{
  //   marginTop:"60px",
  //  marginBottom:32,
  //  position:"fixed",
  //  marginTop:"250px",
  //  marginBottom:1,
  //  bottom:10,
  height: 43,
  gap: 38,
  width: 221,
   "@media(max-width:960px)":{
     width:"100px"
   }
  },
  "& .userverifiedImage":{
     display:"flex",
     gap:5,
     alignItems:'center',
  },
  "& .userInfoGroup":{
   display:"flex",
   gap:10,
  },
  "& .avatarImage":{
     height:"35px",
     width:"35px",
     borderRadius:"100px",
     objectFit:'cover'
  },
  "& .avatarImage1":{
    height:"35px",
    width:"35px",
    cursor:'pointer',
    borderRadius:"100px",
    objectFit:'cover'
 },
  "& .userContainer":{
    display:"flex",
    width:'100%',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
  },

  "& .userNameStyle":{
    color: "#81999E",
    fontFamily:"Manrope",
    fontSize:"11px",
    fontWeight:400,
    marginTop:2
  },
  "& .user-name-text":{
   fontSize:14,
   fontWeight:500,
   fontFamily:"Manrope",
  },

  "& .logoImage":{
    alignSelf:'flex-start',
    marginLeft:"2px",
    marginTop:'10px'
  },
  "& .itemText":{

  "& .MuiTypography-root":{
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    color: isLightTheme ? "#4E4E4E" : "#FFFFFF"
   },
   "@media(max-width:960px)":{
    display:"none"
   }
  },
  "& .activeItemText":{
    "& .MuiTypography-root":{
      fontFamily: 'Manrope',
      fontSize: '24px',
      fontWeight: 800,
      lineHeight: '27.12px',
      letterSpacing: '-0.02em',
      color: isLightTheme ? "#222222" : "#FFFFFF"
     },
     "@media(max-width:960px)":{
        display:"none"
     }
   },

}));
// Customizable Area End
