import React from "react";
//Customizable Area Start
import ActivityFeedController, {
  Props,
} from "./ActivityFeedController";
import {
  Box, Button, Dialog, Grid, IconButton, InputAdornment, Tab, Tabs, TextField, Typography, withStyles, Modal,
  Backdrop,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, createStyles
} from "@material-ui/core";
import { ownerShipIcon, profileLogo, searchDark, searchLight, verifyIcon, verifyOrange, verifyYellow } from "./assests";
import { crossWhiteIcon, crossBlackIcon, radioCheckedDark, radioUnChecked, radioCheckedLight, arrowRight24White, logoXlIcon } from "../../settings2/src/assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PostCreation from "../../../blocks/postcreation/src/PostCreation.web";
import BroadcastMessage from "../../../blocks/broadcastmessage/src/BroadcastMessage.web"
import PostHeader from "./PostHeader.web";
import PostBody from './PostBody.web'
import PostFooter from './PostFooter.web'
import PinedPostModal from "./PinedPostModal.web";
import DeletePostModal from "./DeletePostModal.web";
import Comments from '../../comments/src/Comments.web'
import { highlightText } from "../../../components/src/utils";
import Loader from "../../../components/src/Loader.web";
import SelectRepostModal from '../../repost/src/SelectRepostModal.web'
import CreateRepost from '../../repost/src/CreateRepost.web'
import CommentPreference from "./CommentPreference.web";
import RightSideBar from "./RightSideBar.web";
import PostActivity from "../../customisableuserprofiles/src/PostActivityModal.web";
import NoInternetComponent from "../../../components/src/NoInternetComponent";

const inputStyles = {
  fade: {
    border: '1px solid #5B5B5B',
    borderRadius: '10px',
    display: 'flex',
  },
  lightBackdrop: {
    backgroundColor: '#FFFFFF',
    opacity: 0.9
  },
  darkBackdrop: {
    backgroundColor: '#0E0E0E',
    opacity: 0.8
  }
}
import FeedbackModal from "../../../components/src/FeedBackModal.web";
//Customizable Area End
import { ActivityInterface } from "./dataInterface";

export default class ActivityFeed extends ActivityFeedController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  //Customizable Area Start
  conditionRemover = (condition: boolean, trueStatement: any, falseStatement: any) => {
    return condition ? trueStatement : falseStatement
  }

  modelRender = (classes: any) => {
    return <Dialog data-test-id="memberList" onClose={() =>
      this.handleModelClose()
    }
      open={this.state.isModel}
      PaperProps={{
        className: this.conditionRemover(!this.props.checked, classes.modelDarkStyle, classes.modelLightStyle)
      }}
      fullWidth
      classes={{ paperWidthSm: this.conditionRemover(!this.props.checked, classes.modelDarkStyle, classes.modelLightStyle) }}
    >
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{
        padding: "0 24px"
      }}>

        <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", margin: '15px 0' }}>
          <IconButton style={{
            color: this.conditionRemover(this.props.checked, "black", "#fff"),
            height: "48px",
            width: "48px",
            padding: "10px 12px",

          }} disableRipple disableFocusRipple disableTouchRipple data-test-id="backBtn2"
            onClick={() => this.handleModelClose()}
          >
            <ChevronLeftIcon style={{ height: "44px", width: "28px" }} />
          </IconButton>
          <Typography className={classes.name}>Manage List</Typography>
          <Button onClick={this.handleModelClose} className={classes.doneButton}>Done</Button>
        </Box>

        <Box style={{ width: "100%", marginBottom: '15px' }}>
          <TextField
            variant="outlined"
            placeholder="Search"
            onChange={this.handleSuggestionFilter}
            className={classes.searchField}
            style={{ width: "100%" }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={this.conditionRemover(this.props.checked, searchDark, searchLight)} alt="" />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box style={{ display: "flex", rowGap: "20px", flexDirection: "column", width: "100%" }}>

          {this.state.modelType == "edit" && <Box className={classes.tabsContainer}>
            <Tabs
              value={this.state.activeSiggestion}
              onChange={this.handleSuggestionTabsChange}
              classes={{ indicator: classes.indicator }}
              textColor="primary"
              variant="fullWidth"
              data-test-id="tabBtn1"
            >
              <Tab label="Members" className={classes.tabstyle} />
              <Tab label="Suggestion" className={classes.tabstyle} />
            </Tabs>
          </Box>}

          {(this.state.activeSiggestion == 0 && this.state.modelType == "edit") ?
            <div className={classes.suggestionDisplay} style={{ marginTop: "5px" }} ref={this.scrollSuggestionParentRef}>
              <InfiniteScroll
                pageStart={this.state.page}
                loadMore={() => this.gettailoredUserList()}
                data-test-id="memberstScroller"
                hasMore={this.state.page <= this.state.tailoredPagination?.total_pages}
                loader={<div className={classes.loader} key={0}> {this.state.page <= this.state.tailoredPagination?.total_pages && <CircularProgress disableShrink />}</div>}
                useWindow={false}
                getScrollParent={() => this.scrollSuggestionParentRef.current}
              >
                <Box style={{ display: "flex", flexDirection: "column", rowGap: "15px", minHeight: "300px" }}>
                  {this.state.tailoredMember.length > 0 ? this.state.tailoredMember.map((val: any) => {
                    return <Box key={val.id} style={{ justifyContent: "space-between", display: "flex" }}>
                      <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                        <img
                          src={this.conditionRemover(val.attributes.profile_photo, val.attributes.profile_photo, profileLogo)}
                          className={classes.postImage}
                          alt="img"
                          style={{
                            border: this.conditionRemover(this.props.checked, "2px dashed var(--Primary-Purple-100, black)", "2px dashed var(--Primary-Purple-100, white)"),
                            background: this.conditionRemover(this.props.checked, "var(--Basic-White,#FFF))", "var(--Basic-White,black))")
                          }}
                        />
                        <Box className={classes.headconatin}>
                          <Box className={classes.ContentHead}>
                            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <Typography className={classes.name}>{val.attributes.full_name}</Typography>
                              {this.renderVerifyIcon(val.attributes)} {this.renderOwnershipIcon(val.attributes)}
                            </Box>
                            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <Typography className={classes.userName}>{this.conditionRemover(val.attributes?.user_name, val.attributes?.user_name, " ")} </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Button className={classes.removeBtn} data-test-id="removeBtn"
                        onClick={() => this.handleRemoveUser(val.id)}>remove</Button>
                    </Box>
                  }) : <div className={classes.memberError}>
                    <Typography className={classes.emaptyMessages}>The Member list is empty.</Typography>
                    <Typography className={classes.emaptySubhead} style={{ width: "319px" }}>When people get added, they'll show up here.</Typography>
                  </div>
                  }
                </Box>
              </InfiniteScroll>
            </div> :
            <div className={classes.suggestionList}>
              <Typography className={classes.name}>Suggested</Typography>

              <div className={classes.suggestionDisplay} ref={this.scrollSuggestionParentRef}>
                <InfiniteScroll
                  pageStart={this.state.page}
                  loadMore={() => this.getSuggestions()}
                  data-test-id="suggestionsScroller"
                  hasMore={this.state.page <= this.state.suggestionsPagination?.total_pages}
                  loader={<div className={classes.loader} key={0}> {this.state.page <= this.state.suggestionsPagination?.total_pages && <CircularProgress disableShrink />}</div>}
                  useWindow={false}
                  getScrollParent={() => this.scrollSuggestionParentRef.current}
                >
                  <Box style={{ display: "flex", flexDirection: "column", rowGap: "15px", minHeight: "180px" }}>
                    {this.state.suggestions.length ? this.state.suggestions.map((val: any) => {
                      return <Box key={val.id} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                          <img
                            src={this.conditionRemover(val.attributes.profile_photo, val.attributes.profile_photo, profileLogo)}
                            alt="img"
                            className={classes.postImage}
                            style={{
                              border: this.conditionRemover(this.props.checked, "2px dashed var(--Primary-Purple-100, black)", "2px dashed var(--Primary-Purple-100, white)"),
                              background: this.conditionRemover(this.props.checked, "var(--Basic-White,#FFF))", "var(--Basic-White,black))")
                            }}
                          />
                          <Box className={classes.headconatin}>
                            <Box className={classes.ContentHead}>
                              <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <Typography className={classes.name}>{val.attributes.full_name}</Typography>
                                {this.renderVerifyIcon(val.attributes)} {this.renderOwnershipIcon(val.attributes)}
                              </Box>
                              <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <Typography className={classes.userName}>{this.conditionRemover(val.attributes?.user_name, val.attributes?.user_name, " ")} </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Button className={this.conditionRemover(this.props.checked, classes.addBtn, classes.addBtnDark)}
                          data-test-id="addBtn"
                          onClick={() => this.handleAdduser(val.id)}>add</Button>
                      </Box>
                    }) : <div className={classes.memberError}>
                      <Typography className={classes.emaptyMessages}>The list is empty.</Typography>
                    </div>}
                  </Box>
                </InfiniteScroll>
              </div>
            </div>
          }
        </Box>


        <Box style={{ display: "flex" }}>
        </Box>
      </Grid>
    </Dialog>
  }

  muteModelRender = (classes: any) => {
    return <Dialog data-test-id="muteModel" onClose={() =>
      this.handleMuteModelClose()
    }
      open={this.state.muteModel}
      PaperProps={{
        className: this.conditionRemover(!this.props.checked, classes.modelDarkStyle, classes.modelLightStyle)
      }}

      classes={{ paperWidthSm: this.conditionRemover(!this.props.checked, classes.modelDarkStyle, classes.modelLightStyle) }}
    >
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{
        padding: "27px 24px", rowGap: "24px", width: "375px", height: "220px"
      }}>

        <Box>
          <Typography className={classes.name}>Mute {this.state.activeUser?.user_name}</Typography>
        </Box>

        <Box>
          <Typography className={classes.emaptySubhead}>Are you sure you want to mute {this.state.activeUser?.user_name}?</Typography>
        </Box>

        <Box style={{ display: "flex", columnGap: "12px" }}>
          <Button onClick={() => this.handleMuteModel("no")} className={this.conditionRemover(this.props.checked, classes.muteBtnDeny, classes.muteBtnDenyDark)}>
            <Typography className={classes.showmore} >No</Typography>
          </Button>
          <Button onClick={() => this.handleMuteModel("yes")} data-test-id="muteagree" className={classes.muteBtn}>Mute Now</Button>
        </Box>
      </Grid>
    </Dialog>
  }

  bottomPoperRender = (classes: any) => {
    return <Dialog
      open={this.state.bottompoper}
      PaperProps={{
        className: this.conditionRemover(!this.props.checked, classes.bottomModelDarkStyle, classes.bottomModelStyle)
      }}

      classes={{ paperWidthSm: this.conditionRemover(!this.props.checked, classes.bottomModelDarkStyle, classes.bottomModelStyle) }}
    >
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{
        padding: "10px 20px", gap: "15px", minWidth: "270px", height: "52px"
      }}>
        <Box display={"flex"} style={{ gap: "15px", alignItems: "center" }}>
          <Typography className={classes.subcriptionBody}>
            {this.state.activeUser?.user_name} has been {this.state.isBlockingUser ? 'blocked' : 'muted'}
          </Typography>
          {this.state.isBlockingUser === false && (
            <Button onClick={() => this.handleUnmuteUser(this.state.activeUser?.id)} data-test-id="undoBtn" className={classes.undoBtn} >Undo</Button>
          )}
        </Box>

      </Grid>
    </Dialog>
  }

  showEmptyMessage = (classes: any) => {
    return <div className={classes.emptyMesage}>
      <Box style={{ display: "flex", flexDirection: "column", width: "299px", justifyContent: "center", alignItems: "center" }}>
        <Typography className={classes.emaptyHead}> {this.conditionRemover(this.state.activeIndex != 2, "Waiting for posts", "Your Custom List")}</Typography>
        <Typography className={classes.emaptySubhead}>{this.conditionRemover(this.state.activeIndex != 2, "Post from people in this list will show up here", "You havent created any Custom Lists. When you do, theyll show up here.")}</Typography>
        {this.conditionRemover((this.state.activeIndex == 2),
          <Button className={classes.addUserBtn} onClick={() => this.openModel()} endIcon={<ChevronRightIcon />}>Add Users</Button>, "")}
      </Box>
    </div>
  }

  renderVerifyIcon = (val: any) => {
    if (val.user_subscription) {
      switch (val.user_subscription) {
        case 'I am a business':
          return <img src={verifyYellow} alt="" />;
        case 'I am an individual':
          return <img src={verifyIcon} alt="" />;
        case 'I am a news organization':
          return <img src={verifyOrange} alt="" />;
      }
    }
  }

  renderOwnershipIcon = (val: any) => {
    if (val.user_ownership) {
      return <img src={ownerShipIcon} alt="" />;
    }
  }

  renderMainCatList = () => {
    const { checked } = this.props;
    const checkedradioIcon = !checked ? radioCheckedDark : radioCheckedLight;

    return (
      <List className={"listReportPost"}>
        {this.state.reportPostMainList.map((each, index: number) =>
        (<ListItem alignItems="center" className={"listItemReportPost"} button data-test-id={`main-cat-${index}`} key={`setingsCat${index}`} onClick={() => this.setReportPostMainCat(each.attributes.id)} >
          <ListItemText className="label-container" primary={each.attributes.title} secondary={each.attributes.description} />
          <ListItemIcon className="radio-icon">
            <img src={this.state.reportCatId === each.attributes.id ? checkedradioIcon : radioUnChecked} className={"iconImage"} />
          </ListItemIcon>
        </ListItem>))}
      </List>
    )
  }

  renderSubCatList = () => {
    const { checked } = this.props;
    const checkedradioIcon = !checked ? radioCheckedDark : radioCheckedLight;

    return (
      <List className={"listReportPost"}>
        {this.state.reportPostSubList.map((each, index: number) => (<ListItem alignItems="center"
          className={"listItemReportPost"}
          button
          data-test-id={`main-cat-${index}`}
          key={`setingsCat${index}`}
          onClick={() => this.setReportPostSubCat(each.attributes.id)}
        >

          <ListItemText className="label-container" primary={each.attributes.title} secondary={each.attributes.description} />
          <ListItemIcon className="radio-icon">
            <img src={this.state.reportSubCatId === each.attributes.id ? checkedradioIcon : radioUnChecked} className={"iconImage"} />
          </ListItemIcon>
        </ListItem>))}
      </List>
    )
  }

  renderReportPostSubmit = () => {
    return (
      <div className="renderReportPostSubmitContainer">
        <img className="cursorPointer logo" src={logoXlIcon} alt="cross" />
        <h5 className="font24 txtGradiant submittedTitle">Submitted</h5>
        <p className="description">Thanks for helping make Arrows better for everyone.We know it wasnt easy, so we appreciate you taking the time to answer those questions.</p>
        <Button onClick={this.closeReportPostModal} className="submitButton" style={webStyle.submitBtn}>
          <p className="label txtCapitalize">Done</p>
          <span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
        </Button>
      </div>
    )
  }

  renderReportModal = () => {
    const { checked } = this.props;
    let btnOpacity = "unset";
    if (this.state.reportCurrentView === "main" && this.state.reportCatId === null) {
      btnOpacity = "50%";
    }
    if (this.state.reportCurrentView !== "main" && this.state.reportSubCatId === null) {
      btnOpacity = "50%";
    }
    return (
      <Modal
        className={`reportPostModal dark-${!this.props.checked}`}
        open={this.state.showReportPostModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          style: this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop
        }}
      >
        <Fade
          in={this.state.showReportPostModal}
          style={inputStyles.fade}
        >
          {this.state.reportCurrentView === "submit" ? this.renderReportPostSubmit() :
            <div 
              data-test-id="reportPostBackdrop" 
              className="reportPostModalContainer"
              style={{background: checked ? "#FFF" : '#000'}}
            >
              <div className="modalHead" style={webStyle.modalHead}>
                <span onClick={() => this.closeReportPostModal()} data-test-id="closereportPostModal">
                  <img className="cursorPointer" src={checked ? crossBlackIcon : crossWhiteIcon} alt="cross" />
                </span>
                <span data-test-id="reportPostTxt" style={webStyle.ModalHeadtitle}>Gathering info</span>
                <Button onClick={this.changeReportView} style={{ ...webStyle.nextBtn, opacity: btnOpacity }} className="submitButton"> Next</Button>
              </div>
              <h2>What type of issue are you reporting?</h2>
              <Loader loading={this.state.reportPostLoading} />
              {this.state.reportCurrentView === "main" ? this.renderMainCatList() : this.renderSubCatList()}
            </div>}
        </Fade>
      </Modal>
    )
  }
  //Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, checked } = this.props
    const { activeIndex, feeds, fetchedPages, pagination, tailoredMember, showComments, userInfo, selectedPost, showQuote } = this.state
    
    return (
      <NavigationMenu id="" tabItem="Timeline" checked={this.props.checked} navigation={this.props.navigation}>
        <Box className={classes.wrapper}>
          {this.state.showReportPostModal && this.renderReportModal()}
          <div style={{ display: "flex", columnGap: "24px", width: "100%" }} >
            {selectedPost && showQuote ? (
              <Box
                className={`${classes.feed} ${this.conditionRemover(this.props.checked, classes.borderColorLightTheme, "")}`}
                style={{ overflow: 'scroll', backgroundColor: this.conditionRemover(this.props.checked, "#BABABA", "#222222") }}
              >
                <CreateRepost 
                  postData={selectedPost} 
                  onCloseQuote={this.handleCloseQuote} 
                  onGoBackAfterSubmit={this.handleGoBackAfterSubmit}
                />
              </Box>
            ) : (
              <Box className={`${classes.feed} ${this.conditionRemover(this.props.checked, classes.borderColorLightTheme, "")}`}>
                <Box>
                  <Box style={{ padding: "10px" }}>
                    <BroadcastMessage checked={this.props.checked} navigation={this.props.navigation} />
                  </Box>

                  <Box className={classes.tabsContainer}>
                    <Tabs
                      value={activeIndex}
                      onChange={this.handleTabsChange}
                      classes={{ indicator: classes.indicator }}
                      textColor="primary"
                      variant="fullWidth"
                      data-test-id="tabBtn"
                    >
                      <Tab label="All" className={classes.tabstyle} />
                      <Tab label="Following" className={classes.tabstyle} />
                      <Tab label="Custom" className={classes.tabstyle} />
                    </Tabs>
                  </Box>
                </Box>

                <div className={classes.feedBody} ref={this.scrollParentRef}>
                  {<InfiniteScroll
                    pageStart={fetchedPages}
                    loadMore={() => this.getFeeds()}
                    hasMore={fetchedPages <= pagination?.total_pages && this.state.isBrowserOnline}
                    loader={<div className={classes.loader} key={0}> {fetchedPages <= pagination?.total_pages && <CircularProgress disableShrink />}</div>}
                    useWindow={false}
                    getScrollParent={() => this.scrollParentRef.current}
                    data-test-id="postScroller"
                  >
                    {activeIndex !== 2 && (
                      <Box width={"100%"} className={classes.postCreation}>
                        <PostCreation 
                          navigation={this.props.navigation} 
                          checked={this.props.checked} 
                          onGetNewFeed={this.handleGetNewFeed} 
                        />
                      </Box>
                    )}
                    <Box className={classes.feedsDisplay}>
                      {this.state.activeIndex == 2 &&
                        <div>
                          {this.conditionRemover((tailoredMember.length > 0),
                            <div className={classes.tailoredPost} style={{ background: this.conditionRemover(checked, "#e0e0e0", "#181818") }}>
                              <Box style={{ display: "flex", gap: "10px", height: "44px", alignItems: "center" }}>
                                <img
                                  src={this.conditionRemover(Boolean(userInfo?.attributes.profile_photo), userInfo?.attributes.profile_photo, profileLogo)}
                                  alt="img"
                                  className={classes.postImage}
                                />

                                <Box className={classes.headconatin}>
                                  <Box className={classes.ContentHead}>
                                    <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                      <Typography className={classes.name}>{userInfo?.attributes.full_name}</Typography>
                                      {this.renderVerifyIcon(userInfo)} {this.renderOwnershipIcon(userInfo)}
                                      <Typography className={classes.userName}>{userInfo?.attributes.user_name}</Typography>
                                    </Box>

                                    <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                      <span className={classes.statustime} style={{ color: "#81999E" }}>{this.state.tailoredPagination?.total_records} members</span>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>

                              <Box>
                                <Button className={classes.editBtn} data-test-id="editList" onClick={() => this.handleMemberModel()}>edit List </Button>
                              </Box>
                            </div>
                            , this.showEmptyMessage(classes))}
                        </div>}

                      {this.conditionRemover((feeds.length > 0), feeds.map((val: any) => {
                        const highlights = [...val.attributes.body_mentions, ...val.attributes.hash_tags];
                        const highlightedBody = val.attributes.body && highlightText(val.attributes.body, highlights);
                        const postId = val.id
                        return <Box className={classes.postDisplay} key={val.id}>
                          <Box style={{ paddingLeft: '24px' }}>
                            <PostHeader
                              checked={checked}
                              postData={val}
                              onFollow={this.handleFollow}
                              onClickMoreOption={this.handleClick}
                              onDeletePost={this.handleDeletePost}
                              onPinPost={this.handlePinPost}
                              onCommentSetting={this.handleCommentSetting}
                              onMuteConformation={this.muteConformation}
                              onShowReportPost={this.showReportPost}
                              onBlockAccount={this.handleBlockAccount}
                              navigation={this.props.navigation}
                              data-testid="postHeader"
                              onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
                            />
                          </Box>
                          <Box style={{marginLeft: "67px"}}>
                            <PostBody
                              postData={val}
                              checked={checked}
                              onFollowTaggedPeople={this.handleFollowTaggedPeople}
                            />
                          </Box>
                          <Box style={{marginLeft: "67px"}}>
                          <PostFooter
                            checked={checked}
                            postData={val}
                            onLikePost={this.handleLikePost}
                            onSavePost={this.handleSavePost}
                            onClickComments={this.handleClickComments}
                            showPostActivity={this.handlePostActivityModel}
                            onClickRepost={this.handleShowRepostModal}
                          />
                          </Box>
                          {this.state.activePostId == postId && (
                            <PostActivity
                              checked={this.props.checked}
                              onBlockAccount={this.handleBlockAccount}
                              onClickMoreOption={this.handleClick}
                              onClosePostActivityModel={this.handleClosePostActivityModel}
                              onFollow={this.handleFollow}
                              highlightedBody={highlightedBody as string}
                              onMuteConformation={this.muteConformation}
                              navigation={this.props.navigation}
                              postActivity={this.state.postActivity}
                              postActivityModel={this.state.postActivityModel}
                              onShowReportPost={this.showReportPost}
                              post={val}
                              onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
                            />
                          )}
                        </Box>
                      }), (this.state.activeIndex !== 2 && this.showEmptyMessage(classes)))}
                    </Box>
                    <Box style={{padding: '24px 0', display: this.state.isBrowserOnline ? 'none' : 'block' }}>
                      <NoInternetComponent checked={checked} />
                    </Box>

                  </InfiniteScroll>}
                </div>
              </Box>
            )}

            <RightSideBar checked={this.props.checked} navigation={this.props.navigation} />
            {this.modelRender(classes)}
            {this.muteModelRender(classes)}
            {this.bottomPoperRender(classes)}
            {selectedPost && showComments && (
              <Comments
                checked={checked}
                open={showComments} 
                onClose={this.handleCloseCommentsModal}
                postData={selectedPost}
                userInfo={{
                  id: userInfo?.attributes.id!
                }}
                onFollow={this.handleFollow}
                onClickMoreOption={this.handleClick}
                onMuteConformation={this.muteConformation}
                onShowReportPost={this.showReportPost}
                onBlockAccount={this.handleBlockAccount}
                onLikePost={this.handleLikePost}
                onSavePost={this.handleSavePost}
                onUpdateNumberOfComments={this.handleUpdateNumberCommentOfPost}
                onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
              />
            )}
            {this.state.selectedPost && (
              <SelectRepostModal
                open={this.state.showRepostModal}
                onClose={this.handleCloseRepostModal}
                onRepost={this.handleRepost}
                onQuote={this.handleQuote}
                onUndoRepost={this.handleUndoRepost}
                isUndoRepost={this.state.selectedPost.attributes.is_reposted}
              />
            )}
          </div>
          <FeedbackModal
          data-testid='feedModal'
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          onSubmit={this.handleReviewSubmit}
          checked={this.props.checked} 
          />
                   <PinedPostModal 
            checked={this.props.checked}
            id=""
            isPinned={this.state.isPinnedPost}
            data-testid="pinPostModal"
            open={this.state.openPinPost}
            onClose={this.handleOnClosePinPostModal}
            onPinnedConfirmation={this.handlePinnedConfirmation}
          />

          <DeletePostModal 
            open={this.state.openDeletePost}
            id=""
            checked={this.props.checked}
            data-testid="deletePostModal"
            onClose={this.handleOnCloseDeletePostModal}
            onDeleteConfirmation={this.DeletePostApi}
          />

          <CommentPreference 
           checked={this.props.checked}
           id={0}
           navigation={this.props.navigation}
           data-testid="commentSettingModal"
           postId={this.state.reportingPostId}
           onClose={this.handleOnCloseCommentSettings}
           open={this.state.openCommentSetting}
          />

        </Box>
      </NavigationMenu>

    );
  }
  //Customizable Area End
}

// Customizable Area Start
const style = createStyles({
  wrapper: {
    width: "100%",
    display: "flex",
  },
  addBtn: {
    fontFamily: "Manrope",
    fontSize: "12px",
    height: '32px',
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    boxShadow: "none !important",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #3858E3 , #BA05F7)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    color: "black",
  },
  undoBtn: {
    boxShadow: "none !important",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "6px 10px",
    width: "51px",
    height: "32px",
    color: "white",
    fontSize: "14px",
    fontWeight: 500
  },
  muteBtn: {
    boxShadow: "none !important",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px",
    color: "white",
    fontSize: "14px",
    fontWeight: 700
  },
  muteBtnDeny: {
    boxShadow: "none !important",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #3858E3 , #BA05F7)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px"
  },
  muteBtnDenyDark: {
    boxShadow: "none !important",
    background: "linear-gradient(to right, #0d0c0c, #0d0c0c),linear-gradient(to right, #3858E3 , #BA05F7)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px"
  },
  addBtnDark: {
    background: "linear-gradient(to right, #0d0c0c, #0d0c0c),linear-gradient(to right, #3858E3 , #BA05F7)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    color: "#FFF",
    height: '32px',
    fontFamily: "Manrope",
    fontSize: "12px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    boxShadow: "none !important"
  },
  removeBtn: {
    fontFamily: "Manrope",
    fontSize: "12px",
    height: '32px',
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    boxShadow: "none !important",
    padding: "10px 16px",
    border: "1px solid red",
    borderRadius: "10px",
    color: "red",
    textTransform: "capitalize" as "capitalize",
  },
  bottomModelDarkStyle: {
    borderRadius: "6px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
    boxShadow: "none",
    position: "absolute" as "absolute",
    top: "80%"
  },
  bottomModelStyle: {
    borderRadius: "6px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
    boxShadow: "none",
    position: "absolute" as "absolute",
    top: "80%"
  },
  modelDarkStyle: {
    borderRadius: "10px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    backdropFilter: "blur(1 px)",
    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
    boxShadow: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  modelLightStyle: {
    borderRadius: "10px",
    boxShadow: "none",
    border: "1px solid transparent",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  followScreen: {
    display: "flex",
    flexDirection: "column" as "column",
    rowGap: "12px",
    height: "270",
    overflowY: "scroll" as "scroll",
    padding: "12px",
  },
  sideTop: {
    display: "flex",
    gap: "21px",
    marginBottom: "28px"
  },
  subscriptionBox: {
    borderRadius: "12px",
    padding: "12px 20px 12px 20px",
    gap: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    border: "0.5px solid #1D1D1D",
  },
  whathappiningBox: {
    borderRadius: "12px",
    padding: "12px 20px 12px 20px",
    gap: "12px",
    display: "flex",
    flexDirection: "column" as "column"
  },
  subscriptionBoxLight: {
    borderRadius: "12px",
    padding: "12px 20px 12px 20px",
    gap: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    border: "0.5px solid #222222"
  },
  subscrptiontext: {
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 800,
    lineHeight: "21px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent' as "transparent"
  },
  subcriptionBody: {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
  },
  showmore: {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  continueBtn: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    Padding: "6px 20px 6px 20px",
    gap: "7px",
    borderRadius: "10px",
    display: "flex",
    width: "35%",
    color: "white",
    fontSize: "12px",
    fontFamily: "inter",
    fontWeight: 500,
    textTransform: "none" as "none",
  },
  feed: {
    display: "flex",
    width: "60%",
    maxWidth: "810px",
    flexDirection: "column" as "column",
    rowGap: "24px",
    padding: "0 20px",
    borderRight: '2px solid #222222',
    paddingTop: "20px",
    height: "calc(100vh - 20px)",
    "@media (max-width:1000px)": {
      width: "80%",
    },
  },
  borderColorLightTheme: {
    borderColor: "#BABABA"
  },
  searchField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: "10px",
      '&.Mui-focused fieldset': {
        border: "1px solid #7A2BF5",
      },
    },
    width: "80%"
  },
  postDisplay: {
    padding: "8px 0px",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "20px",
    width:"100%"
  },
  feedBody: {
    height: "calc(100vh - 150px)",
    overflowY: "scroll" as "scroll",
    rowGap: "24px",
    display: "flex",
    marginTop: "30px",
    overflowX:"hidden" as "hidden",
    scrollbarWidth: "none" as "none",
    "&::-webkit-scrollbar": {
      display: "none" as "none",
    },
    flexDirection: "column" as "column"
  },
  feedsDisplay: {
    display: "flex",
    flexDirection: "column" as "column",
    rowGap: "24px",
  },
  postCreation: {
    marginBottom: "24px",
  },
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center" as "center"
  },
  sideContain: {
    height: "96vh",
    maxWidth: "510px",
    overflow: "scroll" as "scroll",
    width: "100%",
    paddingRight: "98px",
    scrollbarWidth: "none" as "none",
    "&::-webkit-scrollbar": {
      display: "none" as "none",
    },
    "@media (max-width:1000px)": {
      display: "none" as "none"
    },
  },
  memberError: {
    display: "flex",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    flexDirection: "column" as "column",
    height: "350px",
  },
  emaptyMessages: {
    fontSize: "24px",
    fontFamily: "Manrope",
    fontWeight: 800,
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: "center" as "center",
  },
  suggestionList: {
    display: "flex",
    flexDirection: "column" as "column",
    rowGap: "20px",
    width: "100%"
  },
  suggestionDisplay: {
    width: "100%",
    height: "400px",
    overflowY: "scroll" as "scroll",
    paddingRight: "10px"
  },
  tailoredPost: {
    padding: "15px",
    display: "flex",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "space-between"
  },
  emptyMesage: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center" as "center",
    height: "100%",
  },
  emaptyHead: {
    fontSize: "31px",
    fontFamily: "Manrope",
    fontWeight: 800,
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent' as "transparent"
  },
  emaptySubhead: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center" as "center"
  },
  name: {
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 800,
    lineHeight: "21px",
    whiteSpace: "nowrap" as "nowrap",
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  userName: {
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "14px",
    whiteSpace: "nowrap" as "nowrap",
    color: "#6B6B6B"
  },
  statustime: {
    fontSize: "11px",
    fontFamily: "Manrope",
    fontWeight: 400,
    lineHeight: "15px",
    color: "#81999E"
  },
  followBtn: {
    width: "65px",
    height: "25px",
    borderRadius: "10px",
    padding: "4px 8px 4px 8px",
    gap: "7px",
    fontSize: "12px",
    fontFamily: "Manrope",
    color: "white",
    fontWeight: 500,
    textTransform: "capitalize" as "capitalize",
    background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
  },
  editBtn: {
    padding: "6px 10px",
    borderRadius: "5px",
    textTransform: "capitalize" as "capitalize",
    background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))",
    fontWeight: 700,
    color: "white",
    fontSize: "12px",
    "& .MuiButton-label": {
      paddingTop: "2px"
    }
  },
  addUserBtn: {
    padding: "15px 30px 15px 30px",
    borderRadius: "10px",
    display: "flex",
    gap: "5px",
    ontSize: "14px",
    fontFamily: "Manrope",
    color: "white",
    marginTop: "15px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"

  },
  tabsContainer: {
    height: "35px"
  },
  doneButton: {
    boxShadow: "none !important",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "65px",
    height: "25px",
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: "12px",
  },
  postImage: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    objectFit: "cover" as "cover"
  },
  ContentHead: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "5px",
  },
  headconatin: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  },
  tabstyle: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "capitalize" as "capitalize",
    borderBottom: "3px solid gray",
    '&.Mui-selected': {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent' as "transparent",
    },
  },
  indicator: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: '2.9px',
  },
})
const webStyle = {
  modalHead: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: '539px',
  },
  ModalHeadtitle: {
    fontSize: "18px",
    fontWeight: 800,
    fontFamily: "Manrope"
  },
  nextBtn: {
    width: "72px",
    height: "32px",
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Manrope"
  },

  submitBtn: {
    width: "160px",
    height: "48px",
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Manrope",
    marginTop: "15px"
  },

};
export const ActivityFeeds = withStyles(style)(ActivityFeed);
//Customizable Area End
