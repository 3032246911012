// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createGroupChat, getListUserFriendComet } from "../../../components/src/CometChat";
import { createRef, RefObject } from "react";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
import { EmojiData } from "emoji-mart";
import { TAny } from "../../../components/src/utils";
import { IConversationPermissions } from "../../../blocks/chat/src/ViewChatController";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export type TTabGroupScreen = "create" | "edit" | "group_permission" | "send_messages" | "add_members" | "gallery"

interface ICheckedContacts {
  [key: string]: boolean
}

interface IMembersPermissions {
  addMembers: boolean;
  editSettings: boolean;
  sendMessages: boolean;
}

interface IGroupSettingsRequest {
  name: string,
  description: string,
  disappearing_messages: number;
  icon: string;
}

interface IGroupPermissionsRequest {
  can_edit_group_settings: boolean;
  cannot_send_messages: string[];
  can_add_members: boolean;
  admin_approve_new_members: boolean;
}

export interface IGroupsPermissionSettings {
  adminApproveNewMembers: boolean;
  disappearingMessages: number;
  membersPermissions: IMembersPermissions;
}

export interface Props {
  classes?: any
  onClose: () => void;
  checked?: boolean;
  onHandleDoneCreatedGroup: (conversation: CometChat.Group | Object) => void;
  navigation: any;
}

interface S {
  token: string;
  listContact: CometChat.User[];
  checkedContacts: ICheckedContacts;
  listMemberSelected: CometChat.User[];
  emojiAnchorEl: null | HTMLElement;
  createGroupName: string;
  createGroupIcon: string;
  createGroupDescription: string;
  selectedFile: File | null;
  currentGroupScreen: TTabGroupScreen;
  groupsPermissionSetting: IConversationPermissions;
  listMemberCanNotSendMessage: string[];
  isOpenModalDisappearing: boolean;
  isDisableDoneCreateGroup: boolean;
  disappearing_messages: number;
  isCanSendMessage: boolean;
}

interface SS {
  id: any;
}

export default class GroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  fileInputUploadImg: RefObject<HTMLInputElement>;
  uploadImageCallId: string = "";
  uploadGroupsSettingId: string = "";
  uploadGroupsPermissionId: string = "";
  sortIndexTab = ["create", "group_permission", "send_messages"]
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      listContact: [],
      checkedContacts: {},
      listMemberSelected: [],
      createGroupName: "",
      createGroupIcon: "",
      createGroupDescription: "",
      selectedFile: null,
      emojiAnchorEl: null,
      currentGroupScreen: "create",
      groupsPermissionSetting: {
        "admin_approve_new_members": false,
        "can_add_members": true,
        "can_edit_group_settings": true,
        "cannot_send_messages": []
      },
      isCanSendMessage: true,
      listMemberCanNotSendMessage: [],
      isOpenModalDisappearing: false,
      isDisableDoneCreateGroup: false,
      disappearing_messages: 0,
    };

    this.fileInputUploadImg = createRef()

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const token = await getStorageData("authToken");
    const listUsers = await getListUserFriendComet({});
    this.setState({ listContact: listUsers, token })
  }

  async componentDidUpdate(_prevProps: Props, prevStates: S) {
    const { checkedContacts, listContact } = this.state;
    if (prevStates.checkedContacts !== checkedContacts) {
      const listSelectedMember = Object.entries(checkedContacts);
      const listMember = listSelectedMember.map(([uid]) => {
        const member = listContact.find(contact => contact.getUid() === uid && checkedContacts[uid]);
        return member;
      }).filter(Boolean);

      this.setState({ listMemberSelected: listMember as CometChat.User[] });
    }
  }

  async componentWillUnmount() {
    // Revoke the data URI to free up memory
    if (this.state.createGroupIcon) {
      URL.revokeObjectURL(this.state.createGroupIcon);
    }
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uploadImageCallId != null &&
      this.uploadImageCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson?.data) {
        const { url } = responseJson?.data?.attributes;
        await this.createGroup(url);
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };

  handleChangeGroupScreen = (tab: TTabGroupScreen) => {
    this.setState({ currentGroupScreen: tab })
  }

  handleChangeGroupName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const groupName = event.target.value;
    this.setState({ createGroupName: groupName })
  }

  handleCreateGroupPlan = (id: string) => {
    this.props.navigation.navigate("AddGroupSubscription", { id: id })
  }

  handleCheckboxChange = (contactId: string) => {
    const isAlreadyAddMember = this.state.checkedContacts[contactId];
    const { cannot_send_messages } = this.state.groupsPermissionSetting;
    if (isAlreadyAddMember)
      return this.handleRemoveMember(contactId);
    if (!this.state.isCanSendMessage) {
      cannot_send_messages.push(contactId)
    }
    this.setState((prevState) => ({
      checkedContacts: {
        ...prevState.checkedContacts,
        [contactId]: true
      },
      groupsPermissionSetting: {
        ...prevState.groupsPermissionSetting,
        cannot_send_messages
      },
      listMemberCanNotSendMessage: cannot_send_messages,
    }));
  };

  handleRemoveMember = (contactId: string) => {
    this.setState((prevState) => {
      const { checkedContacts, groupsPermissionSetting } = prevState;
      const { cannot_send_messages } = groupsPermissionSetting;

      const newCheckedContacts = { ...checkedContacts };
      delete newCheckedContacts[contactId];

      const newCannotSendMessages = cannot_send_messages.filter(uid => uid !== contactId);

      return {
        checkedContacts: newCheckedContacts,
        groupsPermissionSetting: {
          ...groupsPermissionSetting,
          cannot_send_messages: newCannotSendMessages
        },
        listMemberCanNotSendMessage: newCannotSendMessages,
      };
    });
  };

  handleImageClick = () => {
    if (this.fileInputUploadImg.current) {
      this.fileInputUploadImg.current?.click();
    }
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const imageUrl = URL.createObjectURL(files[0]);
      this.setState({ createGroupIcon: imageUrl, selectedFile: files[0] });
    }
  };

  nextCreateGroup = async () => {
    const listSelectedMember = Object.keys(this.state.checkedContacts)
    if (listSelectedMember.length < 2) {
      toast.error("To create a group, please select at least 2 members.");
      return;
    }
    this.setState({ isDisableDoneCreateGroup: true })
    if (!this.state.selectedFile) {
      await this.createGroup()
      return;
    };

    await this.uploadImageFile(this.state.selectedFile);
  }

  uploadImageFile = async (imageFile: File) => {
    const header = {
      token: this.state.token,
    };
    const form = new FormData();
    form.append("media", imageFile);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadImageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_bulk_uploading/attachments"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadGroupsSetting = async (guid: string, groupSettings: IGroupSettingsRequest) => {
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    const bodyGroupSettings = {
      disappearing_messages: groupSettings.disappearing_messages
    }
    const stringifyBody = JSON.stringify(bodyGroupSettings)
    formData.append('settings', stringifyBody);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadGroupsSettingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupChatSettingEndPoint + `/${guid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadGroupsPermission = async (guid: string, groupPermissionsSetting: IGroupPermissionsRequest) => {
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    const stringifyBody = JSON.stringify(groupPermissionsSetting);
    formData.append('permissions', stringifyBody);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadGroupsPermissionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupChatPermissionEndPoint + `/${guid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSaveSettingsPermissionsGroup = async (conversation: Object, iconGroup?: string) => {
    let guid = "";
    const castConversation = conversation as TAny;
    if (!castConversation?.members) {
      guid = castConversation?.guid;
    } else {
      guid = castConversation?.group?.guid;
    }
    try {
      await this.uploadGroupsSetting(guid, {
        name: this.state.createGroupName,
        description: this.state.createGroupDescription,
        disappearing_messages: this.state.disappearing_messages,
        icon: iconGroup ?? ""
      })
      await this.uploadGroupsPermission(guid, {
        can_edit_group_settings: this.state.groupsPermissionSetting.can_edit_group_settings,
        cannot_send_messages: this.state.listMemberCanNotSendMessage,
        can_add_members: this.state.groupsPermissionSetting.can_add_members,
        admin_approve_new_members: this.state.groupsPermissionSetting.admin_approve_new_members
      })
    } catch (err) {
      console.error("Save setting and permission of group failed with err ", err);
    }
  }

  createGroup = async (iconGroup?: string) => {
    try {
      const listSelectedMember = Object.keys(this.state.checkedContacts)
      const newConversation = await createGroupChat({
        groupName: this.state.createGroupName,
        iconGroup,
        listMemberUid: listSelectedMember,
        description: this.state.createGroupDescription,
        onClose: this.props.onClose,
      });

      // Handle save settings and permission group
      await this.handleSaveSettingsPermissionsGroup(newConversation, iconGroup);
      this.props.onHandleDoneCreatedGroup(newConversation);
    } catch (err) {
      console.error("Create group failed with error", err)
    }
  }

  handleChangeGroupsPermissionSetting = (groupsPermissionSetting: IConversationPermissions, settingKeyValue: string) => {
    const currentGroupsPermissionSetting = this.state.groupsPermissionSetting;

    const updatedGroupsPermissionSetting = {
      ...currentGroupsPermissionSetting,
      ...groupsPermissionSetting,
    };

    if (settingKeyValue !== "cannot_send_messages") {
      this.setState({ groupsPermissionSetting: updatedGroupsPermissionSetting });
      return;
    }

    if (!groupsPermissionSetting.cannot_send_messages) {
      const getListMemberCanNotSendMessage = this.state.listMemberSelected.map(member => member.getUid()) ?? [];
      this.setState({
        isCanSendMessage: false,
        groupsPermissionSetting: { ...groupsPermissionSetting, cannot_send_messages: getListMemberCanNotSendMessage },
        listMemberCanNotSendMessage: getListMemberCanNotSendMessage
      });
      return;
    }

    this.setState({
      isCanSendMessage: true,
      groupsPermissionSetting: { ...groupsPermissionSetting, cannot_send_messages: [] },
      listMemberCanNotSendMessage: []
    });
  }

  handleChangeGroupsDescription = (descriptionValue: string) => {
    this.setState({ createGroupDescription: descriptionValue })
  }

  handleCloseEmoji = () => {
    this.setState({ emojiAnchorEl: null });
  }

  handleClickEmoji = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ emojiAnchorEl: event.currentTarget });
  }

  handleSelectEmoji = (emoji: EmojiData) => {
    this.setState({ createGroupName: this.state.createGroupName + (emoji as any).native })
  }

  handleToggleModalDisappearing = (currentBool: boolean) => {
    this.setState({ isOpenModalDisappearing: !currentBool });
  }

  handleSubmitDisappearingMessage = (value: string) => {
    const newValueDisappearingMessage = Number(value);
    this.setState({ disappearing_messages: newValueDisappearingMessage, isOpenModalDisappearing: false })
  }

  handleToggleUserSendMessage = (uid: string) => {
    const { listMemberCanNotSendMessage } = this.state;
    const updateListMemberCanNotSendMessage = [...listMemberCanNotSendMessage];
    const isExist = updateListMemberCanNotSendMessage.find(memberUid => memberUid === uid);
    if (!isExist) {
      updateListMemberCanNotSendMessage.push(uid);
      this.setState({ listMemberCanNotSendMessage: updateListMemberCanNotSendMessage });
      return;
    }
    const removeUid = updateListMemberCanNotSendMessage.filter(messageUid => messageUid !== uid);
    this.setState({ listMemberCanNotSendMessage: removeUid })
  }
}

// Customizable Area End
