import React from "react"
// Customizable Area Start
import ReactPlayer from 'react-player';
import InfiniteScroll from 'react-infinite-scroller';
import ArchiveStoryController,{Props} from "./ArchiveController.web"
import { 
    Grid,
    Box,
    Dialog,
    styled,
    Typography,
    CircularProgress
 } from "@material-ui/core";
import { IStoryAttributes } from "./CrerateHighlightStoriesController.web";
import { ArrowIcon ,crossIcon, darkArrowIcon , lightCross } from "./assets";
import HighlightedStories from "./HighlightedStories.web";
// Customizable Area End

export default class ArchiveStories  extends ArchiveStoryController {

    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }
    // Customizable Area Start
    
    renderMedia=(storyAttributes:IStoryAttributes,index:number)=>{
        return(
            <Box className='imageContainer'>
          { storyAttributes.media.content_type.startsWith('image')? <img 
            className='image'
            src={storyAttributes.media.url}  
            />: 
            <ReactPlayer 
            className="reactPlayer"
            url = {storyAttributes.media.url}
            />
            }
            {index===0&&<Box className='topPosition'>
              <Box className='dateContainer'>
              <Typography className='textImage'>{this.getDate(storyAttributes.created_at)}</Typography>
              </Box>
              </Box>}
            <Box className='bottomPosition'>   
           </Box>
          </Box>
        )
    }

   renderArchived=()=>{
      return (
        <InfiniteScroll 
        pageStart={this.state.pagination}
        loadMore={this.getArchivedStories}
        hasMore={this.state.pagination<=this.state.totalPages}
        useWindow={false}
        loader={
        <div className={"loader"} key={0}> 
        {this.state.pagination<=this.state.totalPages&&<CircularProgress disableShrink />}
        </div>} 
       > 
    <Grid container spacing={0}>
      {
        this.state.stories.map((item)=>
            item.stories.filter(storyItem => Boolean(storyItem.attributes.media)).map((story,index:number)=>{
                return (
                    <Grid item xs={4} style={webStyles.archivedStyles} data-testid="archived_stories" onClick={()=>this.handleOpenStory(item.stories,index)}>
                     {this.renderMedia(story.attributes,index)}
                    </Grid>
                  )
            })
        )
      }
    </Grid>
    </InfiniteScroll>
      )
   }    
   
   conditionRemover = (condition: boolean, trueStatement: string, falseStatement: string) => {
    return condition ? trueStatement : falseStatement
   }


   renderHighlight=()=>{
     return(
        <HighlightedStories 
         id=""
         data-testid="highlightStories"
         navigation={this.props.navigation}
         userInfo={this.props.useInfo}
         isLoggedUser={true}
         isArchiovedView={true}
         selectedItem={this.state.selectedItem}
         ArchivedStory={this.state.viewStories}
         onClose={this.handleOnHighlightClose}
         open={this.state.openHighlight}
         checked={this.props.checked}
         />
     )
   }

   // Customizable Area End
    render(){
    // Customizable Area Start
    const {checked}= this.props
    return(
        <DialogContainer data-testid="Archive_story_modal" open={this.props.open} onClose={this.handleGoBack}> 
            <StoryContainer style={{background:(this.conditionRemover(this.props.checked,"unset","#0B0B0B"))}}>
            <Box className={this.conditionRemover(checked,'lightContainer','container')}>   
            <Box className='editContainer' data-testid="renderArchived">
            <Box className='headContainer'>
                 <Box data-testid="prev-btn" onClick={this.handleGoBack}><img className='topIcon' src={this.conditionRemover(checked, darkArrowIcon, ArrowIcon)} /></Box>
                 <Box><Typography className='headingStyle'>Stories</Typography></Box>
                 <Box data-testid = "cross-btn"onClick={this.handleGoBack}><img className='topIcon' src={this.conditionRemover(checked, crossIcon ,lightCross)} /></Box>
             </Box> 
             <div className='storyContainer' >
                 {this.renderArchived()}
              </div>
            </Box>
            </Box>
            {this.renderHighlight()}
            </StoryContainer>
        </DialogContainer>
    )
    // Customizable Area End
    }
} 
// Customizable Area Start

const webStyles={
  archivedStyles:{marginTop:5}
}

const DialogContainer=styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
            maxWidth: "412px",
            maxHeight: '774px',
            borderRadius: '10px',
            overflow:'unset',
            width: '90%',
            border: '1px solid #35383F',
        }
})

const StoryContainer=styled(Box)({
    display: 'flex',
    borderRadius:10,
    maxHeight:'774px',
    justifyContent: 'center',
    width: '100%',
    height:'90vh',
    overflow:'unset',
    alignItems: 'center',

    "& .tabstyle": {
        fontFamily: "Manrope",
        fontWeight: 600,
        textTransform: "capitalize" as "capitalize",
        borderBottom: "3px solid #35383F",
        fontSize: "16px",
        minWidth:'unset !important',
        width:'50%',
        '&.Mui-selected': {
          background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent' as "transparent",
        },
       
        '@media(max-width:1000px)':{
          width:'50%'
        },
      },

      "& .tabsContainer": {
        height: "35px",
        width:'100%',
        marginTop:-6
      },

      "& .indicator": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        height: '2.9px',
      },

    '& .container': {
        width: '100%',
        height: '100%',
        background: '#0B0B0B'
    },

    '& .lightContainer': {
      width: '100%',
      height: '100%',
  },


    "& .editContainer": {
        display: 'flex',
        gap: 8,
        flexDirection: 'column',
    },

    "& .headContainer": {
        display: 'flex',
        height: 48,
        padding:'10px 20px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .followBtn": {
        width: "65px",
        fontSize: "12px",
        fontFamily: "Manrope",
        color: "white",
        fontWeight: 500,
        height: "25px",
        borderRadius: "10px",
        padding: "4px 8px 4px 8px",
        gap: "7px",
        textTransform: "capitalize" as "capitalize",
        background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))",
        "&.MuiButton-root.Mui-disabled": {
            color: '#E9E9E9',
            opacity:0.64
          }
    },

    "& .headingStyle": {
        fontSize: 18,
        fontFamily: 'Manrope',
        fontWeight: 800
    },


    "& .imageContainer":{
       height:204,
       width:122,
       position:'relative'
    },

    "& .image":{
        height:204,
        objectFit:"cover",
        width:122
    },

    "& .bottomPosition":{
       position:'absolute',
       margin:12,
       right:0,
       bottom:0
    },

    "& .topPosition":{
      position:'absolute',
      margin:6,
      top:0
    },
    "& .dateContainer":{
      width:33,
      height:35,
      background:'#222222',
      padding:'5px',
      borderRadius:5,
    },

  "& .textImage": {
    fontFamily: "Manrope",
    opacity:0.7,
    fontSize: "12px",
    fontWeight: 700,
    color:'#fff'
   },

   "& .reactPlayer":{
    width:'100% !important',
    height:'100% !important',
   },

   "& .storyContainer":{
      bakgrounf:'#0B0B0B',
      padding:'10px 20px',
      height:'calc(90vh - 100px)',
      maxHeight:'calc(774px - 100px)',
      overflow:'auto',
   },
   "& .selectedCount":{
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 500,

   },
   "& .selected_story":{
    padding:"10px 20px",
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
 },

})
// Customizable Area End