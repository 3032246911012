import { CometChatCalls } from "@cometchat/calls-sdk-javascript";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { Box, IconButton, styled, Typography } from "@material-ui/core";
import { EMITTER_EVENT_VARIABLES, emitterEvents, getCredCometChat } from "../../../components/src/utils";
import React from "react";
import ImageRenderer from "../../../components/src/ImageRenderer.web";
import { acceptCallIcon, rejectCallIcon } from "../../../blocks/call-recording/src/assets";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { EventSubscription } from "fbemitter";
import { toast } from "react-toastify";

interface Props extends RouteComponentProps {
}

interface S {
    isOpenModal: boolean;
    isCalling: boolean;
    currentCall: CometChat.Call | null;
}

class NotificationAudioCall extends BlockComponent<Props, S, {}> {
    listenerCallId = "CALL_LISTENER_ID" + Date.now();
    subscriptionResetCall: EventSubscription | null = null;
    subscriptionIsCalling: EventSubscription | null = null;
    subscriptionCancelCall: EventSubscription | null = null;
    subscriptionAcceptCall: EventSubscription | null = null;
    subscriptionEndCall: EventSubscription | null = null;
    subscriptionCometChatLogged: EventSubscription | null = null;
    subscriptionCometChatLogout: EventSubscription | null = null;

    constructor(props: Props) {
        super(props)

        this.state = {
            isOpenModal: false,
            isCalling: false,
            currentCall: null,
        }
    }

    async componentDidMount(): Promise<void> {
        this.subscriptionResetCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.CLOSE_MODAL_CALL, (bool: boolean) => {
            if (!bool) return;
            this.handleResetCall();
        })

        this.subscriptionIsCalling = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.IS_CALLING, (bool: boolean) => {
            this.setState({ isCalling: bool })
        })

        this.subscriptionAcceptCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.ACCEPT_CALL, (call: CometChat.Call) => {
            this.setState({ isOpenModal: false, currentCall: call, isCalling: false, })
        })

        this.subscriptionCancelCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.CANCEL_CALL, (call: CometChat.Call) => {
            if (this.state.currentCall && call.getSessionId() !== this.state.currentCall?.getSessionId()) return;
            this.handleResetCall();
        })

        this.subscriptionEndCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.RESET_CALL, () => {
            this.handleResetCall();
            CometChat.clearActiveCall();
            CometChatCalls.endSession();
        })

        this.subscriptionCometChatLogged = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.COMETCHAT_LOGGED_IN, () => {
            this.handleCallListener();
        })

        this.subscriptionCometChatLogout = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.COMETCHAT_LOGOUT, () => {
            CometChat.removeCallListener(this.listenerCallId);
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
        const { currentCall } = this.state;
        const { location } = this.props;
        if (prevProps.location !== location) {
            if (location.pathname.toLowerCase() === "/callrecording") {
                this.setState({ isOpenModal: false }, () => {
                    emitterEvents.emit(EMITTER_EVENT_VARIABLES.CURRENT_CALL, this.state.currentCall)
                });
                return;
            }
            if (this.state.currentCall?.getSessionId())
                this.setState({ isOpenModal: true });
        }
        if (prevState.currentCall !== currentCall && currentCall?.getSessionId()) {
            if (location.pathname.toLowerCase() === "/callrecording") {
                this.setState({ isOpenModal: false });
                return;
            }
            this.setState({ isOpenModal: true })
        }
    }

    async componentWillUnmount(): Promise<void> {
        if (this.subscriptionResetCall) {
            this.subscriptionResetCall.remove()
        }

        if (this.subscriptionCancelCall) {
            this.subscriptionCancelCall.remove()
        }
        if(this.subscriptionCometChatLogged) {
            this.subscriptionCometChatLogged.remove()
        }
        if(this.subscriptionCometChatLogout) {
            this.subscriptionCometChatLogout.remove()
        }
        CometChat.removeCallListener(this.listenerCallId);
    }

    handleCallListener = () => {
        console.log("CometChatCalls initialization completed successfully");
        CometChat.addCallListener(
            this.listenerCallId,
            new CometChat.CallListener({
                onIncomingCallReceived: async (call: CometChat.Call) => {
                    const listUnAcceptablePathName = ["/", "Password", "EmailAccount"];
                    const isExistUnAcceptablePathName = listUnAcceptablePathName.find(pathName => pathName.toLowerCase().includes(window.location.pathname.toLowerCase()));
                    if (isExistUnAcceptablePathName) return;
                    if (this.state.currentCall?.getSessionId() || this.state.isCalling) {
                        console.log("Condition Reject Call: ", this.state.currentCall?.getSessionId(), this.state.isCalling)
                        const callStatus = call.getReceiverType() === CometChat.RECEIVER_TYPE.GROUP ? CometChat.CALL_STATUS.REJECTED : CometChat.CALL_STATUS.BUSY
                        await this.handleRejectCall(call.getSessionId(), callStatus);
                        return;
                    }
                    console.log("Incoming call:", call, this.state.currentCall?.getSessionId());
                    this.setState({ isOpenModal: true, currentCall: call }, () => {
                        emitterEvents.emit(EMITTER_EVENT_VARIABLES.INCOMING_CALL, call);
                    });
                },
                onOutgoingCallAccepted: async (call: CometChat.Call) => {
                    console.log("Outgoing call accepted:", call);
                    const currentLoggedUser = await CometChat.getLoggedinUser();
                    const senderUser = call.getSender().getUid();
                    if(senderUser === currentLoggedUser?.getUid()){
                        this.handleResetCall();
                        emitterEvents.emit(EMITTER_EVENT_VARIABLES.CANCEL_CALL, call)
                        return;
                    }
                    emitterEvents.emit(EMITTER_EVENT_VARIABLES.START_CALL_SESSION, call)
                    this.setState({ isOpenModal: false, currentCall: call })
                },
                onOutgoingCallRejected: (call: CometChat.Call) => {
                    console.log("Outgoing call rejected:", call);
                    const actionCall = call.getAction();
                    if (actionCall === CometChat.CALL_STATUS.BUSY) {
                        toast.error(`The ${call.getReceiverType()} is currently busy and cannot connect the call.`)
                    } else {
                        toast.warn(`The ${call.getReceiverType()} is not available for your call`)
                    }
                    emitterEvents.emit(EMITTER_EVENT_VARIABLES.REJECT_CALL, call)
                    this.handleResetCall();
                },
                onIncomingCallCancelled: (call: CometChat.Call) => {
                    console.log("Incoming call calcelled:", call);
                    emitterEvents.emit(EMITTER_EVENT_VARIABLES.CANCEL_CALL, call);
                    this.handleResetCall();
                },
                onCallEndedMessageReceived: (call: CometChat.Call) => {
                    console.log("CallEnded Message:", call);
                    this.handleResetCall();
                    CometChat.clearActiveCall();
                    CometChatCalls.endSession();
                },
            }),
        );
    }
    

    handleNavigateCall = async () => {
        const navigate = (url: string, payload: any) => {
            this.props.history.push(url)
        }
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "/CallRecording");
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            {
                ...this.props,
                navigation: { navigate }
            }
        );

        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );

        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
            isAcceptCall: true,
            sessionIDCall: this.state.currentCall?.getSessionId(),
        });

        msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

        runEngine.sendMessage("NotificationAudioCall", msg);
    }

    handleRejectCall = async (sessionIdCall?: string, statusCall?: string) => {
        try {
            const status = statusCall ?? CometChat.CALL_STATUS.REJECTED;
            const call = await CometChat.rejectCall(sessionIdCall ?? this.state.currentCall?.getSessionId() ?? "", status)
            console.log("Call rejected successfully:", call);
            if (call.getSessionId() !== this.state.currentCall?.getSessionId()) return;
        } catch (error) {
            console.error("Reject call in notification failed with error: ", error)
        }
        this.handleResetCall();
    }

    handleResetCall = () => {
        this.setState({ isOpenModal: false, currentCall: null, isCalling: false })
    }

    render() {
        return this.state.isOpenModal && (
            <NotificationAudioCallStyled>
                <Box className="header">
                </Box>
                <Box className="container">
                    <Typography className="textContent">
                        There is an Incoming Call
                    </Typography>
                    <Box className="list_button">
                        <IconButton onClick={this.handleNavigateCall}>
                            <ImageRenderer className="callBtn" src={acceptCallIcon} width={24} height={24} />
                            <Typography className="text">
                                Accept
                            </Typography>
                        </IconButton>
                        <IconButton onClick={() => this.handleRejectCall()}>
                            <ImageRenderer className="callBtn" src={rejectCallIcon} width={24} height={24} />
                            <Typography className="text">
                                Reject
                            </Typography>
                        </IconButton>
                    </Box>
                </Box>
            </NotificationAudioCallStyled>
        )
    }
}

const NotificationAudioCallStyled = styled(Box)(() => ({
    border: "1px solid #222222",
    borderRadius: 8,
    padding: "20px 20px 8px",
    paddingRight: 0,
    position: "absolute",
    zIndex: 99999999999999,
    background: "#222222",
    right: 20,
    top: 20,
    "& .text": {
        fontFamily: "Manrope",
        color: "#FFFFFF",
        fontWeight: 500,
        fontSize: 12,
    },
    "& .header": {
        display: "flex",
        flexDirection: "row-reverse",
        position: "relative",
    },
    "& .container": {
        paddingRight: "20px",
        "& .textContent": {
            fontFamily: "Manrope",
            fontWeight: 500,
            fontSize: 14,
            color: "#FFFFFF"
        },
        "& .list_button": {
            display: "flex",
            justifyContent: "space-around",
            "& .callBtn": {
                marginRight: 4
            }
        }
    }
}))

export default withRouter(NotificationAudioCall);