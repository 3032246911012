export const imgSplash = require("../assets/logo.png");
export const imgloader = require("../assets/loader.png");
export const arrowLogo = require("../assets/arrowlogo.png");
export const dashboardImage = require("../assets/Dashboard.png");
export const drakThemeImage = require("../assets/darkTheme.png");
export const profile=require('../assets/profile.png');

export const darkBackground = require("../assets/darkBg.png");
export const lightBackground = require("../assets/lightBg.png");
export const logo = require("../../../components/src/logo.png");
export const activeDot = require("../assets/activeDot.svg");
export const inActiveDot = require("../assets/inActiveDot.png");
export const notActiveDot = require("../assets/notActiveDot.png");
export const verificationMark = require("../assets/verificationMark.png");
export const googleLogo = require("../assets/google.svg");
export const appleLogo = require("../assets/appleLogo.png");
export const appleDarkLogo = require("../assets/appleDark.png");
export const inActiveDotImage = require("../assets/lightThemeDot.png");
export const addIcon = require("../assets/group_.svg");
export const RightIcon = require("../assets/lefticon.svg");