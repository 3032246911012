import React from "react";

import { Box, styled } from "@material-ui/core";
// Customizable Area Start
// Customizable Area End

import MyGroupsSubscriptionController, {
  Props,
  // configJSON,
} from "./MyGroupsSubscriptionController";
import TableSubscription from "../../utilities/src/components/TableSubscription";

export default class MyGroupsSubscription extends MyGroupsSubscriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  columns = [
    { key: 'groupName', label: 'Group chat name' },
    { key: 'groupAdmin', label: 'Group admin', width: 150, align: "left" as "left" },
    { key: 'renewalDate', label: 'Renewal Date', width: 150, align: "center" as "center" },
    {
      key: 'price', label: 'Price (monthly)', width: 80, align: "left" as "left", textStyle: {
        wordBreak: "break-word"
      }
    },
    { key: 'action', label: 'Action', width: 80, align: "left" as "left" },
  ];

  columnsCanceled = [
    { key: 'groupName', label: 'Group chat name' },
    { key: 'groupAdmin', label: 'Group admin', width: 150, align: "left" as "left" },
    { key: 'expiresOn', label: 'Expires on', width: 150, align: "left" as "left" },
    {
      key: 'price', label: 'Price', width: 80, align: "center" as "center"
    },
  ];

  subscriptions = [
    {
      id: 1,
      groupName: 'Community Lounge',
      groupAdmin: 'James Ekstrom',
      renewalDate: '25 Jan 2025',
      price: 100,
    },
    {
      id: 2,
      groupName: 'Tech Talk',
      groupAdmin: 'Sara Connor',
      renewalDate: '30 Dec 2024',
      price: 200,
    },
    {
      id: 3,
      groupName: 'Book Club',
      groupAdmin: 'Alice Johnson',
      renewalDate: '15 Feb 2025',
      price: 50,
    },
    {
      id: 4,
      groupName: 'Fitness Group',
      groupAdmin: 'Michael Smith',
      renewalDate: '10 Mar 2025',
      price: 75,
    },
    {
      id: 5,
      groupName: 'Cooking Enthusiasts',
      groupAdmin: 'Linda Brown',
      renewalDate: '22 Apr 2025',
      price: 120,
    },
  ];

  subscriptionsCanceled = [
  ];
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start'
      <BoxContainer>
        <TableSubscription
          title="Ongoing"
          columns={this.columns}
          subscriptions={this.subscriptions}
          tableMaxHeight={250}
        />
        <TableSubscription
          title="Canceled"
          columns={this.columnsCanceled}
          subscriptions={this.subscriptionsCanceled}
          tableMaxHeight={250}
        />
      </BoxContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const BoxContainer = styled(Box)({
  height: "calc(100% - 55px)",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  scrollbarWidth: "none",
  gap: 21,
})

// Customizable Area End
