import React, { Fragment } from "react";

// Customizable Area Start
import {
  Box,
  IconButton,
  styled,
  Typography,
} from "@material-ui/core";
// Customizable Area End

import IncomingCallController, {
  Props,
  configJSON,
} from "./IncomingCallController.web";
import { acceptCallIcon, backgroundIncomingCall, rejectCallIcon, sendMessageIcon } from "./assets";
import ImageRenderer from "../../../components/src/ImageRenderer.web";
import clsx from "clsx";

// Customizable Area Start
// Customizable Area End

export default class IncomingCall extends IncomingCallController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ListButton = () => {
    if(this.props.currentCall?.getReceiverType() === "user") return (
      <Box className="list_button-user">
        <IconButton
          data-test-id="btnLeaveCall"
          className={`buttonDefault`}
          onClick={this.handleRejectCall}
        >
          <ImageRenderer src={rejectCallIcon} width={64} height={64}/>
        </IconButton>
        <IconButton
          data-test-id="btnAcceptCall"
          className={`buttonDefault buttonDefaultActive`}
          onClick={this.handleAcceptCall}
        >
          <ImageRenderer src={acceptCallIcon} width={64} height={64}/>
        </IconButton>
        <IconButton>
          <ImageRenderer src={sendMessageIcon} width={64} height={64}/>
        </IconButton>
      </Box>
    )
    return (
      <Box className="list_button">
        <Box
          data-test-id="btnLeaveCall"
          className={`buttonDefault`}
          onClick={this.handleRejectCall}
        >
          <Typography className="buttonText">Leave</Typography>
        </Box>
        <Box
          data-test-id="btnAcceptCall"
          className={`buttonDefault buttonDefaultActive`}
          onClick={this.handleAcceptCall}
        >
          <Typography className="buttonText">Join</Typography>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    if(!this.props.currentCall?.getSessionId()) return <Fragment />
    const {username} = this.props.currentCall.getSender().getMetadata() as unknown as {username: string};

    return (
      <IncomingCallStyled>
        <Box
          className={`container-incoming 
            ${clsx(this.props.currentCall.getReceiverType() === "user" && "container-incoming-user")}`}
          style={{
            flexDirection: this.props.currentCall.getReceiverType() === "user"
              ? "column-reverse"
              : "column",
          }}>
          <Box className="header__title">
            <Typography component="h4" className={`text__title ${clsx(this.props.checked && "lightTheme-text")}`}>
              {
                this.props.currentCall.getReceiverType() === "user"
                  ? this.props.currentCall.getSender().getName()
                  : configJSON.headerTittleApp
              }
            </Typography>
            <Typography component="p" className={`${clsx(this.props.checked && "lightTheme-text")}`}>
              {
                this.props.currentCall.getReceiverType() === "user"
                ? username
                : this.props.currentCall.getSender().getName()
              }
            </Typography>
          </Box>
          <Box className="profile_call">
            <img
              src={this.props.currentCall.getSender().getAvatar()}
              className="profile_call_avatar"
              alt="Overlay Image"
            />
          </Box>
        </Box>
        {this.ListButton()}
      </IncomingCallStyled>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const IncomingCallStyled = styled(Box)(() => ({
  flex: 20,
  maxWidth: '680px',
  padding: "20px 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .container-incoming": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .header__title": {
      flex: "0 0 40%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      gap: "20px",
      boxSizing: "border-box",
      paddingBottom: "90px",
      "& .text__title": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 800,
        color: "#FFFFFF"
      },
      "& p": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        color: "#E0E0E0"
      },
      "& .lightTheme-text": {
        color: "#222222",
      }
    },
    "& .profile_call": {
      width: "237px",
      height: "271px",
      backgroundImage: `url(${backgroundIncomingCall})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      "& .profile_call_avatar": {
        position: "absolute",
        top: "calc(50% - 12px)",
        left: "calc(50% + 2px)",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        width: "130px",
        height: "130px",
        borderRadius: "100px",
      }
    }
  },
  "& .container-incoming.container-incoming-user": {
    gap: "40px",
    "& .header__title": {
      justifyContent: "unset"
    }
  },
  "& .list_button": {
    display: "flex",
    gap: "25px",
    "& .buttonDefault": {
      boxSizing: "border-box",
      textAlign: "center",
      width: "160px",
      height: "48px",
      padding: "12px",
      border: "1px solid transparent",
      borderRadius: "10px",
      display: "inline-block",
      background: "linear-gradient(90deg, #070707 100%, #070707 100%) padding-box, linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box",
      cursor: "pointer",
      "& .buttonText": {
        backgroundImage: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        color: "transparent",
        backgroundClip: "text",
        fontFamily: "Manrope",
        fontWeight: 700,
        fontSize: "14px"
      },
    },
    "& .buttonDefault.buttonDefaultActive": {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      "& .buttonText": {
        color: "#FFFFFF"
      }
    }
  },
  "& .list_button-user": {
    position: "absolute",
    bottom: 0,
    width: "calc(100% - 4px )",
    background: "#96969633",
    display: "flex",
    justifyContent: "space-around",

  }
}))
// Customizable Area End
