import React from 'react'
// Customizable Area Start 
import ProfileController,{ configJSON, IUserProfile, PaginationDetails, Props} from './ProfileController.web';
import {
    Box,
    styled,
    TextField,
    Button,
    InputAdornment,
    Typography,
    Tabs,
    Tab,
    Popover,
    CircularProgress,
    DialogContent,
    DialogContentText
    } from "@material-ui/core"
import PostHeader from '../../activityfeed/src/PostHeader.web';
import PostBody from '../../activityfeed/src/PostBody.web';
import PostFooter from '../../activityfeed/src/PostFooter.web';
import { highlightText } from '../../../components/src/utils';
import NavigationMenu from '../../navigationmenu/src/NavigationMenu.web';
import { ArrowIcon, callDark,callLight,searchDark,searchLight, MenuDark, MenuLight, backCover, darkArrowIcon, avatar, lightChatIcon, chatIcon, verifyIcon, enterprenuarship, enterprenuarshipDark, locationIconDark, locationIcon, globalIconDark, globalIcon, addHighlightstory, defaultUserPic, Notification, NotificationDark, followedDark, followIcon } from './assets';
import SubscribedUser from './SubscribedUser.web';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../../components/src/Loader.web';
import moment from 'moment';
import MuteModal from './MuteModal.web';
import ReportUserModal from './ReportUserModal.web';
import Comments from '../../comments/src/Comments.web';
import PostActivity from './PostActivityModal.web';
import EditProfileModal from './EditProfileModal.web';
import { Post } from '../../../blocks/activityfeed/src/ActivityFeedController';
import NotificationPreference from './NotificationPrerference.web';
import ActivityFollowers from '../../../blocks/followers/src/ActivityFollowers.web';
import CreateHighlightStories from './CreateHighlightStories.web';
import HighlightedStories from './HighlightedStories.web';
import { IUserAttributes } from './HighlightedStoriesController.web';
import PinedPostModal from '../../activityfeed/src/PinedPostModal.web';
import DeletePostModal from '../../activityfeed/src/DeletePostModal.web';
import SelectRepostModal from '../../repost/src/SelectRepostModal.web';
import CreateRepost from '../../repost/src/CreateRepost.web'
import CommentPreference from '../../activityfeed/src/CommentPreference.web';
import ArchiveStories from './Archive.web';
import Share from '../../share/src/Share.web';
import ConfirmModal from '../../../components/src/ConfirmModal';
import PlanProfileSubscription from '../../utilities/src/components/PlanProfileSubscription.web';
import ModalAction from '../../utilities/src/components/ModalAction';

const followers =[
       {
        name:'abc',
        username:'@abc_23'
       },

       {
        name:'xyz',
        username:'@xyz_23'
       }
    
    ]

// Customizable Area End

export default class Profile  extends ProfileController {
    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }
  
    // Customizable Area Start
    conditionRemover =(condition:boolean,trueStatement:string,falseStatement:string)=>{
        return condition? trueStatement: falseStatement
      }
     

    renderTablist=()=>{
      const  { activeIndex } = this.state
      return(
        <Box className={'tabsContainer'}>
        <Tabs
          classes={{ indicator:"indicator" }}
          textColor="primary"
          className='tabs_style'
          value={activeIndex}
          onChange={this.handleTabChange}
          variant="scrollable"
          data-test-id="tabBtn"
        >
          <Tab label="Arrows" value="Arrows" className={'tabstyle'} />
          <Tab label="Replies" value="Replies" className={'tabstyle'} />
          <Tab label="Likes" value="Likes"className={'tabstyle'} />
          <Tab label="Subscriber" value="Subscriber" className={'tabstyle'} />
        </Tabs>
      </Box>
      )
    }

    renderHighlights=()=>{
      return(
        <>
        {this.state.loadingHightlights&& <Box style={{height:84}}  className='loader'><CircularProgress disableShrink/></Box>}
        {this.state.highlightStories.length>0&&
        <Box className='hightlightStoryContainer'>
         {this.state.highlightStories.map((item)=>{
          return(
            <Box className='story' data-testid="hightlight" onClick={()=>this.openStory(item)}>
              <Box className='storyImageContainer'>
              <img className='storyImage'  src={item.attributes.cover}/>
              </Box>
              <Box className='StoryTextContainer'>
                <Typography className='story_Text'>{item.attributes.title}</Typography>
              </Box>
           </Box>
          )
         })}
          {this.state.isLoggedUser&&<Box className='story'>
              <Box className='AddStoryContainer' data-testid="addCreateHighlight" onClick={this.openCreateHighlightModal}>
               <img src = {addHighlightstory} height={24} width={24} alt='add_stroy'/>
              </Box>
              <Box className='StoryTextContainer'>
                <Typography className='story_Text'>Add Story</Typography>
              </Box>
           </Box>
           }
        </Box>}
          </>
      )
    }


    renderCommentSettingModal=()=>{
       return (
        <CommentPreference 
         checked={this.props.checked}
         id={0}
         postId={this.state.reportingPostId}
         onClose={this.handleOnCloseCommentSetting}
         open={this.state.openCommentSetting}
         navigation={this.props.navigation}
        />
       )
    }

    renderPostActivity=(val:Post,highlightedBody:string)=>{
      return(
        <Box>
        {this.state.ActivePostActivity&&
          <PostActivity 
            checked={this.props.checked}
            data-testid="PostActivity"
            onBlockAccount={this.handleBlockAccount}
            onClickMoreOption={this.handleClick}
            onClosePostActivityModel={this.handleClosePostActivityModel}
            onFollow={this.handleFollow}
            highlightedBody={highlightedBody}
            onMuteConformation={this.muteConformation}
            navigation={this.props.navigation}
            postActivity={this.state.ActivePostActivity}
            postActivityModel={this.state.showPostActivity}
            onShowReportPost={this.showReportPost}
            post={val}
            onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
          />
        }
        </Box>
      )
    }


    renderNotificationPreference=()=>{
      return(
        <Box>
          <NotificationPreference data-testid="notificationPreference" checked={this.props.checked} navigation={this.props.navigation} open={this.state.notificationPref} onClose={this.handleNotificationPreference} id={this.props.navigation.getParam('id')}/>
        </Box>
      )
    }

    renderCommentModal=()=>{
      const {selectedPostComment ,showComments,LoggedUser} = this.state
      const {checked} = this.props
       return(
        <>
         {this.state.selectedPostComment && (
              <Comments
                checked={checked}
                open={showComments} 
                data-testid="comment"
                onClose={this.handleCloseCommentsModal}
                postData={selectedPostComment as Post}
                userInfo={{
                  id:LoggedUser?.attributes.id as number,
                }}
                onFollow={this.handleFollow}
                onClickMoreOption={this.handleClick}
                onLikePost={this.handleLikePost}
                onSavePost={this.handleSavePost}
                onMuteConformation={this.muteConformation}
                onShowReportPost={this.showReportPost}
                onBlockAccount={this.handleBlockAccount}
                onUpdateNumberOfComments={this.handleUpdateCommentNumberOfPost}
                onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}
              />
            )}
        </>
       )      
    }


    renderCreateHighlightStory=()=>{
      return(
        <>
          <CreateHighlightStories 
           navigation={this.props.checked}
           id=''
           data-testid="createHighlight"
           onClose={this.closeAddHighlight}
           onAddHighlight={this.handleCreateHighlight}
           open={this.state.addHighlight}   
           checked={this.props.checked}
           isEditable={this.state.EditHighlight}
           storyDetails={this.state.selectedHighlight}
          />
        </>
      )
    }


  renderEditProfileModal=()=>{
    return (
      <>
     {this.state.userProfile?.attributes&& 
     <EditProfileModal 
        data-testid = "EditProfile"
        navigation={this.props.navigation}
        open={this.state.editProfile}
        userInfo={this.state.userProfile?.attributes}
        onClose={this.handleEditProfile}
        onSubmit={this.updateProfile}
        checked={this.props.checked}
        id=''
      />}
      </>
    )
  }

  renderRepostModal=()=>{
    return(
      <>
      {this.state.selectedPostComment && (
              <SelectRepostModal
                open={this.state.showRepostModal}
                onClose={this.handleCloseRepostModal}
                onRepost={this.handleRepost}
                onQuote={this.handleQuote}
                data-testid="repostModal"
                onUndoRepost={this.handleUndoRepost}
                isUndoRepost={this.state.selectedPostComment.attributes.is_reposted}
              />
            )}
      </>
    )
  }


  renderArchivedStoryModal=()=>{
    if(this.state.userProfile){
    return(
      <ArchiveStories 
        checked={this.props.checked}
        data-testid="Archived"
        navigation={this.props.navigation}
        open ={this.state.openArchives}
        onClose={this.handleCloseArchives}
        useInfo={this.state.userProfile?.attributes}
      />
    )
   }
  }


  renderFollowerList=()=>{
    return(
      <>
      {this.state.userProfile?.attributes && 
       <ActivityFollowers 
       checked={this.props.checked}
       id=''
       navigation={this.props.navigation}
       isLoggedUser={this.state.isLoggedUser}
       followerCount={this.state.userProfile.attributes.followers_count}
       followingCount={this.state.userProfile.attributes.following_count}
       onClose={this.handleCloseFollowList}
       open={this.state.openFollowerList}
       data-testid="followerModal"
       userId={this.state.userProfile?.attributes.id}
       Tab={this.state.isFollowerList}
       />
      }
      </>
    )
  }



  renderHighlightedStories=()=>{
       return(<>
            <HighlightedStories 
              id=''
              data-testid="highlightStoryModal"
              isLoggedUser={this.state.isLoggedUser}
              navigation={this.props.navigation}
              open={this.state.storyOpen}
              onClose={this.handleCloseHighlightedStory}
              checked={this.props.checked}
              userInfo={this.state.userProfile?.attributes as IUserAttributes}
              HighlightId={this.state.selectedHighlight}   
              onDeleteHighlight={this.deleteHighlight}
              onEditHighlight={this.EditHighlight}
              />
       </>)
  }
  


    renderSubscribedUserList=()=>{
      return(
        <Box style={{width:'100%'}}>
        {["","","",""].map((subscriber)=>(
        <Box>
          <SubscribedUser id={''} checked={this.props.checked} navigation={this.props.navigation}/>
         </Box>
        ))}
         </Box>
      )
    }
    
    renderPostList=()=>{
      const {checked} = this.props
      const {feed,fetchedPages} = this.state
      const pagination = this.state.pagination as PaginationDetails
      const sortedPost = feed.sort((a,b)=> {
        if (a.attributes.is_pinned && !b.attributes.is_pinned) return -1;  
         if (!a.attributes.is_pinned && b.attributes.is_pinned) return 1; 
           return 0;
           })

      return(

        <div>        
        <InfiniteScroll
        pageStart={fetchedPages}
        loadMore={()=>this.getPosts()}
        hasMore={fetchedPages <= pagination?.total_pages}
        loader={<div className={"loader"} key={0}> {fetchedPages <= pagination?.total_pages && <CircularProgress disableShrink />}</div>}
        useWindow={false}
        getScrollParent={() => this.scrollParentRef.current}
        data-test-id="postScroller"
      >

        <Box className='postList'>
          {feed.length>0 && sortedPost.map((post) => {
                      const highlights = [...post.attributes.body_mentions, ...post.attributes.hash_tags];
                      const highlightedBody = post.attributes.body && highlightText(post.attributes.body, highlights);
                      const postId = post.id
                      return <Box className={'postDisplay'} key={post.id}>
                        <Box style={{ paddingLeft: '24px' }}>
                          <PostHeader
                            data-testid="postHeader"
                            checked={checked}
                            onFollow={this.handleFollow}
                            onClickMoreOption={this.handleClick}
                            navigation={this.props.navigation}
                            onMuteConformation={this.muteConformation}
                            onShowReportPost={this.showReportPost}
                            onBlockAccount={this.handleBlockAccount} 
                            onPinPost={this.getPinnedPost}
                            onCommentSetting={this.openCommentSetting}
                            onDeletePost={this.openDeletePostModal}
                            postData={post}       
                            onToggleRemoveAddUserCustomList={this.handleToggleRemoveAddUserCustomList}                   
                          />
                        </Box>
                        <Box style={{marginLeft: "67px"}}>
                        <PostBody postData={post} checked={checked} />
                        </Box>
                        <Box style={{marginLeft: "67px"}}>
                          <PostFooter
                            checked={checked}
                            data-testid="PostFooter"
                            postData={post}
                            onLikePost={this.handleLikePost}
                            onSavePost={this.handleSavePost}
                            onClickComments={this.handleClickComments}
                            showPostActivity={this.handlePostActivityModel}
                            onClickRepost={this.handleShowRepostModel}
                          />
                        </Box>
                        {this.state.ActivePostActivityId == postId && this.renderPostActivity(post,highlightedBody as string)}
                      </Box>
                    })
          }         
        </Box>
        </InfiniteScroll>
        </div>

      )
    }  

    ReporOthertUser=()=>{
      if(this.state.userProfile){
      const popper = Boolean(this.state.anchorEl)
      const popperId = popper ? 'simple-popper' : undefined;
      const childComponent = this.state.isLoggedUser?this.MenuLoggedUser():this.MenuOtherUser()
     return(<>
        <CustomPopover
        id={popperId} 
        open={popper} 
        data-testid="MenuPopover"
        anchorEl={this.state.anchorEl} 
        onClose={this.handleMenuClose}
        style={{background: "rgba(0, 0, 0, 0.4)bottom",zIndex:99999}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        >
          {childComponent}
       </CustomPopover>  
       </>
     )
    }
    }

    MenuLoggedUser=()=>{
      return(
        <Box>  
         <Box className="MenuItem" data-testid="menuItem" onClick={this.handleShare}>
          <Box>
            <Typography className="item_list_name">{configJSON.share}</Typography>
          </Box>
        </Box> 
        <Box className="MenuItem" data-testid="menuItem" onClick={this.handleEditProfile}>
          <Box>
            <Typography className="item_list_name">{configJSON.editProfile}</Typography>
          </Box>
        </Box>
        <Box className="MenuItem" data-testid="menuItem" onClick={this.handleArchive}>
          <Box>
            <Typography className="item_list_name">{configJSON.archived}</Typography>
          </Box>
        </Box>
       </Box>
      )
    }
   
    MenuOtherUser=()=>{
      const userProfile =  this.state.userProfile as IUserProfile
       return(
        <Box>  
          <Box className="MenuItem" data-testid="menuItem" onClick={this.handlefollowUnfollow}>
          <Box>
            <Typography className="item_list_name">{this.conditionRemover(userProfile?.attributes.is_following,`Unfollow ${userProfile.attributes.user_name}`,`Follow ${userProfile.attributes.user_name}`)}</Typography>
          </Box>
        </Box>
        <Box className="MenuItem" data-testid="menuItem" onClick={this.handleTailoredUser}>
          <Box>
            <Typography className="item_list_name">{this.conditionRemover(userProfile?.attributes.is_tailored,`Remove ${userProfile.attributes.user_name} from custom` ,`Add ${userProfile.attributes.user_name} from  Custom`)}</Typography>
          </Box>
        </Box>
        <Box className="MenuItem" data-testid="menuItem" onClick={this.handleMuteUnmute}>
          <Box>
            <Typography className="item_list_name"> {this.conditionRemover(userProfile?.attributes.is_muted,`Unmute ${userProfile.attributes.user_name}`,`Mute ${userProfile.attributes.user_name}`)} </Typography>
          </Box>
        </Box>
        <Box className="MenuItem" data-testid="menuItem" onClick={this.handleBlockUnBlockAccount}>
          <Box>
            <Typography className="item_list_name">{this.conditionRemover(userProfile?.attributes.is_blocked,`Unblock ${userProfile.attributes.user_name}` , `Block ${userProfile.attributes.user_name}`)}</Typography>
          </Box>
        </Box>
        <Box className="MenuItem" data-testid="menuItem" onClick={this.handleShare}>
          <Box>
            <Typography className="item_list_name">{configJSON.share}</Typography>
          </Box>
        </Box>
        <Box className="MenuItem" data-testid="menuItem" onClick={this.handleReportUser}>
          <Box>
            <Typography className="item_list_name">{configJSON.report}</Typography>
          </Box>
        </Box>
       </Box>
       )
    }

    DialogContentDeletePlan = () => (
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this plan?
        </DialogContentText>
        <br />
        <DialogContentText>
          This action is irreversible
        </DialogContentText>
      </DialogContent>
    )

    DialogActionDeletePlan = () => (
      <Button className="dialogBtn modalPrimaryBtn" onClick={this.handleDeletePlan}>
        Delete
      </Button>
    )

    renderBlockedAccountButton=()=>{
     return ( 
     <Box className="buttonContainer">
      <Box>
      </Box>
      <Box>
        <Button data-testid="Blocked" onClick={this.handleBlockUnBlockAccount} className="blockedButton" >Blocked</Button>
      </Box>
     </Box>
     )
    }



    renderOtherUserButton=()=>{
       
      if(this.state.userProfile?.attributes.user_subscription!==null){
        return this.renderOtherUserButtonWithSubscribe()
      }
         else{
        return this.renderOtherUserButtonWithoutSubscribe()
      }
    }

    renderLoggedUserButtons=()=>{
      const title = !this.state.userProfile?.attributes.subscription_id ? "Create Plan" : "Edit Plan"
    return(
      <Box className="buttonContainer">
          <Box>
            <Button data-test-id="btnSavePlan" className="gradientButton" onClick={this.handleEditPlanSubscription}>{title}</Button>
          </Box>
      </Box>
      )
    }

    renderPinPostModal=()=>{
      return<>
          <PinedPostModal
          data-testid="pinpostModal"
          checked={this.props.checked}
          open={this.state.openPinpost}
          onClose={this.handleClosePinPost}
          isPinned={this.state.isPinned}
          onPinnedConfirmation={this.handlePinnedConfirmation}
          id=''
          />
      </>
    
  }


  renderDeletePost=()=>{
     return(
      <>
      <DeletePostModal
       checked={this.props.checked}
       id=''
       data-testid="DeletePostModal"
       onClose={this.closeDeletePost}
       onDeleteConfirmation={this.DeletePostApi}
       open={this.state.openDeletPost}
      />
      </>
     )
  }

    renderOtherUserButtonWithSubscribe=()=>{
      const {checked} = this.props
      const  userProfile   = this.state.userProfile as IUserProfile
      return(
        <Box className="buttonContainer">
          <Box
            className={this.conditionRemover(checked, "icons", "iconsLight")}
            data-test-id="btnNavigateChat"
            onClick={this.handleNavigateToChatConversation}>
            <img src={this.conditionRemover(checked,chatIcon,lightChatIcon)}/>
            </Box>
           {userProfile.attributes.is_following&&<Box onClick={this.handleNotificationPreference} className={this.conditionRemover(checked,"icons","iconsLight")}>
              <img src = {this.conditionRemover(checked,NotificationDark,Notification)}/>
            </Box>}

            {userProfile.attributes.is_following&&
              <Box 
                onClick={this.handleShowUnfollowConfirmModal}
                className={this.conditionRemover(userProfile.attributes.is_following,"gradientIcon",this.conditionRemover(checked,"icons","iconsLight"))}>
                <img src = {this.conditionRemover(userProfile.attributes.is_following,followIcon ,this.conditionRemover(checked,followedDark,followIcon))}/>
              </Box>  
            }
            
            {!(userProfile.attributes.is_following&&userProfile.attributes.is_subscribed)&&<Box>
              <Button 
                onClick={!userProfile.attributes.is_following ? this.handleSubscriberFollow: this.goBuySubscription}
                className="gradientButton" >
                  {this.conditionRemover(!userProfile.attributes.is_following,"follow", "subscriber")}
                  </Button>
            </Box>}

        </Box>
      )
    }



    renderOtherUserButtonWithoutSubscribe=()=>{
      const {checked} = this.props
      const userProfile = this.state.userProfile as IUserProfile
      return(
        <Box className="buttonContainer">
          <Box
            className={this.conditionRemover(checked, "icons", "iconsLight")}
            data-test-id="btnNavigateChat"
            onClick={this.handleNavigateToChatConversation}
          >
              <img src = {this.conditionRemover(checked,chatIcon,lightChatIcon)}/>
            </Box>
           {userProfile.attributes.is_following&&
           <Box data-testid="notification" onClick={this.handleNotificationPreference} className={this.conditionRemover(checked,"icons","iconsLight")}>
              <img src = {this.conditionRemover(checked,NotificationDark,Notification)}/>
            </Box>
            }
            <Box>
              <Button 
              data-testid="followbtn"
              onClick={this.handlefollowUnfollow}
              className={this.conditionRemover(userProfile.attributes.is_following,"gradientBorderButton","gradientButton")} >
                {this.conditionRemover(userProfile?.attributes.is_following,"following",'follow')}
              </Button>
            </Box>
        </Box>
      )
    }


    renderReportModal=()=>{
      return(
        <ReportUserModal 
        navigation={this.props.navigation} 
        id={''}  
        showReportPostModal={this.state.showReportPostModal} 
        reportPostMainList={this.state.reportPostMainList}
        reportPostSubList={this.state.reportPostSubCatList} 
        setReportPostMainCat={this.setReportPostIssue} 
        setReportPostSubCat={this.setReportPostSubIssues}
        closeReportPostModal={this.closeReportPostModal}
        changeReportView={this.changeReportView}
        reportPostLoading={false} 
        reportCurrentView={this.state.reportCurrentView} 
        reportCatId={this.state.reportIssue} 
        reportSubCatId={this.state.reportSubIssue}
        checked={this.props.checked}   
        data-testid="ReportPost"     
        />
      )
    }
    

    renderMuteModal=()=>{
      return(
        <MuteModal 
        navigation={this.props.navigation} 
        id={''} 
        activeUser={this.state.activeUser} 
        data-testid="MuteModal"
        onMuteModelClose={this.handleMuteModelClose} 
        onMuteUser={this.handleMuteModel} 
        checked={this.props.checked} 
        bottompoper={this.state.bottompoper}
        onUnmuteUser={this.handleUnmuteUser}
        onButtompoperlClose={this.handleButtompoperlClose}
        muteModel={this.state.muteModal} />
      )
    }
    
    renderButtons=()=>{
      if(this.state.isLoggedUser){
       return this.renderLoggedUserButtons()
      }
      else if(this.state.userProfile?.attributes.is_blocked){
        return this.renderBlockedAccountButton()
      }
      else{
       return this.renderOtherUserButton()
      }
    }

    renderRightPortion=()=>{
      const {checked} = this.props
      return(
        <Box className='rightPortion'>
            <Box className={"sideTop"}>
          <TextField
           variant="outlined"
            placeholder="Search"
            className={"searchField"}
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={this.conditionRemover(checked,searchDark,searchLight)} alt="" />
              </InputAdornment>
            ),
          }}
         />

         <img src={this.conditionRemover(checked,callDark,callLight)} alt="" style={{cursor: "pointer"}} onClick={() => this.props.navigation.navigate("CallRecording")}/>
         </Box>

          <Box style={{display:"flex",flexDirection:"column",rowGap:"18px"}}>
          <Box className={this.conditionRemover(checked,"subscriptionBoxLight","subscriptionBox")}>
            <Typography className={"name"}>Subscribe to Premium</Typography>
            <Typography className={"subcriptionBody"}>Subscribe to unlock new features and if eligible, receive a share of ads revenue.</Typography>
            <Button className={"continueBtn"}>Continue</Button>
          </Box>

          <Box className={"whathappiningBox"} style={{border:this.conditionRemover(checked,"0.5px solid #222222","0.5px solid #1D1D1D")}}>
             <Typography className={"name"} style={{whiteSpace:"wrap"}}  >Whats Happening</Typography>
             {["","","",""].map((trend)=><Box style={{display:"flex",flexDirection:"column",rowGap:"4px"}}>
              <Typography className={"subcriptionBody"}>Trending in India</Typography>
               <Typography className={"name"}>#shardot</Typography>
               <Typography className={"subcriptionBody"}>19.5k posts</Typography>
            </Box>)}
            <Typography className={"showmore"}>Show more</Typography>

          </Box>

          <Box className={"whathappiningBox"} style={{border:this.conditionRemover(checked,"0.5px solid #222222","0.5px solid #1D1D1D"),maxHeight:"299px"}} >
             <Typography className={"name"}>Who to follow</Typography>
   
             <Box className={"followScreen"} >            
          {followers.map((follower:{name:string,username:string})=>{
           return  <Box style={{display:"flex",justifyContent:"space-between"}}>
              <Box  style={{display:"flex",gap:"5px",alignItems:"center"}}>            
                 <img
                    src={avatar}
                    alt="img"
                    className={"postImage"}
                  />
              <Box className={"headconatin"}>                  
                  <Box className={"ContentHead"}>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={"name"}>{follower.name}</Typography>
                      <img src={verifyIcon} alt="" />                      
                    </Box>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={"userName"}>{follower.username} </Typography>
                    </Box>
                  </Box>                  
              </Box>
            </Box>
            <Button className={"followBtn"}>Follow </Button>
            </Box>             
          })}            
             </Box>   

          </Box>
          </Box> 
            </Box>
      )
    }


    renderSelectedRepost=()=>{
      return(
        <>
        {this.state.selectedPostComment && (
              <SelectRepostModal
                open={this.state.showRepostModal}
                onClose={this.handleCloseRepostModal}
                onRepost={this.handleRepost}
                onQuote={this.handleQuote}
                onUndoRepost={this.handleUndoRepost}
                isUndoRepost={this.state.selectedPostComment.attributes.is_reposted}
              />
            )}
        </>
      )
    }

    // Customizable Area End 
    render() {
      // Customizable Area Start 
      const {checked} = this.props
      const {selectedPostComment,showQuote}=this.state
      const TabItem = {tabItem:'Profile'}
      const NavigationTab = this.state.isLoggedUser? TabItem :{}  
      // Customizable Area End
      return (
        // Customizable Area Start 
         <NavigationMenu id='' navigation={this.props.navigation} checked={this.props.checked} {...NavigationTab} >
          <BoxContainer>
          {selectedPostComment && showQuote ? (
              <Box className={"centerQuote"} style={{overflow: 'scroll'}}>
                <CreateRepost 
                  postData={selectedPostComment} 
                  data-testid="createRepost"
                  onCloseQuote={this.handleCloseQuote} 
                  onGoBackAfterSubmit={this.handleGoBackAfterSubmit}
                />
              </Box>
            ):
             <Box className='centerPortionWithTopNavition'>
               <Loader loading={this.state.loading} />
           {this.state.userProfile&&
            <div >  
                <Box className='TopNavigation'>
                  <Box><img className='topIcon' onClick={this.goBack} src={this.conditionRemover(checked, darkArrowIcon,ArrowIcon)} /></Box>
                   <Box onClick={this.openMenu}><img className='topIcon' src={this.conditionRemover(checked,MenuDark,MenuLight)} data-testid="Menu_icon"  /></Box>
                </Box>  

              <div className='centerContanier' ref = {this.scrollParentRef}>
            <div className='centerPortion'>
                 <Box className='userProfile'>
                <Box className='BackCover'>
                    <img src={this.state.userProfile.attributes.cover_photo || backCover} className='backCoverImage'/>
                </Box>
                <Box className='userProfileBox'>
                    <Box className='userProfileImageContainer'>
                     <Box className='AvatarImage'>
                       <img src={this.state.userProfile.attributes.profile_photo || defaultUserPic} className='AvatarStyle'/>
                     </Box>
                     {this.renderButtons()}
                    </Box>
                    <Box className='userDetails'>
                    <Box className='userInformation'>
                        <Box className="fullNameStyle">{this.state.userProfile.attributes.full_name}</Box>
                        <Box className="usernameStyle">{this.state.userProfile.attributes.user_name}</Box>
                        <Box> {this.renderVerifyIcon(this.state.userProfile.attributes)} </Box>
                        <Box> {this.renderOwnershipIcon(this.state.userProfile.attributes)} </Box>
                     </Box>
                     <Box className='location_work_info'>
                        {this.state.userProfile.attributes.occupation&&<Box className='info_container'> 
                           <Box> <img src={this.conditionRemover(this.props.checked,enterprenuarshipDark,enterprenuarship)}/> </Box>
                           <Box><Typography className='userConnectDetail' >{this.state.userProfile.attributes.occupation}</Typography> </Box>
                        </Box>}
                        {this.state.userProfile.attributes.location&&<Box className='info_container'> 
                           <Box> <img src={this.conditionRemover(this.props.checked,locationIconDark,locationIcon)}/> </Box>
                           <Box><Typography className='userConnectDetail'>{this.state.userProfile.attributes.location}</Typography></Box>
                        </Box>}
                       {this.state.userProfile.attributes.website&&<Box className='info_container'> 
                           <Box> <img src={this.conditionRemover(this.props.checked,globalIconDark,globalIcon)}/> </Box>
                           <Box><Typography className='userConnectDetail'>{this.state.userProfile.attributes.website}</Typography></Box>
                        </Box>}
                     </Box>
                     <Box className='userDescription'>
                       <Typography className='userDescription-paragraph'>{this.state.userProfile.attributes.bio}</Typography>
                     </Box>
                     </Box>

                     <Box className='followers_count_container'>
                       <Box data-testid="follower" onClick={()=>this.handleGetFollowerList(false)} ><Typography className='follow_text_1'>{this.state.userProfile.attributes.followers_count} Followers</Typography></Box> 
                       <Box  data-testid="following" onClick={()=>this.handleGetFollowerList(true)}><Typography className='follow_text_1'>{this.state.userProfile.attributes.following_count} Following</Typography></Box> 
                       <Box><Typography className='follow_text'> {moment(this.state.userProfile.attributes.created_at).format("MMMM yyyy")}</Typography></Box> 
                     </Box>
                </Box>
                </Box> 
                {this.renderHighlights()}
                {this.renderTablist()}
                {this.state.activeIndex === "Arrows" && this.renderPostList()}
                {this.state.activeIndex === "Replies" && this.renderPostList()}
                {this.state.activeIndex === "Likes" && this.renderPostList()}
                {this.state.activeIndex === "Subscriber" && this.renderSubscribedUserList()}
            </div>
            </div>
            </div>
            }
            </Box>
          }
           {this.renderRightPortion()}

            {this.ReporOthertUser()}
            {this.renderMuteModal()}
            {this.renderReportModal()}
            {this.renderCommentModal()}
            {this.renderEditProfileModal()}
            {this.renderNotificationPreference()}
            {this.renderFollowerList()}
            {this.renderCreateHighlightStory()}
            {this.renderPinPostModal()}
            {this.renderDeletePost()}
            {this.renderHighlightedStories()}
            {this.renderRepostModal()}
            {this.renderCommentSettingModal()}
            {this.renderArchivedStoryModal()}
            <Share data-testid="share" Url={this.state.shareProfileUrl} open={this.state.openShare} onClose={this.onCloseShare} navigation={this.props.navigation} id={''} checked={this.props.checked}/>
            <ConfirmModal
              open={this.state.showUnfollowConfirmModal}
              title={`Unfollow ${this.state.userProfile?.attributes.user_name}`}
              description={`Are you sure you want to unfollow ${this.state.userProfile?.attributes.user_name}`}
              cancelText="No"
              confirmText="Yes"
              onConfirm={this.handlefollowUnfollow}
              onClose={this.handleCloseUnfollowConfirmModal}
            />
            <PlanProfileSubscription
              isOpen={this.state.isShowModalPlan}
              isEditPlan={Boolean(this.state.userProfile?.attributes.subscription_id)}
              statusStripeConnect={this.state.userProfile?.attributes.stripe_account_connected}
              planPrice={this.state.planPrice}
              handleCloseModal={this.handleResetPlanSubscription}
              onDeletePlan={this.handleSetModalDeletePlan}
              onNextButton={this.handleNextButtonPlan}
              onChangePlanPrice={this.handleChangePricePlan}
            />
            <ModalAction 
              title='Delete Plan'
              isOpen={this.state.isShowModalDeletePlan}
              dialogContent={this.DialogContentDeletePlan()}
              actionDialog={this.DialogActionDeletePlan()}
              onSetModal={this.handleSetModalDeletePlan}
            />
          </BoxContainer>
         </NavigationMenu>
        // Customizable Area End
      );
    }
  }



  // Customizable Area Start 

  const CustomPopover=styled(Popover)({
    "& .MuiPaper-elevation8-53":{
       boxShadow:'none'
    },

    "& .MenuItem":{
    width: "256px",
    height:"32px",
    display:'flex',
    padding:'9px 20px 9px 16px',
    alignItems:'center',
    cursor:'pointer',
    gap:"8px",
  },
  "& .item_list_name":{
    fontSize:"14px",
    fontWeight:500,
    fontFamily:'Manrope',
   }
  })
  const BoxContainer = styled(Box)({
    display:"flex",

    "& .centerQuote":{
      display: "flex",
      width: "60%",
      maxWidth: "810px",
      flexDirection: "column" as "column",
      rowGap: "24px",
      padding: "20px",
      height: "calc(100vh - 20px)",
      "@media (max-width:1000px)": {
        width: "100%",
      },
    },

    "& .centerPortionWithTopNavition":{
      display:'flex',
      width:'60%',
      height:'100vh',
      flexDirection:'column',
      maxWidth:'810px',
      "@media (max-width:1000px)": {
        width:'100%'
       }
    },

    "& .TopNavigation":{
      display:'flex',
      flexDirection:'row',
      top:1,
      zIndex:1000,
      maxWidth:" 810px",
      height:84,
      padding:"0px 20px",
      justifyContent:'space-between',
      alignItems:'center',
    },

    "& .centerContanier":{
      maxHeight: "calc(100vh - 84px)",
      height:'100%',
      overflowY:'scroll',
      scrollbarWidth:'none',
      padding:" 0 20px 0px 20px",
      maxWidth:" 810px",
    },
    "& .centerPortion":{
        display: "flex",
        rowGap: "24px",
        scrollbarWidth:'none',
        flexDirection: "column",
    },
    "& .topIcon":{
        padding:"10px 12px",
       "&:hover":{
        opacity:0.7,
        background:'grey'
       }
    },
    "& .rightPortion":{
        height:"100vh",
        paddingTop:'20px',
        maxWidth:"510px",
        overflow:"scroll" as "scroll",
        width:"40%",
        paddingRight:"98px",
        scrollbarWidth: "none" as "none",
        "&::-webkit-scrollbar": {
          display: "none" as "none",
        },
        "@media (max-width:1000px)": {
          display:"none" as "none"
        },
    },
    "& .sideTop":{
        display:"flex",
        gap:"21px",
        marginBottom:"28px"
      },

    "& .postDisplay": {
        padding: "8px 0px",
        display: "flex",
        flexDirection: "column" as "column",
        gap: "20px"
    },

    "& .searchField":{
        '& .MuiOutlinedInput-root': {
          borderRadius: "10px", 
          '&.Mui-focused fieldset': {
            border :"1px solid #7A2BF5",       
          },
        },
        width:"80%"
      },  
      

    "& .subscriptionBoxLight":{
        borderRadius:"12px",
        padding:"12px 20px 12px 20px",
        gap:"12px",
        display: "flex",
        flexDirection: "column" as "column",
        border: "0.5px solid #222222"
    },
    "& .subscriptionBox":{
        borderRadius:"12px",
        padding:"12px 20px 12px 20px",
        gap:"12px",
        display: "flex",
        flexDirection: "column" as "column",
        border: "0.5px solid #1D1D1D",  
      },
    
    "& .subscrptiontext":{
        fontSize:"18px",
        fontFamily:"Manrope",
        fontWeight:800, 
        lineHeight:"21px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent' as "transparent"
    }, 
    "& .subcriptionBody":{
        fontSize:"14px",
        fontFamily:"Manrope",
        fontWeight:500,
        lineHeight:"21px",
    },
    
    "& .showmore":{
        fontSize:"14px",
        fontFamily:"Manrope",
        fontWeight:500,
        lineHeight:"21px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    },

    "& .name":{
        fontSize:"18px",
        fontFamily:"Manrope",
        fontWeight:800,
        lineHeight:"21px",
        whiteSpace:"nowrap" as "nowrap",
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        }
    },

    "& .whathappiningBox":{
        borderRadius:"12px",
        padding:"12px 20px 12px 20px",
        gap:"12px",
        display: "flex",
        flexDirection: "column" as "column"
    },

    "& .followScreen":{
        display:"flex",
        flexDirection:"column" as "column",
        rowGap:"12px",
        height:"270",
        overflowY:"scroll" as "scroll",
        padding: "12px",
      },
    
    "& .continueBtn":{
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        Padding:"6px 20px 6px 20px",
        gap:"7px",
        borderRadius:"10px",
        display:"flex",
        width:"35%",
        color:"white",
        fontSize:"12px",
        fontFamily:"inter",
        fontWeight:500,
        textTransform:"none" as "none",
    },  

    "& .userProfile":{
       width:'100%',
       position:'relative',
    },

    "& .BackCover":{
        width:"100%",
        height:"229px",
    },

    "& .backCoverImage":{
        objectFit:'cover',
        width:"100%",
        height:'100%'
    },

    "& .userProfileBox":{
      backgroundColor: '#D9D9D933',
      marginLeft:"12px",
      background:'transparent',
      marginRight:'12px',
      width:'calc(100% - 24px)',
      border:'1px solid #fffff',
      borderRadius:'14px',
      backdropFilter:'blur(32px)',
      marginTop:'-60px'
    },
    
    "& .AvatarImage":{
      height:62,
      width:62,
      marginTop:'-24px',
      borderRadius:'50%',
      marginLeft:'14px',
      backgroundClip: "padding-box, border-box",
      border :"3px solid transparent",
      backgroundOrigin: "padding-box, border-box",
      background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,

    },

    "& .AvatarStyle":{
        height:62,
        width:62,
        borderRadius:'50%',
        objectFit:'cover'
      },


    "& .userProfileImageContainer":{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
    },

    "& .loader": {
      display: "flex",
      width: "100%",
      justifyContent: "center" as "center"
    },
    
    "& .buttonContainer":{
      marginRight:'8px',
      display:'flex',
      gap:'5px'
    },

    "& .Icons":{
        background:'#E9E9E9',
        padding:"2px",
        height:24,
        width:24,
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        borderRadius:"50%",
        cursor: "pointer"
    },


    "& .IconsLight":{
        background:'#222222',
        padding:"2px",
        height:24,
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        width:24,
        borderRadius:"50%",
        cursor: "pointer",
    },

    "& .IconImage":{
        height:18,
        width:18
    },

    "& .gradientIcon":{
        background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        padding:"2px",
        height:24,
        width:24,
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        borderRadius:"50%"
    },

    "& .gradientBorderButton":{
        minWidth:'70px',
        height:'24px',
        padding:"6px 10px",
        textAlign:'center',
        width:'100%',
        borderRadius:'5px',
        textTransform:'capitalize',
        color:'#fff',
        fontFamily:'Manrope',
        fontWeight:700,
        position: 'relative',
        zIndex: 0,
        "&::before":{
          aspectRatio: 1,
          height:24,
          content: "''",
          position: 'absolute',
          zIndex: -1,
          gap:'6px',
          padding: '1px',
          minWidth:'70px',
          borderRadius:'5px',
          width: '100%',
          background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
          webkitMask:  'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          webkitMaskComposite: 'xor',
          maskComposite: 'exclude',
        }
    },

    "& .gradientButton":{
        padding:"6px 10px",
        height:24,
        gap:'6px',
        fontFamily:'Manrope',
        fontSize:12,
        borderRadius:'5px',
        textTransform:'capitalize',
        fontWeight:700,
        color:'#fff',
        minWidth:'70px',
        background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)'
    },
    "& .blockedButton":{
        padding:"6px 10px",
        height:24,
        gap:'6px',
        fontFamily:'Manrope',
        fontSize:12,
        borderRadius:'5px',
        textTransform:'capitalize',
        border: "1px solid red",
        fontWeight:700,
        color: "red",
        minWidth:'70px',
    },

    "& .userInformation":{
        display:'flex',
        position:'unset',
        alignItems:'center',
        justifyContent:'left',
        height:"unset",
        gap:'5px',
        paddingTop:'5px',
        margin:'unset',
    },

    "& .fullNameStyle":{
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 800,
        textAlign: "left",
    },

    "& .usernameStyle":{
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 500,
        color:'unset',
        textAlign: "left",
    },

    "& .userDescription-paragraph":{
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 500,
        textAlign: "left",
    },

    '& .userDetails':{
     padding:'0px 20px',
     display:'flex',
     flexDirection:'column',
     gap:'5px'
    },

    "& .location_work_info":{
        margin:'5px 0px',
        flexWrap:"wrap",
        width:'100%',
        display:'flex',
        columnGap:'20px'
    },

    "& .info_container":{
        display:'flex',
        columnGap:'5px',
        alignItems:'center',
        justifyContent:'center',
       
    },

    "& .userConnectDetail":{
        fontFamily: 'Manrope',
        fontSize: '12px',
        fontWeight: 500,
        textAlign: 'left',
    },

    "& .followers_count_container":{
        display:'flex',
        padding:20,
        columnGap:'49px',
        flexWrap:'wrap',
        width:'100%',
    },

    "& .follow_text":{
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 500,
        textAlign: 'left',
    },
    "& .follow_text_1":{
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 500,
        cursor:'pointer',
        textAlign: 'left',
    },

    "& .postImage":{
        height:"35px",
        width:"35px",
        borderRadius:"50%",
        objectFit:"cover" as "cover"
    },

    "& .ContentHead":{
        display:"flex",
        flexDirection:"column" as "column",
        gap:"5px",
    },

    "& .headconatin":{
        display:"flex",
        width:"100%",
        justifyContent:"space-between"
    },

    "& .followBtn":{
        width:"65px",
        height:"25px",
        borderRadius:"10px",
        padding:"4px 8px 4px 8px",
        gap:"7px",
        fontSize:"12px",
        fontFamily:"Manrope",
        color:"white",
        fontWeight:500,
        textTransform:"capitalize" as "capitalize",
        background:"var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
      },

      '& .postList':{
        width:"100%",
        marginTop:20,
        minHeight:200
      },
   
      "& .tabstyle": {
        fontFamily: "Manrope",
        fontWeight: 600,
        fontSize: "16px",
        minWidth:'unset !important',
        width:'25%',
        textTransform: "capitalize" as "capitalize",
        borderBottom: "3px solid #35383F",
        '&.Mui-selected': {
          background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent' as "transparent",
        },
       
        '@media(max-width:1000px)':{
          width:'50%'
        },
      },
      "& .indicator": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        height: '2.9px',
      },

      "& .tabsContainer": {
        height: "35px",
        width:'100%',
      },

      "& .hightlightStoryContainer":{
        margin:12,
        display:'flex',
        flexDirection:'row',
         minHeight:84,
         overflowX:'scroll',
         overflowY:'hidden',
        scrollbarWidth:'none',
        gap:20
      },

      "& .story":{
        display:'flex',
        flexDirection:'column',
        width:65,
        gap:5
      },

      "& .storyImage":{
        border: '2px solid #6B6B6B',
        width: '64px',
        height: '64px',
        borderRadius: '50px'
      },

      "& .storyImageContainer":{
        cursor:'pointer'
      },

      "& .AddStoryContainer":{
        width: '64px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height: '64px',
        borderRadius: '50px',
        border: '2px solid #fff',
        cursor:'pointer',
        background:'#6B6B6B'
      },

      "& .story_Text":{
        fontFamily: 'Manrope',
        fontSize: '10px',
        fontWeight: 500,
        width: "100%",
        textOverflow:'ellipsis',
        overflow:'hidden',
        lineHeight: '12px',
        textAlign: 'center',
      }
  })
  // Customizable Area End
  