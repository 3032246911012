// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import "firebase/messaging";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  checked?:boolean
  classes:{[key:string]:string}
}

interface PaginationDetails {
  page_number: number;
  records_in_this_page: number;
  records_per_page: number;
  total_pages: number;
  total_records: number;
}

export interface  Story  {
  id: number;
  body: string;
  media: {
    id: number;
    url: string;
    filename: string;
    content_type: string;
  };
  created_at: string;
  is_viewed: boolean;
  is_liked:boolean|null;
  hash_tags: string[];
  body_mentions: string[];
  livestream_session: {
    channel_name: string,
    chatroom_id: string | null,
  }
};

export interface  Account  {
  id: number;
  type: string;
  attributes: {
    id: number;
    full_name: string;
    user_name: string;
    email: string | null;
    full_phone_number: string;
    country_code: string;
    phone_number: number;
    bio: string | null;
    location: string | null;
    website: string | null;
    occupation: string | null;
    created_at: string;
    is_early_adopter: boolean;
    date_of_birth: string | null;
    admin_verification: boolean;
    profile_photo: string | null;
    cover_photo: string | null;
    is_following: boolean;
    is_follower: boolean;
    is_muted: boolean;
    is_blocked: boolean;
    following_count: number;
    followers_count: number;
    user_subscription: string | null;
    user_ownership: boolean;
    is_tailored: boolean;
    stories_count: number;
    viewed_stories: number;
    stories: Story[];
  };
};
interface Media {
  id: number;
  url: string;
  filename: string;
  content_type: string;
}

export interface OwnerStory {
  id: string;
  type: string;
  attributes: {
  id: number;
  body: string;
  media: Media;
  created_at: string;
  is_viewed: boolean;
  hash_tags: string[];
  body_mentions: string[];
  view_count: number;
  livestream_session: {
    channel_name: string,
    chatroom_id: string | null,
  }
  };
}

interface Viewer {
  id: number;
  full_name: string;
  user_name: string;
  email: string;
  full_phone_number: string;
  country_code: string;
  phone_number: number;
  bio: string;
  location: string | null;
  website: string | null;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string | null;
  admin_verification: boolean;
  profile_photo: string;
  cover_photo: string | null;
  is_following: boolean;
  is_follower: boolean;
  is_muted: boolean;
  is_blocked: boolean;
  following_count: number;
  followers_count: number;
  user_subscription: string;
  user_ownership: boolean;
  is_tailored: boolean;
  stories_count: number;
  viewed_stories: number;
}

export interface StoryViewer {
  id: string;
  type: string;
  attributes: {
  viewed_at: string;
  is_liked: boolean;
  viewer: Viewer;
  };
}


interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  token:string;
  email:string;
  pass:string;
  fcmToken1:string;
  isTokenFound:boolean;
  notificationTitle:string;
  notificationBody:string;
  selectedItem:number
  userStories:Account[],
  isModel:boolean,
  replyMessage:string,
  progress:number,
  timer:any,
  isPlaying:boolean,
  childIndex:number,
  showFullText:boolean,
  isMuted:boolean,
  isOwnerModel:boolean,
  ownerStories:OwnerStory[],
  showViewer:boolean,
  viewerList:StoryViewer[],
  page:number,
  viewerPagination:PaginationDetails,
  viewerType:number,
  userInfo:any,
  isLoading:boolean,
  isDisabled:boolean,
  loggedUserAvatar: string | null
}

interface SS {
  id?: any;
}

export default class BroadcastMessageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getNotifyApiCallId:string="";
  apiEmailLoginId:string="";
  getStoriesApiCallId:string ="";
  seenStoriesApiCallId:string="";
  seenOwnerStoriesApiCallId:string="";
  getOwnerStoriesApiCallId:string="";
  likeStoryApiCallId:string="";
  UnlikeStoryApiCallId:string=""
  getLoggedUserApiCallId:string=""
  scrollViewerStoryParentRef = React.createRef<HTMLDivElement>();
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token: "",
      fcmToken1:"",
      email:"",
      pass:"",
      isTokenFound:false,
      notificationTitle:"",
      notificationBody:"",
      selectedItem:0,
      isModel:false,
      replyMessage:"",
      userStories:[],
      progress:0,
      timer: null,
      isPlaying:true,
      childIndex:0,
      showFullText:false,
      isMuted:true,
      isOwnerModel:false,
      ownerStories:[],
      showViewer:false,
      viewerList:[],
      page:1,
      viewerPagination:{
        page_number: 0,
        records_in_this_page: 0,
        records_per_page: 0,
        total_pages: 0,
        total_records: 0        
      },
      userInfo:{},
      viewerType:0,
      isLoading:true,
      isDisabled:false,
      loggedUserAvatar:  null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) 
    {//istanbul ignore next
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      if (!responseJson.errors){
        this.handleGetStoriesRes(from, message)
        this.handleSeenStoryRes(from, message)
        this.handleOwnerStorySeenRes(from, message)
        this.handleOwnerStoryRes(from, message)
        this.handleLikesStoryRes(from, message)
        this.handleLoggedUserRes(from,message)
        this.handleUnLikesStoryRes(from, message)        
      }     
    }
  }

 
  async componentDidMount() {
    const selectedUser = JSON.parse(localStorage.getItem('selectedUser') || '')
    const profilePhoto = selectedUser?.profile_photo
    this.setState({ loggedUserAvatar: profilePhoto })

    await this.loggedUser()
    await this.getOwnerStories()
    await this.getStories() 
       
   }  

  handleGetStoriesRes =(from: string, message: Message) =>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (apiRequestCallId === this.getStoriesApiCallId && responseJson.data){
      this.setState({userStories:responseJson.data,isLoading:false})
    }

  } 


  handleLoggedUserRes =(from: string, message: Message) =>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (apiRequestCallId === this.getLoggedUserApiCallId && responseJson.data){
      this.setState({userInfo:responseJson.data.attributes})
    }
  }
  

  handleOwnerStoryRes=(from: string, message: Message) =>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (apiRequestCallId === this.getOwnerStoriesApiCallId && responseJson.data){
        this.setState({ownerStories:responseJson.data})
    }

  }

  handleLikesStoryRes =(from: string, message: Message) =>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (apiRequestCallId === this.likeStoryApiCallId && responseJson.messages){
      this.setState(prevState => {
        const updatedData = [...prevState.userStories];
        const selectedStory = updatedData[this.state.selectedItem].attributes.stories[this.state.childIndex];
        selectedStory.is_liked = true;  
        return { userStories: updatedData };
      });      
    }

  }

  handleUnLikesStoryRes =(from: string, message: Message) =>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (apiRequestCallId === this.UnlikeStoryApiCallId && responseJson.messages){
      this.setState(prevState => {
        const updatedData = [...prevState.userStories];
        const selectedStory = updatedData[this.state.selectedItem].attributes.stories[this.state.childIndex];
        selectedStory.is_liked = false;
  
        return { userStories: updatedData };
      });   
    }

  }

  

  

  handleOwnerStorySeenRes=(from: string, message: Message) =>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (apiRequestCallId === this.seenOwnerStoriesApiCallId && responseJson.data){
       this.setState({viewerList:[...this.state.viewerList,...responseJson.data],
        viewerPagination:responseJson.pagination_details,page:this.state.page+1})
    }
  } 

  handleSeenStoryRes=(from: string, message: Message) =>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (apiRequestCallId === this.seenStoriesApiCallId && responseJson.data){
      let stories= [...this.state.userStories]

      const targetObject = stories[this.state.selectedItem];
  
      if (targetObject) {
        const storyIndex = targetObject.attributes.stories.findIndex(story => story.id == responseJson?.data?.id );

        targetObject.attributes.viewed_stories += 1;

        if (storyIndex !== -1) {
          targetObject.attributes.stories[storyIndex] = {
            ...targetObject.attributes.stories[storyIndex],
            is_viewed: true,
          };         
        }
      }
      this.setState({userStories:stories})  
    }

  } 

 handlelClose =()=>{
  this.setState({isModel:false,isPlaying:true,childIndex:0,isMuted:true,isOwnerModel:false,selectedItem:0},()=>{
    this.resetProgress();
  })
 }

 handlelViewerClose =()=>{
  this.setState({showViewer:false,page:1,viewerList:[],viewerType:0,viewerPagination:{ page_number: 0,
    records_in_this_page: 0,
    records_per_page: 0,
    total_pages: 0,
    total_records: 0}})
  this.togglePlayPause()
 }

 handleChangeMessage =(event:any) =>{
  if(event.target.value){
  this.setState({replyMessage:event.target.value},()=>{
    this.pauseProgress()
 })}else{
  this.setState({replyMessage:event.target.value,isPlaying: true},()=>{
     this.startProgress()
  })
 }}

 handleSendMessage =()=>{
  this.setState({replyMessage:"",isPlaying: true},()=>{
    this.startProgress()
  })
 }

 setShowFullText =()=>{
  this.setState({showFullText:!this.state.showFullText})
 }

 handleLikeStory= async(id:number)=>{
  let token = await getStorageData("authToken")
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token:token,
  };

  let httpBody ={
   likeable_id:id,
   likeable_type:"story"   
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.likeStoryApiCallId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.likeStoriesAPiEndPoint);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
  runEngine.sendMessage(requestMessage.id, requestMessage)    
 }

 handleUnLikeStory  = async(id:number)=>{
  let token = await getStorageData("authToken")
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token:token,
  };
 

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  let httpBody ={
    likeable_id:id,
    likeable_type:"story"   
   }

  this.UnlikeStoryApiCallId = requestMessage.messageId;
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.likeStoriesAPiEndPoint);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAPiMethod);
  runEngine.sendMessage(requestMessage.id, requestMessage)    
 }

 getHoursDifference = (dateString:string) => {    
    const createdAt:any = new Date(dateString);
    const now:any= new Date();
    const diffInMilliseconds = now - createdAt;
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    return diffInHours < 1 ? "just now" : `${diffInHours} h`;  
};

handleSeenStories =async() =>{
  let token = await getStorageData("authToken")
  let user_details =  this.state.userStories[this.state.selectedItem]
  let story_details =  user_details?.attributes?.stories[this.state.childIndex] 
  if(!story_details.id || story_details.is_viewed) {
    return
  }
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token:token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.seenStoriesApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getStoriesAPiEndPoint}/${story_details?.id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

handleSeenOwnStories =async() =>{
  let token = await getStorageData("authToken")
  let user_details =  this.state.ownerStories[this.state.selectedItem]
  if(!user_details.id) {
    return
  }
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token:token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.seenOwnerStoriesApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getownSennStoriesAPiEndPoint+`?id=${user_details?.id}&page=${this.state.page}&_limit=15&sort_order=desc`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

loggedUser = async() =>{
  let token = await getStorageData("authToken")
  const header = {
    "Content-Type": configJSON.apiContentType,
    token: token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getLoggedUserApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.loggedUserEndpoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}


handleView=()=>{
  this.pauseProgress()
  this.handleSeenOwnStories()
  this.setState({showViewer:true})
}

muteHandler =()=>{
  this.setState({isMuted:!this.state.isMuted})
}

 handleOpen =(val: Account,index:number)=>{
  const channelName = val.attributes.stories[0]?.livestream_session?.channel_name
  const chatRoomId = val.attributes.stories[0]?.livestream_session?.chatroom_id

  if (channelName) {
    return this.props.navigation.navigate("LiveStreaming", {
      channelName,
      chatRoomId,
      role: "audience",
      ownerId: val.attributes.id,
      storyId: val.id
    });
  }
  
  this.setState({isModel:true,selectedItem:index},()=>{
    if (this.state.isPlaying) {
      this.startProgress();
      this.handleSeenStories()
    }
  })
 } 

 handleOpenOwner =() =>{
  if(this.state.ownerStories.length){
  this.setState({isOwnerModel:true})
  if (this.state.isPlaying) {
    this.startProgress();
  }
}
 }

 getStories =async() =>{
  let token = await getStorageData("authToken")
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token:token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getStoriesApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getStoriesAPiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 getOwnerStories =async() =>{
  let token = await getStorageData("authToken")
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token:token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getOwnerStoriesApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getOwnerStoriesAPiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 handlePrev =()=>{
  if (this.state.childIndex > 0) {
    setTimeout(()=>{
      this.setState({ isPlaying: true,childIndex:this.state.childIndex?this.state.childIndex-1:0,isMuted:true,showFullText:false,isDisabled:false},()=>{
        this.startProgress(); 
        this.handleSeenStories()
     }) 
    },500)     

  } else if(this.state.selectedItem>0){ 
  setTimeout(()=>{
    this.setState({ selectedItem:this.state.selectedItem?this.state.selectedItem-1:0,isPlaying: true,childIndex:0,isMuted:true,showFullText:false,isDisabled:false},()=>{
      this.startProgress(); 
      this.handleSeenStories()
   }) 
  },500) }
 }

handlePrevClick = () => {
  this.setState({isDisabled:true})
  this.resetProgress();   
  if(this.state.isModel){
   this.handlePrev()
}else{
  if (this.state.selectedItem > 0) {
    setTimeout(()=>{
      this.setState({ isPlaying: true,isMuted:true,selectedItem: this.state.selectedItem?this.state.selectedItem-1:0,showFullText:false,isDisabled:false},()=>{
        this.startProgress(); 
  
     }) 
    },500)    
  } 
}
  
};

handleNextClick = () => {
  this.setState({isDisabled:true})
  this.resetProgress();
  if(this.state.isModel){
  let user_details = this.state.userStories[this.state.selectedItem]
  let countOfStory = user_details.attributes.stories_count     

   if (this.state.childIndex < countOfStory-1) {
    setTimeout(()=>{
      this.setState({ isPlaying: true,childIndex:this.state.childIndex+1,isMuted:true,showFullText:false,isDisabled:false},()=>{
        this.startProgress(); 
        this.handleSeenStories();
     }) 
    },500)     

  } else if(this.state.userStories.length-1>this.state.selectedItem){ 
  setTimeout(()=>{
    this.setState({ selectedItem: this.state.selectedItem+1,isPlaying: true,showFullText:false,childIndex:0,isMuted:true,isDisabled:false},()=>{
      this.startProgress(); 
   }) 
  },500) }
  else{
    this.handlelClose()
  }
}else{
  if (this.state.selectedItem <this.state.ownerStories.length-1) {
    setTimeout(()=>{
      this.setState({ isPlaying: true,isMuted:true,selectedItem: this.state.selectedItem+1,showFullText:false,isDisabled:false},()=>{
        this.startProgress();   
       
     }) 
    },500)    
  } else{
    this.handlelClose()
  }
}
};


startProgress = () => {
  clearInterval(this.state.timer);
  if (this.state.isPlaying) {
  const interval = setInterval(() => {
    this.setState(prevState => ({
      progress: prevState.progress + (100 / 300),
    }), () => {
      if (this.state.progress >= 100) {
        clearInterval(this.state.timer);
        this.setState({ progress: 100},()=>{
          this.handleNextClick();
        })
        
      }
    });
  }, 100); 
  this.setState({ timer: interval });
}
};

pauseProgress = () => {
  clearInterval(this.state.timer);
  this.setState({ isPlaying: false });
};


togglePlayPause = () => {
  this.setState(prevState => {
    if (prevState.isPlaying) {
      this.pauseProgress();
    } else {
      this.setState({ isPlaying: true }, this.startProgress);
    }
  });
};

resetProgress = () => {
  clearInterval(this.state.timer);
  this.setState({ progress: 0 ,timer: null});
};

}

// Customizable Area End
