import React from "react";
// Customizable Area Start

import { Box, Button, DialogContent, DialogContentText, styled, Typography } from "@material-ui/core";

import MyPlatformSubscriptionController, {
  Props,
  // configJSON,
} from "./MyPlatformSubscriptionController";
import { checkedIcon, iconExpiryMessage } from "./assets";
import { NotInterestedOutlined } from "@material-ui/icons";
import CancelSubscription from "../../../blocks/utilities/src/components/CancelSubscription";
import ImageRenderer from "../../../components/src/ImageRenderer.web";
export default class MyPlatformSubscription extends MyPlatformSubscriptionController {
  constructor(props: Props) {
    super(props);
  }

  handleRenderButtonSubscription = (subscriptionId: string, status?: string) => {
    switch (status) {
      case "active": {
        return <Box
          data-test-id="cancelSubscriptionBtn"
          className="cancelSubscriptionBtn"
          onClick={() => this.handleCancelSubscription(subscriptionId)}
        >
          <NotInterestedOutlined style={{ width: 16, height: 16, color: "#DC2626" }} />
          <Typography>
            Cancel Subscription
          </Typography>
        </Box>
      }
      case "canceled": {
        return <Box className="cancelledSubscriptionBtn">
          <Typography>
            Canceled
          </Typography>
        </Box>
      }
      case "upcoming": {
        return <Box className="cancelledSubscriptionBtn" style={{ background: "#059669" }}>
          <Typography>
            Upcoming
          </Typography>
        </Box>
      }
      default: {
        return <Box
          className={`purchasePlanBtn ${this.state.listUpcomingSubscription.length && "disabledButton"}`}
          data-test-id="purchaseBtn"
          onClick={() => this.handlePurchaseSubscription(subscriptionId)}
        >
          <Typography>
            Purchase Plan
          </Typography>
        </Box>
      }
    }
  }

  DialogContentCancelSubscription = () => (
    <DialogContent>
      <DialogContentText>
        Are you sure you want to cancel your subscription?
      </DialogContentText>
      <br />
      <DialogContentText>
        Upon expiration of the subscription, you will lose access to the platform
      </DialogContentText>
    </DialogContent>
  )

  DialogActionCancelSubscription = () => (
    <Button className="dialogBtn cancelSubscriptionBtn" onClick={this.cancelSubscription}>
      Cancel Subscription
    </Button>
  )

  NoAnySubscription = (label?: string) => {
    const nameSubscription = label ? label + " " : " ";
    return <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Typography variant="h3" style={{ fontFamily: "Manrope", fontWeight: 600, fontSize: 16, color: "#FFF" }}>
        {`You don’t have any ${nameSubscription}subscriptions.`}
      </Typography>
    </Box>
  }

  ActiveSubscription = () => {
    const listAvailableSubscription = [...this.state.listActiveSubscription, ...this.state.listUpcomingSubscription];
    if (!listAvailableSubscription.length) {
      return this.NoAnySubscription("Active")
    }
    const getLableDateSubscription = (status?: string) => {
      switch (status) {
        case "active": {
          return "Renews";
        }
        case "canceled": {
          return "Expires on"
        }
        case "upcoming": {
          return "Starts on"
        }
      }
    }

    return (
      listAvailableSubscription.map(activeSubscription => (
        <Box key={activeSubscription.id} className="body-item item-active">
          <Box className="body-header">
            <Box className="header-title">
              <Typography className="planName">
                {activeSubscription.attributes.name} <span>({activeSubscription.attributes.validity_unit})</span>
              </Typography>
            </Box>
            <Box className="header-price">
              <Typography>
                {activeSubscription.attributes.price}
              </Typography>
            </Box>
          </Box>
          <Box className="body-content">
            {activeSubscription.attributes.features.map(feature => (
              <Box key={feature} className="content-item">
                <ImageRenderer src={checkedIcon} width={16} height={16} />
                <Typography variant="h6">
                  {feature}
                </Typography>
              </Box>
            ))}
            <Box className="body-button">
              {this.handleRenderButtonSubscription(activeSubscription.attributes?.subscription_id ?? activeSubscription.id, activeSubscription.attributes?.status)}
            </Box>
          </Box>
          <Box className="body-footer">
            <Typography>
              {getLableDateSubscription(activeSubscription.attributes.status)}: {activeSubscription.attributes.renewal_date || activeSubscription.attributes.start_on}
            </Typography>
          </Box>
        </Box>
      ))
    )
  }

  AvailableSubscription = () => {
    const listAvailableSubscription = this.state.listAvailableSubscription;
    if (!listAvailableSubscription.length) return this.NoAnySubscription("Available")
    return (listAvailableSubscription.map(availableSubscription => (
      <Box className="body-item">
        <Box className="body-header">
          <Box className="header-title">
            <Typography className="planName">
              {availableSubscription.attributes.name} <span>({availableSubscription.attributes.validity_unit})</span>
            </Typography>
          </Box>
          <Box className="header-price">
            <Typography>
              {availableSubscription.attributes.price}
            </Typography>
          </Box>
        </Box>
        <Box className="body-content">
          {availableSubscription.attributes.features.map(feature => (
            <Box key={feature} className="content-item">
              <ImageRenderer src={checkedIcon} width={16} height={16} />
              <Typography variant="h6">
                {feature}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box className="body-button">
          {this.handleRenderButtonSubscription(availableSubscription.id)}
        </Box>
      </Box>
    )))
  }

  render() {
    return (
      <PlatformSubscriptionStyled>
        <Box className={`container__expiryMessage ${!this.state.expiryMessage && "blockNone"}`}>
          <ImageRenderer src={iconExpiryMessage} width={28} height={28} />
          <Box className="description__expiryMessage">
            <Typography>
              {this.state.expiryMessage}
            </Typography>
          </Box>
        </Box>
        <Box className="listPlatformSubscription">
          <Box className="platformSubscription-item">
            <Box className="header">
              <Typography variant="h6">
                Active
              </Typography>
            </Box>
            <Box className="container-body">
              {this.ActiveSubscription()}
            </Box>
          </Box>
          <Box className="platformSubscription-item">
            <Box className="header">
              <Typography variant="h6">
                Available subscriptions
              </Typography>
            </Box>
            <Box className="container-body">
              {this.AvailableSubscription()}
            </Box>
          </Box>
        </Box>
        <CancelSubscription
          title={"Cancel Subscriptions"}
          isOpen={Boolean(this.state.currentSubscriptionId)}
          dialogContent={this.DialogContentCancelSubscription()}
          actionDialog={this.DialogActionCancelSubscription()}
          onClose={this.handleCloseModalCancelSubscription}
        />
      </PlatformSubscriptionStyled>
    );
  }
}

const PlatformSubscriptionStyled = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  height: "calc(100% - 55px)",
  "& .blockNone": {
    display: "none!important",
  },
  "& .disabledButton": {
    opacity: 0.5,
    pointerEvents: "none",
  },
  "& .container__expiryMessage": {
    display: "flex",
    alignItems: "center",
    background: "#6E1A1A66",
    borderRadius: 10,
    padding: 12,
    gap: 12,
    "& .description__expiryMessage": {
      "& p": {
        fontFamily: "Manrope",
        color: "#FFF",
        fontWeight: 700,
        fontSize: 14
      }
    }
  },
  "& .listPlatformSubscription": {
    overflowY: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 21,
    "& .platformSubscription-item": {
      display: "flex",
      flexDirection: "column",
      gap: 8,
      "& .header": {
        padding: "10px 0",
        "& h6": {
          fontFamily: "Manrope",
          fontWeight: 600,
          fontSize: "16px",
          color: "#FFF",
          lineHeight: "19px",
        }
      },
      "& .container-body": {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        "& .body-item.item-active": {
          border: "1px solid #792EED",
        },
        "& .body-item": {
          padding: '12px',
          borderRadius: '10px',
          position: "relative",
          border: "1px solid #57534E",
          display: "flex",
          flexDirection: "column",
          gap: 20,
          "& .body-header": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .header-title": {
              "& .planName": {
                fontFamily: "Manrope",
                fontWeight: 800,
                fontSize: 18,
                color: "#FFF",
                position: "relative",
                "& span": {
                  position: "absolute",
                  top: 5,
                  fontWeight: 500,
                  fontSize: 10,
                  marginLeft: 4,
                },
              },
            },
            "& .header-price": {
              "& p": {
                fontFamily: "Manrope",
                fontWeight: 700,
                fontSize: 14,
                color: "#FFF",
              }
            }
          },
          "& .body-content": {
            display: "flex",
            flexDirection: "column",
            gap: 10,
            "& .content-item": {
              display: "flex",
              alignItems: "center",
              gap: 8,
            }
          },
          "& .body-footer": {
            "& p": {
              fontFamily: "Manrope",
              fontSize: 10,
              fontWeight: 500,
              color: "#BABABA",
            }
          },
          "& .body-button": {
            position: "absolute",
            bottom: 12,
            right: 12,
            cursor: "pointer",
            "& .purchasePlanBtn": {
              padding: "6px 27px",
              border: "1px solid transparent",
              borderRadius: "10px",
              display: "inline-block",
              background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
              "& p": {
                backgroundImage: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                backgroundClip: "text",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "12px",
                color: "#FFF"
              }
            },
            "& .cancelSubscriptionBtn": {
              padding: "4px 6px",
              borderRadius: 10,
              border: "1px solid #DC2626",
              display: "flex",
              gap: 4,
              "& p": {
                color: "#DC2626",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: 12,
              }
            },
            "& .cancelledSubscriptionBtn": {
              padding: "4px 8px",
              background: "#57534E",
              borderRadius: 10,
              "& p": {
                color: "#FFF",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: 12,
              }
            }
          }
        }
      },
    }
  },
})

// Customizable Area End
