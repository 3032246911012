import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { EventSubscription } from "fbemitter";
import { EMITTER_EVENT_VARIABLES, emitterEvents, generateTokenCall, joinCallSession, sendAPIRequest, startCallSession, TAny } from "../../../components/src/utils";
import { CallLog, CometChatCalls } from "@cometchat/calls-sdk-javascript";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import { toast } from "react-toastify";
import { CUSTOM_MESSAGE_TYPE, fetchCallHistory, getListUserFriendComet, sendCallLinkChatMessage, sendCustomMessage, TTypeConversation } from "../../../components/src/CometChat";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Recording {
  userId: string;
  roomId: string;
  sessionId: string;
  fileId: string;
  file: {
    meta: {
      resolution: {
        width: number;
        height: number;
      };
      format: string;
      duration: number;
    };
  };
  size: number;
  filePath: string;
  ratio: {
    [key: string]: number;
  };
  fileUrl: string;
  type: string;
  id: string;
}

export interface RecordingDetail {
  userId: string;
  roomId: string;
  sessionId: string;
  fileId: string;
  resolution: string;
  format: string;
  duration: number;
  size: number;
  filePath: string;
  fileUrl: string;
  type: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  recordingDetail: RecordingDetail;
  tokenSessionCall: string;
  currentCall: CometChat.Call | null;
  sessionId: string;
  isCaller: boolean;
  loggedInUser: CometChat.User | null;
  isOpenModalUser: boolean;
  isOpenModalCallLink: boolean;
  listMemberSelected: CometChat.User[];
  listContact: CometChat.User[];
  checkedContacts: {
    [key: string]: boolean;
  };
  callLinkUrl: string;
  isLoadingCallSession: boolean;
  listCallHistory: CallLog[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CallRecordingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subscriptionIncomingCall: EventSubscription | null = null;
  subscriptionAcceptCallSession: EventSubscription | null = null;
  subscriptionRejectCallSession: EventSubscription | null = null;
  subscriptionStartCallSession: EventSubscription | null = null;
  subscriptionEndCallSession: EventSubscription | null = null;
  subscriptionCancelCall: EventSubscription | null = null;
  subscriptionCurrentCall: EventSubscription | null = null;
  subscriptionInitialCometChat: EventSubscription | null = null;
  createPaymentLinkCallId = "";
  getLoggedUserId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      loading: true,
      recordingDetail: {} as RecordingDetail,
      tokenSessionCall: "",
      currentCall: null,
      isCaller: false,
      loggedInUser: null,
      sessionId: "",
      isOpenModalUser: false,
      isOpenModalCallLink: false,
      listContact: [],
      checkedContacts: {},
      listMemberSelected: [],
      callLinkUrl: "",
      isLoadingCallSession: false,
      listCallHistory: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    switch (message.id) {
      case getName(MessageEnum.SessionResponseMessage): {
        let token: string = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        runEngine.debugLog("TOKEN", token);
        if (token) {
          this.setState({ token });
        }
        break;
      }
      case getName(MessageEnum.NavigationPayLoadMessage): {
        try {
          const navigationPayload = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
          if (navigationPayload?.isAcceptCall) {
            const call = await CometChat.acceptCall(navigationPayload.sessionIDCall);
            const token = await generateTokenCall(call.getSessionId())
            await startCallSession(token, call);
            this.setState({ tokenSessionCall: token, currentCall: call, sessionId: navigationPayload.sessionIDCall })
            emitterEvents.emit(EMITTER_EVENT_VARIABLES.CLOSE_MODAL_CALL, true);
            return;
          }
          if (navigationPayload?.receiverType === CUSTOM_MESSAGE_TYPE.MEETING) {
            await this.handleJoinCallSession(navigationPayload?.sessionId)
            return;
          }
          await this.startCallAudio(navigationPayload);
        } catch (error) {
          console.error("Start call session failed with error: ", error)
        }
        break;
      }
      case getName(MessageEnum.RestAPIResponceMessage): {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.apiSuccessCallBackController(apiRequestCallId, responseJson)
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const cometChatToken: string = localStorage.getItem("cometChatToken") || ''
    this.getLoggedUser();
    if (cometChatToken === 'null' || !cometChatToken) {
      toast.error('This account does not exist in cometchat!');
      this.setState({loading: false})
      return
    }
    if(CometChat.isInitialized()){
      this.handleFetchingDataCometChat();
    }
    this.getToken();
    this.subscriptionAcceptCallSession = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.ACCEPT_CALL, (call: CometChat.Call, tokenSessionCall: string) => {
      this.setState({ tokenSessionCall, currentCall: call, isCaller: false, sessionId: call.getSessionId() })
    })
    this.subscriptionEndCallSession = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.RESET_CALL, () => {
      this.setState({ tokenSessionCall: "", currentCall: null, sessionId: "", callLinkUrl: "" }, () => this.handleOpenModalConnections(false))
      this.handleGetCallHistory();
    })
    this.subscriptionRejectCallSession = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.REJECT_CALL, () => {
      this.handleResetCall();
      this.handleGetCallHistory();
    })
    this.subscriptionCurrentCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.CURRENT_CALL, (call: CometChat.Call | null) => {
      if(!call) return;
      this.setState({currentCall: call})
    })
    this.subscriptionStartCallSession = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.START_CALL_SESSION, async (call: CometChat.Call) => {
      const token = await generateTokenCall(call.getSessionId());
      this.setState({ tokenSessionCall: token, isCaller: false })
      await startCallSession(token, call);
    })
    this.subscriptionIncomingCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.INCOMING_CALL, (call: CometChat.Call) => {
      this.setState({currentCall: call})
    })
    this.subscriptionCancelCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.CANCEL_CALL, (call: CometChat.Call) => {
      if (call.getSessionId() !== this.state.currentCall?.getSessionId()) return;
      this.handleResetCall()
      this.handleGetCallHistory();
    })
    this.subscriptionInitialCometChat = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.COMETCHAT_LOGGED_IN, async () => {
      await this.handleFetchingDataCometChat();
      const callKeyParams = new URLSearchParams(window.location.search).get('callKey');
      if (callKeyParams) {
        this.setState({isLoadingCallSession: true})
      }
    })
    // Customizable Area End
  }

  // Customizable Area Start
  handleFetchingDataCometChat = async () => {
    try {
      await Promise.all([
        this.handleGetListContacts(),
        this.handleGetCallHistory(),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: TAny
  ) => {
    const successCallbackMap = {
      [this.createPaymentLinkCallId]: this.handleCreatePaymentLinkResponse,
    };

    if (apiRequestCallId) {
      const successCallback: ((responseJson: TAny) => void) = successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  async componentDidUpdate(_prevProps: Props, prevStates: S) {
    const { checkedContacts, listContact } = this.state;
    if (prevStates.checkedContacts !== checkedContacts) {
      const listSelectedMember = Object.entries(checkedContacts);
      const listMember = listSelectedMember.map(([uid]) => {
        const member = listContact.find(contact => contact.getUid() === uid && checkedContacts[uid]);
        return member;
      }).filter(Boolean);

      this.setState({ listMemberSelected: listMember as CometChat.User[] });
    }
  }
  
  async componentWillUnmount() {
    if (this.subscriptionStartCallSession) {
      this.subscriptionStartCallSession.remove()
    }
    if (this.subscriptionEndCallSession) {
      this.subscriptionEndCallSession.remove()
    }
    if (this.subscriptionAcceptCallSession) {
      this.subscriptionAcceptCallSession.remove()
    }
    if (this.subscriptionRejectCallSession) {
      this.subscriptionRejectCallSession.remove()
    }
    if (this.subscriptionCurrentCall) {
      this.subscriptionCurrentCall.remove()
    }
    if (this.subscriptionIncomingCall) {
      this.subscriptionIncomingCall.remove()
    }
    if (this.subscriptionCancelCall) {
      this.subscriptionCancelCall.remove()
    }
    if (this.state.currentCall?.getSessionId()) {
      try {
        if (this.state.isCaller){
          // Cancel call if you're calling status
          const status = CometChat.CALL_STATUS.CANCELLED;
          const call = await CometChat.rejectCall(this.state.currentCall?.getSessionId() ?? "", status);
          console.info("Call canceled successfully", call);
          return;
        }
        // End call when you are in a call
        if(this.state.tokenSessionCall){
          // End call 
          this.endCallAudio();
        }
        toast.info("The call ended as you navigated to another screen.")
      } catch (error) {
        console.error("Call cancelation failed with error:", error);
      }
    }

    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages)
  }

  handleGetListContacts = async () => {
    try{
      const user = await CometChatUIKit.getLoggedinUser()
      const listUsers = await getListUserFriendComet({});
      this.setState({ loggedInUser: user, listContact: listUsers, })
    } catch(error) {
      console.error("Get list contact failure with error: ", error);
    }
  }

  handleGetCallHistory = async () => {
    try {
      const user = await CometChatUIKit.getLoggedinUser();
      const listCallHistory = await fetchCallHistory(user?.getAuthToken() ?? "");
      this.setState({ listCallHistory, loading: false })
    } catch (error) {
      console.error("Get list contact failure with error: ", error);
    }
  }

  handleJoinCallSession = async (sessionId: string) => {
    try {
      const { token } = await CometChatCalls.generateToken(sessionId, localStorage.getItem("cometChatToken") || "")
      this.setState({ tokenSessionCall: token, sessionId })
      await joinCallSession(token, sessionId);
      this.setState({isLoadingCallSession: false})
      
    } catch (error) {
      console.error("Join call failed with error: ", error)
    }
  }

  startCallAudio = async ({receiverID, receiverType}: {receiverID: string, receiverType: TTypeConversation}) => {
    const callType: string = CometChat.CALL_TYPE.AUDIO;
    const getReceiverType: string = receiverType || CometChat.RECEIVER_TYPE.USER;

    const call: CometChat.Call = new CometChat.Call(
      receiverID,
      callType,
      getReceiverType
    );
    try {
      // Should need to wait set state firstly, after that will go next line
      await new Promise<void>((resolve) => {
        this.setState({ isCaller: true, isLoadingCallSession: false }, resolve);
      });
      const outGoingCall = await CometChat.initiateCall(call)
      const sessionIDCall = outGoingCall.getSessionId()
      await sendCallLinkChatMessage(receiverID, receiverType, sessionIDCall)
      emitterEvents.emit(EMITTER_EVENT_VARIABLES.IS_CALLING, true)
      console.info("Call initialization successfully with sessionId:", sessionIDCall)
      this.setState({ currentCall: outGoingCall, sessionId: sessionIDCall });
    } catch (error) {
      const errorCast = error as CometChat.CometChatException;
      emitterEvents.emit(EMITTER_EVENT_VARIABLES.IS_CALLING, false)
      this.handleResetCall();
      console.error("Call initialization failed with exception:", errorCast);
      toast.error(errorCast.message)
    }
  }

  endCallAudio = () => {
    emitterEvents.emit(EMITTER_EVENT_VARIABLES.CLOSE_MODAL_CALL, true)
    CometChat.clearActiveCall();
    CometChatCalls.endSession();
  }

  getToken = () => {
    const sessionRequestMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(sessionRequestMessage);
  };

  handleBackChatScreen = () => {
    this.props.navigation.navigate("Chat")
  }

  handleCancelCall = async () => {
    const status = CometChat.CALL_STATUS.CANCELLED;
    try {
      const call = await CometChat.rejectCall(this.state.currentCall?.getSessionId() ?? "", status)
      console.info("Call canceled successfully", call);
      toast.success("Call cancel successfully");
      this.handleGetCallHistory();
    } catch (error) {
      console.error("Call cancelation failed with error:", error);
      toast.error("Call cancel fail");
    }
    emitterEvents.emit(EMITTER_EVENT_VARIABLES.IS_CALLING, false);    
    this.handleResetCall();
  }

  
  handleCheckboxChange = (contactId: string) => {
    const isAlreadyAddMember = this.state.checkedContacts[contactId];
    if (isAlreadyAddMember)
      return this.handleRemoveUserSelected(contactId);
    this.setState((prevState) => ({
      checkedContacts: {
        ...prevState.checkedContacts,
        [contactId]: true
      },
    }));
  };

  handleRemoveUserSelected = (contactId: string) => {
    this.setState((prevState) => {
      const { checkedContacts} = prevState
      const newCheckedContacts = { ...checkedContacts }
      delete newCheckedContacts[contactId]

      return {
        checkedContacts: newCheckedContacts
      };
    });
  };

  handleResetCall = async () => {
    this.setState({ sessionId: "", isCaller: false, tokenSessionCall: "", currentCall: null });
  }

  handleOpenModalConnections = async (bool?: boolean) => {
    if(bool !== undefined) {
      this.setState({isOpenModalUser: bool});
      return;
    }
    this.setState({isOpenModalUser: !this.state.isOpenModalUser});
  }

  handleCopyCallLink = () => {
    if (this.state.callLinkUrl) {
      navigator.clipboard?.writeText(this.state.callLinkUrl);
      toast.success("Call link copied successfully.", {
        toastId: 'copyCallLinkSuccess',
      })
    }
  }

  handleCreateCallLink = async () => {
    this.setState({isOpenModalCallLink: true}, () => {
      this.handleCreatePaymentLink();
    })
  }

  handleResetCallLink = async () => { 
    this.setState({isOpenModalCallLink: false, callLinkUrl: ""})
  }

  handleAddUserInCall = async () => {
    const listReceiverCall = this.state.listMemberSelected.map(member => {
      const customData = {
        sessionId: this.state.sessionId,
        callType: "audio",
        invitation: {
          sender: this.state.loggedInUser
        }
      };
      return sendCustomMessage(member.getUid(), CometChat.RECEIVER_TYPE.USER as "user", customData, CUSTOM_MESSAGE_TYPE.MEETING)
    })
    try{
      await Promise.all(listReceiverCall);
      console.info("Invite user call successfully");
    } catch(error) {
      console.error("Invite user call failed with error: ", error);
    }
    this.handleOpenModalConnections(false);
  }

  handleResetAddUser = () => {
    this.setState({listMemberSelected: [], checkedContacts: {}})
  }

  handleClickCallLink = () => {
    if(this.state.callLinkUrl)
      window.open(this.state.callLinkUrl)
  }

  handleCreatePaymentLink = async () => {
    const token = await getStorageData('authToken');
    let formData = new FormData()
    formData.append('call_link[call_type]', 'voice');
    this.createPaymentLinkCallId = sendAPIRequest(
      configJSON.createPaymentLink,
      {
        method: 'POST',
        headers: {
          token,
        },
        body: formData,
      }
    )
  }

  handleCreatePaymentLinkResponse = async (responseJson: TAny) => {
    const {key} = responseJson.data.attributes;
    const callLinkUrl = `${window.location.origin}/CallRecording?callKey=${key}`
    this.setState({callLinkUrl})
  }

  getLoggedUser = async () => {
		const token = await getStorageData("authToken")

		this.getLoggedUserId = sendAPIRequest(
			'account_block/accounts/logged_user',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					token,
				},
			}
		)
	}

  handleClickJoinCallLink = async () => {
    const callKeyParams = new URLSearchParams(window.location.search).get('callKey');
    if (callKeyParams)
      await this.handleJoinCallSession(callKeyParams);
  }

  // Customizable Area End
}
