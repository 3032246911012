import { Box } from '@material-ui/core';
import { successIcon } from '../../../postcreation/src/assets';
import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Apply global styles for blur effect
const applyBlurEffect = () => {
  // Create style element if it doesn't exist
  let styleElement = document.getElementById('toastify-blur-style');
  if (!styleElement) {
    styleElement = document.createElement('style');
    styleElement.id = 'toastify-blur-style';
    document.head.appendChild(styleElement);
    styleElement.textContent = `
      body.toastify-open::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(4px);
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 999; /* Lower than toast container */
        pointer-events: none;
      }
    `;
  }
};

const getIconToast = (typeToast: ToastType) => {
  if (typeToast) return successIcon;
}

const renderToastContent = (content: string, typeToast: ToastType = "success") => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          width: 165,
          backgroundColor: '#222',
          boxSizing: "border-box",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          padding: '10px 20px 10px 8px',
          borderRadius: '6px'
        }}
      >
        <img src={getIconToast(typeToast)} />
        <p style={{ color: '#FFF', fontFamily: 'Manrope', fontSize: '12px', fontWeight: '500' }}>{content}</p>
      </Box>
    </Box>
  )
}

// Toast types
type ToastType = 'success' | 'error' | 'info' | 'warning';

// Notify function to show toast messages
export const notify = (message: string, type: ToastType = 'success') => {
  // Apply blur effect
  applyBlurEffect();

  // Add class to body for blur effect
  document.body.classList.add('toastify-open');

  // Show toast
  toast(renderToastContent(message, type), {
    position: 'bottom-center',
    theme: 'colored',
    style: { backgroundColor: 'transparent' },
    closeButton: false,
    autoClose: 2000,
    onClose: () => {
      // Remove class when toast closes
      document.body.classList.remove('toastify-open');
    }
  });
};
