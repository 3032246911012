import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  InputAdornment,
  Grid,
  IconButton,
  Box,
  Paper,
  Avatar,
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import darkTheme from "../../../components/src/darkTheme";
import lightTheme from "../../../components/src/lightTheme";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikInputField from "../../../components/src/FormikInputField.web";
import { RightIcon, dashboardBackground, googleImage, lightBackground } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import AppleLogin from "../../applelogin/src/AppleLogin.web"



const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required("This field is required."),
  password: Yup.string().required("This field is required"),
});

const Images=styled(Grid)(({ theme })=>({
  [theme.breakpoints.down("xs")]: {
      backgroundImage:'unset !important',
   }
}))

const CustomPaper=styled(Paper)(({ theme })=>({
[theme.breakpoints.down("xs")]: {
     height :'100vh !important',
   }
}))

const webStyle={
 gridContainerLightTheme: {
    height: "100vh",
    backgroundImage: `url(${lightBackground})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  gridContainerDarkTheme:{
    height: "100vh",
    backgroundImage: `url(${dashboardBackground})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  gridItem:{
    justifyContent: 'space-between' as 'space-between',
    alignItems: 'center',
    height: '90vh',
    paddingTop: "3rem",
  },
  switchGridContainer:{
    paddingRight: "1.5rem"
  },
  labelStyle:{
    fontWeight: 500
  },
  welcomeText:{
    textShadow: '0px 3.453px 6.906px rgba(0, 0, 0, 0.03), 0px 6.906px 27.624px rgba(0, 0, 0, 0.06)',
    fontFamily: 'Manrope',
    fontSize: "31px",
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '150%',
    letterSpacing: '-0.414px',
    marginBottom: "0.5rem"
    
  },
  arrowText:{
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Manrope',
    fontSize: "31px",
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '113%',
    letterSpacing: '-0.414px',
  },
  loginText:{
    fontFamily: 'Manrope',
    fontSize: "31",
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '150%',
    letterSpacing: '-0.56px',
    marginBottom: "1.5rem"
    
  },
  errorContainer:{
    display: 'flex',
    padding: '0.9rem 1rem',
    alignItems: 'center',
    borderRadius: "0.625rem",
    borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
    background: 'linear-gradient(92deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0.00) 28.72%), rgba(33, 33, 33, 0.29)',
    backdropFilter: 'blur(24.5px)',
    fontSize: "10px",
    fontStyle: 'normal',
    color: '#DC2626',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '120%',
    marginBottom: "1.2rem"
    
  },
  forgotPasswordText:{
    color: '#8833ff',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'Manrope',
    fontSize: "14px",
    fontStyle: 'normal' as 'normal',
    fontWeight: 500,
    marginBottom: "3rem",
    textAlign: "right" as 'right',
    cursor: "pointer",
    marginTop: "7px",
    textDecoration:'underline'
  },
  
  textField:{
    "&.MuiOutlinedInput-input":{
     height:48
    }
  },

  buttonText:{
    fontSize:'12px'
  },
  accountText:{
    fontFamily: 'Manrope',
    fontSize: "14px",
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    letterSpacing: '-0.28px',
    textAlign: "center" as 'center',
    marginTop: "2rem"
  },
  signupText:{
    color: '#8833ff',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'Manrope',
    fontSize: "14px",
    fontStyle: 'normal' as 'normal',
    fontWeight: 500,
    marginBottom: "3rem",
    textAlign: "right" as 'right',
    cursor: "pointer",
    marginTop: "7px",
    textDecoration:'underline'
    
  },
  iconButton:{
    color: "#6B6B6B",
    position: "absolute" as const,
    right: "25px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  lightColor:{
    backgroundColor:'#fff',
  },
  darkColor:{
    backgroundColor:'#070707',
  },
  errorText:{
    color: '#DC2626',
    fontFamily: 'Manrope',
    fontSize: "10px",
    fontStyle: 'normal',
    fontWeight: 500,
},
button: {
  borderRadius: "10px",
  background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  textTransform: "capitalize" as "capitalize",
  color: "#FFF",
  fontFamily: "Manrope",
  fontSize: "12px",
  fontStyle: "normal" as 'normal',
  fontWeight: 700,
  height:48
},
buttonDisabled: {
  borderRadius: "10px",
  background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  textTransform: "capitalize" as "capitalize",
  color: "lightGrey",
  fontFamily: "Manrope",
  fontSize: "12px",
  fontStyle: "normal" as 'normal',
  fontWeight: 700,
  height:48
},
avatar: {
  borderRadius: "10px",
  background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  cursor: 'pointer'
},
avatarContainer: {
  display: 'flex',
  flexDirection: 'row' as 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '18%',
  gap: 15,
  width: '100%',
  position: "relative" as "relative"
},
google:{
  opacity: 0,
  cursor: 'pointer',
  zIndex: 200,
  left: '37%',
  position: "absolute" as 'absolute',
  width: '40px',
  overflow: 'hidden',
}

}

const CustomButton = styled(Button)({
  "&.MuiButton-root.Mui-disabled": {
    opacity:"0.64 !important"
  }    
})

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})

// Customizable Area End

import EmailAccountLoginController, {
  Props, configJSON,
} from "./EmailAccountLoginController";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start

 returnLoginButton=(email:string,password:string)=>{
 return(<CustomButton style={email && password? webStyle.button:webStyle.buttonDisabled} fullWidth type='submit'
    disabled={email&& password?false:true}  
    endIcon={<StyledIcon><img src={RightIcon}/></StyledIcon>}>{configJSON.loginNow}</CustomButton>)
 }

  validateEmailLogin = () => {
    const TextFieldColor = (this.state.apiError && this.state.apiError !== 'Incorrect Password') ? "textError" : "TextContainer2"
    const passwordError = this.state.apiError && this.state.apiError === 'Incorrect Password' ? "textError" : "TextContainer2"
    return { TextFieldColor, passwordError }
  }
 BoxContainer=styled(Box)({
    "& .TextContainer > .MuiTextField-root > .MuiInputBase-formControl > .MuiOutlinedInput-input":{
     height:24
    },
    "& .TextContainer > .MuiTextField-root > .MuiOutlinedInput-root":{
      borderWidth:"1px"
    },

    "& .TextContainer2 > .MuiTextField-root > .MuiInputBase-formControl > .MuiOutlinedInput-input":{
      height:24
     },
     "& .TextContainer2 > .MuiTextField-root > .MuiOutlinedInput-root":{
       borderWidth:"1px",
       borderColor:'#8833FF'
     },

     "& .textError> .MuiTextField-root > .MuiInputBase-formControl > .MuiOutlinedInput-input":{
      height:24
     },
     "& .textError > .MuiTextField-root > .MuiOutlinedInput-root":{
       borderWidth:"1px",
       borderColor:'#f44336'
     },
 })
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={this.props.checked ? lightTheme : darkTheme}>
        <CustomPaper style={this.props.checked?webStyle.lightColor:webStyle.darkColor}>
              <Images container 
              style={this.props.checked? webStyle.gridContainerLightTheme:webStyle.gridContainerDarkTheme}>
            <Grid container style={webStyle.gridItem}>
                <Grid item md={5} sm={6} lg={6} justifyContent="center" alignItems="center" direction="column">
                 </Grid>  
                 <Grid item xs={10} md={4} sm={6} lg={4} style={{display:'flex',alignItems:'center',justifyContent:'flex-start'}}>           
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={this.handleLogin}
          data-test-id="login-fromik-form"
        >
        {(formikProps) => {
          const { values,handleChange} = formikProps;
         const result=this.validateEmailLogin()       
          return (
            <Form noValidate autoComplete="off" style={{width:"80%"}}>
              <Loader loading={this.state.loginPageLoading}/>
                  <Grid item>
                    <Typography style={webStyle.welcomeText}>
                      {configJSON.welComeText} <span style={webStyle.arrowText}>{configJSON.arrowsText}</span>
                    </Typography>
                    <Typography data-test-id='loginText' style={webStyle.loginText}>
                      {configJSON.login}
                    </Typography>
                    {this.state.apiError && (
                      <Box style={webStyle.errorContainer}>
                       <Typography style={webStyle.errorText} data-test-id='apiError'>{this.state.apiError}</Typography>
                      </Box>
                    )}

                     {this.state.validationError && (
                      <Box style={webStyle.errorContainer}>
                       <Typography style={webStyle.errorText} data-test-id='validationError'>{this.state.validationError}</Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item>
                    <this.BoxContainer >
                    <div className={values.email?.length>0?result.TextFieldColor:"TextContainer"}>
                    <Field
                      component={FormikInputField}
                      name={configJSON.emailName}
                      value={values.email}
                      autoComplete="off"
                      label={configJSON.emailLabel}
                      placeholder={configJSON.emailPlaceholder}
                      variant="outlined"
                      onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.handleText(event, handleChange)}
                      fullWidth
                      id="emailInput"
                    />
                    </div>
                    </this.BoxContainer>
                  </Grid>
                  <Grid item>
                    <this.BoxContainer>
                      <div className={values.password?.length>0?result.passwordError:"TextContainer"}>
                    <Field
                      component={FormikInputField}
                      id="passwordInput"
                      name={configJSON.authName}
                      value={values.password}
                      autoComplete="off"
                      label={configJSON.authLabel}
                      onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.handleText(event, handleChange)}
                      placeholder={configJSON.authPlaceholder}
                      variant="outlined"
                      fullWidth
                      type={this.state.isPasswordVisible ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                           >
                            <IconButton 
                            style={webStyle.iconButton}  
                            onClick={this.toggleShowHideIcon} 
                            edge="end"
                            >
                              {this.state.isPasswordVisible ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    </div>
                    </this.BoxContainer>
                  </Grid>
                  <Grid item>
                    <Typography data-test-id='forgotPassword' style={webStyle.forgotPasswordText} onClick={this.navigateToForgotPassword}>
                      {configJSON.forgotPassword}
                    </Typography>
                  </Grid>
                  
                  <Grid item>
                    {this.returnLoginButton(values.email,values.password)}

                    <Box style={webStyle.avatarContainer}>
                   
                    <GoogleOAuthProvider clientId={configJSON.clientId}>
                    <Avatar style={webStyle.avatar}>
                    <img src={googleImage} />
                    </Avatar>
                    <div style={webStyle.google}>
                        <GoogleLogin
                          data-test-id='google'
                          size="small"
                          type="icon"
                          shape="square"
                          width="60px"
                 
                         onSuccess={this.handleLoginSuccess}
                         onError={()=>this.handleLoginError}
                        />
                        </div>
                       
                     </GoogleOAuthProvider>
                     <AppleLogin checked={this.props.checked}
                  navigation={this.props.navigation}
                  id={"apple-login-splashscreen"}
                  testId={"apple"}
                  callback={this.handleAppleLoginSuccess}
                  onPress={function (): void {
                    throw new Error("Function not implemented.");
                  } }
                  hideButton={true}
                  buttonTitle="test"
                  />
                  </Box>
                    <Typography style={webStyle.accountText}>
                      {configJSON.account} <span style={webStyle.signupText} id="go-to-signup-btn" onClick={this.goToSignUpFromLogin.bind(this)}>{configJSON.signup}</span>
                    </Typography>
                  </Grid>
                  
            </Form>
            );
          }}
        </Formik>
        </Grid>
        </Grid>
        </Images>
       </CustomPaper>
      </ThemeProvider>
       // Customizable Area End
    );
  }
}



