import React from "react";
// Customizable Area Start
import {
  Box,
  CardContent,
  Grid,
  Card,
  Switch,
  Typography,
  Button
} from "@material-ui/core";
import { createTheme,withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {check,unCheck,profile,verify} from './assets'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  }

});

const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    }
   }
  });

const commonDarkTheme=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",
      },
      secondary:{
      main:"rgba(255,255,255,0.75)"
      }
     }
    });

    const IOSSwitch = withStyles(({
      root: {
        width: 48,
        height: 28,
        padding: 0,
      },
    
      switchBase: {
        padding: 0,
        transform: "translate(4px,4.4px)",
        "&$checked": {
          transform: "translate(23px,4.4px)",
          color: theme.palette.common.white,
          "& + $track": {
            background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            opacity: 1,
            border: "none",
          },
        },
        
      },
      thumb: {
        width: 24,
        height: 24,
        margin:"-2px -1px"
      },
      track: {
        borderRadius: 26 / 2,
        backgroundColor: "#1F1F1F",
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
      },
      checked: {},
      focusVisible: {},
    }))(Switch);   
    
   const fadeContainer={
      display:'flex',
      flexDirection:'column' as 'column',
      alignItems:'center'
    }  
   const modalStyle={
    backdropFilter: "blur(1px)"
   }   

// Customizable Area End

import SettingNotificationsController, {
  Props,
  SETTING_POSTS_VALUE,
  configJSON,
} from "./SettingNotificationsController";

export default class SettingNotifications extends SettingNotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start


  renderMentionAndReplies = (classes: { [key: string]: string }) => {
    return (
      <div className={classes.modalDiv}>
        <Typography data-test-id='mentionText' className={this.props.checked ? classes.mentionsHeading : classes.mentionsHeadingLight}>{this.state.activeModalDetails}</Typography>
        <hr className={this.props.checked? classes.lightThemeLine:classes.line} />
        {this.handlePostsSettingData("mensions_and_replies").map((item) =>
          <Box key={item.id} className={classes.postsContainerMention}>
            <Typography className={this.props.checked ? classes.post : classes.postLight}>{item.title}</Typography>
            <img data-test-id={`mentions${item.id}`} src={item.isClick ? unCheck : check} onClick={() => this.handleClickPostsSetting("mensions_and_replies", item.key as SETTING_POSTS_VALUE)} />
          </Box>
        )}
        <div className={classes.btnStyles}>
        <Button data-test-id='cancel' onClick={this.closeModal} className={this.props.checked ? classes.cancelLightTheme:classes.cancel}>{configJSON.cancel}</Button>
        <Button data-test-id='submitReply' className={classes.submit} onClick={this.submitMentionReplies}>{configJSON.submit}</Button>
      </div>
      </div>
    )

  }

  renderLikes = (classes: { [key: string]: string }) => {
    return (
      <div className={classes.modalDiv}>
        <Typography className={this.props.checked ? classes.mentionsHeadingLike : classes.mentionsHeadingLightLike}>{this.state.activeModalDetails}</Typography>
        <hr className={this.props.checked? classes.lightThemeLine:classes.lineLike} />
        {this.handlePostsSettingData("likes").map((item) =>
          <Box key={item.id} className={classes.postsContainerMentionLike}>
            <Typography className={this.props.checked ? classes.postLike : classes.postLightLike}>{item.title}</Typography>
            <img data-test-id={`likes${item.id}`} src={item.isClick ? unCheck : check} onClick={() => this.handleClickPostsSetting("likes", item.key as SETTING_POSTS_VALUE)} />
          </Box>
        )}
        <div className={classes.btnStyles}>
        <Button onClick={this.closeModal} className={this.props.checked ? classes.cancelLightTheme:classes.cancel}>{configJSON.cancel}</Button>
        <Button data-test-id='submitLikes' className={classes.submitLike} onClick={this.submitLikes}>{configJSON.submit}</Button>
      </div>
      </div>
    )
  }

  renderRepost = (classes: { [key: string]: string }) => {
    return (
      <div className={classes.modalDiv}>
        <Typography className={this.props.checked ? classes.mentionsHeadingReport : classes.mentionsHeadingLightReport}>{this.state.activeModalDetails}</Typography>
        <hr className={this.props.checked? classes.lightThemeLine:classes.lineReport} />
        {this.handlePostsSettingData("repost").map((item) =>
          <Box key={item.id} className={classes.postsContainerMentionReport}>
            <Typography className={this.props.checked ? classes.postReport : classes.postLightReport}>{item.title}</Typography>
            <img data-test-id={`post${item.id}`} src={item.isClick ? unCheck : check} onClick={() => this.handleClickPostsSetting("repost", item.key as SETTING_POSTS_VALUE)} />
          </Box>
        )}
        <div className={classes.btnStyles}>
        <Button onClick={this.closeModal} className={this.props.checked ? classes.cancelLightTheme:classes.cancel}>{configJSON.cancel}</Button>
        <Button data-test-id='submitRepost' className={classes.submitReport} onClick={this.submitRepost}>{configJSON.submit}</Button>
     </div>
      </div>
    )
  }

  renderMessageReactions = (classes: { [key: string]: string }) => {
    return (
      <div className={classes.modalDiv}>
        <Typography className={this.props.checked ? classes.mentionsHeading : classes.mentionsHeadingLight}>{this.state.activeModalDetails}</Typography>
        <hr className={this.props.checked? classes.lightThemeLine:classes.line} />
        {this.state.mentionsList.map((item) =>
          <Box key={item.id} className={classes.postsContainerMention}>
            <Typography className={this.props.checked ? classes.post : classes.postLight}>{item.title}</Typography>
            <img data-test-id={`message${item.id}`} src={item.isClick ? unCheck : check} onClick={() => this.handleMentions(item.id, item.title, item.isClick)} />
          </Box>
        )}
        <div className={classes.btnStyles}>
        <Button onClick={this.closeModal} className={this.props.checked ? classes.cancelLightTheme:classes.cancel}>{configJSON.cancel}</Button>
        <Button data-test-id='submitReaction' className={classes.submit} onClick={this.submitMessageReactions}>{configJSON.submit}</Button>
      </div>
      </div>
    )

  }
  renderPersons = (classes: { [key: string]: string }) => {
    return (
      <div className={classes.modalDiv}>
        <Typography className={this.props.checked ? classes.mentionsHeading : classes.mentionsHeadingLight}>{configJSON.miss}</Typography>
        <Typography className={classes.descriptionsPerson}>{this.state.activeUserName}</Typography>
        <hr className={this.props.checked? classes.lightThemeLinePerson:classes.linePerson} />
        {this.state.personPreferences.map((item) =>
          <Box key={item.id} className={classes.postsContainerMentionPerson}>
            <Box className={classes.preferBox}>
              <Typography className={this.props.checked ? classes.post : classes.postLight}>{item.title}</Typography>
              <Typography className={classes.descriptions}>{item.description}</Typography>
            </Box>
            <img data-test-id={`person${item.id}`} className={classes.checked} src={item.isClick ? unCheck : check} onClick={() => this.handlepersonPrefer(item.id)} />
          </Box>
        )}
        <div className={classes.btnStylesPerson}>
        <Button onClick={this.closeModalPerson} className={this.props.checked ? classes.cancelLightTheme:classes.cancel}>{configJSON.cancel}</Button>
        <Button data-test-id='updatePreferenceUser' onClick={this.updatePreferences} className={classes.submit}>{configJSON.submit}</Button>
        </div>
      </div>
    )
  }
  showPostData = (classes: { [key: string]: string }) => {
    return (<>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={modalStyle}
        className={classes.modal}
        open={this.state.open}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.open} style={fadeContainer}>
          <div className={this.props.checked ? classes.paperLight : classes.paper}>
            {this.state.activeModalDetails === 'Mensions and replies' &&
            this.renderMentionAndReplies(classes)
            }

            {this.state.activeModalDetails === 'Likes' &&
            this.renderLikes(classes)
            }

            {this.state.activeModalDetails === 'Repost' &&
            this.renderRepost(classes)
            }
            {this.state.activeModalDetails === 'Message reactions' &&
              this.renderMessageReactions(classes)
            }

            {this.state.activeModalDetails === 'Persons' &&
            this.renderPersons(classes)
           }

          </div>
        </Fade>
      </Modal>
      <Grid container>
        <Grid item xs={12} className={classes.headingItem}>
          <div className={classes.headingContainer}>
          <div className={classes.arrowStyle}><ArrowBackIosIcon data-test-id='backPost' onClick={this.gotoPushNotification} className={classes.hoverIcon}/></div>
          <div className={classes.heading}><Typography data-test-id='post' className={classes.notification}>{configJSON.posts}</Typography></div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography dat-test-id='followers' className={classes.persons}>{this.state.activeFollowers}{configJSON.persons}</Typography>
        </Grid>
        <Grid item xs={12}>
          {this.state.showUsers.length>0 && this.state.showUsers.map((item)=>
          <Box key={item.id} className={classes.iconsBox} data-test-id='userModal' onClick={()=>this.openUserModal(item.id,item.userName)}>
          <Box className={classes.profileBox}>
            <img src={item.profile!==null?item.profile:profile} />
            <Box>
              <Typography dat-test-id={`name${item.id}`} className={classes.name}>{item.fullName} <img src={verify} /></Typography>
              <Typography className={classes.descriptions}>{item.userName}</Typography>
            </Box>
          </Box>
          <ArrowForwardIosIcon className={classes.hoverIcon}/>
        </Box>
          )}
        </Grid>
      </Grid>
      </> )
  }

  showMutedNotifications = (classes: { [key: string]: string }) => {
    return (<Grid container>
      <Grid item xs={12} className={classes.headingItem}>
        <div className={classes.headingContainer}>
        <div className={classes.arrowStyle}><ArrowBackIosIcon data-test-id='backNotification' onClick={this.gotoNotification} className={classes.hoverIcon} /></div>
         <div className={classes.heading}><Typography data-test-id='notification' className={classes.notification}>{configJSON.muteNotification}</Typography></div>
        </div>
      </Grid>
      <Grid item xs={10}>
        <Card className={this.props.checked?classes.cardLight:classes.card}>
          <Typography className={classes.muteText}>{configJSON.muteNotifications}</Typography>
          <CardContent>
            {this.state.muteNotification.map((item) =>
              <Box key={item.id} className={classes.iconsContainer}>
                <Typography className={classes.iconTitle}>{item.title}</Typography>
                <img className={classes.checked} data-test-id={`muteImage${item.id}`} src={item.isClick ? unCheck : check} onClick={() => this.handleSelectOption(item.id,item.title,item.isClick)} />
              </Box>
            )}
          </CardContent>
        </Card>
        <Typography className={this.props.checked ? classes.filterTextLight:classes.filterText}>{configJSON.filters}</Typography>
      </Grid>
    </Grid>)
  }

  showPreferences = (classes: { [key: string]: string }) => {
    return (<Grid container>
      <Grid item xs={12} className={classes.headingItem}>
        <div className={classes.headingContainer}>
        <div className={classes.arrowStyle}><ArrowBackIosIcon onClick={this.gotoPreference} className={classes.hoverIcon} /></div>
        <div className={classes.heading}><Typography data-test-id='preferences' className={classes.notification}>{configJSON.preferences}</Typography></div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.preferTextPush}>{configJSON.selectPreference}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card className={this.props.checked?classes.preferCardPushLight:classes.preferCardPush}>
          <CardContent>
            <Box className={classes.switchContainer}>
              <Typography className={classes.unreadText}>{configJSON.unreadNotification}</Typography>
              <IOSSwitch
               data-test-id='switch'
               onChange={(event) => this.handleChange(event)}
               checked={this.state.unreadNotification} />
            </Box>
            <Typography className={classes.badgeText}>{configJSON.displayBadge}</Typography>
            <Box data-test-id='push' className={classes.switchContainerPush} onClick={this.navigateToPushNotification}>
              <Typography className={classes.pushText}>{configJSON.pushNotification}</Typography>
              <ArrowForwardIosIcon className={classes.hoverIcon}/>
            </Box>
               
            <Box className={classes.switchContainer2}>
              <Typography className={classes.unreadText}>{configJSON.emailTitle}</Typography>
              <IOSSwitch
               data-test-id='switchEmail'
               onChange={(event) => this.handleChangeEmail(event)}
               checked={this.state.emailNotification} />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>)
  }

  renderNewsArrows=(classes: { [key: string]: string })=>{
    return(
      <Card className={this.props.checked?classes.preferCardLight:classes.preferCard}>
      <CardContent>
        <Typography data-test-id='news' className={classes.posts}>{configJSON.newsArrows}</Typography>
        {this.state.news.map((each) =>
          <Box key={each.id} className={classes.postsContainer}>
            <Typography className={classes.post}>{each.title}</Typography>
            <img data-test-id={`newsOption${each.id}`}src={each.isClick ? unCheck : check} onClick={() => this.setNews(each.id,each.title,each.isClick)} />
          </Box>)}
      </CardContent>
    </Card>
    )
  }

  renderPushNotification=(classes: { [key: string]: string })=>{
    return(
      <Grid container>
      <Grid item xs={12} className={classes.headtingItem}>
        <div className={classes.headingContainer}>
        <div className={classes.arrowStyle}><ArrowBackIosIcon  onClick={this.gotoPreferenceScreen} className={classes.hoverIcon}/></div>
        <div className={classes.heading}><Typography data-test-id='pushNotification' className={classes.notification}>{configJSON.pushNotification}</Typography></div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.preferText}>{configJSON.selectPreference}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card className={this.props.checked?classes.preferCardLight:classes.preferCard}>
          <CardContent data-test-id='posts' onClick={this.navigateToPosts}>
            <Typography className={classes.posts}>{configJSON.followPosts}</Typography>
            <Box className={classes.postsContainer}>
              <Typography className={classes.post}>{configJSON.posts}</Typography>
              <ArrowForwardIosIcon  className={classes.hoverIcon} />
            </Box>
            <Typography className={classes.people}>{configJSON.people}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={this.props.checked?classes.preferCardLight:classes.preferCard}>
          <CardContent>
            <Typography data-test-id='relatePost' className={classes.posts}>{configJSON.postRelate}</Typography>
            {this.state.preferenceDetails.map((item) => <div key={item.id} data-test-id={`preferences${item.id}`} onClick={()=>this.openMentionsModal(item.title)} className={classes.mentionStyle}>
              <Box  className={classes.postsContainer}>
                <Typography className={classes.post}>{item.title}</Typography>
                <ArrowForwardIosIcon  className={classes.hoverIcon} />
              </Box>
              <Typography className={classes.people}>{configJSON.tailor}</Typography>
            </div>)}
            <Box className={classes.postsContainer}>
              <Typography className={classes.post}>{configJSON.tag}</Typography>
              <img data-test-id='photoTag' src={this.state.photoTag ? unCheck : check} onClick={this.handlePhotoTag} />
            </Box>
          </CardContent>
        </Card>
        <Card className={this.props.checked?classes.preferCardLight:classes.preferCard}>
          <CardContent>
            <Typography data-test-id='followers' className={classes.posts}>{configJSON.followContacts}</Typography>
            {this.state.contacts.map((each) =>
              <Box key={each.id} className={classes.postsContainer}>
                <Typography className={classes.post}>{each.title}</Typography>
                <img data-test-id={`contact${each.id}`} src={each.isClick ? unCheck : check} onClick={() => this.handleContacts(each.id,each.isClick,each.title)} />
              </Box>
            )}
          </CardContent>
        </Card>
        <Card className={this.props.checked?classes.preferCardLight:classes.preferCard}>
          <CardContent>
            <Typography className={classes.posts}>{configJSON.directMessage}</Typography>
            <Box className={classes.postsContainer}>
              <Typography data-test-id='directMessage' className={classes.post}>{configJSON.directMessage}</Typography>
              <img data-test-id='message' src={this.state.message ? unCheck : check} onClick={this.handleMessage} />
            </Box>
            <Box className={classes.postsContainer} data-test-id='messageReaction' onClick={this.showReactions}>
              <Typography className={classes.post}>{configJSON.reactions}</Typography>
              <ArrowForwardIosIcon  className={classes.hoverIcon}  />
            </Box>
            <Typography className={classes.people}>{configJSON.ownMessage}</Typography>
          </CardContent>
        </Card>
        <Card className={this.props.checked?classes.preferCardLight:classes.preferCard}>
          <CardContent>
            <Typography data-test-id='recommend' className={classes.posts}>{configJSON.recommends}</Typography>
            {this.state.recommendations.map((item) =>
              <Box key={item.id} className={classes.postsContainer}>
                <Typography className={classes.post}>{item.title}</Typography>
                <img data-test-id={`recommend${item.id}`} src={item.isClick ? unCheck : check} onClick={() => this.handleRecommendations(item.id,item.title,item.isClick)} />
              </Box>
            )}
          </CardContent>
        </Card>
        {this.renderNewsArrows(classes)}
        <Card className={this.props.checked?classes.preferCardLight:classes.preferCard}>
          <CardContent>
            <Typography className={classes.posts}>{configJSON.arrowProfessional}</Typography>
            <Box className={classes.postsContainer}>
              <Typography className={classes.post}>{configJSON.ads}</Typography>
              <img data-test-id='ads' src={this.state.add ? unCheck : check} onClick={this.setAdds} />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    )
  }

  // Customizable Area End

  render() {
      // Customizable Area Start
      const {classes}=this.props
      // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.activeOption === '' && <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.headingItem} >
            <div className={classes.headingContainer}>
            <div className={classes.arrowStyle}> <ArrowBackIosIcon onClick={this.navigateToSettings} className={classes.hoverIcon}/></div>
            <div className={classes.heading}><Typography data-test-id='notification' className={classes.notification}>{configJSON.notification}</Typography></div> 
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.selectText}>
              {configJSON.notificationRecommends}
            </Typography>
            <div className={classes.itemGap}>
            {this.state.settingsDetails.map((item) => <Grid key={item.id} data-test-id={`mute${item.id}`} onClick={() => this.navigateToOption(item.title)} item xs={12} container className={classes.itemContainer}>
              <Grid item xs={11} >
                <Box className={classes.iconBox}>
                  <img src={this.props.checked?item.iconDark:item.iconLight} />
                  <Box className={classes.contentGap}>
                    <Typography className={classes.titleText}>{item.title}</Typography>
                    <Typography className={classes.description}>{item.description}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <ArrowForwardIosIcon className={classes.hoverIcon}  
                />
              </Grid>
            </Grid>)}
            </div>
          </Grid>
        </Grid>}
        {this.state.activeOption === 'Muted notification' &&
         this.showMutedNotifications(classes)
          }
        {this.state.activeOption === 'Preferences' &&
          this.showPreferences(classes)
        }
        {this.state.activeOption === 'push notification' &&
          this.renderPushNotification(classes)
        }
        {this.state.activeOption === 'posts' &&
        this.showPostData(classes)  
        }
      </>
     
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = {
  submit: {
    background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        width:"140px",
        height:'44px',
        borderRadius:'10px',
        border:'none',
        color:"#fff",
        fontWeight:700,
        textTransform:'capitalize' as 'capitalize',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontFamily: "Manrope",
        fontSize: "14px",
        cursor:'pointer',
        textDecoration:'none'
    
  },
  cancel:{
    background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border :"1px solid transparent",
      borderRadius: "10px",
      textTransform: "capitalize" as "capitalize",
      color: "#8833FF",
      height:'44px',
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal" as 'normal',
      fontWeight: 700,
      padding:"16px", 
      boxShadow:"none !important",
      "&.MuiButton-root.Mui-disabled": {
        opacity:0.64
      },
      width:'140px'
  },
  cancelLightTheme:{
    width:'140px',
    fontFamily: "Manrope",
    fontSize: "14px",
    height:'44px',
    padding:"16px", 
    boxShadow:"none !important",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    background: `linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    color: "black", 
    "&.MuiButton-root.Mui-disabled": {
      opacity:0.64
    }, 
  },
  submitReport: {
    background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        width:"140px",
        height:'44px',
        borderRadius:'10px',
        border:'none',
        color:"#fff",
        fontWeight:700,
        textTransform:'capitalize' as 'capitalize',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontFamily: "Manrope",
        fontSize: "14px",
        cursor:'pointer',
        textDecoration:'none'
  },
  submitLike: {
    background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        width:"140px",
        height:'44px',
        borderRadius:'10px',
        border:'none',
        color:"#fff",
        fontWeight:700,
        textTransform:'capitalize' as 'capitalize',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontFamily: "Manrope",
        fontSize: "14px",
        cursor:'pointer',
        textDecoration:'none'
  },
  preferBox: {
    display: 'flex',
    flexDirection: 'column' as 'column'
  },
  checked: {
    cursor: 'pointer'
  },
  hideButton: {
  },
  modalDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center'
  },
  line: {
    width: '100%',
    border: "none",
  height: "1px",
  backgroundColor: "#222222"
  },
  linePerson: {
    width: '95%',
    border: "none",
  height: "1px",
  backgroundColor: "#222222"
  },
  lightThemeLine:{
    width: '100%',
    border: "none",
  height: "1px",
  backgroundColor: "#BABABA"
  },
  lightThemeLinePerson:{
    width: '95%',
    border: "none",
  height: "1px",
  backgroundColor: "#BABABA"
  },
  lineLike: {
    width: '100%',
    border: "none",
    height: "1px",
    backgroundColor: "#222222"
  },
  lineReport: {
    width: '100%',
    border: "none",
  height: "1px",
  backgroundColor: "#222222"
  },
  mentionsHeading: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    marginTop:'10px',
    marginBottom:'10px'
  },
  mentionsHeadingLight: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    color: 'white',
    marginTop:'10px',
    marginBottom:'10px'
  },
  mentionsHeadingLike: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    marginTop:'10px',
    marginBottom:'10px'
  },
  mentionsHeadingLightLike: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    color: 'white',
    marginTop:'10px',
    marginBottom:'10px'
  },
  mentionsHeadingReport: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    marginTop:'10px',
    marginBottom:'10px'
  },
  mentionsHeadingLightReport: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    color: 'white',
    marginTop:'10px',
    marginBottom:'10px'
  },
  paper: {
    backgroundColor: '#0B0B0B',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    borderRadius: '8px',
    minWidth: '290px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    border: '1px solid #35383F'
  },
  paperLight: {
    backgroundColor: '#FFFFFF',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    borderRadius: '8px',
    minWidth: '290px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    border: '1px solid #999999'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardLight: {
    boxShadow: 'none !important',
    backgroundColor: '#E9E9E9',
    borderRadius: '10px',
    border: '1px solid #5B5B5B',
    marginTop: '30px',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '0px !important',
    },
    '& .MuiCardContent-root': {
      padding: '15px !important',
    }

  },
  hoverIcon: {
    cursor: 'pointer',
  },
  persons: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    marginTop: '20px'
  },
  name: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
  },
  description: {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.4px',
    color:'#6B6B6B',
    marginTop:'10px'
  },
  iconsBox: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '12px',
  },
  profileBox: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    gap: '8px',
    cursor:'pointer'
  },
  people: {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.4px',
    color: '#6B6B6B'
  },
  posts: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
  },
  post: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
  },
  postReport: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
  },
  postLike: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
  },
  postLight: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
    color: 'white'
  },
  postLightReport: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
    color: 'white'
  },
  postLightLike: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
    color: 'white'
  },
  preferText: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    marginTop: '30px',
  },
  preferTextPush: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    marginTop: '30px'
  },
  filterText: {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.4px',
    marginTop: '20px',
    color:"#BABABA"
  },
  filterTextLight: {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.4px',
    marginTop: '20px',
    color:"#6B6B6B"
  },
  badgeText: {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.4px',
    color: '#6B6B6B',
    marginTop: '8px',
    marginBottom: '8px'
  },
  pushText: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
    marginTop: '8px'
  },
  unreadText: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.2px',
  },
  muteText: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    lineHeight: '21.6px',
    paddingLeft: '15px',
    marginTop: '15px'
  },
  iconTitle: {
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
  },
  card: {
    boxShadow: 'none !important',
    backgroundColor: '#181818',
    borderRadius: '10px',
    border: '1px solid #5B5B5B',
    marginTop: '30px',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '0px !important',
    },
    '& .MuiCardContent-root': {
      padding: '15px !important',
    }
  },
  preferCard: {
    boxShadow: 'none !important',
    backgroundColor: '#181818',
    borderRadius: '10px',
    border: '1px solid #5B5B5B',
    marginTop: '20px',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '15px !important',
    },
    '& .MuiCardContent-root': {
      padding: '15px !important',
    }

  },
  preferCardLight: {
    boxShadow: 'none !important',
    backgroundColor: '#E9E9E9',
    borderRadius: '10px',
    border: '1px solid #5B5B5B',
    marginTop: '20px',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '15px !important',
    },
    '& .MuiCardContent-root': {
      padding: '15px !important',
    }

  },
  preferCardPush: {
    boxShadow: 'none !important',
    backgroundColor: '#181818',
    borderRadius: '10px',
    border: '1px solid #5B5B5B',
    marginTop: '20px',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '15px !important',
    },
    '& .MuiCardContent-root': {
      padding: '15px !important',
    }

  },
  preferCardPushLight: {
    boxShadow: 'none !important',
    backgroundColor: '#E9E9E9',
    borderRadius: '10px',
    border: '1px solid #5B5B5B',
    marginTop: '20px',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '15px !important',
    },
    '& .MuiCardContent-root': {
      padding: '15px !important',
    }

  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px'
  },
  titleText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19.2px",
  },
  selectText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19.2px",
    marginTop: '32px'
  },
  title: {
    marginBottom: '100px'
  },
  notification: {
    fontFamily: "Manrope",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "28px",
    marginLeft: '2%'

  },
  descriptions: {
    marginTop: '5px',
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.4px',
    color: '#6B6B6B'
  },
  descriptionsPerson:{
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '5.4px',
    color: '#6B6B6B',
    marginBottom:'12px'
  },
  iconBox: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'flex-start',
    width: '100%',
    gap: '10px'

  },
  arrowStartText: {
    fontFamily: "Manrope",
    fontSize: "31px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    textAlign: 'left' as "left",
  },
  container: {
    width: '100%',
  },
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '5%',
    cursor:'pointer'
  },
  itemContainer: {
    marginTop: '24px',
    cursor:'pointer'
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switchContainer2: {
    marginTop:12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switchContainerPush: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor:'pointer'
  },
  postsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '12px',
    cursor:'pointer'
  },
  postsContainerMention: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    width: '100%',
  },
  postsContainerMentionReport: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    width: '100%',
  },
  postsContainerMentionLike: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    width: '100%',
  },
  postsContainerMentionPerson: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    width: '95%',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  mentionStyle:{
    cursor:'pointer'
  },
  btnStyles:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection:'row' as 'row',
    width:'100%',
    marginTop:"20px"
    
  },
  btnStylesPerson:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection:'row' as 'row',
    width:'95%',
    marginTop:"20px"
    
  },
  itemGap:{
    marginTop:'30px'
  },
  contentGap:{
    marginLeft:'10px'
  },

  headingContainer:{
    marginLeft:8,
    marginTop:10,
    display:'flex'
  },
   
  heading:{
    display:'flex',
    alignItems:'center',
  },

  arrowStyle:{
    display:'flex',
    alignItems:'center',
    height:48,
    width:40
  }
};
export const SettingsNotification=withStyles(styles)(SettingNotifications)
// Customizable Area End